.assets-components-SubscriptionManager-PlanSelector-index---subheader---Pdy-- {
  padding: var(--largeVertGutter) 0;
}

.assets-components-SubscriptionManager-PlanSelector-index---centerPlans---3wEwM {
  display: flex;
  justify-content: center;
  margin-bottom: var(--midVertGutter);
  gap: calc(2 * var(--smallHorzGutter));
  grid-template-columns: 1fr 1fr 1fr;
}

.assets-components-SubscriptionManager-PlanSelector-index---leftPlans---2jKlb {
  display: grid;
  margin-bottom: var(--midVertGutter);
  gap: calc(2 * var(--smallHorzGutter));
  grid-template-columns: 1fr 1fr 1fr;
}

.assets-components-SubscriptionManager-PlanSelector-index---centerButtons---AsZLL {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--hugeHorzGutter);
  gap: var(--largeHorzGutter);
}

.assets-components-SubscriptionManager-PlanSelector-index---rightButtons---2uw38 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: var(--midVertGutter) 0;
  border: unset;
}

.assets-components-SubscriptionManager-PlanSelector-index---caption---2dE4I {
  color: var(--inky400);
}

.assets-components-SubscriptionManager-PlanSelector-index---centerCaption---2eHSW {
  text-align: center;
}

.assets-components-SubscriptionManager-PlanSelector-index---buttonCancel---2U_Mn {
  margin-right: var(--smallVertGutter);
}
