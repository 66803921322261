.assets-components-LinkShortenerDomain-index---main---2JHwv {
  padding: var(--midVertGutter) 0 var(--largeVertGutter);
}

.assets-components-LinkShortenerDomain-index---buttonCancel---24vGW {
  margin-right: var(--smallVertGutter);
}

.assets-components-LinkShortenerDomain-index---rebrandlyDomain---3i_D0{
  display: flex;
}