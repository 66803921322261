.assets-pages-Dashboard-Grid-index---grid---2-w5A {
  -moz-column-gap: 20px;
       column-gap: 20px;
  -moz-column-width: 300px;
       column-width: 300px;
}

.assets-pages-Dashboard-Grid-index---item---3k9F9 {
  margin-bottom: 20px;
  -moz-column-break-inside: avoid;
       break-inside: avoid;
}

/**
 * Yuck... but I don't know what gives with Safari. It doesn't handle block items that do not break
 * the same way as the rest of the big boy browsers.
 *
 * @see https://stackoverflow.com/a/25975282/1858091
 **/

/* stylelint-disable */
_::-webkit-full-page-media,
_:future,
:root .assets-pages-Dashboard-Grid-index---item---3k9F9 {
  display: inline-block;
}
/* stylelint-enable */
