.assets-components-ArticleCard-index---card---z4HeE {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: var(--cardBorderRadius);
  box-shadow: 0 2px 10px -3px var(--inky150);
}

.assets-components-ArticleCard-index---card---z4HeE:hover {
  box-shadow: 0 2px 19px -2px var(--inky300);
}

.assets-components-ArticleCard-index---top---tMJe9 {
  border-bottom: 1px solid var(--inky150);
}

.assets-components-ArticleCard-index---body---3mkc1 {
  padding: var(--cardVertGutter) var(--midHorzGutter);
  color: var(--inky400);
  text-align: center;
}

.assets-components-ArticleCard-index---title---25pkE {
  margin-top: 0;
  margin-bottom: var(--tinyVertGutter);
  color: var(--inky500);
  font-weight: var(--semiBold);
}

.assets-components-ArticleCard-index---download---u3YSg {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 8px;
  margin: 10px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.assets-components-ArticleCard-index---download---u3YSg img {
  width: 18px;
}

.assets-components-ArticleCard-index---card---z4HeE:hover > .assets-components-ArticleCard-index---download---u3YSg {
  background: rgba(0, 0, 0, 0.2);
}
