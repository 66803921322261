.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-common-index---container---rmycr {
  display: flex;
  flex-wrap: wrap;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-common-index---primaryImage---2N2Aw {
  overflow: hidden;
  margin: 0.5px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-common-index---image---3RUXo {
  position: relative;
  overflow: hidden;
  margin: 0.5px;
}
