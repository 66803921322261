.assets-pages-Payment-PlanSelector-index---root---2rT8P {
  margin: var(--largeVertGutter) 0 var(--tinyVertGutter);
}

.assets-pages-Payment-PlanSelector-index---root---2rT8P li {
  min-height: 0;
}

.assets-pages-Payment-PlanSelector-index---root---2rT8P li > * {
  padding: var(--midVertGutter) 0 var(--midVertGutter) var(--smallHorzGutter);
}

.assets-pages-Payment-PlanSelector-index---root---2rT8P li[data-selected="true"] {
  border: 1px solid var(--kelp400);
  box-shadow: 0 2px 1px var(--inky200);
}

.assets-pages-Payment-PlanSelector-index---root---2rT8P li[data-selected="false"] {
  border: 1px solid transparent;
  background-color: var(--inky100);
}

.assets-pages-Payment-PlanSelector-index---savingsText---1Cg3w {
  margin-left: var(--tinyHorzGutter);
  color: var(--kelp400);
  font-size: var(--captionFontSize);
}
