@keyframes assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---fade---3ZgZo {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---root---3RMGP {
  width: 40px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.67);
  border-radius: 15px;
  color: white;
  letter-spacing: 2px;
  text-align: center;
}

/* See FADE_OUT_TIME in index.js, should match (delay + animation time) here */
.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---appearActive---3ZXnP,
.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---enterActive---ZUz-G {
  animation: assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---fade---3ZgZo 0.75s linear 1s forwards;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---exit---17Aar,
.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---exitActive---17Hbr,
.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---exitDone---29hWW,
.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---enterDone---3CYvp {
  opacity: 0;
}
