.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFive-index---container---2NAsJ {
  width: 480px;
  height: 410px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFive-index---primaryImage---21G-W {
  width: 236px;
  height: 252px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFive-index---image---1xEfc {
  width: 157px;
  height: 156px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFive-index---image---1xEfc > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFive-index---primaryImage---21G-W > div {
  border-radius: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFive-index---overlay---2x3Cm {
  line-height: 157px;
  font-size: 36px;
}
