.assets-pages-Composer-Actions-index---root---PAuJB {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--largeVertGutter);
  text-align: right;
}

.assets-pages-Composer-Actions-index---root---PAuJB > *:not(:first-child) {
  margin-left: var(--largeHorzGutter);
}

.assets-pages-Composer-Actions-index---saveContainer---1FG1Q {
  display: flex;
  align-items: stretch;
}

.assets-pages-Composer-Actions-index---saveContainer---1FG1Q a {
  height: 100%;
}
