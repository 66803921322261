.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Three-index---container---3oxVR {
  flex-direction: column;
  width: 504px;
  height: 338px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Three-index---primaryImage---1UPRz {
  width: 338px;
  height: 338px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Three-index---image---39Xao {
  width: 168px;
  height: 168px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Three-index---image---39Xao:nth-child(2) {
  border-top-right-radius: 5px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Three-index---image---39Xao:last-child {
  border-bottom-right-radius: 5px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Three-index---image---39Xao > div,
.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Three-index---primaryImage---1UPRz > div {
  border-radius: 0;
}
