@value var(--borderRadius): 10px;

.assets-components-TopNav-Flasher-index---root---26zHT {
  position: fixed;
  top: calc(44px + 1px);
  width: 100%;
  padding: 0;
  padding-bottom: 20px; /* reserve extra space for shadow otherwise animation is janky */
  margin: 0;
}

.assets-components-TopNav-Flasher-index---root---26zHT > div {
  display: block;
  padding: 0;
  border-top: solid 1px var(--inky300);
  margin: 0 auto;
  background-color: #fff;
  border-radius: 0 0 var(--borderRadius) var(--borderRadius);
  box-shadow: 0 0 2px 1px var(--inky300);
  list-style: none;
  opacity: 0.95;
}

.assets-components-TopNav-Flasher-index---hide---2dlgw {
  overflow: hidden;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-width: 0;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.assets-components-TopNav-Flasher-index---show---3pPLj {
  overflow: hidden;
  max-height: 1000px;
  transition-duration: 1s;
  transition-timing-function: ease-in;
}

@media (min-width: 600px) {
  .assets-components-TopNav-Flasher-index---root---26zHT {
    top: calc(55px + 50px - 1px);
  }
}

@media (min-width: 995px) {
  .assets-components-TopNav-Flasher-index---root---26zHT {
    top: 55px;
  }

  .assets-components-TopNav-Flasher-index---root---26zHT > div {
    width: 970px;
  }
}
