.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---root---1tf27 {
  height: 100%;
  box-sizing: content-box;
  margin-top: 10px;
  box-shadow: 0 0 0 1.5px rgba(0, 0, 0, 0.1) inset,
    0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---cols---eGFLa {
  display: flex;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---imageChecker---2XZah {
  position: absolute;
  left: -5000px;
  visibility: hidden;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---imageWrapper---31sDL {
  position: relative;
  overflow: hidden;
  width: 525px;
  height: 249px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---imageWrapper---31sDL::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  content: "";
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---cols---eGFLa .assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---imageWrapper---31sDL {
  width: 158px;
  min-width: 158px;
  height: 158px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---image---2vXXa {
  width: 100%;
  height: auto;
  min-height: 100%;
  line-height: 0;
  border-radius: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---details---1KYuP {
  height: auto;
  max-height: 100px;
  box-sizing: content-box;
  padding-top: var(--smallVertGutter);
  margin: 0 var(--midVertGutter) var(--smallHorzGutter);
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---meta---2ruvg {
  max-height: 80px;
  overflow-y: hidden;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---cols---eGFLa .assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---details---1KYuP {
  position: relative;
  overflow: hidden;
  height: 121px;
  max-height: 121px;
  padding-bottom: var(--midVertGutter);
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---cols---eGFLa .assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---meta---2ruvg {
  max-height: none;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---title---3QvmI {
  overflow: hidden;
  max-height: 110px;
  margin-bottom: var(--tinyVertGutter);
  line-height: var(--bodyLineHeight);
  font-family: Georgia, serif;
  font-size: var(--subheadingFontSize);
  font-weight: 500;
  word-wrap: break-word;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---cols---eGFLa .assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---title---3QvmI {
  max-height: 66px; /* 3 lines */
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---description---3hJxu {
  overflow: hidden;
  max-height: 80px;
  line-height: var(--bugLineHeight);
  font-size: var(--captionFontSize);
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---cols---eGFLa .assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---description---3hJxu {
  max-height: 48px; /* 3 lines */
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---domain---3ocuY {
  overflow: hidden;
  margin-top: var(--smallVertGutter);
  line-height: var(--bugFontSize);
  color: #90949c;
  font-size: var(--bugFontSize);
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---cols---eGFLa .assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-LinkPreview-index---domain---3ocuY {
  position: absolute;
  bottom: 0;
  left: 0;
}
