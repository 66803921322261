.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeThree-index---container---3B1c7 {
  width: 480px;
  height: 480px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeThree-index---primaryImage---1R0gw {
  width: 476px;
  height: 237px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeThree-index---image---1kzGH {
  width: 237px;
  height: 237px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeThree-index---image---1kzGH > div,
.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeThree-index---primaryImage---1R0gw > div {
  border-radius: 0;
}
