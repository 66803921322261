.assets-components-BulkEditTable-Delete-index---main---3QR9S {
  padding: var(--tinyHorzGutter) 0;
  text-align: center;
}

.assets-components-BulkEditTable-Delete-index---message---1r4tc {
  padding: var(--largeVertGutter) 0 var(--midVertGutter);
}

.assets-components-BulkEditTable-Delete-index---button---3iXkK {
  width: 200px;
}

.assets-components-BulkEditTable-Delete-index---pushRight---15392 {
  margin-left: auto;
}

.assets-components-BulkEditTable-Delete-index---buttonCancel---2Ouvu {
  margin-right: var(--smallVertGutter);
}
