.assets-components-ImportManager-RssFeedForm-index---colWrapper---1jsNr {
  display: grid;
  grid-template-columns: 38% 2% 2% 58%;
}

.assets-components-ImportManager-RssFeedForm-index---leftCol---2RUf7 {

}

.assets-components-ImportManager-RssFeedForm-index---rightCol---Vgt3w {

}

:first-of-type(div.assets-components-ImportManager-RssFeedForm-index---middleCol---1R50z) {
  border-right: solid 1px var(--inky200);
}

.assets-components-ImportManager-RssFeedForm-index---feedInfo---eRMo9 {
  padding: 1.5em;
  border: 1px solid rgb(230, 230, 230);
  background-color: rgb(241, 241, 241);
}

.assets-components-ImportManager-RssFeedForm-index---feedInfo---eRMo9 > h3 {
  padding: 0;
  margin: 0;
  line-height: 1.3em;
  color: rgb(88, 88, 88);
  font-weight: 400;
}

.assets-components-ImportManager-RssFeedForm-index---feedInfo---eRMo9 > p {
  line-height: 1.4em;
  color: rgb(88, 88, 88);
  font-weight: 100;
}

.assets-components-ImportManager-RssFeedForm-index---categoryField---yruPs {
  width: 100%;
  color: rgb(88, 88, 88);
}

.assets-components-ImportManager-RssFeedForm-index---lastImported---141oh {
  margin-bottom: 0.8em;
  color: rgb(160, 160, 160);
  font-size: 0.8em;
  font-weight: 300;
}

.assets-components-ImportManager-RssFeedForm-index---rssFeedFormRadioButtonWrapper---2X_Jy {
  display: flex;
  flex-wrap: wrap;
}

.assets-components-ImportManager-RssFeedForm-index---rssFeedFormRadioButton---24cnb {
  align-items: center;
}

.assets-components-ImportManager-RssFeedForm-index---rssFeedFormRadioButton---24cnb label {
  /* weird issue here where the radio button background (white dot) goes off-center if a width < 95% is not set on the label */
  width: 90%;
  padding-bottom: 0.2em;
  margin-bottom: 0;
  margin-left: 0.5em;
  font-size: 0.95em;
  font-weight: 400;
  vertical-align: middle;
}

.assets-components-ImportManager-RssFeedForm-index---accountSelect---2HXvV {
  margin-bottom: 2em;
}

.assets-components-ImportManager-RssFeedForm-index---delete---320Uu {
  float: left;
}

.assets-components-ImportManager-RssFeedForm-index---useOnceLabel---3LLOT {
  font-weight: normal;
}

.assets-components-ImportManager-RssFeedForm-index---useOnceCaption---1VllX {
  margin-left: var(--checkboxOffset);
}

.assets-components-ImportManager-RssFeedForm-index---secondaryActions---bF6J6 {
  margin-right: var(--midHorzGutter);
}
