.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Header-ProfilePicture-index---root---2K-iy {
  flex-grow: 0;
  width: 20px;
  height: 20px;
  padding: 0 var(--tinyHorzGutter);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Header-ProfilePicture-index---image---1m3Pn {
  width: 100%;
  border-radius: 20px;
}
