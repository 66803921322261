.assets-pages-Composer-Actions-SeeMorePendingContentDialog-index---root---1pkRW {
  text-align: center;
}

.assets-pages-Composer-Actions-SeeMorePendingContentDialog-index---title---2HqPN {
  margin-bottom: var(--largeVertGutter);
}

.assets-pages-Composer-Actions-SeeMorePendingContentDialog-index---message---3nBIf {
  display: block;
  margin-bottom: var(--hugeVertGutter);
}
