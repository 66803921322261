.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Header-ProfilePicture-index---root---3sDvl {
  flex-grow: 0;
  width: 40px;
  height: 40px;
  padding: 0 var(--tinyHorzGutter);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Header-ProfilePicture-index---image---1Cao0 {
  width: 100%;
  border-radius: 20px;
}
