.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeThree-index---container---QLQQb {
  width: 480px;
  height: 480px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeThree-index---primaryImage---1ApxT {
  width: 476px;
  height: 237px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeThree-index---image---3xosm {
  width: 237px;
  height: 237px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeThree-index---image---3xosm > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeThree-index---primaryImage---1ApxT > div {
  border-radius: 0;
}
