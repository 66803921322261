.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-index---root---3DxFF {
  position: relative;
  margin-top: 10px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-index---root---3DxFF::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-index---video---3q787,
.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-index---singleImage---3EhmT {
  width: 100%;
}
