.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Actions-Paginator-Indicator-index---root---3gtXH {
  display: inline-block;
  background-color: var(--instagramLightGray);
  border-radius: 50%;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Actions-Paginator-Indicator-index---large---3EHBD,
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Actions-Paginator-Indicator-index---active---2Rf72 {
  width: 6px;
  height: 6px;
  margin: 2px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Actions-Paginator-Indicator-index---medium---1ICOZ {
  width: 4px;
  height: 4px;
  margin: 3px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Actions-Paginator-Indicator-index---small---1uDtJ {
  width: 3px;
  height: 3px;
  margin: 4px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Actions-Paginator-Indicator-index---active---2Rf72 {
  background-color: #3897f0;
}
