.assets-pages-ScheduleOld-EmptyState-index---root---3egj4 {
  margin-top: var(--hugeVertGutter);
  text-align: center;
}

.assets-pages-ScheduleOld-EmptyState-index---octoImage---ueDUh {
  display: block;
  width: 50%;
  margin: var(--hugeVertGutter) auto;
}
