.assets-components-common-forms-LegacyField-index---field---13dsT {
  margin-bottom: 2em;
}

.assets-components-common-forms-LegacyField-index---field---13dsT > input {
  display: block;
  width: 100%;
  padding: 0.1em 0.25em;
  border: 1px solid rgb(229, 229, 229);
  font-size: 2em;
}

.assets-components-common-forms-LegacyField-index---field---13dsT > label {
  display: block;
  width: 100%;
  font-weight: 500;
}

.assets-components-common-forms-LegacyField-index---fieldWithError---kr4my {
  position: relative;
}

.assets-components-common-forms-LegacyField-index---fieldWithError---kr4my::before {
  position: absolute;
  top: 0;
  left: -2em;
  display: block;
  width: 3px;
  height: 100%;
  background-color: rgb(255, 126, 141);
  content: " ";
}

.assets-components-common-forms-LegacyField-index---fieldWithError---kr4my > input {
  color: rgb(195, 0, 22);
}
