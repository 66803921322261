
.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Text-index---root---1vJKI {
  margin-bottom: 0;
  line-height: 1.38em;
  font-family: var(--fontMeta);
  font-size: 14px;
  font-weight: normal;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Text-index---big---1OgIL {
  line-height: 28px;
  font-family: var(--fontMeta);
  font-size: 24px;
  font-weight: 300;
  letter-spacing: normal;
}

/* Use consecutive newLines to simulate <p>s by styling second to have height */
.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Text-index---newLine---tHs4A + .assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Text-index---newLine---tHs4A {
  display: block;
  height: 12px !important;
  line-height: 0 !important;
  content: ' ' !important;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Text-index---root---1vJKI a {
  color: var(--fbLinkBlue);
  cursor: pointer;
  text-decoration: none;
  word-wrap: break-word;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Text-index---root---1vJKI a:hover {
  text-decoration: underline;
}
