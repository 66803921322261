
.assets-components-PaymentMethod-index---form---204Th {
  padding-bottom: var(--largeVertGutter);
  border-bottom: 1px solid var(--inky200);
}

.assets-components-PaymentMethod-index---formRow---3FZeh,
.assets-components-PaymentMethod-index---formErrors---1Daub,
.assets-components-PaymentMethod-index---creditCardNumber---3yUXf,
.assets-components-PaymentMethod-index---creditCardExpMonth---h4iMY,
.assets-components-PaymentMethod-index---creditCardExpYear---3OBgh {
  margin-bottom: var(--largeVertGutter);
}

.assets-components-PaymentMethod-index---formErrors---1Daub > div {
  width: 100%;
  margin-bottom: var(--smallVertGutter);
}

@media (min-width: 600px) {
  .assets-components-PaymentMethod-index---formRow---3FZeh {
    display: flex;
    width: 100%;
  }

  .assets-components-PaymentMethod-index---formRow---3FZeh > div {
    width: 100%;
  }

  .assets-components-PaymentMethod-index---formErrors---1Daub,
  .assets-components-PaymentMethod-index---creditCardNumber---3yUXf,
  .assets-components-PaymentMethod-index---creditCardExpMonth---h4iMY,
  .assets-components-PaymentMethod-index---creditCardExpYear---3OBgh {
    margin-bottom: 0;
  }

  .assets-components-PaymentMethod-index---creditCardNumber---3yUXf {
    flex: 3 0;
    margin-right: var(--smallHorzGutter);
  }

  .assets-components-PaymentMethod-index---creditCardVerificationNumber---35rtB {
    flex: 1 0;
    margin-left: var(--smallHorzGutter);
  }

  .assets-components-PaymentMethod-index---creditCardVerificationNumber---35rtB input {
    width: calc(100% - 3px);
    margin-left: 3px; /* removes three borders' worth to align w/ CC num */
  }

  .assets-components-PaymentMethod-index---creditCardExpMonth---h4iMY {
    flex: 4 0;
    margin-right: var(--tinyHorzGutter);
  }

  .assets-components-PaymentMethod-index---creditCardExpYear---3OBgh {
    flex: 2 0;
    padding-top: var(--display2FontSize); /* filler for "missing" label */
    margin-right: var(--smallHorzGutter);
    margin-left: var(--tinyHorzGutter);
  }

  .assets-components-PaymentMethod-index---creditCardExpYear---3OBgh select {
    width: calc(100% - 3px); /* removes three borders' worth to align w/ CC num */
  }

  .assets-components-PaymentMethod-index---creditCardPostal---1Q7Mh {
    flex: 2 0;
    margin-left: var(--smallHorzGutter);
  }
}
