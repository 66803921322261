.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeTwo-index---container---1peK0 {
  width: 480px;
  height: 480px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeTwo-index---image---1H6iQ {
  width: 476px;
  height: 237px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeTwo-index---image---1H6iQ > div {
  border-radius: 0;
}
