.assets-pages-UserProfile-index---loading---1GIjF {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-pages-UserProfile-index---container---w4zuI {
  border: 1px solid var(--inky200); /* TODO: This may need to be lighter via Dashboard PR. */
  margin-top: calc(2 * var(--smallVertGutter));
  background-color: #fff;
  border-radius: var(--elementBorderRadius);
}

.assets-pages-UserProfile-index---section---2Iols {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  border-top: 1px solid var(--inky200); /* TODO: This may need to be lighter via Dashboard PR. */
}

.assets-pages-UserProfile-index---sectionAction---kNoNR {
  display: flex;
  align-items: flex-end;
}

.assets-pages-UserProfile-index---section---2Iols:first-child {
  border-top: none;
}

.assets-pages-UserProfile-index---sectionButtonCancel---1Bm-v {
  margin-right: var(--tinyHorzGutter);
}

.assets-pages-UserProfile-index---sectionField---3-wAe {
  width: 60%;
  margin-top: var(--largeVertGutter);
}

.assets-pages-UserProfile-index---sectionField---3-wAe:first-child {
  margin-top: 0;
}

.assets-pages-UserProfile-index---sectionFieldAction---2pt_b {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  width: auto;
}

.assets-pages-UserProfile-index---sectionFieldLabelWrapper---2sKDU {
  display: flex;
  align-items: center;
}

.assets-pages-UserProfile-index---sectionFieldLabelRequired---1q9IQ::after {
  color: var(--coral500);
  content: '\0020\002A';
}

.assets-pages-UserProfile-index---sectionFieldRequired---3YFZs {
  display: block;
  margin-left: var(--tinyHorzGutter);
  color: var(--coral500);
  font-size: var(--captionFontSize);
}

.assets-pages-UserProfile-index---sectionFieldDescription---11unS {
  display: block;
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-pages-UserProfile-index---sectionFieldInput---1hSxk {
  margin-top: var(--tinyVertGutter);
}

.assets-pages-UserProfile-index---sectionFieldInputInvalid---1fYIC,
.assets-pages-UserProfile-index---sectionFieldInputInvalid---1fYIC:focus {
  border-color: var(--dangerColor);
  outline-color: var(--dangerColor);
}

.assets-pages-UserProfile-index---saveButton---1HJy6 {
  min-width: var(--previewImageSize);
}
