.assets-pages-Composer-CategorySelect-index---root---3Vr7q {
  margin-bottom: var(--midVertGutter);
}

.assets-pages-Composer-CategorySelect-index---labelContainer---3hcVX {
  margin-bottom: var(--tinyVertGutter);
}

.assets-pages-Composer-CategorySelect-index---select---3_Vfp {
  display: inline-block;
  width: 100%;
}

.assets-pages-Composer-CategorySelect-index---errorText---3tj3G {
  color: var(--dangerColor);
}
