.assets-components-Accounts-ProviderList-index---loading---3mw4Z {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-components-Accounts-ProviderList-index---container---1wD9p {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.assets-components-Accounts-ProviderList-index---container---1wD9p a {
  text-decoration: none;
}

.assets-components-Accounts-ProviderList-index---socialContainer---cmSUk {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
  padding: var(--tinyVertGutter) var(--midHorzGutter);
  margin-bottom: var(--midVertGutter);
  background-color: white;
  border-radius: var(--elementBorderRadius);
  cursor: pointer;
  text-decoration: none;
}

.assets-components-Accounts-ProviderList-index---socialContainer---cmSUk.assets-components-Accounts-ProviderList-index---facebook---6WX9O {
  background-color: var(--facebook);
}

.assets-components-Accounts-ProviderList-index---socialContainer---cmSUk.assets-components-Accounts-ProviderList-index---linkedin---3MDcr {
  background-color: var(--linkedin);
}

.assets-components-Accounts-ProviderList-index---socialContainer---cmSUk.assets-components-Accounts-ProviderList-index---instagram---33CT2 {
  background-color: var(--instagram);
}

.assets-components-Accounts-ProviderList-index---socialContainer---cmSUk.assets-components-Accounts-ProviderList-index---instagram_professional---21qOD {
  background-color: var(--instagram);
}

.assets-components-Accounts-ProviderList-index---socialContainer---cmSUk.assets-components-Accounts-ProviderList-index---instagram_business---15Cjz {
  background-color: var(--facebook);
}

.assets-components-Accounts-ProviderList-index---socialContainer---cmSUk.assets-components-Accounts-ProviderList-index---pinterest---plWHp {
  background-color: var(--pinterest);
}

.assets-components-Accounts-ProviderList-index---socialContainer---cmSUk.assets-components-Accounts-ProviderList-index---twitter---1xbKh {
  background-color: var(--twitter);
}

.assets-components-Accounts-ProviderList-index---socialContainer---cmSUk.assets-components-Accounts-ProviderList-index---x---jdznX {
  background-color: var(--x);
}

.assets-components-Accounts-ProviderList-index---socialContainer---cmSUk.assets-components-Accounts-ProviderList-index---google---1TuqV {
  background-color: #4079d8;
}

.assets-components-Accounts-ProviderList-index---socialContainer---cmSUk.assets-components-Accounts-ProviderList-index---tiktok---2W8Lg {
  background-color: var(--tiktok); /* color 2 */
}

.assets-components-Accounts-ProviderList-index---socialContainer---cmSUk.assets-components-Accounts-ProviderList-index---threads---17XdK {
  background-color: var(--threads);
}

.assets-components-Accounts-ProviderList-index---socialContainer---cmSUk.assets-components-Accounts-ProviderList-index---loadingButton---1j4yl {
  cursor: wait;
  opacity: 0.5;
}

.assets-components-Accounts-ProviderList-index---socialIcon---IjEyx {
  margin-right: var(--smallHorzGutter);
  color: white;
  font-size: 36px;
}

.assets-components-Accounts-ProviderList-index---socialImg---2c3IR {
  width: 31.5px;
  margin: 1px var(--smallHorzGutter) 3px 0;
  border-radius: 3px;
}

.assets-components-Accounts-ProviderList-index---providerText---1jECT {
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: capitalize;
}

.assets-components-Accounts-ProviderList-index---socialContainer---cmSUk.assets-components-Accounts-ProviderList-index---google---1TuqV .assets-components-Accounts-ProviderList-index---providerText---1jECT {
  color: #fff;
}

.assets-components-Accounts-ProviderList-index---instagramModalTitle---1em4A {
  font-weight: 700;
  font-size: 16px;
}
.assets-components-Accounts-ProviderList-index---instagramModalSubtitle---25of2 {
  margin-bottom: var(--largeVertGutter);
  font-size: 13px;
}

.assets-components-Accounts-ProviderList-index---instagramModalSection---2RfXC {
  margin-bottom: var(--largeVertGutter);
  padding-left: 10px;
}

.assets-components-Accounts-ProviderList-index---instagramModalCaption---2HHL3 {
  padding-bottom: var(--smallVertGutter);
}

.assets-components-Accounts-ProviderList-index---instagramModalButton---t-vLb {
  width: 100%;
}
