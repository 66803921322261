.assets-components-common-content-common-CategoryBar-index---root---CXQv0 {
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 0 var(--largeHorzGutter);
}

.assets-components-common-content-common-CategoryBar-index---root---CXQv0 > * {
  flex: 0 0 auto;
}

.assets-components-common-content-common-CategoryBar-index---root---CXQv0 > *:first-child {
  max-width: 300px;
}
