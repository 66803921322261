.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareThree-index---container---XOxNJ {
  width: 478px;
  height: 161px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareThree-index---image---28hLm {
  width: 157px;
  height: 159px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareThree-index---image---28hLm > div {
  border-radius: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareThree-index---overlay---3GuGS {
  line-height: 160px;
  font-size: 28px;
}
