.assets-components-common-icons-NewAccountBadge-index---root---2iGh9 {
  position: relative;
  display: flex;
  margin: 0 6px 0 0;
  line-height: 0;
}

.assets-components-common-icons-NewAccountBadge-index---avatar---1e6HL {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.assets-components-common-icons-NewAccountBadge-index---platformIcon---3OX1V {
  position: absolute;
  right: -2px;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-radius: var(--elementBorderRadius);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.assets-components-common-icons-NewAccountBadge-index---tipBody---3WNn7 {
  font-family: var(--primaryFont);
}

.assets-components-common-icons-NewAccountBadge-index---tipCaption---55qub {
  overflow: hidden;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
}
