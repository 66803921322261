
.assets-components-Footer-index---root---1CkQE {
  margin: var(--giganticVertGutter) auto;
  text-align: center;
}

.assets-components-Footer-index---footer---2AHXG {
  font-weight: var(--normalWeight);
}

.assets-components-Footer-index---footer---2AHXG a {
  margin: 0 var(--tinyHorzGutter);
}
