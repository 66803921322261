
.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsError-index---root---2JM7Z {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--tinyVertGutter) var(--midHorzGutter) var(--miniVertGutter);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsError-index---rootFocused---2-mrr {
  background-color: var(--whale100);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsError-index---banIcon---1G0QS {
  flex-basis: 30px;
  align-self: flex-start;
  padding-right: var(--tinyHorzGutter);
  color: var(--coral400);
  font-size: var(--display1FontSize);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsError-index---imageContainer---19xP3 {
  flex: 1;
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsError-index---image---1L5k3 {
  display: inline-block;
  width: auto;
  height: auto;
  border-radius: var(--elementBorderRadius);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsError-index---textContainer---1yo2S {
  line-height: var(--captionLineHeight);
  font-size: var(--captionFontSize);
}
