.assets-pages-NewComposer-Variation-Attachments-LinkPreviewAttachmentToggle-RestoreLinkPreviewAttachment-index---root---3-fBL {
  margin-bottom: var(--smallVertGutter);
}

.assets-pages-NewComposer-Variation-Attachments-LinkPreviewAttachmentToggle-RestoreLinkPreviewAttachment-index---restoreLinkPreviewLinkOverrides---3czyQ,
.assets-pages-NewComposer-Variation-Attachments-LinkPreviewAttachmentToggle-RestoreLinkPreviewAttachment-index---restoreLinkPreviewLinkOverrides---3czyQ:visited {
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-pages-NewComposer-Variation-Attachments-LinkPreviewAttachmentToggle-RestoreLinkPreviewAttachment-index---disabledRestoreLinkPreviewContainer---3_X1D {
  opacity: var(--disabledOpacity);
}
