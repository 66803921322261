.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-LoadingLine-index---root---2PeoT {
  height: 4px;
  width: calc(100% - 26px);
  background-color: #b9acac;
  position: absolute;
  z-index: 2;
  top: 9px;
  left: 13px;
  right: 13px;
}
