
.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Actions-index---root---_IfzK {
  padding: 0 var(--tinyHorzGutter);
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Actions-index---instaButton---3nP7p {
  width: 20px;
  margin: var(--smallVertGutter) var(--smallHorzGutter);
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Actions-index---bookmark---25m08 {
  float: right;
}
