.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-index---root---2i00c {
  width: var(--instagramPreviewWidth);
  color: var(--instagramBlack);
  font-family: var(--instagramFontStack);
  position: relative;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-index---carousel---1g0CX {
  position: relative;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-index---scroller---36szB {
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 500px;
  margin-top: -15px;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-index---imageXOfYIndicator---UvE4i {
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
}
