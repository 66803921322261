.assets-components-common-buttons-ComboButton-index---root---xTP9M {
  display: flex;
}

.assets-components-common-buttons-ComboButton-index---root---xTP9M .assets-components-common-buttons-ComboButton-index---primaryOption---78GVB {
  margin-right: 1px;
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px;
}

.assets-components-common-buttons-ComboButton-index---root---xTP9M .assets-components-common-buttons-ComboButton-index---dropDownButton---3u6E- {
  border-bottom-left-radius: 1px;
  border-top-left-radius: 1px;
}
