.assets-components-common-Table-index---root---34eY9 {
  border: solid 1px var(--inky200);
  border-collapse: collapse;
  border-spacing: 0;
}

.assets-components-common-Table-index---root---34eY9 > thead > tr > th,
.assets-components-common-Table-index---root---34eY9 > tbody > tr > td {
  padding: var(--tinyVertGutter) var(--tinyHorzGutter);
  text-align: left;
}

.assets-components-common-Table-index---root---34eY9 > thead > tr > th {
  background-color: var(--inky50);
}

.assets-components-common-Table-index---root---34eY9 > tbody > tr:nth-child(2n) > td {
  background-color: var(--inky50);
}
