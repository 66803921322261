.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Two-index---container---3KQee {
  width: 551px;
  height: 551px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Two-index---image---3yTpY {
  width: 274px;
  height: 551px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Two-index---image---3yTpY > div {
  border-radius: 0;
}
