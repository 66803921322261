.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareFour-index---container---2AdES {
  width: 478px;
  height: 478px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareFour-index---image---OOkJG {
  width: 237px;
  height: 237px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareFour-index---image---OOkJG > div {
  border-radius: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareFour-index---overlay---nVM5- {
  line-height: 237px;
  font-size: 36px;
}
