.assets-pages-Payment-Testimonials-Pager-index---dot---1nRI_ {
  color: var(--sanddollar400);
  font-size: 48px;
  letter-spacing: 10px;
}

.assets-pages-Payment-Testimonials-Pager-index---dot---1nRI_:hover {
  cursor: pointer;
}

.assets-pages-Payment-Testimonials-Pager-index---active---2NdLk {
  color: var(--sanddollar600);
  font-weight: bold;
}
