.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-index---root---ZRsuq {
  position: relative;
  margin-top: 10px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-index---root---ZRsuq::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-index---video---1ENsm,
.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-index---singleImage---m7hEH {
  width: 100%;
}
