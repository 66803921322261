
.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Actions-index---root---1ktp0 {
  padding: 0 var(--tinyHorzGutter);
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Actions-index---instaButton---3ry37 {
  width: 30px;
  margin: var(--smallVertGutter) var(--smallHorzGutter);
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Actions-index---bookmark---3Ay3W {
  float: right;
}
