.assets-pages-ScheduleOld-ScheduleModal-index---modalBody---uFRIm {
  display: flex;
  flex-direction: row;
}

.assets-pages-ScheduleOld-ScheduleModal-index---modalFooter---plGJZ {
  display: flex;
  flex-direction: row-reverse;
}

.assets-pages-ScheduleOld-ScheduleModal-index---dayOptions---1lTQH {
  display: flex;
  justify-content: space-between;
}

.assets-pages-ScheduleOld-ScheduleModal-index---accountSelect---1fA-O {
  flex: 1;
  max-width: 280px;
  padding-top: var(--midVertGutter);
  border-right: 1px solid var(--inky100);
}

.assets-pages-ScheduleOld-ScheduleModal-index---sendAtAndCategory---BKqTi {
  flex: 4;
  overflow: hidden;
  padding-top: var(--midVertGutter);
  margin-left: var(--midHorzGutter);
}

.assets-pages-ScheduleOld-ScheduleModal-index---sendAtAndCategory---BKqTi > * {
  margin-bottom: var(--midVertGutter);
}

.assets-pages-ScheduleOld-ScheduleModal-index---sendAtAndCategoryLabel---TA93R {
  margin-bottom: var(--tinyVertGutter);
}

.assets-pages-ScheduleOld-ScheduleModal-index---sendAt---2GxWu {
  display: flex;
  align-items: center;
}

.assets-pages-ScheduleOld-ScheduleModal-index---time---1ORWh {
  flex-basis: 120px;
  margin-right: var(--tinyHorzGutter);
}

.assets-pages-ScheduleOld-ScheduleModal-index---day---3s2k3 {
  flex: 1;
  margin-left: var(--tinyHorzGutter);
}

.assets-pages-ScheduleOld-ScheduleModal-index---secondaryActions---3unrQ {
  margin-right: var(--midHorzGutter);
  display: flex;
}

@media (max-width: 700px) {
  .assets-pages-ScheduleOld-ScheduleModal-index---modalBody---uFRIm {
    flex-direction: column-reverse;
  }

  .assets-pages-ScheduleOld-ScheduleModal-index---accountSelect---1fA-O {
    max-width: 100%;
    border-right: none;
  }

  .assets-pages-ScheduleOld-ScheduleModal-index---sendAt---2GxWu {
    flex-direction: column;
    align-items: flex-start;
  }

  .assets-pages-ScheduleOld-ScheduleModal-index---time---1ORWh {
    width: 100%;
    margin: 0;
  }

  .assets-pages-ScheduleOld-ScheduleModal-index---day---3s2k3 {
    width: 100%;
    margin: 0;
  }
}
