.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-ByLine-index---root---1IGC3 {
  display: flex;
  align-items: flex-start;
  margin: var(--midVertGutter) 0 var(--midVertGutter);
  font-size: var(--captionFontSize);
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-ByLine-index---name---1CiE5 {
  display: block;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-ByLine-index---followers---37zqF {
  display: block;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-ByLine-index---byLine---2hSFz {
  flex-grow: 1;
  line-height: var(--bugLineHeight);
  cursor: pointer;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-ByLine-index---followButton---2ROpc {
  padding: 0 14px;
  line-height: 34px;
  background-color: var(--inky100);
  border-radius: var(--largeBorderRadius);
  color: var(--inky500);
  font-weight: 700;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-ByLine-index---followButton---2ROpc:hover {
  background-color: #ad081b;
  cursor: pointer;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-ByLine-index---image---1pqtR {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  border-radius: 50%;
}
