.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-common-index---container---375Fp {
  display: flex;
  flex-wrap: wrap;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-common-index---primaryImage---1nw5P {
  overflow: hidden;
  margin: 0.5px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-common-index---image---2xoVV {
  position: relative;
  overflow: hidden;
  margin: 0.5px;
}
