.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-index---toggleAll---1HmEM {
  padding: 0 0 20px;
}

@media (min-width: 700px) {
  .assets-pages-NewComposer-AccountSelect-BaseAccountSelect-index---toggleAll---1HmEM {
    padding: 0 20px 20px;
  }
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-index---toggleAll---1HmEM > label {
  margin-left: 0;
  font-weight: 400;
}
