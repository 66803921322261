.assets-pages-NewComposer-Variation-PostPreview-Post-HelpText-index---root---2QAWl {
  display: flex;
  margin-top: var(--midVertGutter);
  text-align: left;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-HelpText-index---infoIcon---1SJ-I {
  margin-top: 3px;
  margin-right: var(--tinyHorzGutter);
}
