.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---z1000---2f56w {
    z-index: 1000 !important;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---ml0---tyct1 {
    margin-left: 0;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---h100---2J-9w {
    height: 100%;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---textBlack---PPOWJ {
    color: #000;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---gap8---a33nN {
    display: flex;
    gap: 8px;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---gap40---1u31v {
    display: flex;
    gap: 40px;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---itemsCenter---2S40h {
    align-items: center;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---nml2---3k3iO {
    margin-left: -2px;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---nml8---zdmA9 {
    margin-left: -8px;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---repeatText---PDqlC {
    width: -moz-max-content;
    width: max-content;
    color: #000;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---numInput---1OOUt {
    width: 50px;
    padding-block: 7px;
    background-color: #f0f0f0;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
    text-align: center;
    height: 36px;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---numInput---1OOUt:focus-visible {
    outline: none;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---popper---2b4Xi {
    margin: 0 0 0 40px;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---datePicker---1N7cr {
    opacity: 60% !important;
    cursor: not-allowed !important;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---dateInput---3F6KG {
    padding: 7px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---endSection---OUl-h {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---repeatSection---3H2Pu {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---body---2HJzN {
    display: flex;
    flex-direction: column !important;
    gap: 16px;
    overflow: hidden;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---customRadio---2yTlu {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---radioBtn---3YJBG {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #f4f4f4;
    border-radius: 50%;
    border: 2px solid #ddd;
    /* Border color */
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---radioBtn---3YJBG.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---checked---1jyS5 {
    background-color: var(--kelp400);
    /* Background color when checked */
    border-width: 1px;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---radioBtn---3YJBG::after {
    content: "";
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---radioBtn---3YJBG.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---checked---1jyS5::after {
    display: block;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---onOption---m3LWb {
    height: 100%;
    top: 25%;
}

.assets-pages-Schedule-ScheduleNewModal-CustomDateModal-index---CustomDropdown---3OhFi {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 36px;
}