.assets-components-common-AccountSelect-Option-index---root---30c_4 {
  display: flex;
  flex: 1;
  align-items: flex-start;
  overflow: hidden;
  min-width: 50%;
  max-width: 50%;
  padding: var(--midVertGutter) 0;
  border-radius: var(--elementBorderRadius);
  cursor: pointer;
  text-overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

@media (min-width: 700px) {
  .assets-components-common-AccountSelect-Option-index---root---30c_4 {
    padding: var(--midVertGutter) var(--midHorzGutter);
  }
}

.assets-components-common-AccountSelect-Option-index---root---30c_4:hover {
  background-color: var(--inky100);
}

.assets-components-common-AccountSelect-Option-index---root---30c_4[disabled] {
  opacity: var(--disabledOpacity);
}

.assets-components-common-AccountSelect-Option-index---root---30c_4[disabled]:hover {
  background-color: white;
}

.assets-components-common-AccountSelect-Option-index---fullWidth---3Rrgz {
  min-width: 100%;
}

.assets-components-common-AccountSelect-Option-index---tooltipped---3Cgui {
  min-width: 50%;
  max-width: 50%;
}

.assets-components-common-AccountSelect-Option-index---account---TDP1x {
  flex: 1;
  overflow: hidden;
}

.assets-components-common-AccountSelect-Option-index---accountInfo---1Cwxs {
  display: flex;
  overflow: hidden;
  width: 100%;
}

.assets-components-common-AccountSelect-Option-index---details---2hPpk {
  flex: 1;
  overflow: hidden;
  padding-right: var(--midHorzGutter);
}

.assets-components-common-AccountSelect-Option-index---name---r13hS {
  line-height: 20px; /* prevent low descending characters from getting clipped */
}

.assets-components-common-AccountSelect-Option-index---name---r13hS,
.assets-components-common-AccountSelect-Option-index---caption---hbzLu {
  overflow: hidden;
  width: 11.4em;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assets-components-common-AccountSelect-Option-index---providerOptions---2IYPs {
  width: 100%;
  cursor: default;
}

.assets-components-common-AccountSelect-Option-index---providerOptions---2IYPs > * {
  margin-top: var(--tinyVertGutter);
}

.assets-components-common-AccountSelect-Option-index---input---1RFH1 {
  padding-top: var(--smallVertGutter);
}
