.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Three-index---container---3AH8E {
  width: 551px;
  height: 551px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Three-index---primaryImage---W1Byz {
  width: 551px;
  height: 330px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Three-index---image---2zlr7 {
  width: 274px;
  height: 221px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Three-index---image---2zlr7 > div,
.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Three-index---primaryImage---W1Byz > div {
  border-radius: 0;
}
