.assets-components-UtmParametersSettings-Option-index---service---a_HTW {
  display: flex;
}

.assets-components-UtmParametersSettings-Option-index---service---a_HTW ~ .assets-components-UtmParametersSettings-Option-index---service---a_HTW {
  margin-top: var(--smallVertGutter);
}

.assets-components-UtmParametersSettings-Option-index---radioButton---1Fwc1 {
  margin: var(--tinyHorzGutter) var(--smallHorzGutter) 0 0;
}

.assets-components-UtmParametersSettings-Option-index---caption---2aXrE {
  color: var(--inky400);
  font-size: var(--captionFontSize);
}
