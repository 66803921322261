.assets-components-common-ScaledImage-index---root---prfiW {
  display: inline-block;
  overflow: hidden;
  width: var(--previewImageSize);
  height: var(--previewImageSize);
  background-color: var(--inky100);
  border-radius: var(--elementBorderRadius);
}

.assets-components-common-ScaledImage-index---altText---3d6iF {
  padding: 0 var(--tinyHorzGutter);
  margin-top: var(--tinyVertGutter);
}
