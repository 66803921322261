.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Three-index---container---Xx-zT {
  flex-direction: column;
  width: 504px;
  height: 338px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Three-index---primaryImage---3hAfP {
  width: 338px;
  height: 338px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Three-index---image---1eA32 {
  width: 168px;
  height: 168px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Three-index---image---1eA32:nth-child(2) {
  border-top-right-radius: 5px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Three-index---image---1eA32:last-child {
  border-bottom-right-radius: 5px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Three-index---image---1eA32 > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Three-index---primaryImage---3hAfP > div {
  border-radius: 0;
}
