.assets-pages-Payment-Testimonials-Endorsement-index---root---NljBY {
  position: absolute;
  top: 80px;
  left: 0;
}

@media (max-height: 850px) {
  .assets-pages-Payment-Testimonials-Endorsement-index---root---NljBY {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.assets-pages-Payment-Testimonials-Endorsement-index---inactive---JZNCx {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.assets-pages-Payment-Testimonials-Endorsement-index---active---2AWhU {
  display: block;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.assets-pages-Payment-Testimonials-Endorsement-index---photo---3nM8R {
  width: calc(125px * 2);
  height: calc(125px * 2);
  margin: var(--midVertGutter) 0;
  border-radius: 125px;
}

.assets-pages-Payment-Testimonials-Endorsement-index---quote---Hw8G6 {
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  border: 0;
  margin: 0 0 20px;
  line-height: var(--midLineHeight);
  color: var(--inky500);
  font-size: var(--bodyFontSize);
  font-style: italic;
}

.assets-pages-Payment-Testimonials-Endorsement-index---citation----vQpW {
  color: var(--inky500);
  font-size: var(--display1FontSize);
}

.assets-pages-Payment-Testimonials-Endorsement-index---logo---1fGX8 {
  max-width: 60%;
  margin: var(--largeVertGutter) 0;
}
