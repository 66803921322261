.assets-components-TeamManager-DeleteTeamMember-index---main---1sOkj {
  padding: var(--tinyHorzGutter) 0;
  text-align: center;
}

.assets-components-TeamManager-DeleteTeamMember-index---title---1gCG8 {
  padding: var(--largeVertGutter) 0 var(--midVertGutter);
}

.assets-components-TeamManager-DeleteTeamMember-index---message---_sCTD {
  padding: var(--tinyVertGutter) 0;
}

.assets-components-TeamManager-DeleteTeamMember-index---buttons---1Eg2P {
  padding-top: var(--hugeHorzGutter);
}

.assets-components-TeamManager-DeleteTeamMember-index---buttonCancel---UJz-e {
  margin-right: var(--smallVertGutter);
}
