.assets-components-BulkEditTable-UseOnceEdit-index---main---eh13V {
  padding: var(--midVertGutter) 0 var(--largeVertGutter);
}

.assets-components-BulkEditTable-UseOnceEdit-index---sectionFieldDescription---3YI1r {
  display: block;
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-components-BulkEditTable-UseOnceEdit-index---sectionFieldInput---3wn9m {
  align-items: center;
  margin-top: var(--midHorzGutter);
}

.assets-components-BulkEditTable-UseOnceEdit-index---buttonCancel---2QJ6b {
  margin-right: var(--smallVertGutter);
}
