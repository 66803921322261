.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---aiEditor---146rc {
  width: 100%;
  height: 100%;
  border: 1px solid #cbcbcb;
  position: relative;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---aiButtonBackgroud---1HaaJ {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  background: #e9fbfe;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---aiEditorButton---39pvo {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---aiEditorButton---39pvo img {
  width: 100px;
  height: 100px;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---aiComposer---1nZVv {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---aiComposerHeading---ZlEIK {
  width: 100%;
  padding: 5px 5px;
  background-color: var(--kelp400);
  border: none;
  border-color: var(--kelp400);
  color: #fff;
  text-align: center;
  font-weight: bold;
}
.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---aiComposerInfo---2587H {
  position: absolute;
  margin: 20px 55px 0px 55px;
  top: 25px;
  left: 0px;
  color: #a0a0a0;
  text-align: center;
  font-size: 10px;
  line-height: 15px;
  border-radius: 5px;
  background: #f2f2f2;
  padding: 9px 26px 14px 26px;
}
.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---aiInput---2FWyG {
  margin: 14px 29px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---aiInputText---1p-uu {
  display: flex;
  flex-wrap: nowrap;
  padding: 5px 10px;
  border-radius: 7px 20px 20px 7px;
  border: 0.25px solid #d5cbcb;
  background: #ffffff;
  box-sizing: border-box;
  align-items: center;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---aiInputText---1p-uu input {
  width: 80%;
  border: none;
  outline: none;
  background: transparent;
  font-size: 12px;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---aiInputText---1p-uu .assets-pages-NewComposer-Variation-TextField-AIGenerator-index---limitCount---1t9h6 {
  font-style: normal;
  font-weight: 400;
  color: #9f9f9f;
  flex-basis: 125px;
  font-size: 12px;
  text-align: center;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---aiInputText---1p-uu .assets-pages-NewComposer-Variation-TextField-AIGenerator-index---iconContainer---3lTg8 {
  padding: 0px 5px;
  min-width: auto;
  height: 33px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---aiInputText---1p-uu .assets-pages-NewComposer-Variation-TextField-AIGenerator-index---iconContainer---3lTg8 span {
  line-height: normal;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---aiInputText---1p-uu i {
  font-size: 25px;
  color: white;
}
.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---regenerateButton---t6_qp {
  border: 1px solid #cbcbcb;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 9px 13px;
  background-color: #ffffff;
  color: #6c6d79;
  font-size: 10px;
  font-weight: 700;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---buttonDiv---THb-O {
  display: flex;
  justify-content: flex-end;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---checkBoxContainer---1SrUU {
  display: flex;
  justify-content: center;
  padding-right: 16px;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---checkBoxContainer---1SrUU label {
  color: #2d968f;
  padding-left: 10px;
  padding-bottom: 4px;
  font-size: 12px;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---emotionButtons---3d-3V {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---emotionButtons---3d-3V input[type="checkbox"] {
  background-color: #ffffff;
  width: 16px;
  height: 16px;
  border: 0.5px solid #d9d9d9;
  border-radius: 2px;
}
.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---selectEmojiContainer---2L5xg {
  width: 200px;
  font-size: 12px;
  color: var(--primaryTextColor);
}
.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---selectEmojiContainer---2L5xg > div {
  min-height: 20px;
  border-color: var(--primaryTextColor);
}


.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---upgradeMessage---37e2y {
  margin-top: 10px;
  font-size: 12px;
}
.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---upgradeMessage---37e2y strong {
  color: var(--primaryColor);
}
.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---upgradeMessage---37e2y a {
  background-color: white;
  font-size: 10px;
  font-weight: 600;
  margin-left: 12px;
}
.assets-pages-NewComposer-Variation-TextField-AIGenerator-index---upgradeMessage---37e2y a span {
  color: #818181;
}
