.assets-pages-NewComposer-Variation-TextField-PostEditor-index---editor---1yQvq {
  padding: 5px;
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-index---editor---1yQvq .public-DraftEditor-content {
  min-height: calc(var(--textAreaLineHeight) * 5 * 3);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-index---editor---1yQvq .public-DraftEditorPlaceholder-root {
  position: relative;
  color: var(--inky300);
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-index---editor---1yQvq .public-DraftEditorPlaceholder-inner {
  position: absolute;
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-index---emojiSuggestionsContainer---oKdTc img {
  margin-bottom: var(--bugLineHeight);
}
