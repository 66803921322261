.assets-pages-Schedule-index---header---Fz-e3 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  margin: 0 0 var(--tinyVertGutter) 0;
}

.assets-pages-Schedule-index---toolbar---3HpDC {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 0 0 var(--tinyVertGutter) 0;
}

.assets-pages-Schedule-index---secondToolbar---2uYvw {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: calc(1.25 * var(--largeVertGutter)) 0 0;
}

.assets-pages-Schedule-index---toolbar---3HpDC > * {
  margin-left: var(--midHorzGutter);
}

.assets-pages-Schedule-index---toolbar---3HpDC *:first-child {
  margin-left: 0;
}

.assets-pages-Schedule-index---pageTitle---j1w54 {
  flex: 1;
}

.assets-pages-Schedule-index---filterByLabel---1Ah5y {
  white-space: nowrap;
}

.assets-pages-Schedule-index---accountFilter---1UpA3 {
  flex-basis: 150px;
}

.assets-pages-Schedule-index---categoryFilter---3OcNv {
  flex-basis: 150px;
}

.assets-pages-Schedule-index---upgradeLink---2x2MY {
  margin-bottom: var(--largeVertGutter);
}

.assets-pages-Schedule-index---addTimeslotButton---3B61Q {
  white-space: nowrap;
}

.assets-pages-Schedule-index---loadingContainer---CR96c {
  width: 100%;
  height: 902px;
  border: 1px solid var(--inky200);
  background-color: white;
}

.assets-pages-Schedule-index---loading---286R0 {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

@media (max-width: 920px) {
  .assets-pages-Schedule-index---toolbar---3HpDC {
    flex-direction: column;
    align-items: stretch;
  }

  .assets-pages-Schedule-index---accountFilter---1UpA3 {
    flex: auto;
    width: auto;
  }

  .assets-pages-Schedule-index---categoryFilter---3OcNv {
    flex: auto;
  }

  .assets-pages-Schedule-index---pageTitle---j1w54 {
    flex: auto;
  }

  .assets-pages-Schedule-index---toolbar---3HpDC > * {
    margin: 0 0 var(--tinyVertGutter) 0;
  }
}

.assets-pages-Schedule-index---scheduleInformationWrapper---2QWQ6 {
  margin-bottom: 1.5rem;
}

.assets-pages-Schedule-index---weekCenter---3nLLz {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 36px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
