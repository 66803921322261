.assets-pages-Queue-index---information---2KJYY {
  margin-top: var(--largeVertGutter);
}

.assets-pages-Queue-index---titleButtonWrapper---23thr {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.assets-pages-Queue-index---titleButton---2IBly {
  margin-left: 15px;
}
