/*
  Usage
  Current assumption is one Container per view. Child col widths should add up
  to 8. At 8 they wrap.
 */

/* TODO current implementation will show horz scrolls 30px too early */
.assets-components-common-layout-Container-index---root---1NlEm {
  height: 100%;
  min-height: 100%;
  line-height: 1.42857143;
  color: var(--inky500);
  font-family: var(--primaryFont);
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}

.assets-components-common-layout-Container-index---rootUI---1GWEf {
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 970px) {
  .assets-components-common-layout-Container-index---rootUI---1GWEf {
    width: 970px;
  }
}

@media (min-width: 1100px) {
  .assets-components-common-layout-Container-index---newRootUIWrapper---3zv_m {
    margin-top: -93px;
    margin-left: 88px;
  }
}

@media (min-width: 1300px) {
  .assets-components-common-layout-Container-index---newRootUIWrapper---3zv_m {
    margin-left: 272px;
  }

  .assets-components-common-layout-Container-index---newRootUICollapsed---5aFOX {
    margin-left: 88px;
  }
}

.assets-components-common-layout-Container-index---root---1NlEm > * {
  margin: 0 calc(30px / 2);
}
