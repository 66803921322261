.assets-components-common-content-FullContent-Layout-index---root---1vYAE:last-child {
  margin-bottom: 0;
}

.assets-components-common-content-FullContent-Layout-index---primary---1pfuB {
  display: flex;
  justify-content: space-between;
}

.assets-components-common-content-FullContent-Layout-index---text---W9Cdb {
  width: 100%;
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.assets-components-common-content-FullContent-Layout-index---unqueueable---La6fc {
  color: var(--inky300);
}

.assets-components-common-content-FullContent-Layout-index---unqueueable---La6fc a {
  color: var(--kelp100);
}

.assets-components-common-content-FullContent-Layout-index---unqueueable---La6fc img {
  opacity: 0.5;
}

.assets-components-common-content-FullContent-Layout-index---metaContainer---3JVFm {
  padding: 0 var(--largeHorzGutter) var(--midVertGutter);
  border-bottom: 1px solid var(--inky200);
}

.assets-components-common-content-FullContent-Layout-index---linkPreviewContainer---TtXUJ,
.assets-components-common-content-FullContent-Layout-index---thumbnailContainer---nI9hg,
.assets-components-common-content-FullContent-Layout-index---accountsContainer---1RoiE {
  margin-top: var(--largeVertGutter);
}

.assets-components-common-content-FullContent-Layout-index---advancedSettingsDescriptionContainer---2XTVK {
  margin-top: var(--midVertGutter);
  color: var(--inky400);
}
