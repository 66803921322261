.assets-pages-NewComposer-Variation-Attachments-LinkPreviewAttachmentToggle-LinkPreviewAttachment-index---header---qcssy {
  display: flex;
  align-items: center;
  margin-right: var(--midVertGutter);
  margin-bottom: var(--tinyVertGutter);
}

.assets-pages-NewComposer-Variation-Attachments-LinkPreviewAttachmentToggle-LinkPreviewAttachment-index---content---1sIvs {
  padding: var(--smallVertGutter) var(--smallHorzGutter);
}

.assets-pages-NewComposer-Variation-Attachments-LinkPreviewAttachmentToggle-LinkPreviewAttachment-index---header---qcssy > * {
  margin-right: var(--tinyHorzGutter);
}

.assets-pages-NewComposer-Variation-Attachments-LinkPreviewAttachmentToggle-LinkPreviewAttachment-index---url---3RbUN {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assets-pages-NewComposer-Variation-Attachments-LinkPreviewAttachmentToggle-LinkPreviewAttachment-index---loadingIconContainer---2vHjK {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--hugeVertGutter);
  color: var(--inky200);
  font-size: var(--hugeIconSize);
}
