.assets-components-common-typography-Caption-index---root---1ZKBj {
  line-height: var(--captionLineHeight);
  font-size: var(--captionFontSize);
}

.assets-components-common-typography-Caption-index---root---1ZKBj a {
  cursor: pointer;
  text-decoration: underline;
}

.assets-components-common-typography-Caption-index---helpText---QCOmC {
  color: var(--inky400);
}

.assets-components-common-typography-Caption-index---helpText---QCOmC a {
  color: var(--inky400);
}

.assets-components-common-typography-Caption-index---root---1ZKBj a:hover {
  text-decoration: none;
}

.assets-components-common-typography-Caption-index---helpText---QCOmC a:hover {
  color: var(--inky300);
}

.assets-components-common-typography-Caption-index---error---QCCvu {
  color: var(--coral600);
}

.assets-components-common-typography-Caption-index---hasBottomMargin---1h1Cj {
  margin-bottom: var(--captionLineHeight);
}

.assets-components-common-typography-Caption-index---textAlignLeft---ri_90 {
  text-align: left;
}

.assets-components-common-typography-Caption-index---textAlignCenter---2sbPt {
  text-align: center;
}

.assets-components-common-typography-Caption-index---textAlignRight---1y68m {
  text-align: right;
}
