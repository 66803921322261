.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Header-ProfilePicture-index---root---ZkGV2 {
  flex-grow: 0;
  width: 20px;
  height: 20px;
  padding: 0 var(--tinyHorzGutter);
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Header-ProfilePicture-index---image---NWX7A {
  width: 100%;
  border-radius: 20px;
}
