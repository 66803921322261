.assets-pages-Library-Header-Nav-index---pendingCount---3OZ1V {
  position: absolute;
  z-index: 1;
  top: -7px;
  right: -18px;
}

.assets-pages-Library-Header-Nav-index---tooltip---6rHf7 {
  max-width: 310px;
  border-radius: 10px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
  font-size: 12px;
}
