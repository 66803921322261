.assets-components-BulkEditTable-index---stickyEl---3geZi {
  position: sticky;

  /* This accommodates the fixed main navigation bar. */
  top: 55px;
}

.assets-components-BulkEditTable-index---loadingWrapper---1s84d {
  position: sticky;

  /* This accommodates the fixed main navigation bar with some extra padding. */
  top: calc(55px + var(--smallVertGutter));
}

.assets-components-BulkEditTable-index---topDisplayErrors---350rh {
  display: flex;
  border: 1px solid var(--inky200);
  margin: var(--hugeVertGutter) 0 var(--midVertGutter);
  background-color: #fff;
}

.assets-components-BulkEditTable-index---topDisplayErrorsCol---2kzQu {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
}

.assets-components-BulkEditTable-index---topDisplayErrorsIcon---2-P12 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--inky200);
  background-color: var(--inky100);
}

.assets-components-BulkEditTable-index---topDisplayErrorsIconWrapper---1elRL {
  padding: var(--tinyVertGutter);
  border: 1px solid var(--inky200);
  background-color: #fff;
  border-radius: 50%;
}

.assets-components-BulkEditTable-index---loading---3bumA {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-components-BulkEditTable-index---emptyState---2o8OO {
  margin-top: var(--largeVertGutter);
  color: var(--inky200);
  text-align: center;
}

.assets-components-BulkEditTable-index---updating---3ozxg {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  border: 1px solid var(--inky200);
  margin-top: calc(3 * var(--midVertGutter));
  background-color: #fff;
  border-radius: var(--elementBorderRadius);
}

.assets-components-BulkEditTable-index---loadingBar---16fge {
  margin-top: var(--midVertGutter);
}

.assets-components-BulkEditTable-index---totalFilteredContents---2DZmJ {
  padding: var(--midVertGutter) 0 var(--tinyVertGutter);
  color: var(--inky400);
  font-size: var(--captionFontSize);
  text-align: right;
}

.assets-components-BulkEditTable-index---actions---9cKCM {
  z-index: calc(var(--modalZIndex) - 1);
  display: flex;
  padding: var(--midVertGutter) var(--smallHorzGutter);
  border: 1px solid var(--inky200);
  background-color: #fff;
}

.assets-components-BulkEditTable-index---action---1rKFg {
  margin-right: var(--midHorzGutter);
}

.assets-components-BulkEditTable-index---multiSelectWrapper---3oRik {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: solid 1px var(--inky200);
  background-color: var(--inky100);
  border-radius: var(--elementBorderRadius);
  color: var(--inky500);
}

.assets-components-BulkEditTable-index---multiSelectDefault---11twf {
  padding-left: var(--midHorzGutter);
}

.assets-components-BulkEditTable-index---multiSelectAction---2FItD {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 var(--midHorzGutter) 0 var(--smallHorzGutter);
}

.assets-components-BulkEditTable-index---totalSelected---2vEh1 {
  align-self: center;
  font-size: var(--captionFontSize);
}

.assets-components-BulkEditTable-index---contentItems---OjbIu {
  border-right: 1px solid var(--inky200);
  border-left: 1px solid var(--inky200);
  font-size: var(--captionFontSize);
}

.assets-components-BulkEditTable-index---displayError---3CcNL {
  display: flex;

  /* Match the left padding of the row checkbox. */
  padding-top: var(--smallVertGutter);
  padding-right: var(--smallHorzGutter);
  padding-bottom: 0;
  padding-left: calc(var(--smallHorzGutter) + var(--midHorzGutter) + 1px);
  background-color: #fff;
  color: var(--dangerColor);
}

.assets-components-BulkEditTable-index---displayErrorIcon---1tFj1 {
  padding: var(--miniVertGutter) var(--tinyHorzGutter) 0 0;
}

.assets-components-BulkEditTable-index---row---3BOmg {
  display: grid;
  padding: var(--smallVertGutter) var(--smallHorzGutter);
  border-bottom: 1px solid var(--inky200);
  background-color: #fff;
  grid-template-columns: 8% 18% 10% 25% 39%;
}

.assets-components-BulkEditTable-index---displayError---3CcNL + .assets-components-BulkEditTable-index---row---3BOmg {
  padding-top: 0;
}

.assets-components-BulkEditTable-index---cell---39sF9 {
  padding: var(--smallVertGutter) var(--smallHorzGutter);
  vertical-align: top;
}

.assets-components-BulkEditTable-index---selectItem---2NC0H {
  /* Add 1px so the checkbox aligns with the multi select default checkbox above. */
  padding-left: calc(var(--midHorzGutter) + 1px);
}

.assets-components-BulkEditTable-index---useOnce---129nd {
  padding-left: 0;
}

.assets-components-BulkEditTable-index---accounts---1PoWr {
  padding-right: 0;
}

.assets-components-BulkEditTable-index---avatars---2CXwU > * {
  margin-bottom: var(--miniVertGutter);
}

.assets-components-BulkEditTable-index---variations---2nHV2 {
  padding-left: 0;
}
