.assets-components-Categories-CategoryList-index---container---1jz5f {
  flex: 1;
}

.assets-components-Categories-CategoryList-index---categoriesContainer---1s8ZB {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0;
}

.assets-components-Categories-CategoryList-index---skeleton---aIHTQ > span {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.assets-components-Categories-CategoryList-index---skeletonWrapper---oHXhg {
  padding-bottom: var(--smallVertGutter);
}

.assets-components-Categories-CategoryList-index---subtitle---BhqnO {
  margin-bottom: var(--largeVertGutter);
  text-align: center;
}

.assets-components-Categories-CategoryList-index---subtitleWarning---3IrVd {
  color: var(--coral500);
}
