/*
  NOTE - Some of the repetition here is needed to cancel out bootstrap.
  Once we get off it we can clean up some of the styling (like :visited psuedos)
*/

/* .root colors used when type not provided */
.assets-components-common-buttons-Button-index---root---DbCjP,
a.assets-components-common-buttons-Button-index---root---DbCjP {
  position: relative;
  display: inline-block;
  padding: 0 var(--midHorzGutter);
  border: none;
  line-height: var(--maxLineHeight);
  background-color: var(--inky200);
  border-radius: var(--elementBorderRadius);
  color: var(--inky500);
  font-size: var(--bodyFontSize);
  font-weight: var(--semiBold);
  outline: none;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.23s ease-in-out 0s;
}

.assets-components-common-buttons-Button-index---root---DbCjP[disabled],
a.assets-components-common-buttons-Button-index---root---DbCjP[disabled] {
  background-color: var(--inky100);
  color: var(--inky300);
  cursor: not-allowed;
}

.assets-components-common-buttons-Button-index---root---DbCjP:hover,
.assets-components-common-buttons-Button-index---root---DbCjP:focus,
a.assets-components-common-buttons-Button-index---root---DbCjP:hover,
a.assets-components-common-buttons-Button-index---root---DbCjP:focus {
  background-color: var(--inky100);
  text-decoration: none;
}

.assets-components-common-buttons-Button-index---root---DbCjP:active {
  background-color: var(--inky300);
}

.assets-components-common-buttons-Button-index---primary---1uuqZ,
a.assets-components-common-buttons-Button-index---primary---1uuqZ {
  background-color: var(--kelp400);
  color: #fff;
}

.assets-components-common-buttons-Button-index---primary---1uuqZ:visited,
a.assets-components-common-buttons-Button-index---primary---1uuqZ:visited {
  color: #fff;
}

.assets-components-common-buttons-Button-index---primary---1uuqZ:hover,
.assets-components-common-buttons-Button-index---primary---1uuqZ:focus,
a.assets-components-common-buttons-Button-index---primary---1uuqZ:hover,
a.assets-components-common-buttons-Button-index---primary---1uuqZ:focus {
  background-color: var(--kelp300);
  color: #fff;
}

.assets-components-common-buttons-Button-index---primary---1uuqZ:active,
a.assets-components-common-buttons-Button-index---primary---1uuqZ:active {
  background-color: var(--kelp200);
  color: #fff;
}

.assets-components-common-buttons-Button-index---primary---1uuqZ[disabled],
a.assets-components-common-buttons-Button-index---primary---1uuqZ[disabled] {
  background-color: var(--kelp100);
  color: #fff;
}

.assets-components-common-buttons-Button-index---secondary---1Udbh,
a.assets-components-common-buttons-Button-index---secondary---1Udbh {
  background-color: transparent;
  color: var(--kelp500);
}

.assets-components-common-buttons-Button-index---secondary---1Udbh:visited,
a.assets-components-common-buttons-Button-index---secondary---1Udbh:visited {
  color: var(--kelp500);
}

.assets-components-common-buttons-Button-index---secondary---1Udbh:hover,
.assets-components-common-buttons-Button-index---secondary---1Udbh:focus,
a.assets-components-common-buttons-Button-index---secondary---1Udbh:hover,
a.assets-components-common-buttons-Button-index---secondary---1Udbh:focus {
  background-color: var(--inky100);
  color: var(--kelp500);
}

.assets-components-common-buttons-Button-index---secondary---1Udbh:active,
a.assets-components-common-buttons-Button-index---secondary---1Udbh:active {
  background-color: var(--inky200);
  color: var(--kelp500);
}

.assets-components-common-buttons-Button-index---secondary---1Udbh[disabled],
a.assets-components-common-buttons-Button-index---secondary---1Udbh[disabled] {
  color: var(--kelp100);
}

.assets-components-common-buttons-Button-index---tint---1MD7A,
a.assets-components-common-buttons-Button-index---tint---1MD7A {
  /* padding: var(--tinyVertGutter) var(--midHorzGutter); */

  /* line-height: var(--captionLineHeight); */
  background-color: var(--inky100);
  color: var(--inky400);

  /* font-size: var(--captionFontSize); */

  /* font-weight: normal; */
}

.assets-components-common-buttons-Button-index---tint---1MD7A:visited,
a.assets-components-common-buttons-Button-index---tint---1MD7A:visited {
  color: var(--inky400);
}

.assets-components-common-buttons-Button-index---tint---1MD7A:hover,
.assets-components-common-buttons-Button-index---tint---1MD7A:focus,
a.assets-components-common-buttons-Button-index---tint---1MD7A:hover,
a.assets-components-common-buttons-Button-index---tint---1MD7A:focus {
  background-color: var(--inky200);
  color: var(--inky400);
}

.assets-components-common-buttons-Button-index---tint---1MD7A:active,
a.assets-components-common-buttons-Button-index---tint---1MD7A:active {
  background-color: var(--inky300);
  color: var(--inky400);
}

.assets-components-common-buttons-Button-index---tint---1MD7A[disabled],
a.assets-components-common-buttons-Button-index---tint---1MD7A[disabled] {
  color: var(--inky200);
}

.assets-components-common-buttons-Button-index---primary-negative---1cNiV,
a.assets-components-common-buttons-Button-index---primary-negative---1cNiV {
  background-color: var(--coral600);
  color: #fff;
}

.assets-components-common-buttons-Button-index---primary-negative---1cNiV:visited,
a.assets-components-common-buttons-Button-index---primary-negative---1cNiV:visited {
  color: #fff;
}

.assets-components-common-buttons-Button-index---primary-negative---1cNiV:hover,
.assets-components-common-buttons-Button-index---primary-negative---1cNiV:focus,
a.assets-components-common-buttons-Button-index---primary-negative---1cNiV:hover,
a.assets-components-common-buttons-Button-index---primary-negative---1cNiV:focus {
  background-color: var(--coral300);
  color: #fff;
}

.assets-components-common-buttons-Button-index---primiary-negative---1bnW7:active,
a.assets-components-common-buttons-Button-index---primary-negative---1cNiV:active {
  background-color: var(--coral200);
  color: #fff;
}

.assets-components-common-buttons-Button-index---primary-negative---1cNiV[disabled],
a.assets-components-common-buttons-Button-index---primary-negative---1cNiV[disabled] {
  background-color: var(--coral100);
  color: #fff;
}

.assets-components-common-buttons-Button-index---secondary-negative---17BbU,
a.assets-components-common-buttons-Button-index---secondary-negative---17BbU {
  background-color: transparent;
  color: var(--coral600);
}

.assets-components-common-buttons-Button-index---secondary-negative---17BbU:visited,
a.assets-components-common-buttons-Button-index---secondary-negative---17BbU:visited {
  color: var(--coral600);
}

.assets-components-common-buttons-Button-index---secondary-negative---17BbU:hover,
.assets-components-common-buttons-Button-index---secondary-negative---17BbU:focus,
a.assets-components-common-buttons-Button-index---secondary-negative---17BbU:hover,
a.assets-components-common-buttons-Button-index---secondary-negative---17BbU:focus {
  background-color: var(--inky100);
  color: var(--coral600);
}

.assets-components-common-buttons-Button-index---secondary-negative---17BbU:active,
a.assets-components-common-buttons-Button-index---secondary-negative---17BbU:active {
  background-color: var(--inky200);
  color: var(--coral600);
}

.assets-components-common-buttons-Button-index---secondary-negative---17BbU[disabled],
a.assets-components-common-buttons-Button-index---secondary-negative---17BbU[disabled] {
  color: var(--coral100);
}

/* From the "Alternatives" section of the styleguide https://sketchviewer.com/sketches/592d789ca65c280011dc53f7/latest/ */
.assets-components-common-buttons-Button-index---alt-primary---o7pn1,
a.assets-components-common-buttons-Button-index---alt-primary---o7pn1 {
  background-color: var(--whale400);
  color: #fff;
}

.assets-components-common-buttons-Button-index---alt-primary---o7pn1:visited,
a.assets-components-common-buttons-Button-index---alt-primary---o7pn1:visited {
  color: #fff;
}

.assets-components-common-buttons-Button-index---alt-primary---o7pn1:hover,
.assets-components-common-buttons-Button-index---alt-primary---o7pn1:focus,
a.assets-components-common-buttons-Button-index---alt-primary---o7pn1:hover,
a.assets-components-common-buttons-Button-index---alt-primary---o7pn1:focus {
  background-color: var(--whale300);
  color: #fff;
}

.assets-components-common-buttons-Button-index---alt-primary---o7pn1:active,
a.assets-components-common-buttons-Button-index---alt-primary---o7pn1:active {
  background-color: var(--whale200);
  color: #fff;
}

.assets-components-common-buttons-Button-index---alt-primary---o7pn1[disabled],
a.assets-components-common-buttons-Button-index---alt-primary---o7pn1[disabled] {
  background-color: var(--whale100);
  color: #fff;
}

.assets-components-common-buttons-Button-index---inline---1Rgua,
a.assets-components-common-buttons-Button-index---inline---1Rgua {
  border: solid 1px var(--inky300);
  margin-top: var(--tinyVertGutter);
  margin-right: var(--smallHorzGutter);
  line-height: var(--bodyLineHeight);
  background-color: var(--inky100);
  font-size: var(--captionFontSize);
  font-weight: var(--normalWeight);
}

.assets-components-common-buttons-Button-index---inline---1Rgua:hover,
a.assets-components-common-buttons-Button-index---inline---1Rgua:hover {
  background-color: var(--inky200);
}

.assets-components-common-buttons-Button-index---inline---1Rgua ~ .assets-components-common-buttons-Button-index---inline---1Rgua {
  margin-left: 0;
}

.assets-components-common-buttons-Button-index---small---1cArI,
a.assets-components-common-buttons-Button-index---small---1cArI {
  line-height: var(--midLineHeight);
}

.assets-components-common-buttons-Button-index---enforceMinWidth---2DwyE,
a.assets-components-common-buttons-Button-index---enforceMinWidth---2DwyE {
  min-width: 90px;
}

.assets-components-common-buttons-Button-index---loading---SqIfQ .assets-components-common-buttons-Button-index---childrenContainer---20HRx {
  opacity: 0.4;
}

.assets-components-common-buttons-Button-index---spinner---1lPM8 {
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  line-height: var(--maxLineHeight);
  opacity: 1;
}

a.assets-components-common-buttons-Button-index---small---1cArI > .assets-components-common-buttons-Button-index---spinner---1lPM8 {
  line-height: var(--midLineHeight);
}

.assets-components-common-buttons-Button-index---block---2U0MX {
  display: block;
}

.assets-components-common-buttons-Button-index---light---3yKkj,
a.assets-components-common-buttons-Button-index---light---3yKkj {
  border: solid 1px var(--inky200);
  background-color: var(--inky100);
  color: var(--inky500);
}

.assets-components-common-buttons-Button-index---light---3yKkj:visited,
a.assets-components-common-buttons-Button-index---light---3yKkj:visited {
  color: var(--inky500);
}

.assets-components-common-buttons-Button-index---light---3yKkj:hover,
.assets-components-common-buttons-Button-index---light---3yKkj:focus,
a.assets-components-common-buttons-Button-index---light---3yKkj:hover,
a.assets-components-common-buttons-Button-index---light---3yKkj:focus {
  background-color: var(--inky100);
  color: var(--inky500);
}

.assets-components-common-buttons-Button-index---light---3yKkj:active,
a.assets-components-common-buttons-Button-index---light---3yKkj:active {
  background-color: var(--inky200);
  color: var(--inky500);
}

.assets-components-common-buttons-Button-index---light---3yKkj[disabled],
a.assets-components-common-buttons-Button-index---light---3yKkj[disabled] {
  color: var(--inky400);
}

.assets-components-common-buttons-Button-index---thin---2PAKH,
a.assets-components-common-buttons-Button-index---thin---2PAKH {
  padding: 0 var(--tinyHorzGutter);
}
