.assets-components-PostsTable-index---loading---2If-E {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-components-PostsTable-index---table---si5T2 {
  width: 100%;
  background-color: #fff;
  border-collapse: collapse;
  border-radius: var(--cardBorderRadius);
  box-shadow: 0 2px 10px -3px var(--inky150);
  font-size: 13px;
}

.assets-components-PostsTable-index---statsHeaderColumn---1Xw04 {
  padding-left: 0;
}

.assets-components-PostsTable-index---statsColumn---3IOMZ {
  width: 60px;
  min-width: 60px;
  padding-right: var(--midHorzGutter);
  text-align: right;
}

.assets-components-PostsTable-index---sentAtColumn---3UB2b {
  min-width: 90px;
  max-width: 115px;
}

.assets-components-PostsTable-index---tableBodyRow---3n-7L {
  border-top: 1px solid var(--inky150);
  border-bottom: 1px solid var(--inky150);
}

.assets-components-PostsTable-index---tableBodyRow---3n-7L:last-child {
  border-top: 1px solid var(--inky150);
  border-bottom: none;
}

.assets-components-PostsTable-index---emptyState---2mkdU {
  color: var(--inky200);
  text-align: center;
}
