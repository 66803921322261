.assets-pages-Composer-index---addedAt---bVRGS {
  margin-bottom: var(--tinyVertGutter);
}

.assets-pages-Composer-index---addVariationCard---23GwZ {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--largeVertGutter);
}

.assets-pages-Composer-index---addVariationButton---f5E6Z,
.assets-pages-Composer-index---addVariationButton---f5E6Z:visited {
  flex: 1;
  padding: var(--midVertGutter) var(--midHorzGutter);
  color: var(--inky400);
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.assets-pages-Composer-index---suggestVariationsButtonContainer---30LqJ {
  padding: var(--midVertGutter) var(--smallHorzGutter);
}

.assets-pages-Composer-index---suggestVariationsButton---CMKUm {
  padding: 0;
  margin: 0 auto;
  background-color: var(--whale500) !important;
  border-radius: var(--midBorderRadius) !important;
  text-decoration: none;
}

.assets-pages-Composer-index---contentForm---2VISg {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  padding: 0 var(--largeHorzGutter);
}

.assets-pages-Composer-index---main---29hQE {
  min-width: 0;
}

@media (min-width: 995px) {
  .assets-pages-Composer-index---contentForm---2VISg {
    flex-direction: row;
    padding: 0 0;
    margin-right: var(--largeHorzGutter);
  }

  .assets-pages-Composer-index---sidebar---2HhI4 {
    flex: 0;
    flex-basis: 350px;
    margin-right: var(--largeHorzGutter);
  }
}

.assets-pages-Composer-index---loading---EQp9f {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-pages-Composer-index---loadingIconContainer---2JJbr {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--midVertGutter);
  color: var(--inky200);
  font-size: 30px;
}

.assets-pages-Composer-index---messageContainer---n1FFt {
  margin-bottom: var(--largeVertGutter);
  font-size: var(--bodyFontSize);
}

.assets-pages-Composer-index---toggle_area---1kfRE {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 31%;
  padding: 0 0 20px 0;
  margin-top: -25px;
}

.assets-pages-Composer-index---toggle_area---1kfRE p {
  line-height: 24px;
  font-size: 18px;
  font-weight: 600;
}

@media (min-width: 768px) and (max-width: 994px) {
  .assets-pages-Composer-index---toggle_area---1kfRE {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    margin-left: 25px;
  }
}

.assets-pages-Composer-index---wrapper---20tQm {
  text-align: center;
  background-color: #cff4fc;
  border: 1px solid #b6effb;
  border-radius: 4px;
  /* display: flex; */
  /* justify-content: space-between; */
  margin: 20px 0 30px;
  padding: 16px;
  width: 100%;
}
