.assets-components-common-links-PageTitle-index---left---C978I {
  display: flex;
  align-items: baseline;
  padding-right: 10px;
}

.assets-components-common-links-PageTitle-index---right---2hVco {
  display: flex;
  align-items: baseline;
  margin-bottom: 25px;
}

@media (min-width: 460px) {
  .assets-components-common-links-PageTitle-index---root---36EF1 {
    display: flex;
    flex-basis: 250px;
    align-items: baseline;
    white-space: nowrap;
  }

  .assets-components-common-links-PageTitle-index---right---2hVco {
    margin-bottom: 0;
    margin-left: var(--smallHorzGutter);
  }
}

.assets-components-common-links-PageTitle-index---title---1nvCs {
  margin: 0;
}

a.assets-components-common-links-PageTitle-index---link---3w50_ {
  margin-left: var(--smallHorzGutter);
  color: var(--inky300);
  font-size: var(--helpIconFontSize);
}

a.assets-components-common-links-PageTitle-index---link---3w50_:focus {
  outline: none;
}

a.assets-components-common-links-PageTitle-index---link---3w50_:hover {
  color: var(--kelp300);
}

a.assets-components-common-links-PageTitle-index---link---3w50_ i {
  vertical-align: top;
}
