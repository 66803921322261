.assets-components-common-typography-Badge-index---root---fI4Ln {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  line-height: 1;
  border-radius: 0.25em;
  color: #fff;
  font-size: 75%;
  font-weight: bold;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.assets-components-common-typography-Badge-index---danger---3d7Tf {
  background-color: #a04250;
}
