
.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Actions-index---root---1w-C9 {
  padding: 0 var(--tinyHorzGutter);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Actions-index---instaButton---1ZnJT {
  width: 20px;
  margin: var(--smallVertGutter) var(--smallHorzGutter);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Actions-index---bookmark---1tkwB {
  float: right;
}
