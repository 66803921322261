.assets-pages-Composer-Variation-PostPreview-Post-HelpText-index---root---32IeY {
  display: flex;
  margin-top: var(--midVertGutter);
  text-align: left;
}

.assets-pages-Composer-Variation-PostPreview-Post-HelpText-index---infoIcon---OOFcx {
  margin-top: 3px;
  margin-right: var(--tinyHorzGutter);
}
