.assets-pages-Composer-Variation-Attachments-common-MediaList-AutoScrollContainer-index---root---3ugHn {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

.assets-pages-Composer-Variation-Attachments-common-MediaList-AutoScrollContainer-index---root---3ugHn::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

.assets-pages-Composer-Variation-Attachments-common-MediaList-AutoScrollContainer-index---root---3ugHn::-webkit-scrollbar-thumb {
  border-color: rgba(0, 0, 0, 0);
}

.assets-pages-Composer-Variation-Attachments-common-MediaList-AutoScrollContainer-index---root---3ugHn > * {
  margin-right: var(--smallHorzGutter);
  margin-bottom: 3px;
}

.assets-pages-Composer-Variation-Attachments-common-MediaList-AutoScrollContainer-index---root---3ugHn > *:last-child {
  margin-right: 0;
}
