.assets-pages-Composer-Variation-Attachments-PinterestSettings-index---root---3Dd-f {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: var(--smallVertGutter) var(--smallHorzGutter);
}

.assets-pages-Composer-Variation-Attachments-PinterestSettings-index---input---uGGEa {
  margin-top: var(--tinyVertGutter);
}

.assets-pages-Composer-Variation-Attachments-PinterestSettings-index---header---2i58L {
  display: flex;
  align-items: center;
  width: 100%;
}

.assets-pages-Composer-Variation-Attachments-PinterestSettings-index---header---2i58L > * {
  margin-right: var(--tinyHorzGutter);
}

.assets-pages-Composer-Variation-Attachments-PinterestSettings-index---titleLength---31Tyn {
  flex-grow: 2;
  text-align: right;
}

.assets-pages-Composer-Variation-Attachments-PinterestSettings-index---errorCaption---1nHFX {
  align-self: flex-start;
}
