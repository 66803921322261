.assets-pages-Composer-Variation-Attachments-common-MediaList-PendingMedia-index---root---1Rhaj {
  position: relative;
  overflow: visible;
  min-width: 120px;
  line-height: 120px;
  color: var(--inky400);
  text-align: center;
}

.assets-pages-Composer-Variation-Attachments-common-MediaList-PendingMedia-index---progressBar---3rnNd {
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  height: 3px;
  background-color: var(--kelp400);
  border-radius: var(--elementBorderRadius);
}
