
.assets-components-common-layout-modals-Confirmation-index---root---2G1Rt {
  text-align: center;
}

.assets-components-common-layout-modals-Confirmation-index---icon---nUTr7 {
  margin-bottom: var(--largeVertGutter);
  font-size: var(--hugeIconSize);
}

.assets-components-common-layout-modals-Confirmation-index---warningIcon---1aCot {
  color: var(--starfish400);
}

.assets-components-common-layout-modals-Confirmation-index---errorIcon---fFm0h {
  color: var(--coral300);
}

.assets-components-common-layout-modals-Confirmation-index---successIcon---3GeDI {
  color: var(--algae400);
}

.assets-components-common-layout-modals-Confirmation-index---deleteActions---2fSrE {
  display: flex;
  justify-content: center;
  align-items: center;
}

.assets-components-common-layout-modals-Confirmation-index---infoIcon---M1T8w {
  color: var(--whale400);
}

.assets-components-common-layout-modals-Confirmation-index---title---_RFFi {
  margin-bottom: var(--midVertGutter);
}

.assets-components-common-layout-modals-Confirmation-index---subTitle---rr_-7 {
  margin-bottom: var(--largeVertGutter);
}

.assets-components-common-layout-modals-Confirmation-index---secondaryActions---2QLIo {
  margin-right: var(--midHorzGutter);
}
