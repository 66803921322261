.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareThree-index---container----WaX5 {
  width: 478px;
  height: 161px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareThree-index---image---1_PR0 {
  width: 157px;
  height: 159px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareThree-index---image---1_PR0 > div {
  border-radius: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareThree-index---overlay---Pzplh {
  line-height: 160px;
  font-size: 28px;
}
