.assets-components-LinkShortenerSettings-index---main---3MeRb {
  padding: var(--midVertGutter) 0 var(--largeVertGutter);
}

.assets-components-LinkShortenerSettings-index---buttonCancel---3Imur {
  margin-right: var(--smallVertGutter);
}

.assets-components-LinkShortenerSettings-index---LinkShortnerSpan---1HAXE{
  display: flex;
}