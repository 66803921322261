.assets-pages-Composer-Variation-ImageAttachmentButton-index---root---1fxR5 {
  display: inline-block;
  cursor: pointer;
}

.assets-pages-Composer-Variation-ImageAttachmentButton-index---icon---2jqJH[disabled] {
  cursor: not-allowed;
  opacity: var(--lighterDisabledOpacity);
}

.assets-pages-Composer-Variation-ImageAttachmentButton-index---icon---2jqJH:hover:not([disabled]) {
  opacity: var(--hoverOpacity);
}
.assets-pages-Composer-Variation-ImageAttachmentButton-index---tooltiperBody---SI4A8 {
  display: flex;
}

.assets-pages-Composer-Variation-ImageAttachmentButton-index---tooltiperBodyLeft---3Yvrc {
  width: 50%;
  padding: 0 5px 0 0;
}

.assets-pages-Composer-Variation-ImageAttachmentButton-index---tooltiperBodyRight---32Ker {
  width: 50%;
  padding: 0 0 0 5px;
}

.assets-pages-Composer-Variation-ImageAttachmentButton-index---tooltiperBody---SI4A8 strong {
  display: block;
  margin-top: 5px;
}
