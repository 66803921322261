.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Header-ProfilePicture-index---root---2dyru {
  flex-grow: 0;
  width: 40px;
  height: 40px;
  padding: 0 var(--tinyHorzGutter);
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Header-ProfilePicture-index---image---3aOu8 {
  width: 100%;
  border-radius: 20px;
}
