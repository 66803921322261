.assets-components-SubscriptionStatus-index---root---ZTazD {
  position: relative;
  z-index: 3;
  width: 100%;
  padding: var(--midVertGutter) var(--midHorzGutter);
  background-color: var(--sanddollar600);
  box-shadow: 0 0 1px 1px var(--inky300);
}

.assets-components-SubscriptionStatus-index---rootAlert---3raA3 {
  position: relative;
  z-index: 3;
  width: 100%;
  padding: var(--midVertGutter) var(--midHorzGutter);
  background-color: #FCF0CA;
  box-shadow: 0 0 1px 1px var(--inky300);
}

.assets-components-SubscriptionStatus-index---messageAlert---3NOcg {
  font-weight: 700;
  font-size: 11px;
  flex: 1;

}

.assets-components-SubscriptionStatus-index---body---3OhFG {
  display: flex;
  align-items: center;
  max-width: 970px;
  margin: 0 auto;
}

.assets-components-SubscriptionStatus-index---message---19hWD {
  flex: 1;
}

.assets-components-SubscriptionStatus-index---ctaButton---1AqEJ,
a.assets-components-SubscriptionStatus-index---ctaButton---1AqEJ,
a.assets-components-SubscriptionStatus-index---ctaButton---1AqEJ:visited,
a.assets-components-SubscriptionStatus-index---ctaButton---1AqEJ:active {
  border-radius: 20px;
  color: var(--inky500);
}

.assets-components-SubscriptionStatus-index---ctaAlert---24o8h,
a.assets-components-SubscriptionStatus-index---ctaAlert---24o8h,
a.assets-components-SubscriptionStatus-index---ctaAlert---24o8h:visited,
a.assets-components-SubscriptionStatus-index---ctaAlert---24o8h:active {
  border-radius: 20px;
  color: #fff;
  background-color: #7B6114;
}

.assets-components-SubscriptionStatus-index---ctaAlert---24o8h {
  background-color: #7B6114;
  color: #fff;
}

.assets-components-SubscriptionStatus-index---trialExtensionModalCaption---2ny7t {
  padding: var(--largeVertGutter) 0;
}

@media print {
  .assets-components-SubscriptionStatus-index---root---ZTazD {
    display: none;
  }
}
