.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitThree-index---container---1n3Co {
  flex-direction: column;
  width: 480px;
  height: 480px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitThree-index---primaryImage---1kWW5 {
  width: 237px;
  height: 476px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitThree-index---image---2buB0 {
  width: 237px;
  height: 237px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitThree-index---image---2buB0 > div,
.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitThree-index---primaryImage---1kWW5 > div {
  border-radius: 0;
}
