.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFive-index---container---SLiDV {
  flex-direction: column;
  width: 480px;
  height: 480px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFive-index---primaryImage---hFGpW {
  width: 237px;
  height: 238px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFive-index---image---72YMs {
  width: 237px;
  height: 158px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFive-index---image---72YMs > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFive-index---primaryImage---hFGpW > div {
  border-radius: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFive-index---overlay---3f0yT {
  line-height: 157px;
  font-size: 36px;
}
