@keyframes assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---fade---oKgmO {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---root---17F8L {
  width: 40px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.67);
  border-radius: 15px;
  color: white;
  letter-spacing: 2px;
  text-align: center;
}

/* See FADE_OUT_TIME in index.js, should match (delay + animation time) here */
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---appearActive---3qZXb,
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---enterActive---2hc_G {
  animation: assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---fade---oKgmO 0.75s linear 1s forwards;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---exit---3iPKO,
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---exitActive---1d9wP,
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---exitDone---3mEur,
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---enterDone---21_RJ {
  opacity: 0;
}
