.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Caption-index---root---krbVq {
  overflow: hidden;
  padding: 5px 30px;
  text-overflow: "more";
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Caption-index---nickname---Zq16E {
  margin-right: 5px;
  font-weight: 600;
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Caption-index---hashtag---3fn1w {
  color: var(--instagramNavy);
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Caption-index---root---krbVq mark {
  background: unset;
  color: var(--instagramGray);
}
