.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionPlugin-index---mention---3YFIl {
  display: inline-flex;
  align-items: center;
  padding: 2px;
  border: 1px solid var(--inky100);
  line-height: 1;
  background-color: var(--inky100);
  border-radius: var(--elementBorderRadius);
  color: var(--inky400);
  font-weight: var(--semiBold);
  white-space: nowrap;
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionPlugin-index---socialIconContainer---1tpFv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  padding: 2px;
  margin-right: 2px;
  border-radius: var(--elementBorderRadius);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionPlugin-index---socialIconContainer---1tpFv.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionPlugin-index---twitter---2nj9v {
  background: var(--twitter);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionPlugin-index---socialIconContainer---1tpFv.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionPlugin-index---facebook---Z_OFo {
  background: var(--facebook);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionPlugin-index---socialIcon---3lr6i {
  color: white;
  font-size: 12px;
}
