.assets-pages-NewComposer-AdvancedSettings-LinkShorteningOption-index---setupLink---3ieWr a {
  color: var(--inky400);
  font-weight: var(--semiBold);
  text-decoration: underline;
}

.assets-pages-NewComposer-AdvancedSettings-LinkShorteningOption-index---setupLink---3ieWr a:hover {
  color: var(--inky300);
  text-decoration: none;
}
