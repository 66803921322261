.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-index---root---34xCs {
  width: var(--instagramPreviewWidth);
  color: var(--instagramBlack);
  font-family: var(--instagramFontStack);
  position: relative;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-index---carousel---nDg36 {
  position: relative;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-index---scroller---1cUIp {
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 500px;
  margin-top: -15px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-index---imageXOfYIndicator---1mUt2 {
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
}
