.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFive-index---container---2WsJW {
  width: 480px;
  height: 410px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFive-index---primaryImage---2gx7H {
  width: 236px;
  height: 252px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFive-index---image---2X_yk {
  width: 157px;
  height: 156px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFive-index---image---2X_yk > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFive-index---primaryImage---2gx7H > div {
  border-radius: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFive-index---overlay---3fDS_ {
  line-height: 157px;
  font-size: 36px;
}
