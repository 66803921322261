.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-index---root---NqGdJ {
  width: var(--instagramPreviewWidth);
  margin-top: var(--smallVertGutter);
  margin-bottom: calc(var(--smallVertGutter));
  color: var(--instagramBlack);
  font-family: var(--instagramFontStack);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-index---carousel---2935Q {
  position: relative;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-index---scroller---pjE03 {
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 500px;
  margin-top: -15px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-index---imageXOfYIndicator---32NDZ {
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
}
