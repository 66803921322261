.assets-pages-Composer-AdvancedSettings-LinkShorteningOption-index---setupLink---35U8p {
  line-height: undefined;
}

.assets-pages-Composer-AdvancedSettings-LinkShorteningOption-index---setupLink---35U8p a {
  color: var(--inky400);
  font-weight: var(--semiBold);
  text-decoration: underline;
}

.assets-pages-Composer-AdvancedSettings-LinkShorteningOption-index---setupLink---35U8p a:hover {
  color: var(--inky300);
  text-decoration: none;
}
