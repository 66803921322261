.assets-pages-NewComposer-Variation-FbTextField-index---label---3kjZk {
  color: var(--inky400);
}

.assets-pages-NewComposer-Variation-FbTextField-index---textInput---1QGvi {
  padding: 0 var(--smallHorzGutter);
}

.assets-pages-NewComposer-Variation-FbTextField-index---errorList---A331l {
  margin-bottom: var(--smallVertGutter);
}

.assets-pages-NewComposer-Variation-FbTextField-index---hasErrors---397om {
  border-top: 0;
}

.assets-pages-NewComposer-Variation-FbTextField-index---hasWarnings---3Dk2y {
  border: 2px solid var(--starfish400) !important;
}
