.assets-components-common-forms-RadioGroup-index---radioGroup---39HqS {
  padding: 0;
  margin: 0;
  list-style: none;
}

.assets-components-common-forms-RadioGroup-index---radioGroup---39HqS > li {
  min-height: 5rem;
  margin-top: 0.5em;
}

.assets-components-common-forms-RadioGroup-index---radioGroup---39HqS > li:last-child > div > label {
  border-bottom: none;
}
