.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFour-index---container---2mKg9 {
  flex-direction: column;
  width: 480px;
  height: 480px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFour-index---primaryImage---3cMNJ {
  width: 317px;
  height: 476px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFour-index---image---2SdFn {
  width: 157px;
  height: 157px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFour-index---image---2SdFn > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFour-index---primaryImage---3cMNJ > div {
  border-radius: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFour-index---overlay---3lnB- {
  line-height: 157px;
  font-size: 36px;
}
