a.assets-components-common-layout-NavTabs-Tab-index---root---1Lqgi,
a.assets-components-common-layout-NavTabs-Tab-index---root---1Lqgi:visited {
  position: relative;
  height: var(--maxLineHeight);
  line-height: var(--maxLineHeight);
  color: var(--inky300);
  font-size: var(--subheadingFontSize);
  font-weight: var(--semiBold);
  text-decoration: none;
}

a.assets-components-common-layout-NavTabs-Tab-index---root---1Lqgi:hover {
  color: var(--kelp400);
  text-decoration: none;
}

a.assets-components-common-layout-NavTabs-Tab-index---active---2SZ1h,
a.assets-components-common-layout-NavTabs-Tab-index---active---2SZ1h:visited {
  border-bottom: 2px solid var(--kelp500);
  color: var(--kelp500);
}
