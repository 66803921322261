.assets-pages-Composer-Confirmation-index---root---2kr9V {
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 1.42857143;
  color: var(--inky500);
  font-family: var(--primaryFont);
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  text-rendering: optimizelegibility;
}

.assets-pages-Composer-Confirmation-index---wrapper---2F9tj {
  margin: auto;
}

.assets-pages-Composer-Confirmation-index---text---3tktY {
  max-width: 340px;
}

.assets-pages-Composer-Confirmation-index---icon---795HS {
  display: block;
  margin-bottom: var(--midVertGutter);
  color: var(--algae300);
}
