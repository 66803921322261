.assets-components-common-forms-Toggle-index---root---1Y7kC {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.assets-components-common-forms-Toggle-index---icon---1H2ZF {
  cursor: pointer;
}

.assets-components-common-forms-Toggle-index---label---2DwOZ {
  margin: -1px 0 0 12px;
  color: var(--inky400);
  font-size: 16px;
  font-weight: 300;
}

.assets-components-common-forms-Toggle-index---label---2DwOZ b {
  font-weight: 600;
}
