.assets-components-Categories-CategoryList-CategoryCard-index---container---aFpgw {
  display: flex;
  flex-basis: 48.5%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--largeVertGutter) var(--largeHorzGutter) var(--largeVertGutter)
    var(--largeHorzGutter);
  margin: var(--midVertGutter) 0;
  background-color: white;
  border-radius: var(--cardBorderRadius);
  box-shadow: 0 2px 10px -3px var(--inky150);
}

.assets-components-Categories-CategoryList-CategoryCard-index---container---aFpgw:hover {
  box-shadow: 0 2px 19px -2px var(--inky300);
}

.assets-components-Categories-CategoryList-CategoryCard-index---categoryColorContainer---QhJnp {
  align-self: flex-start;
  padding-top: 5px;
}

.assets-components-Categories-CategoryList-CategoryCard-index---categoryColor---1xmbN {
  width: var(--largeIconSize);
  height: var(--largeIconSize);
  margin-right: var(--smallHorzGutter);
  border-radius: var(--midBorderRadius);
}

.assets-components-Categories-CategoryList-CategoryCard-index---categoryInfoContainer---DqKIo {
  display: flex;
  flex: 5;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.assets-components-Categories-CategoryList-CategoryCard-index---contentsCountContainer---3qgY0 {
  padding-top: var(--tinyVertGutter);
  padding-bottom: var(--smallVertGutter);
  cursor: pointer;
  text-decoration: underline;
}

.assets-components-Categories-CategoryList-CategoryCard-index---contentsCountContainer---3qgY0:hover {
  color: var(--kelp300);
}

.assets-components-Categories-CategoryList-CategoryCard-index---timeslotCountContainer---2UM1w {
  padding-top: var(--tinyVertGutter);
  padding-bottom: var(--smallVertGutter);
  cursor: pointer;
  text-decoration: underline;
}

.assets-components-Categories-CategoryList-CategoryCard-index---timeslotCountContainer---2UM1w:hover {
  color: var(--kelp300);
}

.assets-components-Categories-CategoryList-CategoryCard-index---timeslotCountCheckIcon---34_xT {
  margin-right: var(--tinyVertGutter);
  color: var(--kelp400);
}

.assets-components-Categories-CategoryList-CategoryCard-index---timeslotCountExclamationIcon---3cEIR {
  margin-right: var(--tinyVertGutter);
  color: var(--starfish400);
}

.assets-components-Categories-CategoryList-CategoryCard-index---categoryActionsContainer---1fqXo {
  align-self: flex-start;
}

.assets-components-Categories-CategoryList-CategoryCard-index---icon---1ePks {
  padding: var(--miniVertGutter) var(--tinyHorzGutter);
  border: 1px solid var(--inky300);
  border-radius: var(--elementBorderRadius);
  color: var(--inky400);
  cursor: pointer;
}

.assets-components-Categories-CategoryList-CategoryCard-index---deleteIcon---1jiJG:hover {
  background-color: var(--inky100);
}

.assets-components-Categories-CategoryList-CategoryCard-index---body---23K3R {
  padding: var(--midVertGutter) 0;
}

.assets-components-Categories-CategoryList-CategoryCard-index---caption---3-0OC {
  font-size: var(--captionFontSize);
}
