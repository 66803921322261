.assets-pages-ScheduleOld-Calendar-MobileCalendar-index---root---13por {
  padding: 0;
  margin: 0;
  background-color: white;
  list-style: none;
}

.assets-pages-ScheduleOld-Calendar-MobileCalendar-index---dayHeader---3M9lS {
  position: sticky;
  z-index: 1;
  top: 44px;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: var(--tinyVertGutter) var(--tinyHorzGutter);
  border-bottom: 1px solid var(--inky200);
  line-height: var(--midLineHeight);
  background-color: white;
  font-weight: bold;
}

/* Day contents */
.assets-pages-ScheduleOld-Calendar-MobileCalendar-index---root---13por > li {
  min-height: 10rem;
  border-top: 1px solid var(--inky200);
  border-right: 1px solid var(--inky200);
  border-left: 1px solid var(--inky200);
  cursor: cell;
}

/* Time contents */
.assets-pages-ScheduleOld-Calendar-MobileCalendar-index---root---13por > li > ol > li {
  padding: var(--tinyVertGutter) var(--midHorzGutter);
}

/* Time list */
.assets-pages-ScheduleOld-Calendar-MobileCalendar-index---root---13por > li > ol {
  padding: 0;
  list-style: none;
}

.assets-pages-ScheduleOld-Calendar-MobileCalendar-index---root---13por > li:last-child {
  border-bottom: 1px solid var(--inky200);
}
