.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Four-index---container---2PJL3 {
  flex-direction: column;
  width: 504px;
  height: 381px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Four-index---primaryImage---3LO17 {
  width: 380px;
  height: 380px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Four-index---image---C8khz {
  width: 126px;
  height: 126px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Four-index---image---C8khz:nth-child(2) {
  border-top-right-radius: 5px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Four-index---image---C8khz:last-child {
  border-bottom-right-radius: 5px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Four-index---image---C8khz > div,
.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Four-index---primaryImage---3LO17 > div {
  border-radius: 0;
}
