.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---root---3sIXA {
  height: 100%;
  box-sizing: content-box;
  margin-top: 10px;
  box-shadow: 0 0 0 1.5px rgba(0, 0, 0, 0.1) inset,
    0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---cols---1UX1- {
  display: flex;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---imageChecker---vKHzu {
  position: absolute;
  left: -5000px;
  visibility: hidden;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---imageWrapper---3Gl18 {
  position: relative;
  overflow: hidden;
  width: 476px;
  height: 249px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---imageWrapper---3Gl18::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  content: "";
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---cols---1UX1- .assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---imageWrapper---3Gl18 {
  width: 158px;
  min-width: 158px;
  height: 158px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---image---2TlY6 {
  width: 100%;
  height: auto;
  min-height: 100%;
  line-height: 0;
  border-radius: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---details---1NP-G {
  height: auto;
  max-height: 100px;
  box-sizing: content-box;
  padding-top: 10px;
  margin: 0 12px 10px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---meta---ibmBs {
  max-height: 80px;
  overflow-y: hidden;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---cols---1UX1- .assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---details---1NP-G {
  position: relative;
  overflow: hidden;
  height: 121px;
  max-height: 121px;
  padding-bottom: 11px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---cols---1UX1- .assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---meta---ibmBs {
  max-height: none;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---title---2UrVk {
  overflow: hidden;
  max-height: 110px;
  margin-bottom: 5px;
  line-height: 22px;
  font-family: Georgia, serif;
  font-size: 18px;
  font-weight: 500;
  word-wrap: break-word;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---cols---1UX1- .assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---title---2UrVk {
  max-height: 66px; /* 3 lines */
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---description---1o9R1 {
  overflow: hidden;
  max-height: 80px;
  line-height: 16px;
  font-size: 12px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---cols---1UX1- .assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---description---1o9R1 {
  max-height: 48px; /* 3 lines */
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---domain---3Q6sE {
  overflow: hidden;
  margin-top: 9px;
  line-height: 11px;
  color: #90949c;
  font-size: 11px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---cols---1UX1- .assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---domain---3Q6sE {
  position: absolute;
  bottom: 0;
  left: 0;
}
