.assets-styles-layout---hasScrollbars---kWGgS::-webkit-scrollbar {
  -webkit-appearance: none;
}

.assets-styles-layout---hasScrollbars---kWGgS::-webkit-scrollbar-thumb {
  border: 2px solid #fff; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.assets-styles-layout---hasScrollbars---kWGgS::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.assets-styles-layout---hasScrollbars---kWGgS::-webkit-scrollbar:vertical {
  width: 11px;
}

.assets-styles-layout---hasScrollbars---kWGgS::-webkit-scrollbar:horizontal {
  height: 11px;
}
