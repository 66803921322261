.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---image---2V7Q6 {
  float: left;
  width: var(--hugeVertGutter);
  height: var(--hugeVertGutter);
  margin-top: var(--miniVertGutter);
  margin-right: var(--smallHorzGutter);
  margin-bottom: var(--smallVertGutter);
  border-radius: 50%;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---name---2EiEJ,
.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---nickname---16m-d,
.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---time---X-fkK {
  line-height: 19.25px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---name---2EiEJ {
  cursor: pointer;
  font-weight: bold;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---name---2EiEJ:hover {
  text-decoration: none;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---name---2EiEJ a {
  color: var(--twitterTextDark);
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---nickname---16m-d,
.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---time---X-fkK {
  color: var(--twitterTextMid);
  font-size: var(--captionFontSize);
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---time---X-fkK::after {
  content: "  \00b7";
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---time---X-fkK a {
  color: var(--twitterTextMid);
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---icon---1i5z4 {
  color: var(--inky400);
}
