.assets-components-common-NewAccountSelect-Option-InstagramPersonalCaption-index---select---1raDJ {
  width: 100%;
}

.assets-components-common-NewAccountSelect-Option-InstagramPersonalCaption-index---description---iclG3 {
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-components-common-NewAccountSelect-Option-InstagramPersonalCaption-index---link---1AmbG {
  margin-right: var(--tinyHorzGutter);
}
