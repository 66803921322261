/* .cardElementContainer { */
.StripeElement {
  width: 100%;
  padding: 9px var(--horzInputPadding);
  border: solid 1px var(--inky300);
  margin-bottom: var(--tinyVertGutter);
  line-height: var(--inputLineHeight);
  border-radius: var(--borderRadius);
}

.assets-components-PaymentMethod-StripeCardElement-index---errorMessage---hjTnD {
  height: 1em;
  color: var(--coral600);
  font-size: var(--captionFontSize);
}

.StripeElement--invalid {
  border-color: var(--coral400);
}

.StripeElement--focus {
  border-color: transparent;
  outline-color: #479d9d;
  outline-offset: -2px;
  outline-style: auto;
  outline-width: 5px;
}

.StripeElement--invalid.StripeElement--focus {
  outline-color: #dd4b6f;
}
