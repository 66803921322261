.assets-pages-Library-List-index---list---qV5Ai {
  padding: 0;
  list-style: none;
}

.assets-pages-Library-List-index---list---qV5Ai > li {
}

.assets-pages-Library-List-index---loadingSpinner---1v0HD {
  width: 100%;
  margin: 2em 0;
  font-size: 2em;
  text-align: center;
}

.assets-pages-Library-List-index---enter---1APBM {
  opacity: 0;
}

.assets-pages-Library-List-index---enterActive---2cMlm {
  opacity: 1;
  transition: opacity 50ms ease-in;
}

.assets-pages-Library-List-index---leave---36PA9 {
  opacity: 1;
  transition: opacity 200ms ease-out;
}

.assets-pages-Library-List-index---leaveActive---3v60y {
  opacity: 0;
}

.assets-pages-Library-List-index---emptyState---3ARUg {
  margin-top: var(--largeVertGutter);
  color: var(--inky200);
  text-align: center;
}
