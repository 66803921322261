.assets-pages-Composer-Variation-index---root---Q8rbV {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  margin-bottom: var(--largeVertGutter);
}

.assets-pages-Composer-Variation-index---hovered---11cuy {
  box-shadow: 0 3px 10px var(--inky300);
}

.assets-pages-Composer-Variation-index---field---3R1aj {
  border-bottom-width: 0;
  border-radius: 0;
}

.assets-pages-Composer-Variation-index---firstField---1oFnj {
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
}

.assets-pages-Composer-Variation-index---lastField---3eV-F {
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  border-bottom-width: 1px;
}

.assets-pages-Composer-Variation-index---mediaCounts---1dIbr {
  display: flex;
  flex-direction: column;
  margin-top: var(--tinyVertGutter);
}

.assets-pages-Composer-Variation-index---mediaButtons---2CY4G {
  margin-top: var(--midVertGutter);
}

.assets-pages-Composer-Variation-index---mediaButtons---2CY4G > * {
  margin-right: var(--midHorzGutter);
}

.assets-pages-Composer-Variation-index---tiktokHelper---3VZOI {
  margin: 7.5px 0 0;
  color: #999;
  font-size: 13px;
  font-style: italic;
}
