
.assets-components-ImportManager-Manage-NoFeeds-index---noFeeds---1GG9E {
  width: 90%;
  padding: 2em 0;
  margin: 0 auto;
}

.assets-components-ImportManager-Manage-NoFeeds-index---noFeeds---1GG9E > img {
  display: none;
}

.assets-components-ImportManager-Manage-NoFeeds-index---body---2GuYD {
  line-height: 1.6;
  font-size: var(--bodyFontSize);
}

.assets-components-ImportManager-Manage-NoFeeds-index---body---2GuYD a {
  color: var(--inky500);
  font-weight: var(--semiBold);
}

@media (min-width: 767px) {
  .assets-components-ImportManager-Manage-NoFeeds-index---noFeeds---1GG9E {
    min-height: 30em;
    border-bottom: none;
    margin: 0 auto;
  }

  .assets-components-ImportManager-Manage-NoFeeds-index---noFeeds---1GG9E > img {
    display: inline-block;
    float: right;
    max-width: 200px;
    margin-top: var(--tinyVertGutter);
    margin-left: 5%;
  }

  .assets-components-ImportManager-Manage-NoFeeds-index---noFeedsMessage---3XZNO {
    max-width: 800px;
    padding-bottom: 2em;
  }
}
