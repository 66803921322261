.assets-components-Paginator-index---root---WcfY9 {
  padding: 0;
  margin: 0;
  list-style: none;
}

.assets-components-Paginator-index---root---WcfY9 > li {
  display: inline;
}

.assets-components-Paginator-index---root---WcfY9 > li > a {
  cursor: pointer;
}

.assets-components-Paginator-index---page---3cgVn {
  padding: 0.5em;
}

.assets-components-Paginator-index---currentPage---1yCS4 {
  color: black;
}
