.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Four-index---container---xiBOW {
  width: 551px;
  height: 551px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Four-index---primaryImage---PF5Gd {
  width: 551px;
  height: 330px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Four-index---image---2paja {
  width: 181px;
  height: 221px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Four-index---image---2paja > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Four-index---primaryImage---PF5Gd > div {
  border-radius: 0;
}
