.assets-pages-Schedule-EmptyState-index---root---13cCf {
  margin-top: var(--hugeVertGutter);
  text-align: center;
}

.assets-pages-Schedule-EmptyState-index---octoImage---2pAX_ {
  display: block;
  width: 50%;
  margin: var(--hugeVertGutter) auto;
}
