.assets-components-common-content-AbbreviatedContent-index---root---20l0W {
  margin-bottom: var(--cardVertGutter);
  background-color: white;
  border-radius: var(--cardBorderRadius);
  box-shadow: 0 2px 10px -3px var(--inky150);
  font-size: var(--bodyFontSize);
}

.assets-components-common-content-AbbreviatedContent-index---root---20l0W:hover {
  box-shadow: 0 2px 19px -2px var(--inky300);
}

.assets-components-common-content-AbbreviatedContent-index---body---3fLWd {
  padding: var(--largeVertGutter) var(--largeHorzGutter) var(--midVertGutter);
  border-bottom: 1px solid var(--inky150);
}

.assets-components-common-content-AbbreviatedContent-index---unqueueable---16lLi {
  background-color: var(--sanddollar400);
}
