.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-common-index---container---1gxyX {
  display: flex;
  flex-wrap: wrap;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-common-index---primaryImage---1KDxh {
  overflow: hidden;
  margin: 0.5px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-common-index---image---96gOj {
  position: relative;
  overflow: hidden;
  margin: 0.5px;
}
