.assets-components-Thumbnail-Video-index---root---2NKed {
  position: relative;
  margin-right: var(--largeVertGutter);
}

.assets-components-Thumbnail-Video-index---root---2NKed > a {
  display: block;
}

.assets-components-Thumbnail-Video-index---video---1hnDV {
  border-radius: var(--elementBorderRadius);
  -o-object-fit: cover;
     object-fit: cover;
}

.assets-components-Thumbnail-Video-index---overlay---wD1JG {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  border-radius: var(--elementBorderRadius);
  color: white;
  opacity: 0.5;
  text-align: center;
}

.assets-components-Thumbnail-Video-index---playIcon---30Zs6 {
  font-size: var(--display2FontSize);
}

.assets-components-Thumbnail-Video-index---count---20AHo {
  position: absolute;
  right: 0;
  bottom: 0;
  width: var(--badgeSize);
  height: var(--badgeSize);
  line-height: var(--badgeSize);
  background-color: var(--inky400);
  border-bottom-right-radius: var(--elementBorderRadius);
  border-top-left-radius: var(--elementBorderRadius);
  color: white;
  font-size: var(--captionFontSize);
  text-align: center;
}
