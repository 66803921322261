.assets-components-common-content-FullContent-index---root---2Ll8- {
}

.assets-components-common-content-FullContent-index---textContainer---UTG8x {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  border-bottom: 1px solid var(--inky200);
}

.assets-components-common-content-FullContent-index---variationTitle---MS-Gz {
  display: flex;
  align-items: center;
  margin-bottom: var(--midVertGutter);
  font-size: var(--subheadingFontSize);
  font-weight: var(--semiBold);
}

.assets-components-common-content-FullContent-index---expiredTitle---Os9gv {
  margin-bottom: var(--miniVertGutter);
  color: var(--inky300);
  font-size: var(--captionFontSize);
}

.assets-components-common-content-FullContent-index---expiredTitle---Os9gv::before {
  margin: 0 var(--tinyHorzGutter);
  content: "·";
}

.assets-components-common-content-FullContent-index---destinationLink---1Ipx8 {
  overflow: hidden;

  /* margin-top: var(--tinyVertGutter); */
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assets-components-common-content-FullContent-index---pinterestIcon---2ZWm7 {
  margin-right: var(--tinyHorzGutter);
}

.assets-components-common-content-FullContent-index---variationContentContainer---1o_CY,
.assets-components-common-content-FullContent-index---pinterestDetailContainer---1TTvz {
  display: flex;
}

.assets-components-common-content-FullContent-index---pinterestDetailContainer---1TTvz {
  margin-top: var(--largeVertGutter);
}

.assets-components-common-content-FullContent-index---pinterestTitleAndLink---30jrH {
  margin-left: var(--tinyHorzGutter);
}

.assets-components-common-content-FullContent-index---pinterestTitleAndLink---30jrH > div {
  line-height: var(--captionLineHeight);
  font-size: var(--captionFontSize);
}

.assets-components-common-content-FullContent-index---linkPreviewContainer---3Lrbb {
  margin-top: var(--largeVertGutter);
}
