.assets-pages-Library-index---title---2O09- {
  display: flex;
  align-items: center;
  margin-bottom: var(--largeVertGutter);
}

.assets-pages-Library-index---title---2O09- > h1 {
  margin-bottom: 0;
}

.assets-pages-Library-index---alert---3O4H2 {
  margin: var(--midVertGutter) 0;
}

.assets-pages-Library-index---information---DMqrL {
  margin-top: var(--largeVertGutter);
}

.assets-pages-Library-index---alertBody---1zfMZ {
  display: flex;
  width: 100%;
}

.assets-pages-Library-index---message---3QUek {
  flex: 1;
}
