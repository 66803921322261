.assets-components-LoadingBar-index---container---2rmuX {
  position: relative;
  height: var(--smallVertGutter);
  background: var(--inky100);
  border-radius: var(--elementBorderRadius);
}

.assets-components-LoadingBar-index---bar---3SMvq {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: var(--kelp400);
  border-radius: var(--elementBorderRadius);
  transition: width 0.2s;
}

.assets-components-LoadingBar-index---barAuto---3d72n {
  animation: assets-components-LoadingBar-index---loadingbarauto---3rDhG 4s ease 1.75s infinite;
}

@keyframes assets-components-LoadingBar-index---loadingbarauto---3rDhG {
  0% {
    width: 0%;
  }

  87.5% {
    width: 100%;
  }
}
