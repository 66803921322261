.assets-components-TeamManager-UpdateInvitation-index---main---3r8aS {
  padding: var(--midVertGutter) 0 var(--largeVertGutter);
}

.assets-components-TeamManager-UpdateInvitation-index---sectionFieldDescription---3e_qI {
  display: block;
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-components-TeamManager-UpdateInvitation-index---sectionFieldInput---3Uqc1 {
  margin-top: var(--midHorzGutter);
}

.assets-components-TeamManager-UpdateInvitation-index---sectionFieldInput---3Uqc1:first-of-type {
  margin-top: var(--tinyVertGutter);
}

.assets-components-TeamManager-UpdateInvitation-index---radioButtonOverride---14qhv {
  margin-top: 3px;
}

.assets-components-TeamManager-UpdateInvitation-index---buttonCancel---2DAiW {
  margin-right: var(--smallVertGutter);
}

.assets-components-TeamManager-UpdateInvitation-index---roleDescription---3T2jw {
  font-size: var(--captionFontSize);
}
