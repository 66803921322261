.assets-pages-Schedule-OldScheduleModal-index---modalBody---39rpi {
  display: flex;
  flex-direction: row;
}

.assets-pages-Schedule-OldScheduleModal-index---modalFooter---3IPBB {
  display: flex;
  flex-direction: row-reverse;
}

.assets-pages-Schedule-OldScheduleModal-index---dayOptions---ukFOv {
  display: flex;
  flex-wrap: wrap;
}

.assets-pages-Schedule-OldScheduleModal-index---dayOption---3urZz {
  min-width: 65px;
  margin: 2.5px 0;
}

@media (min-width: 485px) {
  .assets-pages-Schedule-OldScheduleModal-index---dayOptions---ukFOv {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .assets-pages-Schedule-OldScheduleModal-index---dayOption---3urZz {
    min-width: 0;
    margin: 0;
  }
}

.assets-pages-Schedule-OldScheduleModal-index---accountSelect---1YtxN {
  flex: 1;
  max-width: 280px;
  padding-top: var(--midVertGutter);
  border-right: 1px solid var(--inky100);
}

.assets-pages-Schedule-OldScheduleModal-index---sendAtAndCategory---2mdZ6 {
  flex: 4;
  overflow: hidden;
  padding-top: var(--midVertGutter);
}

@media (min-width: 700px) {
  .assets-pages-Schedule-OldScheduleModal-index---sendAtAndCategory---2mdZ6 {
    margin-left: var(--midHorzGutter);
  }
}

.assets-pages-Schedule-OldScheduleModal-index---sendAtAndCategory---2mdZ6 > * {
  margin-bottom: var(--midVertGutter);
}

.assets-pages-Schedule-OldScheduleModal-index---sendAtAndCategoryLabel---2doml {
  margin-bottom: var(--tinyVertGutter);
}

.assets-pages-Schedule-OldScheduleModal-index---sendAt---2TMk- {
  display: flex;
  align-items: center;
}

.assets-pages-Schedule-OldScheduleModal-index---time---1gkwt {
  flex-basis: 120px;
  margin-right: var(--tinyHorzGutter);
}

.assets-pages-Schedule-OldScheduleModal-index---day---36r5J {
  flex: 1;
  margin-left: var(--tinyHorzGutter);
}

.assets-pages-Schedule-OldScheduleModal-index---secondaryActions---2fYUS {
  margin-right: var(--midHorzGutter);
}

@media (max-width: 700px) {
  .assets-pages-Schedule-OldScheduleModal-index---modalBody---39rpi {
    flex-direction: column-reverse;
  }

  .assets-pages-Schedule-OldScheduleModal-index---accountSelect---1YtxN {
    max-width: 100%;
    border-right: none;
  }

  .assets-pages-Schedule-OldScheduleModal-index---sendAt---2TMk- {
    flex-direction: column;
    align-items: flex-start;
  }

  .assets-pages-Schedule-OldScheduleModal-index---time---1gkwt {
    width: 100%;
    margin: 0;
  }

  .assets-pages-Schedule-OldScheduleModal-index---day---36r5J {
    width: 100%;
    margin: 0;
  }
}
