.assets-components-SubscriptionManager-Usage-ModalAddAccounts-index---plan---1rOh3 {
  padding: var(--midVertGutter) var(--midHorzGutter);
  border: 1px solid var(--inky200);
  border-radius: var(--elementBorderRadius);
}

.assets-components-SubscriptionManager-Usage-ModalAddAccounts-index---selectablePlan---SpBRo {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.assets-components-SubscriptionManager-Usage-ModalAddAccounts-index---unselectedPlan---32fyc {
  border: 0;
  background: var(--inky100);
}

.assets-components-SubscriptionManager-Usage-ModalAddAccounts-index---selectedPlan---X0YtP {
  border: 1px solid var(--kelp400);
  box-shadow: var(--lightBoxShadow);
}

.assets-components-SubscriptionManager-Usage-ModalAddAccounts-index---radioButton---lQhFF {
  margin: var(--tinyHorzGutter) var(--smallHorzGutter) 0 0;
}

.assets-components-SubscriptionManager-Usage-ModalAddAccounts-index---labelOverride---33stW {
  cursor: pointer;
}

.assets-components-SubscriptionManager-Usage-ModalAddAccounts-index---savings---1Vo6y {
  color: var(--kelp400);
}

.assets-components-SubscriptionManager-Usage-ModalAddAccounts-index---add_accounts_modal---1ytTe main {
  overflow: hidden;
}

.assets-components-SubscriptionManager-Usage-ModalAddAccounts-index---socialAccModal---3zFoR {
  justify-content: end !important;
}

.assets-components-SubscriptionManager-Usage-ModalAddAccounts-index---socialAccModal---3zFoR > div {
  flex: inherit !important;
}

