.assets-components-common-buttons-AnimatedLoadingLabel-index---indicator---2KIfT {
  position: relative;
}

.assets-components-common-buttons-AnimatedLoadingLabel-index---indicatorChildren---1KuoT {
  visibility: hidden;
}

.assets-components-common-buttons-AnimatedLoadingLabel-index---icon---1NEhI {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
}
