.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFour-index---container---35EEu {
  flex-direction: column;
  width: 480px;
  height: 480px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFour-index---primaryImage---mjY0b {
  width: 317px;
  height: 476px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFour-index---image---3wB-H {
  width: 157px;
  height: 157px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFour-index---image---3wB-H > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFour-index---primaryImage---mjY0b > div {
  border-radius: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFour-index---overlay---rwyL8 {
  line-height: 157px;
  font-size: 36px;
}
