.assets-pages-NewComposer-Variation-TextField-AIGenerator-CreditRemainingTooltip-index---root---1yTyD {
  color: #f8f8f8;
  cursor: pointer;
  margin-left: 6px;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-CreditRemainingTooltip-index---previewButtonTooltip---l5KPF {
  text-align: center;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-CreditRemainingTooltip-index---postPreviewButtonTooltipTether---qbZ5a {
  align-self: center;
}
