.assets-pages-Composer-Variation-Attachments-common-MediaList-index---caption---23jR_ {
  margin-bottom: var(--tinyVertGutter);
}

.assets-pages-Composer-Variation-Attachments-common-MediaList-index---overlay---3dVks {
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  line-height: var(--previewImageSize);
  border-radius: var(--elementBorderRadius);
  text-align: center;
}

.assets-pages-Composer-Variation-Attachments-common-MediaList-index---loadingIcon---751yr {
  margin-top: 1em;
  font-size: 4em;
}
