.assets-components-ErrorWithRetry-index---error---1apph {
  margin: var(--midVertGutter) 0;
}

.assets-components-ErrorWithRetry-index---errorBody---iJm8E {
  display: flex;
  width: 100%;
}

.assets-components-ErrorWithRetry-index---errorMessage---X1CPA {
  flex: 1;
}

a.assets-components-ErrorWithRetry-index---retryLink---1mAmU,
a.assets-components-ErrorWithRetry-index---retryLink---1mAmU:active,
a.assets-components-ErrorWithRetry-index---retryLink---1mAmU:focus,
a.assets-components-ErrorWithRetry-index---retryLink---1mAmU:visited,
a.assets-components-ErrorWithRetry-index---retryLink---1mAmU:hover {
  color: var(--coral400);
}
