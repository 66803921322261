.assets-pages-Composer-Variation-TextField-PostEditor-index---editor---CqCPj .public-DraftEditor-content {
  min-height: calc(var(--textAreaLineHeight) * 5);
}

.assets-pages-Composer-Variation-TextField-PostEditor-index---editor---CqCPj .public-DraftEditorPlaceholder-root {
  position: relative;
  color: var(--inky300);
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.assets-pages-Composer-Variation-TextField-PostEditor-index---editor---CqCPj .public-DraftEditorPlaceholder-inner {
  position: absolute;
}

.assets-pages-Composer-Variation-TextField-PostEditor-index---emojiSuggestionsContainer---3E_L5 img {
  margin-bottom: var(--bugLineHeight);
}
