@value var(--badgeSize): calc(var(--avatarSize) / 2);

.assets-components-common-icons-AccountAvatarWithBadge-index---root---2ZdZ7 {
  position: relative;
  display: inline-block;
  width: calc(var(--avatarSize) + (var(--avatarSize) / 4));
  height: calc(var(--avatarSize) + (var(--avatarSize) / 4));
}

.assets-components-common-icons-AccountAvatarWithBadge-index---tiny---JyEGI {
  width: calc(calc(var(--avatarSize) + (var(--avatarSize) / 4)) * 0.666);
  height: calc(calc(var(--avatarSize) + (var(--avatarSize) / 4)) * 0.666);
}

.assets-components-common-icons-AccountAvatarWithBadge-index---small---i_uCq {
  width: calc(calc(var(--avatarSize) + (var(--avatarSize) / 4)) * 1);
  height: calc(calc(var(--avatarSize) + (var(--avatarSize) / 4)) * 1);
}

.assets-components-common-icons-AccountAvatarWithBadge-index---medium---1cgJr {
  width: calc(calc(var(--avatarSize) + (var(--avatarSize) / 4)) * 1.666);
  height: calc(calc(var(--avatarSize) + (var(--avatarSize) / 4)) * 1.666);
}

.assets-components-common-icons-AccountAvatarWithBadge-index---avatar---8bJkD {
  display: inline-block;
  width: var(--avatarSize);
  height: var(--avatarSize);
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: var(--elementBorderRadius);
  color: transparent;
}

.assets-components-common-icons-AccountAvatarWithBadge-index---tiny---JyEGI .assets-components-common-icons-AccountAvatarWithBadge-index---avatar---8bJkD {
  width: calc(var(--avatarSize) * 0.666);
  height: calc(var(--avatarSize) * 0.666);
}

.assets-components-common-icons-AccountAvatarWithBadge-index---small---i_uCq .assets-components-common-icons-AccountAvatarWithBadge-index---avatar---8bJkD {
  width: calc(var(--avatarSize) * 1);
  height: calc(var(--avatarSize) * 1);
}

.assets-components-common-icons-AccountAvatarWithBadge-index---medium---1cgJr .assets-components-common-icons-AccountAvatarWithBadge-index---avatar---8bJkD {
  width: calc(var(--avatarSize) * 1.666);
  height: calc(var(--avatarSize) * 1.666);
}

.assets-components-common-icons-AccountAvatarWithBadge-index---platformIcon---9W7K0 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(var(--badgeSize) / 2);
  height: calc(var(--badgeSize) / 2);
  border-radius: var(--elementBorderRadius);
}

.assets-components-common-icons-AccountAvatarWithBadge-index---tiny---JyEGI .assets-components-common-icons-AccountAvatarWithBadge-index---platformIcon---9W7K0 {
  width: calc((var(--badgeSize) / 2) * 0.666);
  height: calc((var(--badgeSize) / 2) * 0.666);
}

.assets-components-common-icons-AccountAvatarWithBadge-index---small---i_uCq .assets-components-common-icons-AccountAvatarWithBadge-index---platformIcon---9W7K0 {
  width: calc((var(--badgeSize) / 2) * 1);
  height: calc((var(--badgeSize) / 2) * 1);
}

.assets-components-common-icons-AccountAvatarWithBadge-index---medium---1cgJr .assets-components-common-icons-AccountAvatarWithBadge-index---platformIcon---9W7K0 {
  width: calc((var(--badgeSize) / 2) * 1.666);
  height: calc((var(--badgeSize) / 2) * 1.666);
}

.assets-components-common-icons-AccountAvatarWithBadge-index---tipBody---dqgC6 {
  font-family: var(--primaryFont);
}

.assets-components-common-icons-AccountAvatarWithBadge-index---tipCaption---2gIU7 {
  overflow: hidden;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
}
