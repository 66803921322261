.assets-components-common-layout-Expander-index---root---3cxvg {
  transition-duration: 0.3s;
}

.assets-components-common-layout-Expander-index---collapsed---oMKdi {
  overflow: hidden;
  max-height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  border-width: 0 !important;
}

.assets-components-common-layout-Expander-index---expanded---2I-UY {
  overflow: hidden;
  max-height: 700px;
}
