.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-index---root---1umKW {
  margin: calc(var(--smallVertGutter) - 1px) calc(var(--smallHorzGutter) + 2px);
  line-height: var(--captionLineHeight);
  color: var(--twitterTextDark);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: calc(var(--bodyFontSize) - 1px);
  -webkit-font-smoothing: antialiased;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-index---loading---3LjG0 {
  padding-left: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-index---root---1umKW a {
  cursor: pointer;
  text-decoration: none;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-index---root---1umKW a:hover {
  color: var(--linkedin); /* TODO: check color */
  text-decoration: underline;
}
