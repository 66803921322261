.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-Video-index---root---2IwJe {
  position: relative;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-Video-index---root---2IwJe .assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-Video-index---video---1hKTq {
  overflow: hidden;
  max-height: 625px;
  -o-object-fit: cover;
     object-fit: cover;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-Video-index---root---2IwJe .rh5v-Overlay_component {
  display: none;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-Video-index---timer---1EzLa {
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
}
