.assets-pages-Errors-index---titleWrapper---3m5vf {
  display: flex;
  justify-content: space-between;
}

.assets-pages-Errors-index---loading---1N1XP {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-pages-Errors-index---errorWrapper---2ZWqJ {
  display: flex;
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  border: 1px solid var(--inky200);
  margin-top: calc(2 * var(--smallVertGutter));
  background-color: #fff;
  border-radius: var(--elementBorderRadius);
}

.assets-pages-Errors-index---error---3GAVM {
  flex: 1;
  margin-left: var(--midHorzGutter);
}

.assets-pages-Errors-index---withoutPermission---325mA {
  margin: 17.5px 0 0;
}

.assets-pages-Errors-index---withoutPermission---325mA h4 {
  margin: 0 0 3px;
  font-weight: 700;
}

.assets-pages-Errors-index---withoutPermission---325mA ul {
  padding: 0 0 0 26px;
}
