@keyframes assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---fade---2uHq- {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---root---3eRUO {
  width: 40px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.67);
  border-radius: 15px;
  color: white;
  letter-spacing: 2px;
  text-align: center;
}

/* See FADE_OUT_TIME in index.js, should match (delay + animation time) here */
.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---appearActive---1F5GC,
.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---enterActive---zpxOS {
  animation: assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---fade---2uHq- 0.75s linear 1s forwards;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---exit---1Ee5m,
.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---exitActive---20MyB,
.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---exitDone---S7yR9,
.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-ImageXOfYIndicator-index---enterDone---2ympw {
  opacity: 0;
}
