.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-Scroller-index---root---l4pAU {
  width: 500px;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-Scroller-index---root---l4pAU img {
  height: 30px;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-Scroller-index---rightChevron---c8LyD {
  float: right;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-Scroller-index---leftChevron---3jCbh:hover,
.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-Scroller-index---rightChevron---c8LyD:hover {
  cursor: pointer;
}
