.assets-components-common-AccountSelect-EmptyState-index---noAccountsHeader---LaeE8 {
  padding: 0 0 var(--tinyHorzGutter);
  border-bottom: solid 1px var(--inky200);
}

.assets-components-common-AccountSelect-EmptyState-index---socialIcon---1mCB3 {
  margin: 20px 18px 0 0;
  font-size: 36px;
}

.assets-components-common-AccountSelect-EmptyState-index---socialIcon---1mCB3.assets-components-common-AccountSelect-EmptyState-index---facebook---3cVDA {
  color: var(--facebook);
}

.assets-components-common-AccountSelect-EmptyState-index---socialIcon---1mCB3.assets-components-common-AccountSelect-EmptyState-index---linkedin---1ihuR {
  color: var(--linkedin);
}

.assets-components-common-AccountSelect-EmptyState-index---socialIcon---1mCB3.assets-components-common-AccountSelect-EmptyState-index---twitter---16SMV {
  color: var(--twitter);
}

.assets-components-common-AccountSelect-EmptyState-index---socialIcon---1mCB3.assets-components-common-AccountSelect-EmptyState-index---instagram---3mW6j {
  color: var(--instagram);
}

.assets-components-common-AccountSelect-EmptyState-index---socialIcon---1mCB3.assets-components-common-AccountSelect-EmptyState-index---pinterest---Qw0-x {
  color: var(--pinterest);
}
