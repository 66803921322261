.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Three-index---container---2j8gf {
  width: 551px;
  height: 551px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Three-index---primaryImage---1KAiE {
  width: 551px;
  height: 330px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Three-index---image---1nV5W {
  width: 274px;
  height: 221px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Three-index---image---1nV5W > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Three-index---primaryImage---1KAiE > div {
  border-radius: 0;
}
