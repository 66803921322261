
.assets-pages-Payment-PlanSelector-RadioButton-index---title---2zxYi {
  line-height: var(--captionLineHeight);
  font-size: var(--subheadingFontSize);
  font-weight: bold;
}

.assets-pages-Payment-PlanSelector-RadioButton-index---children---3sovO {
  font-weight: normal;
}
