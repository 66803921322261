.assets-components-common-content-AbbreviatedContent-Layout-index---root---uNsEf {
  margin-bottom: var(--midVertGutter);
}

.assets-components-common-content-AbbreviatedContent-Layout-index---root---uNsEf:last-child {
  margin-bottom: 0;
}

.assets-components-common-content-AbbreviatedContent-Layout-index---primary---2tdho {
  display: flex;
  justify-content: space-between;
}

.assets-components-common-content-AbbreviatedContent-Layout-index---thumbnailAndTextContainer---2iH0e {
  display: flex;
}

.assets-components-common-content-AbbreviatedContent-Layout-index---moreVariationsContainer---dR5u1 {
  min-width: 100px;
  max-width: 100px;
  margin-left: var(--midHorzGutter);
}

.assets-components-common-content-AbbreviatedContent-Layout-index---text---1UJYP {
  -webkit-hyphens: auto;
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.assets-components-common-content-AbbreviatedContent-Layout-index---unqueueable---2vOSL {
  color: var(--inky300);
}

.assets-components-common-content-AbbreviatedContent-Layout-index---unqueueable---2vOSL a {
  color: var(--kelp100);
}

.assets-components-common-content-AbbreviatedContent-Layout-index---unqueueable---2vOSL img {
  opacity: 0.5;
}

.assets-components-common-content-AbbreviatedContent-Layout-index---linkPreviewContainer---2SaXX {
  margin-top: var(--midVertGutter);
}

.assets-components-common-content-AbbreviatedContent-Layout-index---accountsContainer---2Qec9 {
  margin-top: var(--largeVertGutter);
}

.assets-components-common-content-AbbreviatedContent-Layout-index---advancedSettingsDescriptionContainer---2MEEK {
  margin-top: var(--midVertGutter);
  color: var(--inky400);
}

.assets-components-common-content-AbbreviatedContent-Layout-index---destinationLink---32d1t {
  overflow: hidden;

  /* margin-top: var(--midVertGutter); */
  text-overflow: ellipsis;
  white-space: normal;
}

.assets-components-common-content-AbbreviatedContent-Layout-index---pinterestIcon---1qNow {
  margin-right: var(--tinyHorzGutter);
}

.assets-components-common-content-AbbreviatedContent-Layout-index---variationContentContainer---3gySg,
.assets-components-common-content-AbbreviatedContent-Layout-index---pinterestDetailContainer---3_cnG {
  display: flex;
}

.assets-components-common-content-AbbreviatedContent-Layout-index---pinterestDetailContainer---3_cnG {
  margin-top: var(--largeVertGutter);
}

.assets-components-common-content-AbbreviatedContent-Layout-index---pinterestTitleAndLink---29nVY {
  margin-left: var(--tinyHorzGutter);
}

.assets-components-common-content-AbbreviatedContent-Layout-index---pinterestTitleAndLink---29nVY > div {
  line-height: var(--captionLineHeight);
  font-size: var(--captionFontSize);
}
