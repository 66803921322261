.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-Scroller-index---root---1-Gv9 {
  width: 500px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-Scroller-index---root---1-Gv9 img {
  height: 30px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-Scroller-index---rightChevron---2JKlo {
  float: right;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-Scroller-index---leftChevron---1phPj:hover,
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-Scroller-index---rightChevron---2JKlo:hover {
  cursor: pointer;
}
