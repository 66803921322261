.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-Video-index---root---1wrie {
  position: relative;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-Video-index---root---1wrie .assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-Video-index---video---p7Ino {
  overflow: hidden;
  max-height: 625px;
  -o-object-fit: cover;
     object-fit: cover;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-Video-index---root---1wrie .rh5v-Overlay_component {
  display: none;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-Video-index---timer---1seqP {
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
}
