.assets-components-common-NewAccountSelect-Option-index---root---Ey6fI {
  display: flex;
  flex: 1;
  align-items: flex-start;
  overflow: hidden;
  min-width: 50%;
  max-width: 50%;
  padding: 10px 10px;
  border-radius: var(--elementBorderRadius) 0 0 var(--elementBorderRadius);
  cursor: pointer;
  text-overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.assets-components-common-NewAccountSelect-Option-index---checkboxWrapper---5rRC- {
  margin-top: 9px;
}

.assets-components-common-NewAccountSelect-Option-index---root---Ey6fI:hover {
  background-color: var(--inky100);
}

.assets-components-common-NewAccountSelect-Option-index---root---Ey6fI[disabled] {
  opacity: var(--disabledOpacity);
}

.assets-components-common-NewAccountSelect-Option-index---root---Ey6fI[disabled]:hover {
  background-color: white;
}

.assets-components-common-NewAccountSelect-Option-index---fullWidth---6R_qY {
  min-width: 100%;
}

.assets-components-common-NewAccountSelect-Option-index---tooltipped---2XW6Y {
  min-width: 50%;
  max-width: 50%;
}

.assets-components-common-NewAccountSelect-Option-index---account---21gCo {
  flex: 1;
  overflow: hidden;
}

.assets-components-common-NewAccountSelect-Option-index---accountInfo---A9P8- {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.assets-components-common-NewAccountSelect-Option-index---details---3pWRT {
  flex: 1;
  overflow: hidden;
  padding-right: var(--midHorzGutter);
}

.assets-components-common-NewAccountSelect-Option-index---name---1uXe5 {
  line-height: 20px; /* prevent low descending characters from getting clipped */
}

.assets-components-common-NewAccountSelect-Option-index---name---1uXe5,
.assets-components-common-NewAccountSelect-Option-index---caption---1gijr {
  overflow: hidden;
  width: 11.4em;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assets-components-common-NewAccountSelect-Option-index---providerOptions---3c_qD {
  width: 100%;
  cursor: default;
}

.assets-components-common-NewAccountSelect-Option-index---providerOptions---3c_qD > * {
  margin-top: var(--tinyVertGutter);
}
