.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-SquareFour-index---container---2n_w- {
  width: 478px;
  height: 478px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-SquareFour-index---image---3gnsk {
  width: 237px;
  height: 237px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-SquareFour-index---image---3gnsk > div {
  border-radius: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-SquareFour-index---overlay---32Dvl {
  line-height: 237px;
  font-size: 36px;
}
