/* Safari is calculating width as 0 which causes problems when this is flex child */
.assets-components-common-forms-CheckboxInput-index---root---3cAwd {
  display: flex;
  align-items: center;
  min-width: calc(var(--largeIconSize) + var(--smallHorzGutter));
}

.assets-components-common-forms-CheckboxInput-index---right---3Okgi {
  flex-direction: row;
}

.assets-components-common-forms-CheckboxInput-index---right---3Okgi > label {
  margin-left: var(--tinyHorzGutter);
}

.assets-components-common-forms-CheckboxInput-index---bottom---3NksJ {
  flex-direction: column;
}

.assets-components-common-forms-CheckboxInput-index---rootExtraMargin---1zFBK {
  min-width: calc(var(--largeIconSize) + var(--midHorzGutter));
}

.assets-components-common-forms-CheckboxInput-index---small---1yBX0 {
  min-width: calc(var(--largeIconSize) + var(--tinyHorzGutter));
}

.assets-components-common-forms-CheckboxInput-index---icon---1s2jN {
  width: var(--largeIconSize);
  height: var(--largeIconSize);
  cursor: pointer;
}

.assets-components-common-forms-CheckboxInput-index---icon---1s2jN[disabled] {
  opacity: var(--disabledOpacity);
}

.assets-components-common-forms-CheckboxInput-index---extraMargin---14xqT {
  margin-right: var(--midHorzGutter);
}

.assets-components-common-forms-CheckboxInput-index---small---1yBX0 .assets-components-common-forms-CheckboxInput-index---icon---1s2jN {
  margin-right: var(--tinyHorzGutter);
}

.assets-components-common-forms-CheckboxInput-index---icon---1s2jN:hover {
  color: var(--inky200);
}

.assets-components-common-forms-CheckboxInput-index---checked---jodkN {
  color: var(--kelp400);
}

.assets-components-common-forms-CheckboxInput-index---checked---jodkN:hover {
  color: var(--kelp300);
}

.assets-components-common-forms-CheckboxInput-index---small---1yBX0 .assets-components-common-forms-CheckboxInput-index---label---3c_8Z {
  font-size: var(--captionFontSize);
  font-weight: normal;
}

.assets-components-common-forms-CheckboxInput-index---labelDisabled---3u7EQ {
  color: var(--inky300);
}

.assets-components-common-forms-CheckboxInput-index---hasError---3Am7s {
  border: 1px solid var(--dangerColor);
  border-radius: var(--borderRadius);
}
