.assets-pages-Composer-Variation-Attachments-MediaAttacher-index---noScrapedImagesNotice---30eGi {
  padding: 58px 0; /* Props open notice to same height as media list */
  color: var(--inky300);
  font-size: 15px;
  text-align: center;
}

.assets-pages-Composer-Variation-Attachments-MediaAttacher-index---mediaListExpander---2aJ3Q {
  margin-top: var(--smallVertGutter);
}

.assets-pages-Composer-Variation-Attachments-MediaAttacher-index---mediaListCaption---1Q6aI {
  display: flex;
  align-items: center;
}

.assets-pages-Composer-Variation-Attachments-MediaAttacher-index---imageIcon---3gklj {
  height: var(--captionFontSize);
  margin-right: var(--tinyHorzGutter);
}

.assets-pages-Composer-Variation-Attachments-MediaAttacher-index---attachmentContainer---2PlKG {
  padding-top: var(--smallVertGutter);
  padding-bottom: var(--smallVertGutter);
}
