.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-index---root---3f09Z {
  padding-left: 58px;
  margin: 9px 12px;
  line-height: 18px;
  color: var(--twitterTextDark);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-index---loading---13QDY {
  padding-left: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-index---root---3f09Z a {
  cursor: pointer;
  text-decoration: none;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-index---root---3f09Z a:hover {
  color: var(--twitterLinkBlue);
  text-decoration: underline;
}
