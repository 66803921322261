.assets-components-common-layout-Information-index---root---ltjOO {
  position: relative;
  display: flex;
  border: 1px dashed var(--inky200);
  background: var(--inky50);
  border-radius: var(--elementBorderRadius);
}

.assets-components-common-layout-Information-index---icon---1mBeN {
  padding: var(--largeVertGutter) var(--midHorzGutter) var(--largeVertGutter)
    var(--largeHorzGutter);
}

.assets-components-common-layout-Information-index---body---1qW2h {
  flex-grow: 1;
  margin: var(--largeVertGutter) 0;
}

.assets-components-common-layout-Information-index---close---2hUmJ {
  margin: var(--tinyVertGutter) var(--smallHorzGutter) 0 var(--tinyHorzGutter);
}
