.assets-pages-Queue-UpcomingPostList-Post-index---header---3Ypoi {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: var(--midVertGutter);
}

.assets-pages-Queue-UpcomingPostList-Post-index---accountError---3B2Ly {
  flex-basis: 100%;
  margin-bottom: var(--midVertGutter);
  color: var(--coral400);
}

.assets-pages-Queue-UpcomingPostList-Post-index---accountError---3B2Ly > a,
.assets-pages-Queue-UpcomingPostList-Post-index---accountError---3B2Ly > a:active,
.assets-pages-Queue-UpcomingPostList-Post-index---accountError---3B2Ly > a:focus,
.assets-pages-Queue-UpcomingPostList-Post-index---accountError---3B2Ly > a:visited,
.assets-pages-Queue-UpcomingPostList-Post-index---accountError---3B2Ly > a:hover {
  color: var(--coral400);
}

.assets-pages-Queue-UpcomingPostList-Post-index---left---2RZIT {
  display: flex;
  flex: 2;
  flex-wrap: wrap;
  align-items: center;
  height: calc(var(--avatarSize) * 1.666);
  margin: 0 var(--smallHorzGutter);
}

.assets-pages-Queue-UpcomingPostList-Post-index---accountDisplayName---2Bjqu {
  margin: 0 var(--tinyHorzGutter);
  color: var(--inky400);
}

.assets-pages-Queue-UpcomingPostList-Post-index---pinterestBoard---2a1rb {
  flex-basis: 100%;
  color: var(--inky400);
}

.assets-pages-Queue-UpcomingPostList-Post-index---right---RGcag {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: flex-end;
  height: calc(var(--avatarSize) * 1.666);
}

.assets-pages-Queue-UpcomingPostList-Post-index---twoActions---1YOkZ {
  display: flex;
  width: 88px;
}

.assets-pages-Queue-UpcomingPostList-Post-index---oneAction---2KMM5 {
  display: flex;
  width: 54px;
}

.assets-pages-Queue-UpcomingPostList-Post-index---scheduledAt---1_jpp {
  margin-right: var(--smallHorzGutter);
  text-align: right;
}

.assets-pages-Queue-UpcomingPostList-Post-index---actionButton---3SRiu {
  padding: 0 var(--tinyHorzGutter);
  border: 1px solid var(--inky200);
  border-radius: var(--elementBorderRadius);
  color: var(--inky400);
}

.assets-pages-Queue-UpcomingPostList-Post-index---actionButton---3SRiu:hover {
  background-color: var(--inky100);
}

.assets-pages-Queue-UpcomingPostList-Post-index---actionButton---3SRiu:link,
.assets-pages-Queue-UpcomingPostList-Post-index---actionButton---3SRiu:visited,
.assets-pages-Queue-UpcomingPostList-Post-index---actionButton---3SRiu:active {
  color: var(--inky400);
}

.assets-pages-Queue-UpcomingPostList-Post-index---trashButton----rujM {
  margin-right: var(--tinyHorzGutter);
  cursor: pointer;
}

.assets-pages-Queue-UpcomingPostList-Post-index---skipButton---yygoZ {
  margin-right: var(--tinyHorzGutter);
  cursor: pointer;
}

.assets-pages-Queue-UpcomingPostList-Post-index---editButton---23BFp {
}

.assets-pages-Queue-UpcomingPostList-Post-index---mainContent---2E5J_ {
  display: flex;
  align-items: flex-start;
}

.assets-pages-Queue-UpcomingPostList-Post-index---text---qQYbA {
  word-break: break-word;
}

.assets-pages-Queue-UpcomingPostList-Post-index---attachment---2SQI3 {
  width: 75px;
  height: 75px;
  margin-right: var(--largeHorzGutter);
  border-radius: var(--elementBorderRadius);
  -o-object-fit: cover;
     object-fit: cover;
}

.assets-pages-Queue-UpcomingPostList-Post-index---linkPreview---VO5G- {
  margin-top: var(--midVertGutter);
}

.assets-pages-Queue-UpcomingPostList-Post-index---destinationLink---3bGSx {
  margin-top: var(--midVertGutter);
}

.assets-pages-Queue-UpcomingPostList-Post-index---footer---MlIZa {
  margin-top: var(--largeVertGutter);
}

.assets-pages-Queue-UpcomingPostList-Post-index---selectedViaRandom---2NxhL::before {
  padding: var(--tinyHorzGutter);
  content: "\00B7";
}

.assets-pages-Queue-UpcomingPostList-Post-index---expires---2YEgT::before {
  padding: var(--tinyHorzGutter);
  content: "\00B7";
}
