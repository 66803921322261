.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Image-EditImageModal-index---modal---9iu-B {
  max-width: 500px;
  padding: 0;
}

@media (min-width: 320px) {
  .assets-pages-NewComposer-Variation-Attachments-common-MediaList-Image-EditImageModal-index---modal---9iu-B {
    width: 310px;
  }
}

@media (min-width: 400px) {
  .assets-pages-NewComposer-Variation-Attachments-common-MediaList-Image-EditImageModal-index---modal---9iu-B {
    width: 380px;
  }
}

@media (min-width: 540px) {
  .assets-pages-NewComposer-Variation-Attachments-common-MediaList-Image-EditImageModal-index---modal---9iu-B {
    width: 1100px;
  }
}

@media (min-width: 850px) {
  .assets-pages-NewComposer-Variation-Attachments-common-MediaList-Image-EditImageModal-index---modal---9iu-B {
    max-width: 90%;
  }
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Image-EditImageModal-index---buttonSave---fLcY0 {
  width: 49%;
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Image-EditImageModal-index---buttonCancel---3uK7r {
  width: 49%;
  margin-left: 2%;
}
