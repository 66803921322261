.assets-pages-Composer-Variation-TextField-BlurredInput-index---root---261Kj {
  display: -webkit-box;
  overflow: hidden;
  width: 100%;
  height: calc(var(--midInputHeight) * 3);
  line-height: var(--midInputHeight);
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

@-moz-document url-prefix() {
  .assets-pages-Composer-Variation-TextField-BlurredInput-index---root---261Kj {
    display: inline-block;
    height: var(--midInputHeight);
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
