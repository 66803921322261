/* simple gutters are used when <header /> / <footer /> not present */

.assets-components-common-layout-modals-Modal-index---overlay---2FPX_ {
  position: fixed;
  z-index: var(--modalZIndex);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--inky300);
}

.assets-components-common-layout-modals-Modal-index---root---_fTtp {
  position: fixed;
  z-index: calc(var(--modalZIndex) + 1);
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 80vh;
  padding: 36px 35px;
  background-color: white;
  border-radius: var(--elementBorderRadius);
  box-shadow: 0 1px 2px 0 var(--inky300);
  outline: none;
  text-align: left;
  transform: translateX(-50%) translateY(-50%);
}

@media (min-width: 1100px) {
  .assets-components-common-layout-modals-Modal-index---newRootUI---_Mxi_ {
    /* padding-top: 0; */
    margin-left: calc(88px / 2);
  }
}

@media (min-width: 1300px) {
  .assets-components-common-layout-modals-Modal-index---newRootUI---_Mxi_ {
    margin-left: calc(272px / 2);
  }

  .assets-components-common-layout-modals-Modal-index---newRootUICollapsed---1rfNT {
    margin-left: calc(88px / 2);
  }

  .assets-components-common-layout-modals-Modal-index---newRootUICollapsedSchedule---M3e1A {
    margin-left: calc(88px / 2);
  }
}

@media (min-width: 1336px) {
  .assets-components-common-layout-modals-Modal-index---newRootUICollapsedSchedule---M3e1A {
    margin-left: calc(272px / 2);
  }
}

.assets-components-common-layout-modals-Modal-index---forceCenter---ou2uZ {
  margin-left: 0;
}

.assets-components-common-layout-modals-Modal-index---hasHeader---1H-DN,
.assets-components-common-layout-modals-Modal-index---hasFooter---3zTfT {
  padding: 0;
}

.assets-components-common-layout-modals-Modal-index---simpleHeader---3loQf {
  padding-bottom: 36px;
}

.assets-components-common-layout-modals-Modal-index---root---_fTtp > header {
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(var(--largeVertGutter) + var(--largeLineHeight) + var(--midVertGutter));
  padding: var(--largeVertGutter) 30px var(--midVertGutter);
  border-bottom: solid 1px var(--inky100);
}

.assets-components-common-layout-modals-Modal-index---simpleHeader---3loQf > header {
  border-bottom: 0;
}

.assets-components-common-layout-modals-Modal-index---main---1jo27 {
  overflow: auto;
  height: 100%;
  padding: 0;
}

.assets-components-common-layout-modals-Modal-index---hasHeader---1H-DN > main,
.assets-components-common-layout-modals-Modal-index---hasFooter---3zTfT > main {
  padding: 0 30px;
}

.assets-components-common-layout-modals-Modal-index---hasHeader---1H-DN > main {
  margin-top: calc(var(--largeVertGutter) + var(--largeLineHeight) + var(--midVertGutter));
}

.assets-components-common-layout-modals-Modal-index---hasFooter---3zTfT > main {
  margin-bottom: calc(var(--largeVertGutter) + 34px + var(--largeVertGutter));
}

.assets-components-common-layout-modals-Modal-index---main---1jo27::-webkit-scrollbar-thumb,
.assets-components-common-layout-modals-Modal-index---main---1jo27::-webkit-scrollbar-track {
  z-index: calc(var(--modalZIndex) + 2);
  transform: translate3d(0, 0, 0);
}

.assets-components-common-layout-modals-Modal-index---root---_fTtp > footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: calc(var(--largeVertGutter) + 34px + var(--largeVertGutter));
  padding: var(--largeVertGutter) 30px;
  border-top: solid 1px var(--inky100);
  margin: 0;
  background-color: white;
  text-align: right;
}

.assets-components-common-layout-modals-Modal-index---newFooter---YYfUZ,
.assets-components-common-layout-modals-Modal-index---root---_fTtp > footer.assets-components-common-layout-modals-Modal-index---newFooter---YYfUZ {
  border-top: none;
  display: flex;
  justify-content: end;
}

.assets-components-common-layout-modals-Modal-index---footer---kvaEg{
  display: flex;
  justify-content: end;
}

.assets-components-common-layout-modals-Modal-index---footer---kvaEg div {
  display: flex;
  justify-content: end;
  padding-bottom: 0 !important;
  align-items: center;
}

.assets-components-common-layout-modals-Modal-index---lockBodyScroll---2OhHH {
  overflow: scroll;
}

.assets-components-common-layout-modals-Modal-index---small---2TFXd {
  width: 400px;
}

.assets-components-common-layout-modals-Modal-index---medium---pGgwk {
  width: 500px;
}

.assets-components-common-layout-modals-Modal-index---large---ateOd {
  width: 600px;
}

.assets-components-common-layout-modals-Modal-index---legacy---3tUkL {
  width: 750px;
}

.assets-components-common-layout-modals-Modal-index---closeButton---2fIFY {
  position: absolute;
  z-index: calc(var(--modalZIndex) + 2);
  top: 10px;
  right: 10px;
  opacity: 0.6;
}
