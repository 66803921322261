.tether-element {
  z-index: var(--tooltipZIndex);
}

.assets-components-common-behavioral-tooltipper-index---root---ASCgE {
  font-family: var(--primaryFont);
  text-align: center;
}

.assets-components-common-behavioral-tooltipper-index---wrappedComponentContainer---1-G_d {
  display: inline-block;
  line-height: 0;
}

.assets-components-common-behavioral-tooltipper-index---hasTooltip---2Gh6- {
  cursor: help;
}

.assets-components-common-behavioral-tooltipper-index---tip---uO4IF {
  padding: var(--midVertGutter) var(--midHorzGutter);
  background-color: var(--inky500);
  border-radius: var(--elementBorderRadius);
  color: #fff;
}

.assets-components-common-behavioral-tooltipper-index---tipTitle---33ZYO {
  margin-bottom: var(--tinyVertGutter);
}

.assets-components-common-behavioral-tooltipper-index---tipSubtitle---1z5KJ {
  margin-left: var(--tinyVertGutter);
  font-size: var(--captionFontSize);
  font-weight: normal;
}

.assets-components-common-behavioral-tooltipper-index---tipBody---sxkK_ {
  text-align: left;
}

.assets-components-common-behavioral-tooltipper-index---tipBody---sxkK_ ul {
  padding-left: var(--midHorzGutter);
}

.assets-components-common-behavioral-tooltipper-index---downArrow---1UWQ4 {
  width: 0;
  height: 0;
  border-top: var(--smallVertGutter) solid var(--inky500);
  border-right: var(--midHorzGutter) solid transparent;
  border-left: var(--midHorzGutter) solid transparent;
  margin: 0 auto var(--tinyVertGutter);
}

.assets-components-common-behavioral-tooltipper-index---narrowDownArrow---3U9MA {
  width: 0;
  height: 0;
  border-top: 10px solid var(--inky500);
  border-right: 10px solid transparent;
  border-left: 0 solid transparent;
  margin: 0 auto var(--tinyVertGutter);
}

.assets-components-common-behavioral-tooltipper-index---leftAlignDownArrow---2sNeY {
  margin: -0.11em 0 0 0;
}

.assets-components-common-behavioral-tooltipper-index---autoArrow---2D6zh {
  position: relative;
}

.assets-components-common-behavioral-tooltipper-index---autoArrow---2D6zh::after {
  position: absolute;
  width: 0;
  height: 0;
  content: '';
}

.assets-components-common-behavioral-tooltipper-index---autoArrowTopCenter---1HVqN {
  margin-top: var(--smallVertGutter);
}

.assets-components-common-behavioral-tooltipper-index---autoArrowTopCenter---1HVqN::after {
  bottom: 100%;
  left: 50%;
  border-right: var(--midHorzGutter) solid transparent;
  border-bottom: var(--smallVertGutter) solid var(--inky500);
  border-left: var(--midHorzGutter) solid transparent;
  margin-left: calc(-1 * var(--midHorzGutter));
}
