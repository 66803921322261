.assets-components-common-buttons-AddButton-index---root---1Y_4D {
  display: inline-block;
  line-height: 1.42857143;
  background-color: #6ca8a9;
  background-image: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: var(--semiBold);
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.assets-components-common-buttons-AddButton-index---root---1Y_4D:hover {
  background-color: #609697;
  text-decoration: none;
}

.assets-components-common-buttons-AddButton-index---label---3n2Ez {
  display: inline-block;
  padding: 0.7em 1em 0.5em;
  border-radius: 0 4px 4px 0;
  color: white;
  text-decoration: none;
}

.assets-components-common-buttons-AddButton-index---icon---3rx6r {
  display: inline-block;
  padding: 0.7em 1em 0.5em;
  background-color: #88c0c1;
  border-radius: 4px 0 0 4px;
  color: white;
}

.assets-components-common-buttons-AddButton-index---root---1Y_4D:hover .assets-components-common-buttons-AddButton-index---icon---3rx6r {
  background-color: #79abac;
}
