
.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---root---3imFl {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 270px;
  height: 70px;
  padding: var(--tinyVertGutter) var(--smallHorzGutter) var(--miniVertGutter);
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---root---3imFl:active {
  background-color: var(--whale200);
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---root---3imFl:hover {
  background-color: var(--whale100);
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---rootFocused---2qJpi {
  background-color: var(--whale100);
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---imageContainer---3rqJK {
  flex: 1;
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---image---XO5T_ {
  display: inline-block;
  width: auto;
  height: auto;
  border-radius: var(--elementBorderRadius);
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---textContainer---3dT2g {
  display: flex;
  flex: 3;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---name---3H4yU,
.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---description---3IEsk {
  display: inline-block;
  align-self: flex-start;
  overflow: hidden;
  font-size: var(--bodyFontSize);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---description---3IEsk {
  color: var(--inky300);
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---checkCircle---1dRqh {
  color: var(--whale400);
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---infoIcon---1aRyS {
  padding-right: var(--tinyHorzGutter);
  color: var(--inky300);
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---edgarInfoEntry---1XJ_H {
  padding: 0 var(--tinyHorzGutter) var(--smallHorzGutter) var(--smallHorzGutter);
  line-height: var(--captionLineHeight);
  cursor: default;
  font-size: var(--captionFontSize);
}
