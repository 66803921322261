.assets-components-SubscriptionManager-index---loading---3bPEe {
  width: 100%;
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-components-SubscriptionManager-index---manager---21udw {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  margin-top: calc(2 * var(--smallVertGutter));
  background-color: #fff;
  border-radius: var(--cardBorderRadius);
  box-shadow: 0 2px 10px -3px var(--inky150);
}

.assets-components-SubscriptionManager-index---header---3nHIB {
  display: flex;
  justify-content: space-between;
}

.assets-components-SubscriptionManager-index---subheader---3h7uA {
  padding: var(--midVertGutter) 0;
}

.assets-components-SubscriptionManager-index---plans---3ep6c {
  display: grid;
  margin-bottom: calc(2 * var(--midVertGutter));
  grid-gap: calc(2 * var(--smallHorzGutter));
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .assets-components-SubscriptionManager-index---plans---3ep6c {
    margin-bottom: var(--midVertGutter);
    grid-template-columns: 1fr 2fr;
  }
}

.assets-components-SubscriptionManager-index---row---2WWkD {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--midVertGutter) 0;
  border-top: 1px solid var(--greybg);
}

.assets-components-SubscriptionManager-index---rowActions---2PnCq {
  justify-content: flex-end;
  border-top: 0;
}

.assets-components-SubscriptionManager-index---label---3TNF3 {
  width: 30%;
}

.assets-components-SubscriptionManager-index---button---1R_gT {
  width: 200px;
}

.assets-components-SubscriptionManager-index---buttonCancel---1C634 {
  margin-right: var(--smallVertGutter);
}

.assets-components-SubscriptionManager-index---pushRight---2fF98 {
  margin-left: auto;
}

.assets-components-SubscriptionManager-index---annualTrialPriceHint---g0sw2 {
  margin-top: 0;
}
