
.assets-pages-Library-Header-BulkActions-index---root---pSfoI {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--midVertGutter);
}

.assets-pages-Library-Header-BulkActions-index---root---pSfoI > * {
  margin-left: var(--midHorzGutter);
}
