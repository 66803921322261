.assets-components-ImportManager-ShopifyAuthentication-ShopifyProductForm-Attributes-index---checkboxLabel---3fcQ3 {
  color: #444;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
}

.assets-components-ImportManager-ShopifyAuthentication-ShopifyProductForm-Attributes-index---checkboxStyle---3EaE7 {
  margin-top: 8px;
}
