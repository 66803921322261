.assets-pages-NewComposer-Confirmation-index---root---24kdE {
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 1.42857143;
  color: var(--inky500);
  font-family: var(--primaryFont);
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  text-rendering: optimizelegibility;
}

.assets-pages-NewComposer-Confirmation-index---wrapper---14p7g {
  margin: auto;
}

.assets-pages-NewComposer-Confirmation-index---text---1mF1k {
  max-width: 340px;
}

.assets-pages-NewComposer-Confirmation-index---icon---Di37T {
  display: block;
  margin-bottom: var(--midVertGutter);
  color: var(--algae300);
}
