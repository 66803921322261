.assets-components-common-icons-TextBubble-index---root---1qJR8 {
  display: inline-block;
  overflow: hidden;
  max-width: 45px;
  height: var(--bugLineHeight);
  padding: 0 6px;
  line-height: var(--bugLineHeight);
  border-radius: calc(var(--bugLineHeight)/2);
  color: white;
  font-size: var(--bugFontSize);
  font-weight: var(--normalWeight);
  text-align: center;
}

.assets-components-common-icons-TextBubble-index---new---1p41d {
  background-color: var(--urchin300);
}

.assets-components-common-icons-TextBubble-index---count---25ub1 {
  background-color: var(--kelp400);
}

.assets-components-common-icons-TextBubble-index---error---2m7fA {
  background-color: var(--coral600);
}

.assets-components-common-icons-TextBubble-index---recommended---12-9k {
  max-width: 120px;
  margin-left: 5px;
  background-color: var(--urchin300);
  font-size: var(--captionFontSize);
  font-weight: var(--semiBold);
}

.assets-components-common-icons-TextBubble-index---comingSoon---1vk1J {
  max-width: 120px;
  height: var(--captionLineHeight);
  margin-left: 5px;
  background-color: var(--coral400);
  font-size: var(--captionFontSize);
  font-weight: var(--semiBold);
}

.assets-components-common-icons-TextBubble-index---text---X1bhG {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 67%;
  height: 125%;
  margin: auto;
  color: white;
  font-size: 0.7em;
  font-weight: var(--semiBold);
  vertical-align: middle;
}
