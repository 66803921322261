.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---root---31on3 {
  height: 100%;
  box-sizing: content-box;
  margin-top: 10px;
  box-shadow: 0 0 0 1.5px rgba(0, 0, 0, 0.1) inset,
    0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---cols---Scjwb {
  display: flex;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---imageChecker---lOrp5 {
  position: absolute;
  left: -5000px;
  visibility: hidden;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---imageWrapper---2JmXI {
  position: relative;
  overflow: hidden;
  width: 476px;
  height: 249px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---imageWrapper---2JmXI::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  content: "";
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---cols---Scjwb .assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---imageWrapper---2JmXI {
  width: 158px;
  min-width: 158px;
  height: 158px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---image---c5p4u {
  width: 100%;
  height: auto;
  min-height: 100%;
  line-height: 0;
  border-radius: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---details---2F76q {
  height: auto;
  max-height: 100px;
  box-sizing: content-box;
  padding-top: 10px;
  margin: 0 12px 10px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---meta---3823c {
  max-height: 80px;
  overflow-y: hidden;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---cols---Scjwb .assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---details---2F76q {
  position: relative;
  overflow: hidden;
  height: 121px;
  max-height: 121px;
  padding-bottom: 11px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---cols---Scjwb .assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---meta---3823c {
  max-height: none;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---title---2_FCe {
  overflow: hidden;
  max-height: 110px;
  margin-bottom: 5px;
  line-height: 22px;
  font-family: Georgia, serif;
  font-size: 18px;
  font-weight: 500;
  word-wrap: break-word;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---cols---Scjwb .assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---title---2_FCe {
  max-height: 66px; /* 3 lines */
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---description---3YHHv {
  overflow: hidden;
  max-height: 80px;
  line-height: 16px;
  font-size: 12px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---cols---Scjwb .assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---description---3YHHv {
  max-height: 48px; /* 3 lines */
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---domain---3UkYb {
  overflow: hidden;
  margin-top: 9px;
  line-height: 11px;
  color: #90949c;
  font-size: 11px;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---cols---Scjwb .assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-LinkPreview-index---domain---3UkYb {
  position: absolute;
  bottom: 0;
  left: 0;
}
