.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-common-index---container---2yVw4 {
  display: flex;
  flex-wrap: wrap;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-common-index---primaryImage---o6KOR {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 1px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-common-index---image---1cDyG {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 1px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-common-index---overlay---3aB4M {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  color: #fff;

  /* Facebook appears to use a system fontstack for the numbers */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-align: center;
}
