.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-index---root---3UoJs {
  position: relative;
  margin-top: 10px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-index---root---3UoJs::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-index---video---3kvdg,
.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-index---singleImage---2UFN_ {
  width: 100%;
}
