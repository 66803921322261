.assets-pages-Composer-Variation-Attachments-common-MediaList-Video-index---root---2BEeV {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}

.assets-pages-Composer-Variation-Attachments-common-MediaList-Video-index---video---2tKTR {
  width: var(--previewVideoWidth);
  height: var(--previewVideoHeight);
}

.assets-pages-Composer-Variation-Attachments-common-MediaList-Video-index---video---2tKTR > div {
  width: 100%;
}

.assets-pages-Composer-Variation-Attachments-common-MediaList-Video-index---rootStory---3GHtH {
  position: relative;
}

.assets-pages-Composer-Variation-Attachments-common-MediaList-Video-index---rootStory---3GHtH .assets-pages-Composer-Variation-Attachments-common-MediaList-Video-index---video---2tKTR {
  width: 57px;
}

.assets-pages-Composer-Variation-Attachments-common-MediaList-Video-index---rootStory---3GHtH  span{
  width: 42px;
  height: 42px;
  margin-left: -21px;
}

.assets-pages-Composer-Variation-Attachments-common-MediaList-Video-index---overlay---1c4kg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  line-height: var(--previewImageSize);
  background-color: var(--inky300);
  border-radius: var(--elementBorderRadius);
  text-align: center;
  visibility: hidden;
}

.assets-pages-Composer-Variation-Attachments-common-MediaList-Video-index---root---2BEeV:hover .assets-pages-Composer-Variation-Attachments-common-MediaList-Video-index---overlay---1c4kg {
  visibility: visible;
}
