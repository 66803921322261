.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-index---root---1PFZq {
  position: relative;
  margin-top: 10px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-index---root---1PFZq::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-index---video---3liyr,
.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-index---singleImage---2vCVj {
  width: 100%;
}
