.assets-pages-Composer-Variation-PostPreviewButton-index---root---3pO2F {
  color: var(--inky400);
  cursor: pointer;
  font-size: 1.7em; /* prevents visual shift on hover */
}

.assets-pages-Composer-Variation-PostPreviewButton-index---root---3pO2F:hover {
  color: var(--inky500);
}

.assets-pages-Composer-Variation-PostPreviewButton-index---previewButtonTooltip---1DYXs {
  text-align: center;
}

.assets-pages-Composer-Variation-PostPreviewButton-index---postPreviewButtonTooltipTether---2joJH {
  align-self: center;
}
