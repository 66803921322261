.assets-components-common-icons-SocialMediaIcon-index---platformIcon---32d0p {
  display: inline-block;
  width: 23px;
  height: 23px;
}

.assets-components-common-icons-SocialMediaIcon-index---facebookIcon---3M-7n {
  background-color: var(--facebook);
}

.assets-components-common-icons-SocialMediaIcon-index---twitterIcon---3Z3G6 {
  background-color: var(--twitter);
}

.assets-components-common-icons-SocialMediaIcon-index---xIcon---1TCU7 {
  background-color: var(--x);
}

.assets-components-common-icons-SocialMediaIcon-index---linkedinIcon---1m6WE {
  background-color: var(--linkedin);
}

.assets-components-common-icons-SocialMediaIcon-index---tiktokIcon---2or27 {
  background-color: var(--tiktok);
}

.assets-components-common-icons-SocialMediaIcon-index---threadsIcon---UK8Tm {
  background-color: var(--threads);
}

.assets-components-common-icons-SocialMediaIcon-index---instagramIcon---RPqUG {
  background: radial-gradient(
    circle at 20% 110%,
    #fad86b 18%,
    #ff3a49 43%,
    #ff3a49 50%,
    #e518a1 70%,
    #6634e1 100%
  );
}

.assets-components-common-icons-SocialMediaIcon-index---pinterestIcon---wGAQ6 {
  background: var(--pinterest);
}

.assets-components-common-icons-SocialMediaIcon-index---googleIcon---3WMpd {
}

.assets-components-common-icons-SocialMediaIcon-index---tiny---1H-Bd {
  width: var(--captionFontSize);
  height: var(--captionFontSize);
}
