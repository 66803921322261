.react-datepicker {
  font-size: 1rem;
}

.react-datepicker__day--selected {
  background-color: var(--kelp400);
}

.assets-components-common-forms-DateTimeField-index---root---37uoe {
  margin-bottom: var(--midVertGutter);
}

.assets-components-common-forms-DateTimeField-index---helpText---17hGC {
  margin: 0 0 var(--midVertGutter) 0;
}

.assets-components-common-forms-DateTimeField-index---inputs---2mCEA {
  display: flex;
}

.assets-components-common-forms-DateTimeField-index---timeInput---2-Xi- {
  margin-right: var(--midHorzGutter);
}

.assets-components-common-forms-DateTimeField-index---resetLink---3yxCI {
  display: block;
}
