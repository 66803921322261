.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Actions-Paginator-Indicator-index---root---2X0g_ {
  display: inline-block;
  background-color: var(--instagramLightGray);
  border-radius: 50%;
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Actions-Paginator-Indicator-index---large---3Baa6,
.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Actions-Paginator-Indicator-index---active---3koY2 {
  width: 6px;
  height: 6px;
  margin: 2px;
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Actions-Paginator-Indicator-index---medium---2BI5Y {
  width: 4px;
  height: 4px;
  margin: 3px;
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Actions-Paginator-Indicator-index---small---3JKuj {
  width: 3px;
  height: 3px;
  margin: 4px;
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Actions-Paginator-Indicator-index---active---3koY2 {
  background-color: #3897f0;
}
