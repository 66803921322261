.assets-components-BulkEditTable-CategoryEdit-index---main---1xQnX {
  padding: var(--midVertGutter) 0 var(--largeVertGutter);
}

.assets-components-BulkEditTable-CategoryEdit-index---sectionFieldDescription---BLVyv {
  display: block;
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-components-BulkEditTable-CategoryEdit-index---sectionFieldInput---3WdWh {
  margin-top: var(--midHorzGutter);
}

.assets-components-BulkEditTable-CategoryEdit-index---buttonCancel---1G5QS {
  margin-right: var(--smallVertGutter);
}
