.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFour-index---container---1FSkq {
  width: 480px;
  height: 480px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFour-index---primaryImage---2-woh {
  width: 476px;
  height: 317px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFour-index---image---GfFt3 {
  width: 157px;
  height: 157px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFour-index---image---GfFt3 > div,
.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFour-index---primaryImage---2-woh > div {
  border-radius: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFour-index---overlay---2KU3N {
  line-height: 157px;
  font-size: 36px;
}
