.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitThree-index---container---2ES5n {
  flex-direction: column;
  width: 480px;
  height: 480px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitThree-index---primaryImage---2qKrV {
  width: 237px;
  height: 476px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitThree-index---image---IDh0a {
  width: 237px;
  height: 237px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitThree-index---image---IDh0a > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitThree-index---primaryImage---2qKrV > div {
  border-radius: 0;
}
