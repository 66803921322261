.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Video-index---root---1U6A7 {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Video-index---video---1Rf0g {
  width: var(--previewVideoWidth);
  height: var(--previewVideoHeight);
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Video-index---video---1Rf0g > div {
  width: 100%;
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Video-index---rootStory---12OM7 {
  position: relative;
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Video-index---rootStory---12OM7 .assets-pages-NewComposer-Variation-Attachments-common-MediaList-Video-index---video---1Rf0g {
  width: 57px;
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Video-index---rootStory---12OM7  span{
  width: 42px;
  height: 42px;
  margin-left: -21px;
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Video-index---overlay---1sXe5 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  line-height: var(--previewImageSize);
  background-color: var(--inky300);
  border-radius: var(--elementBorderRadius);
  text-align: center;
  visibility: hidden;
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Video-index---root---1U6A7:hover .assets-pages-NewComposer-Variation-Attachments-common-MediaList-Video-index---overlay---1sXe5 {
  visibility: visible;
}
