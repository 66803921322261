.assets-pages-Payment-index---root---29uaC {
  width: 100%;
  padding: 0 var(--midHorzGutter);
  margin: 0 auto;
  background-color: white;
  font-family: var(--primaryFont);
}

.assets-pages-Payment-index---root---29uaC h1 {
  margin-bottom: 0;
}

.assets-pages-Payment-index---header---125Cc {
  padding-top: var(--largeVertGutter);
  margin-bottom: var(--largeVertGutter);
}

.assets-pages-Payment-index---testimonials---o01Fe {
  display: none;
}

/* So we don't accidentally flash the "loading" content */
@keyframes assets-pages-Payment-index---fadeIn---2d2Sr {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.assets-pages-Payment-index---loading---2ja9g {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-pages-Payment-index---loadingPleaseWait---3AylG {
  animation-delay: 2s;
  animation-duration: 500ms;
  animation-fill-mode: backwards;
  animation-name: assets-pages-Payment-index---fadeIn---2d2Sr;
  opacity: 1;
}

.assets-pages-Payment-index---section---ZFbBu {
  margin-bottom: var(--largeVertGutter);
}

.assets-pages-Payment-index---actions---27e9e {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: var(--largeVertGutter);
  border-top: 1px solid var(--inky200);
}

.assets-pages-Payment-index---actions---27e9e > * {
  margin-left: var(--midHorzGutter);
  font-weight: var(--semiBold);
}

.assets-pages-Payment-index---security---1n1H5 {
  display: flex;
  align-items: center;
  line-height: var(--captionFontSize);
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-pages-Payment-index---security---1n1H5 i {
  margin-right: var(--tinyHorzGutter);
  color: var(--kelp400);
}

.assets-pages-Payment-index---formContainer---3lSO7 {
  max-width: var(--mobileWidth);
  margin: var(--largeVertGutter) auto;
}

.assets-pages-Payment-index---orderSummary---SF4nN {
  display: flex;
  width: 100%;
  font-weight: 600;
}

.assets-pages-Payment-index---orderSummaryInterval---8OPCS {
  flex: 1;
  text-align: left;
}

.assets-pages-Payment-index---orderSummaryAmount---1Jszg {
  flex: 1;
  text-align: right;
}

.assets-pages-Payment-index---trialTextAmount---2dMgT{
  flex: 1;
  text-align: right;
  color: green;
  font-weight: 600;
  font-size: 12px;
  padding: 0 0 5px;
}

@media (min-width: 995px) {
  .assets-pages-Payment-index---root---29uaC {
    padding: 0;
  }

  .assets-pages-Payment-index---testimonialsOffset---1BEE2 {
    padding-left: 350px;
  }

  .assets-pages-Payment-index---testimonials---o01Fe {
    position: absolute;
    display: block;
    float: left;
    height: 100%;
  }
}
