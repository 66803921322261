.assets-components-common-layout-ActionBar-index---actions---FoS6r {
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;
}

.assets-components-common-layout-ActionBar-index---actions---FoS6r li {
  display: inline-block;
  margin-left: 0.5em;
  font-size: 0.5em;
}
