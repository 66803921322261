.assets-components-PostsTable-Category-index---category---2OfgX {
  display: flex;
  max-width: 100px;
}

.assets-components-PostsTable-Category-index---categoryName---2Gc_Z {
  display: -webkit-box;
  overflow: hidden;
  padding: 0 0.5em;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}
