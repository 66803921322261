.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-ByLine-index---root---3kWN8 {
  display: flex;
  margin-bottom: 16px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-ByLine-index---root---3kWN8 a {
  cursor: pointer;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-ByLine-index---image---3zeFl {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;
  cursor: pointer;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-ByLine-index---details---2eB5P {
  line-height: 1.34em;
  color: var(--fbTextGrey);
  font-size: 12px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-ByLine-index---details---2eB5P a {
  color: var(--fbTextGrey);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-ByLine-index---name---ADlkN {
  line-height: 1.38em;
  color: #000;
  font-size: 14px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-ByLine-index---name---ADlkN a {
  color: #000;
  cursor: pointer;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-ByLine-index---name---ADlkN a:first-of-type {
  font-weight: bold;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-ByLine-index---name---ADlkN a:hover {
  text-decoration: underline;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-ByLine-index---arrow---L8UTu {
  margin: 0 5px;
  color: rgb(170, 170, 170);
}
