.assets-components-common-AccountSelect-Option-InstagramBusinessSettings-index---radioButton---3rZ2x {
  display: flex;
  align-items: center;
}

.assets-components-common-AccountSelect-Option-InstagramBusinessSettings-index---title---pbBkL {
  padding-left: var(--tinyHorzGutter);
  font-size: var(--bodyFontSize);
}

.assets-components-common-AccountSelect-Option-InstagramBusinessSettings-index---description---3v2j3 {
  display: block;
  padding-left: var(--largeHorzGutter);
  color: var(--inky400);
  font-size: var(--captionFontSize);
  font-size: 0.9em;
  font-weight: 300;
}

.assets-components-common-AccountSelect-Option-InstagramBusinessSettings-index---helpText---2uQ49 {
  padding-left: var(--largeHorzGutter);
  font-size: var(--captionFontSize);
}
