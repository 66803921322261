.assets-components-common-content-common-VariationText-index---baseText---3bVIw {
  display: flex;
}

.assets-components-common-content-common-VariationText-index---text---37UYl {
  word-break: break-word;
}

.assets-components-common-content-common-VariationText-index---facebookSplitSpecificText---3UaBT {
  display: flex;
  margin-top: var(--midVertGutter);
}

.assets-components-common-content-common-VariationText-index---platformIconContainer---3qKQI,
.assets-components-common-content-common-VariationText-index---textLabel---2ApF5 {
  margin-right: var(--tinyHorzGutter);
}

.assets-components-common-content-common-VariationText-index---platformIconContainer---3qKQI.assets-components-common-content-common-VariationText-index---facebook---GqIOC {
  color: var(--facebook);
}

.assets-components-common-content-common-VariationText-index---platformIconContainer---3qKQI.assets-components-common-content-common-VariationText-index---linkedin---Obr1m {
  color: var(--linkedin);
}

.assets-components-common-content-common-VariationText-index---platformIconContainer---3qKQI.assets-components-common-content-common-VariationText-index---twitter---PkrTx {
  color: var(--twitter);
}

.assets-components-common-content-common-VariationText-index---platformIconContainer---3qKQI.assets-components-common-content-common-VariationText-index---instagram---1qla6 {
  color: var(--instagram);
}

.assets-components-common-content-common-VariationText-index---platformIconContainer---3qKQI.assets-components-common-content-common-VariationText-index---pinterest---1bxLH {
  color: var(--pinterest);
}
