.assets-components-common-content-common-LinkPreviewHint-index---root---2-UE7 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 511px;
  padding: var(--smallVertGutter);
  border: 1px solid var(--inky200);
  margin-top: var(--midHorzGutter);
  line-height: var(--captionLineHeight);
  background-color: var(--inky100);
  border-radius: var(--elementBorderRadius);
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-components-common-content-common-LinkPreviewHint-index---text---3dFxQ {
  display: flex;
  flex: 4.5;
  flex-direction: column;
}

.assets-components-common-content-common-LinkPreviewHint-index---title---3RhqW {
  padding-bottom: var(--tinyVertGutter);
  line-height: var(--bodyLineHeight);
  color: var(--inky500);
  font-size: var(--bodyFontSize);
  font-weight: var(--semiBold);
}

.assets-components-common-content-common-LinkPreviewHint-index---description---S82ti {
  line-height: var(--captionLineHeight);
  color: var(--inky400);
  font-size: var(--captionFontSize);
  font-weight: normal;
}
