.assets-components-common-links-Link-index---small----00HZ,
a.assets-components-common-links-Link-index---small----00HZ {
  line-height: var(--captionLineHeight);
  color: var(--inky400);
  cursor: pointer;
  font-size: var(--captionFontSize);
  text-decoration: underline;
}

.assets-components-common-links-Link-index---small----00HZ:hover {
  color: var(--inky300);
  text-decoration: none;
}

.assets-components-common-links-Link-index---menuLink---2uqrB {
  display: block;
  color: var(--inky500);
  font-size: var(--bodyFontSize);
  text-decoration: none;
}

.assets-components-common-links-Link-index---menuLink---2uqrB:hover,
.assets-components-common-links-Link-index---menuLink---2uqrB:active,
.assets-components-common-links-Link-index---menuLink---2uqrB:visited {
  color: var(--inky500);
  text-decoration: none;
}


.assets-components-common-links-Link-index---tiktokErrorTooltip---39I4t {
  position: absolute;
  left: 66%;
  transform: translateY(-80px);
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}