.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFive-index---container---3pPkw {
  flex-direction: column;
  width: 480px;
  height: 480px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFive-index---primaryImage---1Awul {
  width: 237px;
  height: 238px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFive-index---image---2fJrn {
  width: 237px;
  height: 158px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFive-index---image---2fJrn > div,
.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFive-index---primaryImage---1Awul > div {
  border-radius: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFive-index---overlay---3CYy3 {
  line-height: 157px;
  font-size: 36px;
}
