.assets-pages-Composer-Variation-Title-index---root---2cFQo {
  display: flex;
  align-items: baseline;
  margin-bottom: var(--tinyVertGutter);
}

.assets-pages-Composer-Variation-Title-index---label---2kavj {
  flex-grow: 1;
}

.assets-pages-Composer-Variation-Title-index---icon---v3fER {
  margin-left: var(--tinyHorzGutter);
}

.assets-pages-Composer-Variation-Title-index---required---14GLg {
  line-height: 0;

  /* hack to prevent vertical jiggle */
  color: var(--coral500);
  font-size: var(--captionFontSize);
  font-weight: normal;
}

.assets-pages-Composer-Variation-Title-index---remainingCharacterCountsContainer---1mUTW {
  /* margin-right: var(--tinyHorzGutter); */
}

.assets-pages-Composer-Variation-Title-index---root---2cFQo .assets-pages-Composer-Variation-Title-index---deleteLink---3KWPc {
  margin-left: var(--midHorzGutter);
  color: var(--inky400);
  text-decoration: none;
}

.assets-pages-Composer-Variation-Title-index---blurred---8nyNA .assets-pages-Composer-Variation-Title-index---deleteLink---3KWPc {
  display: none;
}

.assets-pages-Composer-Variation-Title-index---hovered---vVf38 .assets-pages-Composer-Variation-Title-index---deleteLink---3KWPc {
  display: block;
}

.assets-pages-Composer-Variation-Title-index---root---2cFQo .assets-pages-Composer-Variation-Title-index---deleteLink---3KWPc:hover {
  color: var(--inky400);
  text-decoration: underline;
}

.assets-pages-Composer-Variation-Title-index---previewButton---3r5PE {
  align-self: center;
  margin-left: var(--midHorzGutter);
}

.assets-pages-Composer-Variation-Title-index---blurred---8nyNA .assets-pages-Composer-Variation-Title-index---previewButton---3r5PE {
  display: none;
}

.assets-pages-Composer-Variation-Title-index---hovered---vVf38 .assets-pages-Composer-Variation-Title-index---previewButton---3r5PE {
  display: block;
}

.assets-pages-Composer-Variation-Title-index---root---2cFQo .assets-pages-Composer-Variation-Title-index---postPreviewButtonTooltipTether---1ZgEF {
  background-color: red;
}

.assets-pages-Composer-Variation-Title-index---twitterPostedNotice---3Ld4a {
  color: var(--inky300);
  font-size: var(--captionFontSize);
}

.assets-pages-Composer-Variation-Title-index---twitterPostedNotice---3Ld4a::before {
  margin: 0 var(--tinyHorzGutter);
  content: "·";
}
