.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-Scroller-index---root---1bo2R {
  width: 500px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-Scroller-index---root---1bo2R img {
  height: 30px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-Scroller-index---rightChevron---2QW7h {
  float: right;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-Scroller-index---leftChevron---1RVKj:hover,
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-Scroller-index---rightChevron---2QW7h:hover {
  cursor: pointer;
}
