.assets-components-ImportManager-Manage-Table-index---root---26G9Y {
  width: 100%;
  margin-bottom: 2em;
  color: #535353;
  font-family: "Open Sans", sans-serif;
}

.assets-components-ImportManager-Manage-Table-index---root---26G9Y > thead > tr > th {
  padding: 0.4em 2rem 0.5em;
  color: #535353;
  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
}

.assets-components-ImportManager-Manage-Table-index---root---26G9Y > thead > tr > th:first-child {
  padding-left: 0;
  text-align: left;
}

.assets-components-ImportManager-Manage-Table-index---root---26G9Y > thead > tr > th:last-child {
  padding-right: 0;
}

.assets-components-ImportManager-Manage-Table-index---root---26G9Y > tbody > tr {
  border-top: 1px solid #e2e2e2;
}
