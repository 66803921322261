.assets-pages-Schedule-ScheduleNewModal-index---dayOptions---21IP3 {
  display: flex;
  justify-content: space-between;
}

.assets-pages-Schedule-ScheduleNewModal-index---accountSelect---2KrVc {
  flex: 1;
  max-width: 280px;
  padding-top: var(--midVertGutter);
  border-right: 1px solid var(--inky100);
}

.assets-pages-Schedule-ScheduleNewModal-index---sendAtAndCategoryLabel---22OW2 {
  margin-bottom: var(--tinyVertGutter);
}

.assets-pages-Schedule-ScheduleNewModal-index---sendAt---1MKV3 {
  display: flex;
  align-items: center;
}

.assets-pages-Schedule-ScheduleNewModal-index---time---sySWP {
  flex-basis: 120px;
  margin-right: var(--tinyHorzGutter);
}

.assets-pages-Schedule-ScheduleNewModal-index---day---2EUc2 {
  flex: 1;
  margin-left: var(--tinyHorzGutter);
}

.assets-pages-Schedule-ScheduleNewModal-index---secondaryActions---2RiBB {
  margin-right: var(--midHorzGutter);
  display: flex;
}

@media (max-width: 700px) {
  .assets-pages-Schedule-ScheduleNewModal-index---modalBody---8oK7c {
    flex-direction: column-reverse;
  }

  .assets-pages-Schedule-ScheduleNewModal-index---accountSelect---2KrVc {
    max-width: 100%;
    border-right: none;
  }

  .assets-pages-Schedule-ScheduleNewModal-index---sendAt---1MKV3 {
    flex-direction: column;
    align-items: flex-start;
  }

  .assets-pages-Schedule-ScheduleNewModal-index---time---sySWP {
    width: 100%;
    margin: 0;
  }

  .assets-pages-Schedule-ScheduleNewModal-index---day---2EUc2 {
    width: 100%;
    margin: 0;
  }
}
