.assets-components-OnboardingBar-AddMoreContentDialog-index---root---2vjBu {
  text-align: center;
}

.assets-components-OnboardingBar-AddMoreContentDialog-index---title---3AdqN {
  margin-bottom: var(--largeVertGutter);
}

.assets-components-OnboardingBar-AddMoreContentDialog-index---message---yseMP {
  display: block;
  margin-bottom: var(--hugeVertGutter);
}
