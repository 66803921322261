.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Five-index---container---3p8_- {
  width: 551px;
  height: 551px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Five-index---primaryImage---2qtmY {
  width: 274px;
  height: 330px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Five-index---image---170lw {
  width: 181px;
  height: 221px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Five-index---image---170lw > div,
.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Five-index---primaryImage---2qtmY > div {
  border-radius: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Five-index---imageBackground---2j3lJ {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--inky300);
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Five-index---imageCounter---29MBM {
  position: absolute;
  top: 46%;
  left: 41%;
  color: white;
  font-size: var(--largeFontSize);
}
