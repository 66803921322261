.assets-components-SubscriptionManager-PlanSelector-PaymentCycle-index---plan---2bcTH {
  padding: var(--midVertGutter) var(--midHorzGutter);
  border: 1px solid var(--inky200);
  border-radius: var(--elementBorderRadius);
}

.assets-components-SubscriptionManager-PlanSelector-PaymentCycle-index---selectablePlan---A01gl {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.assets-components-SubscriptionManager-PlanSelector-PaymentCycle-index---unselectedPlan---3WYpC {
  border: 0;
  background: var(--inky100);
}

.assets-components-SubscriptionManager-PlanSelector-PaymentCycle-index---selectedPlan---2nxQs {
  border: 1px solid var(--kelp400);
  box-shadow: var(--lightBoxShadow);
}

.assets-components-SubscriptionManager-PlanSelector-PaymentCycle-index---radioButton---x0xBM {
  margin: var(--tinyHorzGutter) var(--smallHorzGutter) 0 0;
}

.assets-components-SubscriptionManager-PlanSelector-PaymentCycle-index---labelOverride---1OnAi {
  cursor: pointer;
}

.assets-components-SubscriptionManager-PlanSelector-PaymentCycle-index---savings---1YzAV {
  color: var(--kelp400);
}
