.assets-components-common-forms-Select-index---option---355zi {
  display: flex;
  align-items: center;
  width: 100%;
  font-family: var(--primaryFont);
}

.assets-components-common-forms-Select-index---option---355zi > * {
  margin-right: var(--smallHorzGutter);
}

.assets-components-common-forms-Select-index---colorSwatch---3vTBE {
  width: var(--largeIconSize);
  min-width: var(--largeIconSize);
  height: var(--largeIconSize);
  margin: var(--tinyVertGutter) 0;
  border-radius: 50%;
}

.assets-components-common-forms-Select-index---icon---1xxkY {
  display: flex;
}

.assets-components-common-forms-Select-index---label---1shCC {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.assets-components-common-forms-Select-index---menuListContainer---3D6E8 {
  position: relative;
}

.assets-components-common-forms-Select-index---loadingMenuListContainer---3ziPi {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--inputHeight);
}

.assets-components-common-forms-Select-index---loadingIcon---2yOzz {
  color: var(--inky300);
  font-size: var(--captionLineHeight);
}

.assets-components-common-forms-Select-index---menuFooter---3dj-X {
  position: sticky;
  bottom: 0;
  height: var(--inputHeight);
  border-top: 1px solid var(--inky100);
  margin: 0;
  background-color: white;
  border-radius: 0 0 var(--borderRadius) var(--borderRadius);
}
