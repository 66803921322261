.assets-components-OnboardingBar-OnboardingCompleteDialog-index---root---rpWgZ {
  padding: 2em 7em;
  text-align: center;
}

.assets-components-OnboardingBar-OnboardingCompleteDialog-index---root---rpWgZ::before {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 145px;
  height: 187px;
  background-image: url(/assets/webpack/0c4870dd55a0e141938b51c41c1c88b0.svg);
  content: '';
}

.assets-components-OnboardingBar-OnboardingCompleteDialog-index---root---rpWgZ::after {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 209px;
  height: 190px;
  background-image: url(/assets/webpack/406546377e5935f0c3e2504ea3bd41a1.svg);
  content: '';
}

.assets-components-OnboardingBar-OnboardingCompleteDialog-index---button---1H2Dk {
  margin-top: var(--midHorzGutter);
}
