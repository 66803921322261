.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Four-index---container---2eEM8 {
  flex-direction: column;
  width: 504px;
  height: 381px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Four-index---primaryImage---1p2Q5 {
  width: 380px;
  height: 380px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Four-index---image---3rzUG {
  width: 126px;
  height: 126px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Four-index---image---3rzUG:nth-child(2) {
  border-top-right-radius: 5px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Four-index---image---3rzUG:last-child {
  border-bottom-right-radius: 5px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Four-index---image---3rzUG > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Four-index---primaryImage---1p2Q5 > div {
  border-radius: 0;
}
