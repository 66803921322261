.assets-components-ReportList-index---root---RCjox {
  margin: var(--hugeVertGutter) 0;
}

.assets-components-ReportList-index---heading---3Y9qT {
  border-bottom: 2px solid var(--inky200);
  color: var(--inky400);
}

.assets-components-ReportList-index---subHeading---329lp {
  margin-top: var(--midVertGutter);
  color: var(--inky400);
}

.assets-components-ReportList-index---listContainer---2org4 {
  max-height: 200px;
  padding: 0;
  border: 1px solid var(--inky200);
  background-color: white;
  list-style-type: none;
  overflow-y: scroll;
}
