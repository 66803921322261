.assets-components-TopNav-index---root---10Clb {
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100%;
  height: 44px;
  background: #fff;
  box-shadow: 0 0 1px 1px var(--transparentGrey);
  font-family: var(--primaryFont);
}

.assets-components-TopNav-index---open---2St0o {
  margin-left: 0;
  box-shadow: 0 1000px 0 1000px var(--semiTransparentGrey);
}

.assets-components-TopNav-index---container---3MEmT {
  padding: 0 10px;
  margin: 0 auto;
}

@media (min-width: 600px) {
  .assets-components-TopNav-index---root---10Clb {
    position: fixed;
    height: 55px;
  }

  .assets-components-TopNav-index---container---3MEmT {
    padding: 0 20px;
  }

  .assets-components-TopNav-index---open---2St0o {
    box-shadow: none;
  }
}

@media (min-width: 995px) {
  .assets-components-TopNav-index---root---10Clb {
    position: fixed;
  }

  .assets-components-TopNav-index---container---3MEmT {
    width: 970px;
    padding: 0;
  }
}

@media print {
  .assets-components-TopNav-index---root---10Clb {
    display: none;
  }
}
