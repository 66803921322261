.assets-components-PostsTable-Content-index---content---3WmV- {
  max-width: 175px;
}

.assets-components-PostsTable-Content-index---contentText---ErDiK {
  display: -webkit-box;
  overflow: hidden;
  max-width: 175px;

  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}

@-moz-document url-prefix() {
  .assets-components-PostsTable-Content-index---contentText---ErDiK {
    display: inline-block;
    height: var(--midInputHeight);
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.assets-components-PostsTable-Content-index---contentPerformance---2-vYs {
  text-decoration: none;
}
