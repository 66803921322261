.assets-components-common-layout-Container-Col-index---material---14mbi {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 2px var(--inky300);
}

.assets-components-common-layout-Container-Col-index---col1---wu5CF {
  width: 95px;
}

.assets-components-common-layout-Container-Col-index---col2---h8BtF {
  width: calc(95px * 2 + 30px);
}

.assets-components-common-layout-Container-Col-index---col3---1qCTN {
  width: calc(95px * 3 + 30px * 2);
}

.assets-components-common-layout-Container-Col-index---col4---2G9Ds {
  width: calc(95px * 4 + 30px * 3);
}

.assets-components-common-layout-Container-Col-index---col5---36mBy {
  width: calc(95px * 5 + 30px * 4);
}

.assets-components-common-layout-Container-Col-index---col6---2L0nl {
  width: calc(95px * 6 + 30px * 5);
}

.assets-components-common-layout-Container-Col-index---col7---2705Y {
  width: calc(95px * 7 + 30px * 6);
}

.assets-components-common-layout-Container-Col-index---col8---pzoYk {
  width: calc(95px * 8 + 30px * 7);
}
