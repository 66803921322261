.assets-components-common-links-LinkPreview-index---root---3NOOO {
  padding: var(--smallVertGutter) var(--smallHorzGutter);
  border: 1px solid var(--inky200);
  line-height: var(--captionLineHeight);
  background-color: var(--inky100);
  border-radius: var(--elementBorderRadius);
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-components-common-links-LinkPreview-index---unstyled---286oQ {
  padding: 0;
  border: 0;
  background-color: unset;
  color: unset;
}

.assets-components-common-links-LinkPreview-index---previewContainer---1wVpl {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.assets-components-common-links-LinkPreview-index---imageContainer---2_pKp {
  max-width: var(--mediaThumbnailSize);
  margin-right: var(--smallHorzGutter);
}

.assets-components-common-links-LinkPreview-index---image---11SBR {
  width: var(--mediaThumbnailSize);
  height: var(--mediaThumbnailSize);
  border-radius: var(--elementBorderRadius);
  -o-object-fit: cover;
     object-fit: cover;
}

.assets-components-common-links-LinkPreview-index---text---18JSH {
  display: flex;
  flex: 4.5;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
}

.assets-components-common-links-LinkPreview-index---title---1Q0Df {
  padding-bottom: var(--tinyVertGutter);
  line-height: var(--midLineHeight);
  color: var(--inky500);
  font-size: var(--bodyFontSize);
  font-weight: var(--semiBold);
}

a.assets-components-common-links-LinkPreview-index---title---1Q0Df:visited,
a.assets-components-common-links-LinkPreview-index---title---1Q0Df.assets-components-common-links-LinkPreview-index---btn-link---2SkmM {
  color: var(--inky500);
}

.assets-components-common-links-LinkPreview-index---noTitleText---2jN7w {
  padding-bottom: var(--tinyVertGutter);
  line-height: var(--midLineHeight);
  color: var(--inky500);
  font-size: var(--bodyFontSize);
  font-style: italic;
  font-weight: var(--normalWeight);
}

.assets-components-common-links-LinkPreview-index---description---5hRhg {
  line-height: var(--captionLineHeight);
  color: var(--inky400);
  font-size: var(--captionFontSize);
  font-weight: normal;
}

.assets-components-common-links-LinkPreview-index---footer---3YLU3 {
  overflow: hidden;
  max-width: 150px;
  margin-top: var(--midVertGutter);
  text-overflow: ellipsis;
}

.assets-components-common-links-LinkPreview-index---fbIconContainer---2N0NZ {
  margin-right: var(--tinyHorzGutter);
  color: var(--facebook);
}

.assets-components-common-links-LinkPreview-index---linkedinIconContainer---2l6nn {
  margin-right: var(--tinyHorzGutter);
  color: var(--linkedin);
}

.assets-components-common-links-LinkPreview-index---footerLink---25uHO {
  overflow: hidden;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assets-components-common-links-LinkPreview-index---footerLink---25uHO,
.assets-components-common-links-LinkPreview-index---footerLink---25uHO:visited {
  color: var(--kelp500);
}

@media (min-width: 600px) {
  .assets-components-common-links-LinkPreview-index---footer---3YLU3 {
    max-width: 200px;
  }
}

@media (min-width: 995px) {
  .assets-components-common-links-LinkPreview-index---footer---3YLU3 {
    max-width: 300px;
  }
}
