.assets-components-PostsTable-TableBody-index---tableBody---2pFs1 {
  padding: var(--midVertGutter) var(--tinyVertGutter);
  vertical-align: top;
}

.assets-components-PostsTable-TableBody-index---tableBody---2pFs1:first-child {
  padding-left: var(--midHorzGutter);
}

.assets-components-PostsTable-TableBody-index---tableBody---2pFs1:last-child {
  padding-right: var(--midHorzGutter);
}
