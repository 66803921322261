.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-index---root---3DTpI {
  margin-top: 10px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-index---video---1VNpx {
  overflow: hidden;
  border-radius: var(--elementBorderRadius);
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-index---video---1VNpx video {
  width: 100%;
  max-height: 506px;
}
