.assets-pages-NewComposer-Variation-TextField-AIGenerator-CoversationContainer-index---conversationContainter---1dei_ {
  overflow-y: scroll;
  padding: 20px 44px 0px 44px;
  height: 267px;
  scroll-behavior: smooth;
}
.assets-pages-NewComposer-Variation-TextField-AIGenerator-CoversationContainer-index---selectAiPromptContainer---1Nvef,
.assets-pages-NewComposer-Variation-TextField-AIGenerator-CoversationContainer-index---responseAiPromptContainer---1_VUX {
  color: #5b5b5b;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-CoversationContainer-index---selectAiPromptContainer---1Nvef {
  justify-content: end;
}
.assets-pages-NewComposer-Variation-TextField-AIGenerator-CoversationContainer-index---responseAiPromptContainer---1_VUX {
  justify-content: start;
}
.assets-pages-NewComposer-Variation-TextField-AIGenerator-CoversationContainer-index---selectAiResponseContainer---HQDEe {
  width: calc(100% - 144px);
  font-size: 12px;
  background-color: rgba(45, 150, 143, 0.15);
  border-radius: 6px;
  padding: 10px;
  margin: 2px 2px 2px 50px;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-CoversationContainer-index---selectAiResponseContainer---HQDEe:hover {
  background-color: var(--primaryTextColor);
  color: white;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-CoversationContainer-index---aiMessageDivider---3OqvB {
  height: 1px;
  background-color: #d9d9d9;
  margin: 10px 0px;
}

.assets-pages-NewComposer-Variation-TextField-AIGenerator-CoversationContainer-index---userAvatar---152Yq,
.assets-pages-NewComposer-Variation-TextField-AIGenerator-CoversationContainer-index---aiAvatar---1z4Zo {
  width: 34px;
  height: 34px;
  border-radius: 17px;
}
.assets-pages-NewComposer-Variation-TextField-AIGenerator-CoversationContainer-index---aiAvatar---1z4Zo {
  background-color: var(--primaryTextColor);
}
.assets-pages-NewComposer-Variation-TextField-AIGenerator-CoversationContainer-index---userAvatar---152Yq {
  background-color: var(--lightGrey);
}
