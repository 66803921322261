.assets-pages-Composer-Variation-VideoAttachmentButton-index---root---2kE2m {
  display: inline-block;
  cursor: pointer;
}

.assets-pages-Composer-Variation-VideoAttachmentButton-index---icon---1sZEO[disabled] {
  cursor: not-allowed;
  opacity: var(--lighterDisabledOpacity);
}

.assets-pages-Composer-Variation-VideoAttachmentButton-index---icon---1sZEO:hover:not([disabled]) {
  opacity: var(--hoverOpacity);
}

.assets-pages-Composer-Variation-VideoAttachmentButton-index---tooltiperBody---124i_ {
  display: flex;
}

.assets-pages-Composer-Variation-VideoAttachmentButton-index---tooltiperBodyLeft---25Hev {
  width: 50%;
  padding: 0 5px 0 0;
}

.assets-pages-Composer-Variation-VideoAttachmentButton-index---tooltiperBodyRight---1wAsw {
  width: 50%;
  padding: 0 0 0 5px;
}

.assets-pages-Composer-Variation-VideoAttachmentButton-index---tooltiperBody---124i_ strong {
  display: block;
  margin-top: 5px;
}
