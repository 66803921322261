.assets-components-ImportManager-AddNew-ConnectionFooterZapier-index---apiKeyControlsContainer---2J1MF {
  white-space: nowrap;
}

.assets-components-ImportManager-AddNew-ConnectionFooterZapier-index---apiKeyCopy---3Cehv > span {
  color: var(--inky1000);
}

.assets-components-ImportManager-AddNew-ConnectionFooterZapier-index---apiKeyCopySuccess---1lVgO > span {
  color: var(--whale500);
}

.assets-components-ImportManager-AddNew-ConnectionFooterZapier-index---apiKeyCopyIcon---GN633,
.assets-components-ImportManager-AddNew-ConnectionFooterZapier-index---apiKeyRefreshIcon---2DhN5 {
  margin: 0 var(--tinyHorzGutter);
}

.assets-components-ImportManager-AddNew-ConnectionFooterZapier-index---apiKeyControls---2FHW8 {
  margin-top: var(--tinyVertGutter);
}
