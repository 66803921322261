/* Old styles */
.assets-components-VideoThumbnail-index---thumbnail---1e-no {
  padding: 1px;
  vertical-align: top;
}

.assets-components-VideoThumbnail-index---videoContainer---DcWoI {
  position: relative;
  display: inline-table;
  width: -moz-min-content;
  width: min-content;
  height: -moz-min-content;
  height: min-content;
  cursor: pointer;
}

.assets-components-VideoThumbnail-index---thumbnailOverlay---2otp7 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: table-cell;
  width: 100%;
  height: 100%;
  color: white;
  font-size: var(--display2FontSize);
  text-align: center;
}

.assets-components-VideoThumbnail-index---thumbnailOverlay---2otp7:hover {
  cursor: pointer;
}

.assets-components-VideoThumbnail-index---playIcon---2jRxC {
  width: 30%;
  height: 100%;
  margin: auto;
}
