.assets-components-AccountCancellation-index---main---F7NIg {
  padding: var(--tinyHorzGutter) 0;
  text-align: center;
}

.assets-components-AccountCancellation-index---message---2atzV {
  padding: var(--largeVertGutter) 0 var(--midVertGutter);
}

.assets-components-AccountCancellation-index---button---3LRx5 {
  width: 200px;
}

.assets-components-AccountCancellation-index---pushRight---1Sp5s {
  margin-left: auto;
}

.assets-components-AccountCancellation-index---buttonCancel---17vUV {
  margin-right: var(--smallVertGutter);
  margin-bottom: 10px;
}
