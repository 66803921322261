.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Header-Menu-index---root---8RAiC {
  flex-grow: 0;
  width: 30px;
  height: 30px;
  font-size: 20px;
  text-align: center;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Header-Menu-index---root---8RAiC img {
  width: 100%;
}
