.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-Video-index---root---nVMLe {
  position: relative;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-Video-index---root---nVMLe .assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-Video-index---video---2Str7 {
  overflow: hidden;
  max-height: 625px;
  -o-object-fit: cover;
     object-fit: cover;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-Video-index---root---nVMLe .rh5v-Overlay_component {
  display: none;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-Video-index---timer---2cd-o {
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
}
