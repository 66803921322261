.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-ByLine-index---root---3lNeY {
  display: flex;
  margin-bottom: 16px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-ByLine-index---root---3lNeY a {
  cursor: pointer;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-ByLine-index---image---39tg5 {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;
  cursor: pointer;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-ByLine-index---details---1RZzF {
  line-height: 1.34em;
  color: var(--fbTextGrey);
  font-size: 12px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-ByLine-index---details---1RZzF a {
  color: var(--fbTextGrey);
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-ByLine-index---name---1bsyN {
  line-height: 1.38em;
  color: #000;
  font-size: 14px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-ByLine-index---name---1bsyN a {
  color: #000;
  cursor: pointer;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-ByLine-index---name---1bsyN a:first-of-type {
  font-weight: bold;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-ByLine-index---name---1bsyN a:hover {
  text-decoration: underline;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-ByLine-index---arrow---3xCVZ {
  margin: 0 5px;
  color: rgb(170, 170, 170);
}
