.assets-components-Accounts-AccountCard-index---container---1bBwU {
  display: flex;
  float: left;
  flex-direction: column;
  align-self: flex-start;
  width: 0%;
  min-width: var(--midContainerWidth);
  max-width: var(--midContainerWidth);
  margin-left: var(--hugeVertGutter);
}

.assets-components-Accounts-AccountCard-index---accountContainer---3Jipz {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  width: 0%;
  min-width: var(--midContainerWidth);
  max-width: var(--midContainerWidth);
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  margin-bottom: var(--largeVertGutter);
  background-color: white;
  border-radius: var(--cardBorderRadius);
  box-shadow: 0 2px 10px -3px var(--inky150);
}

.assets-components-Accounts-AccountCard-index---accountContainer---3Jipz:hover {
  box-shadow: 0 2px 19px -2px var(--inky300);
}

.assets-components-Accounts-AccountCard-index---childrenContainer---1Or-d {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: flex-end;
  width: calc(var(--midContainerWidth) - var(--smallHorzGutter));
  border-left: 1px solid var(--inky150);
  margin-bottom: var(--largeVertGutter);
  outline-offset: 10px;
}

.assets-components-Accounts-AccountCard-index---childAccount---1iYpq {
  width: 0%;
  min-width: calc(var(--midContainerWidth) - var(--largeHorzGutter));
  max-width: calc(var(--midContainerWidth) - var(--largeHorzGutter));
  margin-bottom: var(--midVertGutter);
}

.assets-components-Accounts-AccountCard-index---childAccount---1iYpq:nth-last-child(1) {
  margin-bottom: 0;
}

.assets-components-Accounts-AccountCard-index---accountInfo---1A1fK {
  display: flex;
  flex: 1;
  overflow: hidden;
  min-width: 0; /* prevents overflowing parents width */
  margin-left: var(--smallHorzGutter);
}

.assets-components-Accounts-AccountCard-index---details---MtWO4 {
  flex: 1;
  min-width: 0; /* prevents overflowing parents width */
  padding-right: var(--midHorzGutter);
}

.assets-components-Accounts-AccountCard-index---name---3MM24 {
  line-height: 20px; /* prevent low descending characters from getting clipped */
}

.assets-components-Accounts-AccountCard-index---name---3MM24,
.assets-components-Accounts-AccountCard-index---caption---1lxv1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assets-components-Accounts-AccountCard-index---accountActionsContainer---2-ZzC {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.assets-components-Accounts-AccountCard-index---icon---3NooV {
  color: var(--inky300);
  cursor: pointer;
}

.assets-components-Accounts-AccountCard-index---link---ruhrp {
  padding-top: var(--midVertGutter);
  margin-right: var(--tinyHorzGutter);
  line-height: var(--captionLineHeight);
  cursor: pointer;
  font-size: var(--helpIconFontSize);
  text-decoration: underline;
}

.assets-components-Accounts-AccountCard-index---link---ruhrp:hover {
  color: var(--kelp400);
  text-decoration: none;
}
