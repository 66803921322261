.assets-pages-NewComposer-Variation-index---root---2NUrX {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  margin-bottom: var(--largeVertGutter);
}

.assets-pages-NewComposer-Variation-index---hovered---22w0Z {
  box-shadow: 0 3px 10px var(--inky300);
}

.assets-pages-NewComposer-Variation-index---field---3zce1 {
  border-bottom-width: 0;
  border-radius: 0;
}

.assets-pages-NewComposer-Variation-index---firstField---7yZUQ {
  border-top-left-radius: var(--borderRadius);
  border-top-right-radius: var(--borderRadius);
}

.assets-pages-NewComposer-Variation-index---lastField---BgjYL {
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  border-bottom-width: 1px;
}

.assets-pages-NewComposer-Variation-index---mediaCounts---2Pkmi {
  display: flex;
  flex-direction: column;
  margin-top: var(--tinyVertGutter);
}

.assets-pages-NewComposer-Variation-index---mediaButtons---3PtyP {
  margin-top: var(--midVertGutter);
}

.assets-pages-NewComposer-Variation-index---mediaButtons---3PtyP > * {
  margin-right: var(--midHorzGutter);
}

.assets-pages-NewComposer-Variation-index---tiktokHelper---1qWLZ {
  margin: 7.5px 0 0;
  color: #999;
  font-size: 13px;
  font-style: italic;
}

.assets-pages-NewComposer-Variation-index---fields---rGuGs {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Adjust the value as needed */
  flex-wrap: wrap;
}

.assets-pages-NewComposer-Variation-index---fieldElementAi---30CZU {
  width: 100%;
  flex-basis: 48.8%;
}

.assets-pages-NewComposer-Variation-index---fieldElement---1u_Bc {
  width: 100%;
}
