.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Two-index---container---28N9b {
  width: 551px;
  height: 551px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Two-index---image---3Vk6C {
  width: 274px;
  height: 551px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Two-index---image---3Vk6C > div {
  border-radius: 0;
}
