.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeTwo-index---container---23kgw {
  width: 480px;
  height: 480px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeTwo-index---image---1-s5y {
  width: 476px;
  height: 237px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeTwo-index---image---1-s5y > div {
  border-radius: 0;
}
