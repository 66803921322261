.assets-components-ImportManager-ShopifyAuthentication-ShopifyProductForm-index---rootModal---KnKjI {
  width: 100%;
  max-width: 882px;
  padding-right: 19.5px;
  overflow-y: hidden;
}

.assets-components-ImportManager-ShopifyAuthentication-ShopifyProductForm-index---rootModal---KnKjI > main {
  padding: 10px;
}
