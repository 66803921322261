.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFive-index---container---2z3vt {
  flex-direction: column;
  width: 480px;
  height: 480px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFive-index---primaryImage---jdRIy {
  width: 237px;
  height: 238px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFive-index---image---17dWR {
  width: 237px;
  height: 158px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFive-index---image---17dWR > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFive-index---primaryImage---jdRIy > div {
  border-radius: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFive-index---overlay---1pK_j {
  line-height: 157px;
  font-size: 36px;
}
