.assets-pages-Composer-Variation-Attachments-LinkPreviewAttachmentToggle-RestoreLinkPreviewAttachment-index---root---rNW1A {
  margin-bottom: var(--smallVertGutter);
}

.assets-pages-Composer-Variation-Attachments-LinkPreviewAttachmentToggle-RestoreLinkPreviewAttachment-index---restoreLinkPreviewLinkOverrides---oXZ0n,
.assets-pages-Composer-Variation-Attachments-LinkPreviewAttachmentToggle-RestoreLinkPreviewAttachment-index---restoreLinkPreviewLinkOverrides---oXZ0n:visited {
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-pages-Composer-Variation-Attachments-LinkPreviewAttachmentToggle-RestoreLinkPreviewAttachment-index---disabledRestoreLinkPreviewContainer---29eZA {
  opacity: var(--disabledOpacity);
}
