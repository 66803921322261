.assets-pages-UserSettings-index---loading---l3Foi {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-pages-UserSettings-index---section---w8ldq {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  margin-top: calc(2 * var(--smallVertGutter));
  background-color: #fff;
  border-radius: var(--cardBorderRadius);
  box-shadow: 0 2px 10px -3px var(--inky150);
}

.assets-pages-UserSettings-index---sectionHeader---JmaOz,
.assets-pages-UserSettings-index---sectionField---15EVR,
.assets-pages-UserSettings-index---sectionFieldItem---1F4BI {
  display: flex;
}

.assets-pages-UserSettings-index---sectionHeader---JmaOz,
.assets-pages-UserSettings-index---sectionFieldItem---1F4BI {
  justify-content: space-between;
}

.assets-pages-UserSettings-index---sectionFieldAction---3lPSI {
  justify-content: flex-end;
  border-top: 1px solid var(--inky200);
}

.assets-pages-UserSettings-index---sectionFieldItems---wFLRn {
  flex: 1;
}

.assets-pages-UserSettings-index---sectionFieldItem---1F4BI {
  margin-top: var(--midVertGutter);
}

.assets-pages-UserSettings-index---sectionFieldItem---1F4BI:first-child {
  margin-top: 0;
}

.assets-pages-UserSettings-index---sectionButton---1vuuM {
  width: 200px;
}

.assets-pages-UserSettings-index---sectionField---15EVR {
  padding: var(--midVertGutter) 0;
  border-bottom: 1px solid var(--greybg);
}

.assets-pages-UserSettings-index---sectionField---15EVR:last-child {
  border-bottom: none;
}

.assets-pages-UserSettings-index---sectionFieldLabel---3LOeD {
  width: 30%;
}

.assets-pages-UserSettings-index---pushRight---3EwaP {
  margin-left: auto;
}
