.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitThree-index---container---1Pz4C {
  flex-direction: column;
  width: 480px;
  height: 480px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitThree-index---primaryImage---3EeSe {
  width: 237px;
  height: 476px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitThree-index---image---pWCIk {
  width: 237px;
  height: 237px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitThree-index---image---pWCIk > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitThree-index---primaryImage---3EeSe > div {
  border-radius: 0;
}
