.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Footer-index---root---3GD8B {
  overflow: hidden;
  color: white;
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  gap: 20px;
  align-items: center;
  padding-bottom: 10px;
}
.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Footer-index---cameraDiv---3dX9n {
  border: 1px solid white;
  border-radius: 16px;
  padding: 5px;
}
.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Footer-index---sendMessageDiv---3nfRn {
  display: flex;
  border: 1px solid white;
  padding: 10px;
  flex-basis: calc(100% - 27px);
  border-radius: 20px;
  justify-content: space-between;
}