.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitTwo-index---container---8EGkq {
  width: 480px;
  height: 480px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitTwo-index---image---2_1Qp {
  width: 237px;
  height: 476px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitTwo-index---image---2_1Qp > div {
  border-radius: 0;
}
