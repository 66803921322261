.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---root---4Ym3K {
  display: flex;
  padding: 12px;
  font-family: var(--pinterestFontStack);
  font-size: var(--captionFontSize);
  letter-spacing: -0.4px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---loading---R1hyo {
  margin-left: var(--midHorzGutter);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---mediaWrapper---2TBNI {
  width: 53%;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---bodyWrapper---3RyYt {
  width: 47%;
  margin-left: 24px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---largerIcon---26ocL {
  color: var(--inky500);
  font-size: var(--captionFontSize);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---iconBar---30BvD {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---icon---1E7E9 {
  color: var(--inky500);
  font-size: var(--bugFontSize);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---header---5Wqf4 {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---boardSelector---BIFTf {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: var(--smallVertGutter);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---boardName---38CPW {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 120px;
  padding: 0 12px;
  line-height: 32px;
  background-color: var(--inky100);
  border-bottom-left-radius: var(--midBorderRadius);
  border-top-left-radius: var(--midBorderRadius);
  color: var(--inky500);
  font-size: var(--bugFontSize);
  font-weight: 500;
  white-space: nowrap;
  word-wrap: none;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---boardName---38CPW span {
  overflow: hidden;
  width: 80px;
  text-overflow: ellipsis;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---boardSaveButton---3glKm {
  overflow: hidden;
  width: 50px;
  padding: 0 12px;
  line-height: 32px;
  background-color: var(--pinterest);
  border-bottom-right-radius: var(--midBorderRadius);
  border-top-right-radius: var(--midBorderRadius);
  color: white;
  font-size: var(--bugFontSize);
  font-weight: 500;
  text-overflow: ellipsis;
  word-wrap: none;
}

a.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---destinationLink---2HbwJ {
  display: block;
  overflow: hidden;
  color: var(--inky500);
  font-size: var(--captionFontSize);
  text-decoration: underline;
}

a.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---destinationLink---2HbwJ:visited {
  color: var(--inky500);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---linkIcon---2N2om {
  margin-right: 8px;
  transform: translateY(2px);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---pinterestTitle---Fbagx {
  margin-top: var(--smallVertGutter);
  margin-bottom: var(--tinyVertGutter);
  line-height: var(--largeLineHeight);
  font-weight: 700;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---body---3CidS {
  display: flex;
  margin-bottom: 9px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---bodyRight---3xGxd {
  flex-grow: 1;
  overflow: hidden;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---bodyImage---1E6un {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  border-radius: 50%;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---bodyHeader---70nYg {
  display: flex;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---bodyActivity---1D0Dv {
  flex-grow: 1;
  max-width: 188px;
  overflow-wrap: break-word;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---footerContainer---1r-35 {
  display: flex;
  flex-direction: column;
  width: 105px;
  margin-top: var(--smallVertGutter);
  margin-bottom: var(--midVertGutter);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---footer---3ULij {
  display: flex;
  justify-content: space-between;
  width: 105px;
  font-size: var(--bugFontSize);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---activeFooterElement---2fb2V {
  color: var(--inky500);
  font-weight: 700;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---inactiveFooterElement---l3gPe {
  color: var(--inky400);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-index---activeFooterElementUnderline---1IHLg {
  width: 38px;
  border: 1px solid var(--inky500);
  margin-top: var(--smallVertGutter);
}
