
.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsError-index---root---2QSah {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--tinyVertGutter) var(--midHorzGutter) var(--miniVertGutter);
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsError-index---rootFocused---2fVi9 {
  background-color: var(--whale100);
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsError-index---banIcon---2GzIO {
  flex-basis: 30px;
  align-self: flex-start;
  padding-right: var(--tinyHorzGutter);
  color: var(--coral400);
  font-size: var(--display1FontSize);
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsError-index---imageContainer---3w41M {
  flex: 1;
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsError-index---image---1AnEd {
  display: inline-block;
  width: auto;
  height: auto;
  border-radius: var(--elementBorderRadius);
}

.assets-pages-Composer-Variation-TextField-PostEditor-MentionSuggestionsError-index---textContainer---1OUIs {
  line-height: var(--captionLineHeight);
  font-size: var(--captionFontSize);
}
