.assets-pages-ContentPerformance-ContentHistory-index---variationHistory---3a4bN {
  margin-bottom: var(--largeVertGutter);
}

.assets-pages-ContentPerformance-ContentHistory-index---variationHistory---3a4bN:last-child {
  margin-bottom: 0;
}

.assets-pages-ContentPerformance-ContentHistory-index---historyTable---jofWv {
  width: 100%;
  margin-top: var(--smallVertGutter);
}

.assets-pages-ContentPerformance-ContentHistory-index---postLink---3nkJ4 {
  margin-left: var(--tinyHorzGutter);
}
