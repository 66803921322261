.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-SquareTwo-index---container---2taKU {
  width: 480px;
  height: 239px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-SquareTwo-index---image---3rqcW {
  width: 238px;
  height: 237px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-SquareTwo-index---image---3rqcW > div {
  border-radius: 0;
}
