.assets-pages-NewComposer-Variation-Attachments-MediaAttacher-index---noScrapedImagesNotice---1V6U6 {
  padding: 58px 0; /* Props open notice to same height as media list */
  color: var(--inky300);
  font-size: 15px;
  text-align: center;
}

.assets-pages-NewComposer-Variation-Attachments-MediaAttacher-index---mediaListExpander---3eFME {
  margin-top: var(--smallVertGutter);
}

.assets-pages-NewComposer-Variation-Attachments-MediaAttacher-index---mediaListCaption---3I85P {
  display: flex;
  align-items: center;
}

.assets-pages-NewComposer-Variation-Attachments-MediaAttacher-index---imageIcon---30UQW {
  height: var(--captionFontSize);
  margin-right: var(--tinyHorzGutter);
}

.assets-pages-NewComposer-Variation-Attachments-MediaAttacher-index---attachmentContainer---3ruO3 {
  padding-top: var(--smallVertGutter);
  padding-bottom: var(--smallVertGutter);
}
