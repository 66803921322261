.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-SquareThree-index---container---2Q_m9 {
  width: 478px;
  height: 161px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-SquareThree-index---image---1Jq1l {
  width: 157px;
  height: 159px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-SquareThree-index---image---1Jq1l > div {
  border-radius: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-SquareThree-index---overlay---2yGvG {
  line-height: 160px;
  font-size: 28px;
}
