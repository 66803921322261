.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitThree-index---container---xUDML {
  flex-direction: column;
  width: 480px;
  height: 480px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitThree-index---primaryImage---2mHOw {
  width: 237px;
  height: 476px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitThree-index---image---34ThX {
  width: 237px;
  height: 237px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitThree-index---image---34ThX > div,
.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitThree-index---primaryImage---2mHOw > div {
  border-radius: 0;
}
