
.assets-components-common-typography-Body-index---root---37EmU {
  line-height: var(--bodyLineHeight);
  font-size: var(--bodyFontSize);
  font-weight: 400;
}

.assets-components-common-typography-Body-index---textAlignLeft---3doBq {
  text-align: left;
}

.assets-components-common-typography-Body-index---textAlignCenter---3B0Tf {
  text-align: center;
}

.assets-components-common-typography-Body-index---textAlignRight---ihsGz {
  text-align: right;
}
