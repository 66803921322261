.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFive-index---container---1IOAQ {
  width: 480px;
  height: 410px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFive-index---primaryImage---2BLbY {
  width: 236px;
  height: 252px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFive-index---image---2Ubq1 {
  width: 157px;
  height: 156px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFive-index---image---2Ubq1 > div,
.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFive-index---primaryImage---2BLbY > div {
  border-radius: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFive-index---overlay---1DYuG {
  line-height: 157px;
  font-size: 36px;
}
