.assets-components-common-forms-NewDateTimeField-DateInput-CustomInput-index---root---2Nb7O {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 5px 7px;
  background-color: #f0f0f0;
  border-radius: 4px;
  font-size: 14px;
}

.assets-components-common-forms-NewDateTimeField-DateInput-CustomInput-index---root---2Nb7O.assets-components-common-forms-NewDateTimeField-DateInput-CustomInput-index---disabled---9TQsc {
  cursor: not-allowed;
}

.assets-components-common-forms-NewDateTimeField-DateInput-CustomInput-index---root---2Nb7O.assets-components-common-forms-NewDateTimeField-DateInput-CustomInput-index---disabled---9TQsc .assets-components-common-forms-NewDateTimeField-DateInput-CustomInput-index---calendar---NwMsO {
  opacity: 0.8;
}

.assets-components-common-forms-NewDateTimeField-DateInput-CustomInput-index---root---2Nb7O > input {
  width: 84px;
  padding-top: 2px;
  border: none;
  background-color: transparent;
  color: var(--inky500);
  text-align: center;
}

.assets-components-common-forms-NewDateTimeField-DateInput-CustomInput-index---root---2Nb7O > input:disabled {
  opacity: 0.82;
}

.assets-components-common-forms-NewDateTimeField-DateInput-CustomInput-index---root---2Nb7O > input:focus {
  outline: none;
}
