.assets-pages-Invoice-index---loading---2G_LC {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-pages-Invoice-index---section---Iae4D {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  border: 1px solid var(--inky200); /* TODO: This may need to be lighter via Dashboard PR. */
  margin-top: calc(2 * var(--smallVertGutter));
  background-color: #fff;
  border-radius: var(--elementBorderRadius);
}

.assets-pages-Invoice-index---row---2rESr,
.assets-pages-Invoice-index---rowEqual---3M91t {
  display: grid;
  margin: var(--midVertGutter) 0 calc(3 * var(--midVertGutter));
  grid-gap: calc(2 * var(--smallHorzGutter));
  grid-template-columns: 1fr 2fr;
}

.assets-pages-Invoice-index---rowEqual---3M91t {
  margin-bottom: var(--midVertGutter);
  grid-template-columns: 1fr 1fr 1fr;
}

.assets-pages-Invoice-index---colCenter---1QFDo {
  grid-column-start: 2;
}

.assets-pages-Invoice-index---colRight---3qxt2 {
  grid-column-start: 3;
}

.assets-pages-Invoice-index---table---210I9 {
  width: 100%;
  border-collapse: collapse;
}

.assets-pages-Invoice-index---table---210I9 .assets-pages-Invoice-index---tableHeading---1L-tu {
  width: 50%;
  border-bottom: 1px solid var(--inky300);
  color: var(--inky300);
  text-align: left;
  text-transform: uppercase;
}

.assets-pages-Invoice-index---subTotal---7ui_L {
  text-align: center;
  font-size: 15px;
}

.assets-pages-Invoice-index---subTotal---7ui_L span {
  float: revert;
  font-size: 15px;
}

.assets-pages-Invoice-index---charge---gg63n {
  width: 100%;
  border-bottom: 1px solid var(--inky300);
  color: var(--inky300);
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
}

.assets-pages-Invoice-index---row2---YF_Dp {
  margin-top: 17px;
}

.assets-pages-Invoice-index---invoiceData---3wQlC {
  text-align: center;

}

.assets-pages-Invoice-index---invoiceData---3wQlC span{
  font-weight: 100;
  float: revert;
  font-size: 15px;
}

.assets-pages-Invoice-index---dataBolder---9TJe6 {
  padding-bottom: 5px;
}

.assets-pages-Invoice-index---dataBolder---9TJe6 span {
  font-weight: 100;
  font-size: 15px;
}

.assets-pages-Invoice-index---date---qRtpq {
  width: 50%;
  color: var(--inky300);
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
}

.assets-pages-Invoice-index---description---1UMZR {
  text-align: left;
}

.assets-pages-Invoice-index---paddingX---l_Lgf {
  padding-bottom:10px ;
  padding-top: 14px;
}

.assets-pages-Invoice-index---table---210I9 td {
  vertical-align: top;
}

.assets-pages-Invoice-index---table---210I9 tbody tr:first-child td {
  padding-top: var(--smallVertGutter);
}

.assets-pages-Invoice-index---header---3ZWek {
  display: flex;
  justify-content: space-between;
}

.assets-pages-Invoice-index---dueDate---1vr7R {
  color: var(--inky300);
  font-weight: normal;
}

.assets-pages-Invoice-index---total---1y7AJ {
  float: right;
  color: var(--kelp500);
}

.assets-pages-Invoice-index---status---2uXay {
  color: var(--kelp500);
}

@media print {
  .assets-pages-Invoice-index---section---Iae4D {
    padding: 0;
    border: none;
  }

  .assets-pages-Invoice-index---table---210I9 th {
    border-bottom-color: inherit;
    color: inherit;
  }

  .assets-pages-Invoice-index---printButton---snJ6l {
    display: none !important;
  }
}

.assets-pages-Invoice-index---upsell---2MGyY {
  margin-top: 10px;
}

.assets-pages-Invoice-index---planTitle---2XAdY {
  padding-right: 5px;
  border-bottom-color: inherit;
  color: var(--inky300);
  font-size: 17px;
  font-weight: 600;
  text-align: left;
}

.assets-pages-Invoice-index---planName---ysfoE {
  padding-right: 5px;
}

.assets-pages-Invoice-index---addOns---2hcAu {
  padding-right: 5px;
  border-bottom-color: inherit;
  color: var(--inky300);
  font-size: 17px;
  font-weight: 600;
  text-align: left;
}

.assets-pages-Invoice-index---socialAccounts---3vXL0 {
  padding-right: 5px;
}

.assets-pages-Invoice-index---monthlyTotal---VqEOE {
  font-size: 16px;
  font-weight: 600;
}

.assets-pages-Invoice-index---monthlyTotalRow---WT0vf {
  padding-top: 8px;
  padding-bottom: 5px;
}

.assets-pages-Invoice-index---priceMain---1c1Ho {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--inky300);
}
