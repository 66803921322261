.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---image---2NIGb {
  float: left;
  width: var(--hugeVertGutter);
  height: var(--hugeVertGutter);
  margin-top: var(--miniVertGutter);
  margin-right: var(--smallHorzGutter);
  margin-bottom: var(--smallVertGutter);
  border-radius: 50%;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---name---cf_g6,
.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---nickname---kKaqr,
.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---time---J8YP2 {
  line-height: 19.25px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---name---cf_g6 {
  cursor: pointer;
  font-weight: bold;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---name---cf_g6:hover {
  text-decoration: none;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---name---cf_g6 a {
  color: var(--twitterTextDark);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---nickname---kKaqr,
.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---time---J8YP2 {
  color: var(--twitterTextMid);
  font-size: var(--captionFontSize);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---time---J8YP2::after {
  content: "  \00b7";
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---time---J8YP2 a {
  color: var(--twitterTextMid);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-ByLine-index---icon---2UGqG {
  color: var(--inky400);
}
