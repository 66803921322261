.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Two-index---container---2mHJP {
  width: 504px;
  height: 254px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Two-index---image---2-v5t {
  width: 250px;
  height: 252px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Two-index---image---2-v5t:first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Two-index---image---2-v5t:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Two-index---image---2-v5t > div {
  border-radius: 0;
}
