.assets-components-TeamManager-CreateInvitation-index---main---Sek2U {
  padding: var(--midVertGutter) 0 var(--largeVertGutter);
}

.assets-components-TeamManager-CreateInvitation-index---sectionField---11LcT {
  margin-top: var(--largeVertGutter);
}

.assets-components-TeamManager-CreateInvitation-index---sectionField---11LcT:first-child {
  margin-top: 0;
}

.assets-components-TeamManager-CreateInvitation-index---sectionFieldDescription---162F3 {
  display: block;
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-components-TeamManager-CreateInvitation-index---email---229nj {
  margin-top: var(--tinyVertGutter);
}

.assets-components-TeamManager-CreateInvitation-index---invalidEmail---2O-F8 {
  border-color: var(--dangerColor);
  outline-color: var(--dangerColor);
}

.assets-components-TeamManager-CreateInvitation-index---sectionFieldInput---2JeGN {
  margin-top: var(--midHorzGutter);
}

.assets-components-TeamManager-CreateInvitation-index---sectionFieldInput---2JeGN:first-of-type {
  margin-top: var(--tinyVertGutter);
}

.assets-components-TeamManager-CreateInvitation-index---radioButtonOverride---1BNrn {
  margin-top: 3px;
}

.assets-components-TeamManager-CreateInvitation-index---buttonCancel---DKfpE {
  margin-right: var(--smallVertGutter);
}

.assets-components-TeamManager-CreateInvitation-index---roleDescription---3qMlJ {
  font-size: var(--captionFontSize);
}
