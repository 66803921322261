.assets-pages-Composer-RejectionModals-common-RejectionModal-index---reasons---3y23Q {
  padding: 0 var(--largeHorzGutter);
  margin-bottom: var(--largeVertGutter);
  text-align: left;
}

.assets-pages-Composer-RejectionModals-common-RejectionModal-index---reasons---3y23Q li {
  padding-bottom: var(--midVertGutter);
}

.assets-pages-Composer-RejectionModals-common-RejectionModal-index---groupedReasons---FNmPV {
  margin-bottom: var(--largeVertGutter);
  text-align: left;
}

.assets-pages-Composer-RejectionModals-common-RejectionModal-index---group---1LdN7 {
  margin-bottom: var(--tinyVertGutter);
}

.assets-pages-Composer-RejectionModals-common-RejectionModal-index---group---1LdN7:last-of-type {
  margin-bottom: 0;
}

.assets-pages-Composer-RejectionModals-common-RejectionModal-index---group---1LdN7::after {
  display: block;
  clear: both;
  content: "";
}

.assets-pages-Composer-RejectionModals-common-RejectionModal-index---groupedReasonsLabels---16N-C {
  font-weight: var(--semiBold);
}

.assets-pages-Composer-RejectionModals-common-RejectionModal-index---key---2ooCf {
  float: left;
  width: 29%;
  height: var(--captionLineHeight);
  cursor: help;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assets-pages-Composer-RejectionModals-common-RejectionModal-index---reason---1AJZD {
  float: right;
  width: 69%;
  margin-left: var(--tinyHorzGutter);
}
