.assets-components-common-content-common-ActionBar-index---root---3JU0K {
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 0 var(--largeHorzGutter);
}

.assets-components-common-content-common-ActionBar-index---root---3JU0K > * {
  flex: 0 0 auto;
}

.assets-components-common-content-common-ActionBar-index---loading---GRynu > * {
  align-self: center;
}

.assets-components-common-content-common-ActionBar-index---root---3JU0K > *:first-child {
  max-width: 300px;
}

.assets-components-common-content-common-ActionBar-index---loading---GRynu {
  display: flex;
}

.assets-components-common-content-common-ActionBar-index---loading---GRynu > *:last-child,
.assets-components-common-content-common-ActionBar-index---loading---GRynu > *:nth-last-child(2) {
  margin-left: var(--midVertGutter);
}
