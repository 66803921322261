.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---root---3h1H6 {
  display: flex;
  padding: 12px;
  font-family: var(--pinterestFontStack);
  font-size: var(--captionFontSize);
  letter-spacing: -0.4px;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---loading---iRONy {
  margin-left: var(--midHorzGutter);
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---mediaWrapper---37V59 {
  width: 53%;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---bodyWrapper---2r5ug {
  width: 47%;
  margin-left: 24px;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---largerIcon---2_G_U {
  color: var(--inky500);
  font-size: var(--captionFontSize);
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---iconBar---3rpUV {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70px;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---icon---oI5FY {
  color: var(--inky500);
  font-size: var(--bugFontSize);
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---header---3haqU {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---boardSelector---vRlwU {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: var(--smallVertGutter);
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---boardName---3Al-C {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 120px;
  padding: 0 12px;
  line-height: 32px;
  background-color: var(--inky100);
  border-bottom-left-radius: var(--midBorderRadius);
  border-top-left-radius: var(--midBorderRadius);
  color: var(--inky500);
  font-size: var(--bugFontSize);
  font-weight: 500;
  white-space: nowrap;
  word-wrap: none;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---boardName---3Al-C span {
  overflow: hidden;
  width: 80px;
  text-overflow: ellipsis;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---boardSaveButton---28ZGj {
  overflow: hidden;
  width: 50px;
  padding: 0 12px;
  line-height: 32px;
  background-color: var(--pinterest);
  border-bottom-right-radius: var(--midBorderRadius);
  border-top-right-radius: var(--midBorderRadius);
  color: white;
  font-size: var(--bugFontSize);
  font-weight: 500;
  text-overflow: ellipsis;
  word-wrap: none;
}

a.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---destinationLink---lQPi7 {
  display: block;
  overflow: hidden;
  color: var(--inky500);
  font-size: var(--captionFontSize);
  text-decoration: underline;
}

a.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---destinationLink---lQPi7:visited {
  color: var(--inky500);
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---linkIcon---1W4t0 {
  margin-right: 8px;
  transform: translateY(2px);
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---pinterestTitle---3jy7a {
  margin-top: var(--smallVertGutter);
  margin-bottom: var(--tinyVertGutter);
  line-height: var(--largeLineHeight);
  font-weight: 700;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---body---3mXmj {
  display: flex;
  margin-bottom: 9px;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---bodyRight---2xnnR {
  flex-grow: 1;
  overflow: hidden;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---bodyImage---2CUAo {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  border-radius: 50%;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---bodyHeader---1U4ec {
  display: flex;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---bodyActivity---tja3R {
  flex-grow: 1;
  max-width: 188px;
  overflow-wrap: break-word;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---footerContainer---2hk-p {
  display: flex;
  flex-direction: column;
  width: 105px;
  margin-top: var(--smallVertGutter);
  margin-bottom: var(--midVertGutter);
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---footer---2wEha {
  display: flex;
  justify-content: space-between;
  width: 105px;
  font-size: var(--bugFontSize);
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---activeFooterElement---1b_0Q {
  color: var(--inky500);
  font-weight: 700;
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---inactiveFooterElement---6Fc07 {
  color: var(--inky400);
}

.assets-pages-Composer-Variation-PostPreview-Post-PinterestPost-index---activeFooterElementUnderline---RVCjl {
  width: 38px;
  border: 1px solid var(--inky500);
  margin-top: var(--smallVertGutter);
}
