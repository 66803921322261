.assets-components-OnboardingBar-StepBubble-index---root---NJ-2y {
  --rootHeight: 10px;

  height: var(--rootHeight);
  margin-right: 3px;
  background-color: var(--inky100);
  border-radius: calc(var(--rootHeight) / 2);
}

.assets-components-OnboardingBar-StepBubble-index---root---NJ-2y:hover {
  transform: scale(1.05);
}

.assets-components-OnboardingBar-StepBubble-index---isCurrent---njcUr {
  background-color: var(--sanddollar600);
}

.assets-components-OnboardingBar-StepBubble-index---isCompleted---23lQe {
  background-color: var(--kelp400);
}
