.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitTwo-index---container---3XNJU {
  width: 480px;
  height: 480px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitTwo-index---image---3ZQdn {
  width: 237px;
  height: 476px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitTwo-index---image---3ZQdn > div {
  border-radius: 0;
}
