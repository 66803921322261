
.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Text-index---root---1ioh2 {
  line-height: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Text-index---root---1ioh2 a {
  color: var(--twitterLinkBlue);
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Text-index---newLine---2NaQB {

}
