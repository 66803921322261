.assets-pages-NewComposer-Variation-Attachments-MediaAttacher-MediaOverlay-index---root---ibRpM {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.assets-pages-NewComposer-Variation-Attachments-MediaAttacher-MediaOverlay-index---dimensions---2WR5Y {
  position: absolute;
  right: 0;
  bottom: var(--tinyVertGutter);
  left: 0;
  color: #fff;
}
