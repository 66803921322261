.assets-pages-NewComposer-AdvancedSettings-UseOnceField-index---helpText---hUfba {
  margin: 10px 0 0 40px;
  color: #202f39;
  font-size: 16px;
  opacity: 0.8;
}

.assets-pages-NewComposer-AdvancedSettings-UseOnceField-index---helpText---hUfba a {
  color: #4d948f;
  text-decoration: underline;
}
