.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-ByLine-index---image---NeUoP {
  float: left;
  width: 48px;
  height: 48px;
  margin-top: 3px;
  margin-bottom: 9px;
  margin-left: -58px;
  border-radius: 50%;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-ByLine-index---name---10hd3,
.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-ByLine-index---nickname---2HL_S,
.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-ByLine-index---time---1hQHf {
  line-height: 19.25px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-ByLine-index---name---10hd3 {
  cursor: pointer;
  font-weight: bold;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-ByLine-index---name---10hd3:hover {
  text-decoration: none;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-ByLine-index---name---10hd3 a {
  color: var(--twitterTextDark);
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-ByLine-index---nickname---2HL_S,
.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-ByLine-index---time---1hQHf {
  color: var(--twitterTextMid);
  font-size: 14px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-ByLine-index---time---1hQHf::before {
  content: "\00b7";
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-ByLine-index---time---1hQHf a {
  color: var(--twitterTextMid);
}
