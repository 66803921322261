.assets-components-common-layout-Tabs-Tab-index---root---3nEPt {
  position: relative;
  display: inline-block;
  margin-right: 1.5em;
  color: var(--inky300);
  cursor: pointer;
}

.assets-components-common-layout-Tabs-Tab-index---root---3nEPt:hover {
  color: var(--kelp400);
  text-decoration: none;
}

.assets-components-common-layout-Tabs-Tab-index---selected---37FFj {
  border-bottom: solid 2px;
  border-color: var(--kelp500);
  color: var(--kelp500);
  cursor: default;
}

.assets-components-common-layout-Tabs-Tab-index---selected---37FFj:hover {
  color: var(--kelp500);
}

.assets-components-common-layout-Tabs-Tab-index---new---CCABC {
  position: absolute;
  z-index: 1;
  top: -8px;
  right: -30px;
}
