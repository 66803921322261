.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Caption-index---root---H2d3e {
  overflow: hidden;
  padding: 5px 30px;
  text-overflow: "more";
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Caption-index---nickname---2Dqw6 {
  margin-right: 5px;
  font-weight: 600;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Caption-index---hashtag---1JOR0 {
  color: var(--instagramNavy);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Caption-index---root---H2d3e mark {
  background: unset;
  color: var(--instagramGray);
}
