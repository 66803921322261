.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Image-index---root---1aRBW {
  position: relative;
  display: inline-block;
  overflow: hidden;
  min-width: var(--previewImageSize);
  height: var(--previewImageSize);
}
.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Image-index---rootStory---3MdPH {
  position: relative;
  display: inline-block;
  overflow: hidden;
  min-width: 57px;
  height: 100%;
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Image-index---rootStory---3MdPH > div{
  width: 57px !important;
  height: 130px !important;
  background-size: 100% !important;
  background-position: 100% !important;
  background-repeat: no-repeat;
}
.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Image-index---overlay---1fQV8 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  line-height: var(--previewImageSize);
  background-color: var(--inky300);
  border-radius: var(--elementBorderRadius);
  text-align: center;
  visibility: hidden;
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-Image-index---root---1aRBW:hover .assets-pages-NewComposer-Variation-Attachments-common-MediaList-Image-index---overlay---1fQV8 {
  visibility: visible;
}
