.assets-components-Categories-index---container---1K-6Q {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0;
}

.assets-components-Categories-index---header---3rU_E {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: var(--largeVertGutter);
}

.assets-components-Categories-index---newHeader---ipU_x {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: var(--largeVertGutter);
}

.assets-components-Categories-index---headerTitle---fyjK- {
  display: flex;
  flex-direction: row;
}

.assets-components-Categories-index---headerTitleText---QNYgM {
  margin: 0;
}

.assets-components-Categories-index---categoryCountText---oud2h {
  align-self: flex-end;
  padding: var(--tinyVertGutter) var(--smallHorzGutter);
  border: 1px solid var(--inky100);
  margin-bottom: var(--miniVertGutter);
  margin-left: var(--smallHorzGutter);
  background-color: var(--inky50);
  border-radius: var(--elementBorderRadius);
  font-size: var(--subheadingFontSize);
}

.assets-components-Categories-index---newCategoryCountText---2yRp4 {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  height: 36px;
  padding: 0 10px;
  border: 1px solid var(--inky100);
  margin-right: 15px;
  background-color: var(--inky50);
  border-radius: var(--elementBorderRadius);
  font-size: 15px;
}

.assets-components-Categories-index---helpLink---3ir9s {
  align-self: center;
  margin-top: var(--midVertGutter);
  margin-left: var(--smallHorzGutter);
}

.assets-components-Categories-index---questionCircleIcon---3R07T {
  color: var(--inky300);
  font-size: var(--captionFontSize);
}
