.assets-components-Filters-common-Select-index---root---3lTfn {
  margin-top: var(--largeVertGutter);
}

.assets-components-Filters-common-Select-index---select---38gji {
  background-color: transparent;
}

.assets-components-Filters-common-Select-index---hidden---3mcpp {
  display: none;
}

.assets-components-Filters-common-Select-index---labelToggle---3l8Bj{
  display: flex;
  justify-content: space-between;
}

.assets-components-Filters-common-Select-index---labelToggle---3l8Bj div{
  cursor: pointer;
}