.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-index---root---puXJt {
  width: var(--instagramPreviewWidth);
  margin-top: var(--smallVertGutter);
  margin-bottom: calc(var(--smallVertGutter));
  color: var(--instagramBlack);
  font-family: var(--instagramFontStack);
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-index---carousel---14Ty7 {
  position: relative;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-index---scroller---1YDhf {
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 500px;
  margin-top: -15px;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-index---imageXOfYIndicator---1szPZ {
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
}
