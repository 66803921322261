.assets-components-Thumbnail-Image-index---root---1LXE9 {
  margin-right: var(--largeHorzGutter);
}

.assets-components-Thumbnail-Image-index---link---2BWQB {
  position: relative;
  display: block;
}

.assets-components-Thumbnail-Image-index---count---1U9Rf {
  position: absolute;
  right: 0;
  bottom: 0;
  width: var(--badgeSize);
  height: var(--badgeSize);
  line-height: var(--badgeSize);
  background-color: var(--inky400);
  border-bottom-right-radius: var(--elementBorderRadius);
  border-top-left-radius: var(--elementBorderRadius);
  color: white;
  font-size: var(--captionFontSize);
  text-align: center;
}

.assets-components-Thumbnail-Image-index---unqueueable---1dYDf {
  background-color: var(--inky300);
}

.assets-components-Thumbnail-Image-index---image---3pp3M {
  border-radius: var(--elementBorderRadius);
  -o-object-fit: cover;
     object-fit: cover;
}

.assets-components-Thumbnail-Image-index---failedImage---2yeRD {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--inky100);
  border-radius: var(--elementBorderRadius);
  color: var(--inky300);
}
