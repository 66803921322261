.assets-pages-Composer-Variation-FbTextField-index---label---2nIrw {
  color: var(--inky400);
}

.assets-pages-Composer-Variation-FbTextField-index---textInput---337aD {
  padding: 0 var(--smallHorzGutter);
}

.assets-pages-Composer-Variation-FbTextField-index---errorList---w2vUS {
  margin-bottom: var(--smallVertGutter);
}

.assets-pages-Composer-Variation-FbTextField-index---hasErrors---108Jt {
  border-top: 0;
}

.assets-pages-Composer-Variation-FbTextField-index---hasWarnings---XDgQ9 {
  border: 2px solid var(--starfish400) !important;
}
