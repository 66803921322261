.assets-components-UtmParametersSettings-index---main---2b8fp {
  padding: var(--midVertGutter) 0 var(--largeVertGutter);
}

.assets-components-UtmParametersSettings-index---buttonCancel---3drnA {
  margin-right: var(--smallVertGutter);
}

.assets-components-UtmParametersSettings-index---options---3mVJu {
  padding: var(--midVertGutter) 0;
}

.assets-components-UtmParametersSettings-index---utmSpan---GO6NE {
  display: flex;
}