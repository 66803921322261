.assets-pages-NewComposer-Variation-Attachments-common-MediaList-index---caption---3Niwl {
  margin-bottom: var(--tinyVertGutter);
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-index---overlay---3cHyX {
  z-index: 100;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  line-height: var(--previewImageSize);
  border-radius: var(--elementBorderRadius);
  text-align: center;
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-index---loadingIcon---3dDAc {
  margin-top: 1em;
  font-size: 4em;
}
.assets-pages-NewComposer-Variation-Attachments-common-MediaList-index---showLinkedInCarouselProps---3R9eW > div {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.assets-pages-NewComposer-Variation-Attachments-common-MediaList-index---showLinkedInCarouselProps---3R9eW input[type="text"] {
  margin: 0px 16px;
}
.assets-pages-NewComposer-Variation-Attachments-common-MediaList-index---showLinkedInCarouselOneProps---2iU2U {
  display: flex;
  align-items: center;
}
.assets-pages-NewComposer-Variation-Attachments-common-MediaList-index---showLinkedInCarouselOneProps---2iU2U input[type="text"] {
  border: 1px solid #cbcbcb;
  margin: 0px 16px;
}
