.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFour-index---container---3zxfC {
  flex-direction: column;
  width: 480px;
  height: 480px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFour-index---primaryImage---3Txqs {
  width: 317px;
  height: 476px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFour-index---image---2zXYx {
  width: 157px;
  height: 157px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFour-index---image---2zXYx > div,
.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFour-index---primaryImage---3Txqs > div {
  border-radius: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFour-index---overlay---3T86I {
  line-height: 157px;
  font-size: 36px;
}
