.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-Scroller-index---root---3DdLJ {
  width: 500px;
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-Scroller-index---root---3DdLJ img {
  height: 30px;
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-Scroller-index---rightChevron---3VKHd {
  float: right;
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-Scroller-index---leftChevron---QBaZb:hover,
.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-Scroller-index---rightChevron---3VKHd:hover {
  cursor: pointer;
}
