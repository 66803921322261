.assets-components-common-icons-Icon-index---colorPrimary---1avLU {
  color: var(--primaryColor);
}

.assets-components-common-icons-Icon-index---colorTextMid---31cqH {
  color: var(--textMidColor);
}

.assets-components-common-icons-Icon-index---colorTextDark---nSopz {
  color: var(--textDarkColor);
}

.assets-components-common-icons-Icon-index---colorDanger---2rYXV {
  color: var(--dangerColor);
}

.assets-components-common-icons-Icon-index---colorWarning---ZZKHA {
  color: var(--warningColor);
}
