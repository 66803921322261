@keyframes assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-Video-Timer-index---fade---2fLge {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-Video-Timer-index---root---1_yrA {
  width: 40px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.67);
  border-radius: 15px;
  color: white;
  letter-spacing: 2px;
  text-align: center;
}

/* See FADE_OUT_TIME in index.js, should match (delay + animation time) here */
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-Video-Timer-index---appearActive---eZTgr,
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-Video-Timer-index---enterActive---2nZhu {
  animation: assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-Video-Timer-index---fade---2fLge 0.75s linear 1s forwards;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-Video-Timer-index---exit---22Ka6,
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-Video-Timer-index---exitActive---3qPyM,
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-Video-Timer-index---exitDone---1edpE,
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-Video-Timer-index---enterDone---1acLn {
  opacity: 0;
}
