.assets-components-common-NewAccountSelect-Option-InstagramBusinessSettings-index---radioButton---2Z7X_ {
  display: flex;
  align-items: center;
}

.assets-components-common-NewAccountSelect-Option-InstagramBusinessSettings-index---title---3akKQ {
  padding-left: var(--tinyHorzGutter);
  font-size: var(--bodyFontSize);
}

.assets-components-common-NewAccountSelect-Option-InstagramBusinessSettings-index---description---rH5jU {
  display: block;
  padding-left: var(--largeHorzGutter);
  color: var(--inky400);
  font-size: var(--captionFontSize);
  font-size: 0.9em;
  font-weight: 300;
}

.assets-components-common-NewAccountSelect-Option-InstagramBusinessSettings-index---helpText---219w0 {
  padding-left: var(--largeHorzGutter);
  font-size: var(--captionFontSize);
}
