.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-ByLine-index---image---2RHLb {
  float: left;
  width: 48px;
  height: 48px;
  margin-top: 3px;
  margin-bottom: 9px;
  margin-left: -58px;
  border-radius: 50%;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-ByLine-index---name---2K2i5,
.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-ByLine-index---nickname---3ZF7y,
.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-ByLine-index---time---2x6m0 {
  line-height: 19.25px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-ByLine-index---name---2K2i5 {
  cursor: pointer;
  font-weight: bold;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-ByLine-index---name---2K2i5:hover {
  text-decoration: none;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-ByLine-index---name---2K2i5 a {
  color: var(--twitterTextDark);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-ByLine-index---nickname---3ZF7y,
.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-ByLine-index---time---2x6m0 {
  color: var(--twitterTextMid);
  font-size: 14px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-ByLine-index---time---2x6m0::before {
  content: "\00b7";
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-ByLine-index---time---2x6m0 a {
  color: var(--twitterTextMid);
}
