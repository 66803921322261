.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeThree-index---container---anvRa {
  width: 480px;
  height: 480px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeThree-index---primaryImage---Fxdp6 {
  width: 476px;
  height: 237px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeThree-index---image---15_4j {
  width: 237px;
  height: 237px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeThree-index---image---15_4j > div,
.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeThree-index---primaryImage---Fxdp6 > div {
  border-radius: 0;
}
