@keyframes assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---fade---1DnPl {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---root---AJDF- {
  width: 40px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.67);
  border-radius: 15px;
  color: white;
  letter-spacing: 2px;
  text-align: center;
}

/* See FADE_OUT_TIME in index.js, should match (delay + animation time) here */
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---appearActive---2VAMV,
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---enterActive---16D7I {
  animation: assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---fade---1DnPl 0.75s linear 1s forwards;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---exit---Uk_l2,
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---exitActive---2-S8I,
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---exitDone---2dB0c,
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-ImageXOfYIndicator-index---enterDone---ZhJB_ {
  opacity: 0;
}
