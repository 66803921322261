.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-InstagramPersonalCaption-index---select---1Z8ju {
  width: 100%;
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-InstagramPersonalCaption-index---description---26i9J {
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-InstagramPersonalCaption-index---link---gTbbo {
  margin-right: var(--tinyHorzGutter);
}
