.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFour-index---container---3Nu-C {
  width: 480px;
  height: 480px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFour-index---primaryImage---Nq6JF {
  width: 476px;
  height: 317px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFour-index---image---pxx8l {
  width: 157px;
  height: 157px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFour-index---image---pxx8l > div,
.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFour-index---primaryImage---Nq6JF > div {
  border-radius: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFour-index---overlay---pamle {
  line-height: 157px;
  font-size: 36px;
}
