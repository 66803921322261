.assets-components-common-AccountSelect-index---toggleAll---UJ_Im {
  padding: 0 0 4px;
  border-bottom: solid 1px var(--inky200);
}

@media (min-width: 700px) {
  .assets-components-common-AccountSelect-index---toggleAll---UJ_Im {
    padding: 0 var(--midHorzGutter) 4px;
  }
}

.assets-components-common-AccountSelect-index---toggleAll---UJ_Im > label {
  margin-left: 0;
}

.assets-components-common-AccountSelect-index---list----luQF {
  display: flex;
  flex-wrap: wrap;
}

.assets-components-common-AccountSelect-index---alertContainer---2KjxV {
  padding-top: var(--tinyVertGutter);
}

.assets-components-common-AccountSelect-index---alerts----j_Fk {
  padding: var(--midVertGutter) var(--midHorzGutter);
  border-top: solid 1px var(--inky200);
}

.assets-components-common-AccountSelect-index---alerts----j_Fk > * {
  margin-top: var(--tinyVertGutter);
}
