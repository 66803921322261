.assets-pages-NewComposer-Variation-PostPreview-index---root---2QmcG {
  min-width: var(--mainContentMinWidth);
}

.assets-pages-NewComposer-Variation-PostPreview-index---root---2QmcG > main {
  padding: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-index---accountSelectContainer---fSMVM {
  padding: 0 30px;
  margin: var(--midVertGutter) 0;
}

.assets-pages-NewComposer-Variation-PostPreview-index---accountSelectContainerHeader---5k6Kt {
  margin: 0 0 var(--tinyVertGutter) 0;
}

.assets-pages-NewComposer-Variation-PostPreview-index---postContainer---DYRhC {
  padding: var(--midVertGutter) 90px;
  background-color: var(--lighterGrey);
  text-align: center;
}

.assets-pages-NewComposer-Variation-PostPreview-index---nullStateContainer---LK58L {
  padding: 0 30px;
}
