
.assets-components-common-ImportCSVModal-index---body---3ykw_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.assets-components-common-ImportCSVModal-index---rules---2f1mj {
  margin: var(--midVertGutter) 0;
}

.assets-components-common-ImportCSVModal-index---actions---3DZ1o {
  display: flex;
  justify-content: center;
  margin-top: var(--largeVertGutter);
}

.assets-components-common-ImportCSVModal-index---noFlexBasis---28VY0 {
  flex-basis: unset;
}
