.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-Scroller-index---root---16mFV {
  width: 500px;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-Scroller-index---root---16mFV img {
  height: 30px;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-Scroller-index---rightChevron---1W_Bc {
  float: right;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-Scroller-index---leftChevron---3UcH0:hover,
.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Media-ImageCarousel-Scroller-index---rightChevron---1W_Bc:hover {
  cursor: pointer;
}
