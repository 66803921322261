.assets-components-App-index---root---19Ftg {
  height: 100%;
  min-height: 100%;
  padding-top: 55px;
  margin: 0 auto;
  line-height: 1.42857143;
  color: var(--inky500);
  font-family: var(--primaryFont);
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}

@media (min-width: 601px) {
  .assets-components-App-index---root---19Ftg {
    padding-top: 120px;
  }
}

@media (min-width: 995px) {
  .assets-components-App-index---root---19Ftg {
    padding-top: 55px;
  }
}

@media (min-width: 1100px) {
  .assets-components-App-index---newRootUI---3HuM2 {
    padding-top: 0;
    margin-left: 88px;
  }
}

@media (min-width: 1300px) {
  .assets-components-App-index---newRootUI---3HuM2 {
    margin-left: 272px;
  }

  .assets-components-App-index---newRootUICollapsed---KXW76 {
    margin-left: 88px;
  }

  .assets-components-App-index---newRootUICollapsedSchedule---zx4IG {
    margin-left: 88px;
  }
}

@media (min-width: 1336px) {
  .assets-components-App-index---newRootUICollapsedSchedule---zx4IG {
    margin-left: 272px;
  }

  .assets-components-App-index---newRootUICollapsed---KXW76 {
    margin-left: 88px;
  }
}

.assets-components-App-index---signupRoot---3xBye {
  background-color: white;
}

.assets-components-App-index---wrapper---3oryB {
  padding-bottom: var(--largeVertGutter);
  border-bottom: 1px solid var(--inky200);
  background-color: #fff;
}

.assets-components-App-index---container---3VgtB {
  max-width: var(--desktopContentWidth);
  padding: var(--smallVertGutter) 0 0 0;
  margin: 0 auto;
}

@media (min-width: 1200px) {
  .assets-components-App-index---newContainer---2g1vO {
    max-width: var(--desktopNewUIContentWidth);
  }
}

.assets-components-App-index---widgetContainer---1ZdOP {
  max-width: var(--desktopContentWidth);
  padding: var(--smallVertGutter) 0 0 0;
  margin: 0 auto;
}

.assets-components-App-index---signupContainer---3CDEm {
  padding: var(--smallVertGutter) 0 0 0;
  margin: 0 auto;
  background-color: white;
}

.assets-components-App-index---newWidth---kfFyS {
  max-width: var(--desktopNewContentWidth);
  padding: var(--smallVertGutter) 15px 0;
}

.assets-components-App-index---header---keow7 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 var(--largeVertGutter) 0;
}

.assets-components-App-index---messages---dfK17 {
  flex: 0 0 100%;
  height: 50px;
}

.assets-components-App-index---messages2---1Xm74 {
  flex: 0 0 100%;
  min-height: var(--hugeVertGutter);
}

.assets-components-App-index---body---cAx8X {
  display: flex;
  width: var(--desktopContentWidth);
  z-index: 1;
  position: relative;
}

.assets-components-App-index---newWidth---kfFyS .assets-components-App-index---body---cAx8X {
  width: 100%;
}

@media (min-width: 1200px) {
  .assets-components-App-index---newBody---2b_Ke {
    width: var(--desktopNewUIContentWidth);
  }
}

.assets-components-App-index---signupBody---1De4M {
  width: auto;
}

.assets-components-App-index---signupFooter---oe7xa {
  width: auto;
  padding: 15px 0;
  margin: 0 auto;
}

@media (min-width: 995px) {
  .assets-components-App-index---signupFooter---oe7xa {
    padding: 0 0 0 350px;
    margin: var(--giganticVertGutter) auto;
  }
}

.assets-components-App-index---sidebar---3Nmlk {
  flex-basis: 250px;
  min-width: 250px;
  padding-right: var(--largeHorzGutter);
}

.assets-components-App-index---main---WBu0B {
  flex-basis: var(--mainContentMinWidth);
  flex-grow: 1;
  max-width: 100%;
}

@media (max-width: 994px) {
  .assets-components-App-index---body---cAx8X {
    flex-wrap: wrap;
    width: 100%;
  }

  .assets-components-App-index---main---WBu0B {
    flex-basis: 100%;
    max-width: 100%;
  }

  .assets-components-App-index---sidebar---3Nmlk {
    flex-basis: 100%;
  }

  .assets-components-App-index---container---3VgtB {
    margin: 0 var(--smallHorzGutter);
  }

  .assets-components-App-index---containerSchedule---1g2OY {
    margin: 0;
  }

  .assets-components-App-index---messages---dfK17 {
    height: 62px;
  }
}

@media print {
  .assets-components-App-index---messages2---1Xm74 {
    display: none;
  }
}
