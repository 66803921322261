
.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Text-index---root---14QYl {
  line-height: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Text-index---root---14QYl a {
  color: var(--twitterLinkBlue);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Text-index---newLine---r7zwl {

}
