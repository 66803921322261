.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Caption-index---root---1w_tO {
  overflow: hidden;
  height: 48px;
  padding: 5px 15px;
  text-overflow: "more";
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Caption-index---nickname---13i1j {
  margin-right: 5px;
  font-weight: 600;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Caption-index---hashtag---Ozl_A {
  color: var(--instagramNavy);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Caption-index---root---1w_tO mark {
  background: unset;
  color: var(--instagramGray);
}
