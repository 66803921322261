.assets-components-Filters-DateCreated-index---root---1QbYb {
  display: grid;
  margin-top: var(--largeVertGutter);
  grid-gap: var(--smallVertGutter) var(--tinyHorzGutter);
  grid-template-columns: 1fr auto;
}

.assets-components-Filters-DateCreated-index---title---jPTbs {
  margin: 0;
  grid-column: span 2;
}

.assets-components-Filters-DateCreated-index---label---1iSPC {
  line-height: 34px;
  color: var(--inky400);
  font-size: var(--bodyFontSize);
  font-weight: var(--semiBold);
  justify-self: end;
}
