.assets-components-BulkEditTable-RemoveAccounts-index---main---yYhZu {
  padding: var(--midVertGutter) 0 var(--largeVertGutter);
}

.assets-components-BulkEditTable-RemoveAccounts-index---loading---1VkMP {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-components-BulkEditTable-RemoveAccounts-index---sectionFieldDescription---2Coc9 {
  display: block;
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-components-BulkEditTable-RemoveAccounts-index---sectionFieldInput---2bpDN {
  align-items: center;
  margin-top: var(--midHorzGutter);
}

.assets-components-BulkEditTable-RemoveAccounts-index---buttonCancel---1es1q {
  margin-right: var(--smallVertGutter);
}
