/*
  Default styles are for summary card. Other card type styles are handled as
  overrides based on an additional class on root.
*/

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---root---1cEU8 {
  min-height: 131px;
  padding-bottom: 2px;
  margin-top: 10px;
  line-height: 1.3em;
  color: 000;
  cursor: pointer;
  font-size: 14px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---root---1cEU8 h2,
.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---root---1cEU8 p {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---wrap---saqup {
  overflow: hidden;
  border: solid 1px var(--twitterBorderLightGrey);
  border-radius: 0.42857em;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---wrap---saqup::after {
  clear: both;
  height: 0;
  line-height: 0;
  content: "";
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---imageContainer---3gaz7,
.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---content---1PEoQ {
  float: left;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---imageContainer---3gaz7 {
  position: relative;
  float: left;
  width: 8.81667em;
  background-color: var(--twitterBorderLightGrey);
  border-color: inherit;
  border-style: solid;
  border-width: 0 1px 0 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---summaryLargeImage---26WBW .assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---imageContainer---3gaz7 {
  float: none;
  width: 100%;
  border-right: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---imageContainer---3gaz7::before {
  display: block;
  padding-top: 100%;
  content: "";
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---summaryLargeImage---26WBW .assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---imageContainer---3gaz7::before {
  padding-top: 50%;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---imageWrap---2gDdb {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  overflow: hidden;
  line-height: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---playButton---3Cj3S {
  position: absolute;
  top: 34px;
  left: 34px;
  opacity: 0.9;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---image---IFLRp {
  border-radius: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---content---1PEoQ {
  overflow: hidden;
  width: calc(100% - 8.81667em - 2px);
  padding: 0.75em;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---summaryLargeImage---26WBW .assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---content---1PEoQ {
  float: none;
  width: 100%;
  padding-right: 1em;
  padding-left: 1em;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---content---1PEoQ h2,
.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---content---1PEoQ p {
  overflow: hidden;
  max-height: 2.6em;
  line-height: 1.3em;
  color: #000;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---content---1PEoQ h2 {
  margin-top: 0;
  margin-bottom: 0.15em;
  font-size: 1em;
  font-weight: bold;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---summaryLargeImage---26WBW .assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---content---1PEoQ h2 {
  width: 476px;
  max-height: 1.3em;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---content---1PEoQ p {
  overflow: hidden;
  margin-top: 0.32333em;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---content---1PEoQ span {
  display: block;
  overflow: hidden;
  max-height: 1.3em;
  margin-top: 0.32333em;
  color: var(--twitterTextLight);
  text-transform: lowercase;
  white-space: nowrap;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Card-index---noImage---13j6w {
  display: table-cell;
  width: 125px;
  height: 125px;
  text-align: center;
  vertical-align: middle;
}
