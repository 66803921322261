.assets-pages-Composer-Variation-PostPreview-Post-index---postWrap---174-B {
  display: inline-block;
  border: solid 1px var(--inky300);
  margin: var(--midVertGutter) auto;
  background-color: #fff;
  border-radius: var(--midBorderRadius);
  text-align: left;
}

/* These need to be defined for the related test */
.assets-pages-Composer-Variation-PostPreview-Post-index---facebook---F8IhU,
.assets-pages-Composer-Variation-PostPreview-Post-index---twitter---2_qYr,
.assets-pages-Composer-Variation-PostPreview-Post-index---linkedin---wbopz,
.assets-pages-Composer-Variation-PostPreview-Post-index---instagram---2S6qP,
.assets-pages-Composer-Variation-PostPreview-Post-index---pinterest---1z5DR {
}

.assets-pages-Composer-Variation-PostPreview-Post-index---postWrap---174-B.assets-pages-Composer-Variation-PostPreview-Post-index---linkedin---wbopz {
  overflow: hidden;
  width: 551px;
}

.assets-pages-Composer-Variation-PostPreview-Post-index---postWrap---174-B.assets-pages-Composer-Variation-PostPreview-Post-index---twitter---2_qYr {
  width: 587px;
  margin-right: -22px;
  margin-left: -22px;
}

.assets-pages-Composer-Variation-PostPreview-Post-index---postWrap---174-B.assets-pages-Composer-Variation-PostPreview-Post-index---pinterest---1z5DR {
  width: 650px;
  margin-right: -60px;
  margin-left: -60px;
}
