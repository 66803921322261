.assets-components-common-forms-RadioButton2-index---radioButtonWrapper---1-txq {
  display: flex;
  cursor: pointer;
}

.assets-components-common-forms-RadioButton2-index---radioButtonWrapper---1-txq > input[type='radio'] {
  display: none;
}

.assets-components-common-forms-RadioButton2-index---radioButton---2T-Ur {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--midHorzGutter);
  min-width: var(--midHorzGutter);
  height: var(--midHorzGutter);
  border: 1px solid var(--inky200);
  background: #fff;
  border-radius: 50%;
}

.assets-components-common-forms-RadioButton2-index---radioButtonSelected---aErh6 {
  background: var(--kelp400);
}

.assets-components-common-forms-RadioButton2-index---radioButtonSelected---aErh6::before {
  display: block;
  width: var(--tinyHorzGutter);
  height: var(--tinyHorzGutter);
  background: #fff;
  border-radius: 50%;
  content: '';
}

.assets-components-common-forms-RadioButton2-index---radioLabel---2cEva {
  padding-left: var(--tinyHorzGutter);
}
