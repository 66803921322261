.assets-pages-NewDashboard-ActivityOverview-AccountSelect-index---providerIcon---LHlub {
  width: 18px;
  height: 18px;
  margin: 0 4px 0 0;
}

.assets-pages-NewDashboard-ActivityOverview-AccountSelect-index---selectedProviderIcon---21kin {
  width: 18px;
  height: 18px;
  margin: 0 6px 0 0;
}
