.assets-pages-NewComposer-Variation-PostPreview-Post-index---postWrap---6lKmx {
  display: inline-block;
  border: solid 1px var(--inky300);
  margin: var(--midVertGutter) auto;
  background-color: #fff;
  border-radius: var(--midBorderRadius);
  text-align: left;
}

/* These need to be defined for the related test */
.assets-pages-NewComposer-Variation-PostPreview-Post-index---facebook---XmU4m,
.assets-pages-NewComposer-Variation-PostPreview-Post-index---twitter---3CWa6,
.assets-pages-NewComposer-Variation-PostPreview-Post-index---linkedin---mw8FE,
.assets-pages-NewComposer-Variation-PostPreview-Post-index---instagram---1LbQY,
.assets-pages-NewComposer-Variation-PostPreview-Post-index---pinterest---22o7s {
}

.assets-pages-NewComposer-Variation-PostPreview-Post-index---postWrap---6lKmx.assets-pages-NewComposer-Variation-PostPreview-Post-index---linkedin---mw8FE {
  overflow: hidden;
  width: 551px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-index---postWrap---6lKmx.assets-pages-NewComposer-Variation-PostPreview-Post-index---twitter---3CWa6 {
  width: 587px;
  margin-right: -22px;
  margin-left: -22px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-index---postWrap---6lKmx.assets-pages-NewComposer-Variation-PostPreview-Post-index---pinterest---22o7s {
  width: 650px;
  margin-right: -60px;
  margin-left: -60px;
}
