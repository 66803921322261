.assets-pages-NewComposer-Variation-TextField-ButtonRow-index---container---3oMo0 {
  display: flex;
  justify-content: flex-start;
}

.assets-pages-NewComposer-Variation-TextField-ButtonRow-index---emojiSelect---3eB5I {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px 2px 0;
}

.assets-pages-NewComposer-Variation-TextField-ButtonRow-index---emojiSelect---3eB5I > div > button {
  height: auto;
  margin-top: var(--miniVertGutter);
  line-height: var(--midButtonHeight);
  border-color: var(--inky300);
  color: var(--inky400);
  font-size: 1.25em;
  text-align: center;
}

.assets-pages-NewComposer-Variation-TextField-ButtonRow-index---buttonRow---15uWP {
  display: flex;
  align-items: center;
  padding: var(--tinyHorzGutter) 0;
}

.assets-pages-NewComposer-Variation-TextField-ButtonRow-index---addLink---rQH5M {
  margin-right: var(--smallHorzGutter);
}

.assets-pages-NewComposer-Variation-TextField-ButtonRow-index---icon---16qJe {
  margin-right: var(--tinyHorzGutter);
  color: #fff;
}

.assets-pages-NewComposer-Variation-TextField-ButtonRow-index---addLink---rQH5M:hover .assets-pages-NewComposer-Variation-TextField-ButtonRow-index---icon---16qJe {
  color: #fff;
}
