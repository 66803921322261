.assets-pages-NewComposer-CraftYourContent-index---messageContainer---1_dxA {
  margin-bottom: var(--largeVertGutter);
  font-size: var(--bodyFontSize);
}

.assets-pages-NewComposer-CraftYourContent-index---addVariationCard---1JUZJ {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--largeVertGutter);
}

.assets-pages-NewComposer-CraftYourContent-index---addVariationButton---3DQF2,
.assets-pages-NewComposer-CraftYourContent-index---addVariationButton---3DQF2:visited {
  flex: 1;
  padding: var(--midVertGutter) var(--midHorzGutter);
  color: var(--inky400);
  font-weight: 600;
  text-align: center;
  text-decoration: none;
}

.assets-pages-NewComposer-CraftYourContent-index---suggestVariationsButtonContainer---2ee_H {
  padding: var(--midVertGutter) var(--smallHorzGutter);
}

.assets-pages-NewComposer-CraftYourContent-index---suggestVariationsButton---1drLR {
  padding: 0;
  margin: 0 auto;
  background-color: var(--whale500) !important;
  border-radius: var(--midBorderRadius) !important;
  text-decoration: none;
}
