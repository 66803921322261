.assets-components-Accounts-index---header---3AyMB {
  margin-bottom: var(--hugeVertGutter);
}

.assets-components-Accounts-index---headerTitleText---1kXzo {
  margin-bottom: var(--tinyVertGutter);
}

.assets-components-Accounts-index---accountsList---oflyl {
  position: relative;
  display: grid;
  flex: 2;
  grid-template-columns: auto auto;
}

.assets-components-Accounts-index---listContainer---3fj8G {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.assets-components-Accounts-index---subtitleWarning---1NZNL {
  color: var(--coral500);
}
