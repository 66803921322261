
.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Text-index---root---11JSG {
  margin-bottom: 0;
  line-height: 1.38em;
  font-family: var(--fontMeta);
  font-size: 14px;
  font-weight: normal;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Text-index---big---QiPdh {
  line-height: 28px;
  font-family: var(--fontMeta);
  font-size: 24px;
  font-weight: 300;
  letter-spacing: normal;
}

/* Use consecutive newLines to simulate <p>s by styling second to have height */
.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Text-index---newLine---1GbjD + .assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Text-index---newLine---1GbjD {
  display: block;
  height: 12px !important;
  line-height: 0 !important;
  content: ' ' !important;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Text-index---root---11JSG a {
  color: var(--fbLinkBlue);
  cursor: pointer;
  text-decoration: none;
  word-wrap: break-word;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Text-index---root---11JSG a:hover {
  text-decoration: underline;
}
