.assets-pages-Library-List-EmptyState-index---emptyState---3AW__ {
  text-align: left;
}

.assets-pages-Library-List-EmptyState-index---importLink---2u5HZ {
  text-decoration: underline;
}

.assets-pages-Library-List-EmptyState-index---importLink---2u5HZ:visited {
  color: var(--inky300);
}
