.assets-components-SubscriptionManager-Discount-index---appliedCouponContentContainer---1y8sj {
  display: flex;
  justify-content: space-between;
}

.assets-components-SubscriptionManager-Discount-index---appliedCouponContentContainer---1y8sj a {
  text-decoration: none;
}

.assets-components-SubscriptionManager-Discount-index---appliedCouponContentContainer---1y8sj .assets-components-SubscriptionManager-Discount-index---actions---AEeJx a {
  margin-right: 0.5em;
  margin-left: 0.5em;
}

.assets-components-SubscriptionManager-Discount-index---link---39MSj {
  cursor: pointer;
}

.assets-components-SubscriptionManager-Discount-index---subheader---2RLmw {
  padding: var(--midVertGutter) 0;
}

.assets-components-SubscriptionManager-Discount-index---pushRight---2coBf {
  margin-left: auto;
  margin-right: 10px;
}

.assets-components-SubscriptionManager-Discount-index---defaultCouponText---30F0b {
  color: var(--inky300);
}

.assets-components-SubscriptionManager-Discount-index---button---3pPC7 {
  width: 200px;
}
