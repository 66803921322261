.assets-pages-ScheduleOld-index---header---3xojp {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  margin: 0 0 var(--tinyVertGutter) 0;
}

.assets-pages-ScheduleOld-index---toolbar---3sE0F {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: 0 0 var(--tinyVertGutter) 0;
}

.assets-pages-ScheduleOld-index---toolbar---3sE0F > * {
  margin-left: var(--midHorzGutter);
}

.assets-pages-ScheduleOld-index---toolbar---3sE0F *:first-child {
  margin-left: 0;
}

.assets-pages-ScheduleOld-index---pageTitle---3a3FX {
  flex: 1;
}

.assets-pages-ScheduleOld-index---filterByLabel---2sQhL {
  white-space: nowrap;
}

.assets-pages-ScheduleOld-index---accountFilter---3wKfA {
  flex-basis: 150px;
}

.assets-pages-ScheduleOld-index---categoryFilter---2xcRV {
  flex-basis: 150px;
}

.assets-pages-ScheduleOld-index---upgradeLink---2wT2l {
  margin-bottom: var(--largeVertGutter);
}

.assets-pages-ScheduleOld-index---addTimeslotButton---27qZB {
  white-space: nowrap;
}

.assets-pages-ScheduleOld-index---loadingContainer---2g7UH {
  width: 100%;
  height: 902px;
  border: 1px solid var(--inky200);
  background-color: white;
}

.assets-pages-ScheduleOld-index---loading---EGzAr {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

@media (max-width: 695px) {
  .assets-pages-ScheduleOld-index---toolbar---3sE0F {
    flex-direction: column;
    align-items: stretch;
  }

  .assets-pages-ScheduleOld-index---accountFilter---3wKfA {
    flex: auto;
    width: auto;
  }

  .assets-pages-ScheduleOld-index---categoryFilter---2xcRV {
    flex: auto;
  }

  .assets-pages-ScheduleOld-index---pageTitle---3a3FX {
    flex: auto;
  }

  .assets-pages-ScheduleOld-index---toolbar---3sE0F > * {
    margin: 0 0 var(--tinyVertGutter) 0;
  }
}
