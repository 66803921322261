
.assets-pages-NewComposer-Variation-PostPreview-NullState-index---root---3sZ0N {
  margin-top: var(--hugeVertGutter);
  margin-bottom: calc(var(--largeVertGutter) + var(--hugeVertGutter));
  text-align: center;
}

.assets-pages-NewComposer-Variation-PostPreview-NullState-index---platformIcons---34sT2 {
  padding-left: 0;
  margin-bottom: var(--largeVertGutter);
  list-style: none;
}

.assets-pages-NewComposer-Variation-PostPreview-NullState-index---platformIcons---34sT2 li {
  display: inline-block;
}

.assets-pages-NewComposer-Variation-PostPreview-NullState-index---platformIcons---34sT2 li:not(:first-child) {
  margin-left: var(--midHorzGutter);
}

.assets-pages-NewComposer-Variation-PostPreview-NullState-index---platformIcons---34sT2 li:not(:last-child) {
  margin-right: var(--midHorzGutter);
}

.assets-pages-NewComposer-Variation-PostPreview-NullState-index---icon---23CNH {
  width: var(--hugeIconSize);
  height: var(--hugeIconSize);
  border-radius: var(--hugeIconBorderRadius);
}

.assets-pages-NewComposer-Variation-PostPreview-NullState-index---header---39jnY {
  margin-bottom: var(--midVertGutter);
}

.assets-pages-NewComposer-Variation-PostPreview-NullState-index---instructions---2VK8T {
  max-width: 400px;
  margin: 0 auto;
}
