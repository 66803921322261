.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-index---root---1V7vI {
  position: relative;
  margin-top: 10px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-index---root---1V7vI::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-index---video---j6vzy,
.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-index---singleImage---1VweV {
  width: 100%;
}
