.assets-components-common-layout-DropDown-index---root---Th6b2 {
  position: relative;
}

.assets-components-common-layout-DropDown-index---dropShadowed---2TplU {
  position: absolute;
  z-index: 5;
  width: -moz-max-content;
  width: max-content;
  margin-top: var(--tinyVertGutter);
  background-color: #fff;
  border-radius: var(--borderRadius);
  box-shadow: 0 2px 6px 0 var(--inky300);
  display: flex;
  flex-direction: column;
}

.assets-components-common-layout-DropDown-index---anchoredBottomLeft---2mzZg {
  top: 0;
  left: 0;
}

.assets-components-common-layout-DropDown-index---anchoredBottomRight---3P2g4 {
  top: 0;
  right: 0;
}

.assets-components-common-layout-DropDown-index---dropShadowed---2TplU:not(.assets-components-common-layout-DropDown-index---omitDefaultStyle---SztaA) > * {
  display: block;
  width: 100%;
  min-height: var(--inputHeight);
  padding: var(--tinyVertGutter) var(--smallHorzGutter);
  line-height: var(--inputHeight);
  color: var(--inky500);
  cursor: pointer;
  font-size: var(--bodyFontSize);
  text-align: left;
  white-space: nowrap;
}

.assets-components-common-layout-DropDown-index---dropShadowed---2TplU:not(.assets-components-common-layout-DropDown-index---omitDefaultStyle---SztaA) > *:hover {
  background-color: var(--inky100);
}
