.assets-components-common-buttons-EditButton-index---editButton---1QjQa {
  position: absolute;
  top: var(--tinyHorzGutter);
  left: var(--tinyHorzGutter);
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: var(--inky500);
  border-radius: var(--elementBorderRadius);
  color: #fff;
  cursor: pointer;
  text-align: center;
}

.assets-components-common-buttons-EditButton-index---editButton---1QjQa:hover {
  color: #fff;
}
