.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareFour-index---container---H1Uz5 {
  width: 478px;
  height: 478px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareFour-index---image---krIn_ {
  width: 237px;
  height: 237px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareFour-index---image---krIn_ > div {
  border-radius: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareFour-index---overlay---e2ybU {
  line-height: 237px;
  font-size: 36px;
}
