.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-index---root---1I1yr {
  margin-top: 10px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-index---video---2VJ2r {
  overflow: hidden;
  border-radius: var(--elementBorderRadius);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-index---video---2VJ2r video {
  width: 100%;
  max-height: 506px;
}
