.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-InstagramBusinessSettings-index---radioButton---kwQ8p {
  display: flex;
  align-items: center;
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-InstagramBusinessSettings-index---title---2ByKE {
  padding-left: var(--tinyHorzGutter);
  font-size: var(--bodyFontSize);
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-InstagramBusinessSettings-index---description---3rIXu {
  display: block;
  padding-left: var(--largeHorzGutter);
  color: var(--inky400);
  font-size: var(--captionFontSize);
  font-size: 0.9em;
  font-weight: 300;
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-InstagramBusinessSettings-index---helpText---1SPnr {
  padding-left: var(--largeHorzGutter);
  font-size: var(--captionFontSize);
}
