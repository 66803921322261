.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Header-index---root---2KCXc {
  display: flex;
  padding: var(--tinyVertGutter) var(--tinyHorzGutter);
  position: absolute;
  color: white;
  z-index: 2;
  top: 13px;
}
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramStory-Header-index---closeIcon---1u0Vt {
  font-size: 23px;
  position: absolute;
  right: -269px;
  line-height: 1;
}