.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeThree-index---container---1kKLX {
  width: 480px;
  height: 480px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeThree-index---primaryImage---1BUHf {
  width: 476px;
  height: 237px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeThree-index---image---2BBHf {
  width: 237px;
  height: 237px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeThree-index---image---2BBHf > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeThree-index---primaryImage---1BUHf > div {
  border-radius: 0;
}
