.assets-components-LinkShortenerSettings-Service-index---service---mC-kL {
  display: flex;
  margin-top: var(--smallVertGutter);
}

.assets-components-LinkShortenerSettings-Service-index---service---mC-kL:first-child {
  margin-top: 0;
}

.assets-components-LinkShortenerSettings-Service-index---radioButton---30GWm {
  margin: var(--tinyHorzGutter) var(--smallHorzGutter) 0 0;
}

.assets-components-LinkShortenerSettings-Service-index---caption---2PGEU {
  color: var(--inky400);
  font-size: var(--captionFontSize);
}
