.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFour-index---container---9zOfB {
  width: 480px;
  height: 480px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFour-index---primaryImage---22t-C {
  width: 476px;
  height: 317px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFour-index---image---1HUle {
  width: 157px;
  height: 157px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFour-index---image---1HUle > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFour-index---primaryImage---22t-C > div {
  border-radius: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeFour-index---overlay---It5IN {
  line-height: 157px;
  font-size: 36px;
}
