.assets-components-ImportManager-AddNew-index---root---2pHsW {
  display: grid;
  grid-auto-rows: minmax(195px, auto);
}

@media all {
  /* mobile first design approach... (less than 480px, i.e. mobile-portrait mode) */

  /* iPhone X portrait mode */
  .assets-components-ImportManager-AddNew-index---root---2pHsW {
    width: 280px;
    grid-template-columns: 1fr;
  }
}

@media (min-width: 480px) {
  /* iPhone X landscape mode */
  .assets-components-ImportManager-AddNew-index---root---2pHsW {
    width: 360px;
    grid-template-columns: 1fr;
  }
}

@media (min-width: 768px) {
  /* iPad */
  .assets-components-ImportManager-AddNew-index---root---2pHsW {
    width: 720px;
    grid-auto-rows: minmax(240px, auto);
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  /* iPad Pro */
  .assets-components-ImportManager-AddNew-index---root---2pHsW {
    width: 892px;
    grid-auto-rows: minmax(240px, auto);
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  /* desktop */
  .assets-components-ImportManager-AddNew-index---root---2pHsW {
    width: unset;
    grid-auto-rows: minmax(auto, auto);
    grid-template-columns: repeat(3, 1fr);
  }
}
