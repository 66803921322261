.assets-pages-ScheduleOld-Calendar-DesktopCalendar-index---root---2NzYC {
  --timeColumnWidth: 4rem;

  background-color: white;
  border-radius: var(--cardBorderRadius);
  box-shadow: 0 2px 10px -3px var(--inky150);
}

.assets-pages-ScheduleOld-Calendar-DesktopCalendar-index---daysHeader---2E74A {
  position: sticky;
  z-index: 1;
  top: 44px;
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid var(--greybg);
  background-color: white;
  border-top-left-radius: var(--cardBorderRadius);
  border-top-right-radius: var(--cardBorderRadius);
  font-weight: 600;
}

@media (min-width: 600px) {
  .assets-pages-ScheduleOld-Calendar-DesktopCalendar-index---daysHeader---2E74A {
    top: 105px;
  }
}

@media (min-width: 995px) {
  .assets-pages-ScheduleOld-Calendar-DesktopCalendar-index---daysHeader---2E74A {
    top: 55px;
  }
}

.assets-pages-ScheduleOld-Calendar-DesktopCalendar-index---daysHeader---2E74A > div {
  flex: 1;
  overflow: hidden;
  padding-top: 1.25rem;
  padding-bottom: 0.75rem;
  border-left: 1px solid var(--greybg);
  text-align: center;
  text-overflow: "";
}

.assets-pages-ScheduleOld-Calendar-DesktopCalendar-index---daysHeader---2E74A > div:first-child {
  flex: 0 0 var(--timeColumnWidth);
  border: none;
}

.assets-pages-ScheduleOld-Calendar-DesktopCalendar-index---hourRow---2-b6M {
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid var(--greybg);
  border-top: none;
}

.assets-pages-ScheduleOld-Calendar-DesktopCalendar-index---hourHeader---eZOq_ {
  flex: 0 0 var(--timeColumnWidth);
  line-height: var(--midLineHeight);
  font-weight: 600;
  text-align: center;
}

.assets-pages-ScheduleOld-Calendar-DesktopCalendar-index---hour---EYPkj {
  display: flex;
  flex: 1;
  flex-direction: column;
  cursor: cell;
}

.assets-pages-ScheduleOld-Calendar-DesktopCalendar-index---minuteRow---2afhk {
  display: flex;
  flex: 1;
  flex-basis: auto;
}

.assets-pages-ScheduleOld-Calendar-DesktopCalendar-index---day---17d9Z {
  flex: 1;
  padding: 0 0 var(--smallVertGutter) 0;
  border-left: 1px solid var(--greybg);
}

.assets-pages-ScheduleOld-Calendar-DesktopCalendar-index---loading---Hf6Fc {
  width: 100%;
  padding: 2rem 0;
  border: 1px solid var(--greybg);
  border-top: none;
  text-align: center;
}

@media (max-width: 600px) {
  .assets-pages-ScheduleOld-Calendar-DesktopCalendar-index---daysHeader---2E74A {
    position: sticky;
    z-index: 1;
    top: 44px;
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 1px solid var(--greybg);
    background-color: white;
    font-weight: 600;
  }
}
