.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareTwo-index---container---3hghd {
  width: 480px;
  height: 239px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareTwo-index---image---3ISs3 {
  width: 238px;
  height: 237px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareTwo-index---image---3ISs3 > div {
  border-radius: 0;
}
