.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-ByLine-index---root---Z_ffP {
  display: flex;
  align-items: flex-start;
  margin: var(--midVertGutter) 0 var(--midVertGutter);
  font-size: var(--captionFontSize);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-ByLine-index---name---2OUXZ {
  display: block;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-ByLine-index---followers---E05wh {
  display: block;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-ByLine-index---byLine---3DYLJ {
  flex-grow: 1;
  line-height: var(--bugLineHeight);
  cursor: pointer;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-ByLine-index---followButton---3iV9U {
  padding: 0 14px;
  line-height: 34px;
  background-color: var(--inky100);
  border-radius: var(--largeBorderRadius);
  color: var(--inky500);
  font-weight: 700;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-ByLine-index---followButton---3iV9U:hover {
  background-color: #ad081b;
  cursor: pointer;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-PinterestPost-ByLine-index---image---2baIm {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  border-radius: 50%;
}
