.assets-pages-NewComposer-Actions-index---root---2rqKV {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  margin-bottom: 15px;
  text-align: right;
}

.assets-pages-NewComposer-Actions-index---root---2rqKV > *:not(:first-child) {
  margin-left: 5px;
}

.assets-pages-NewComposer-Actions-index---saveContainer---3rzFx {
  display: flex;
  align-items: stretch;
}

.assets-pages-NewComposer-Actions-index---saveContainer---3rzFx a {
  height: 100%;
}
