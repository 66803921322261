
.assets-pages-Composer-Variation-PostPreview-NullState-index---root---1fIF5 {
  margin-top: var(--hugeVertGutter);
  margin-bottom: calc(var(--largeVertGutter) + var(--hugeVertGutter));
  text-align: center;
}

.assets-pages-Composer-Variation-PostPreview-NullState-index---platformIcons---J6Z0m {
  padding-left: 0;
  margin-bottom: var(--largeVertGutter);
  list-style: none;
}

.assets-pages-Composer-Variation-PostPreview-NullState-index---platformIcons---J6Z0m li {
  display: inline-block;
}

.assets-pages-Composer-Variation-PostPreview-NullState-index---platformIcons---J6Z0m li:not(:first-child) {
  margin-left: var(--midHorzGutter);
}

.assets-pages-Composer-Variation-PostPreview-NullState-index---platformIcons---J6Z0m li:not(:last-child) {
  margin-right: var(--midHorzGutter);
}

.assets-pages-Composer-Variation-PostPreview-NullState-index---icon---1YOkZ {
  width: var(--hugeIconSize);
  height: var(--hugeIconSize);
  border-radius: var(--hugeIconBorderRadius);
}

.assets-pages-Composer-Variation-PostPreview-NullState-index---header---1wo_Y {
  margin-bottom: var(--midVertGutter);
}

.assets-pages-Composer-Variation-PostPreview-NullState-index---instructions---3haLN {
  max-width: 400px;
  margin: 0 auto;
}
