.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-ByLine-index---root---NH3aO {
  display: flex;
  margin-bottom: 16px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-ByLine-index---root---NH3aO a {
  cursor: pointer;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-ByLine-index---image---3nUvr {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;
  cursor: pointer;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-ByLine-index---details---1IqEu {
  line-height: 1.34em;
  color: var(--fbTextGrey);
  font-size: 12px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-ByLine-index---details---1IqEu a {
  color: var(--fbTextGrey);
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-ByLine-index---name---2wWzm {
  line-height: 1.38em;
  color: var(--fbTextGrey);
  font-size: 14px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-ByLine-index---name---2wWzm a {
  color: var(--fbLinkBlue);
  cursor: pointer;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-ByLine-index---name---2wWzm a:first-of-type {
  font-weight: bold;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-ByLine-index---name---2wWzm a:hover {
  text-decoration: underline;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-ByLine-index---arrow---24C0y {
  margin: 0 5px;
  color: rgb(170, 170, 170);
}
