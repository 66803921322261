.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Text-index---root---N7Dix {
  position: relative;
  overflow: hidden;
  width: 100%;

  --lh: 20px;
  --maxLines: 3;

  max-height: calc(var(--lh) * var(--maxLines));
  line-height: var(--lh);
  white-space: pre-wrap;
  word-wrap: break-word;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Text-index---container---2FxtA {
  position: relative;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Text-index---overflowText---2-Ii- {
  position: absolute;
  top: 44px;
  right: 0;
  width: 70px;
  background-color: white;
  text-align: right;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Text-index---root---N7Dix a {
  color: var(--linkedin); /* TODO: check color */
}
