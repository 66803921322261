.assets-components-TeamManager-index---teamManager---2RPLQ {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  margin-top: calc(2 * var(--smallVertGutter));
  background-color: #fff;
  border-radius: var(--cardBorderRadius);
  box-shadow: 0 2px 10px -3px var(--inky150);
}

.assets-components-TeamManager-index---header---23vJJ {
  display: flex;
  justify-content: space-between;
}

.assets-components-TeamManager-index---spotsAvailable---L0HSS {
  margin-left: var(--tinyHorzGutter);
  font-size: var(--bodyFontSize);
  font-weight: var(--normalWeight);
}

.assets-components-TeamManager-index---noSpotsAvailable---20JB0 {
  color: var(--dangerColor);
}

.assets-components-TeamManager-index---inviteButton---1im5Q {
  width: 200px;
}

.assets-components-TeamManager-index---teamMember---1nX3m {
  display: flex;
  align-items: center;
  padding: var(--midVertGutter) 0;
  border-top: 1px solid var(--inky200);
}

.assets-components-TeamManager-index---header---23vJJ + .assets-components-TeamManager-index---teamMember---1nX3m {
  border-top: 0;
}

.assets-components-TeamManager-index---teamMemberEmail---x6-DP {
  overflow: hidden;
  width: 40%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assets-components-TeamManager-index---teamMemberRole---3XVLB {
  display: inline-block;
  padding-left: 30px;
  margin-top: 1px;
  text-transform: capitalize;
}

.assets-components-TeamManager-index---teamMemberPending---2vrX2 {
  color: var(--inky300);
}

.assets-components-TeamManager-index---actionsUponTeamMember---12NLs {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  list-style: none;
}

.assets-components-TeamManager-index---deleteInvitationWrapper---1IMAx {
  margin-right: var(--midHorzGutter);
  cursor: pointer;
}

.assets-components-TeamManager-index---updateInvitationWrapper---1S3hQ {
  cursor: pointer;
}
