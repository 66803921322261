.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Five-index---container---2OOyl {
  width: 551px;
  height: 551px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Five-index---primaryImage---1j4oc {
  width: 274px;
  height: 330px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Five-index---image---JEmiu {
  width: 181px;
  height: 221px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Five-index---image---JEmiu > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Five-index---primaryImage---1j4oc > div {
  border-radius: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Five-index---imageBackground---6MCCm {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--inky300);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Five-index---imageCounter---gfHtT {
  position: absolute;
  top: 46%;
  left: 41%;
  color: white;
  font-size: var(--largeFontSize);
}
