.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitTwo-index---container---3kBX9 {
  width: 480px;
  height: 480px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitTwo-index---image---3UDnd {
  width: 237px;
  height: 476px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitTwo-index---image---3UDnd > div {
  border-radius: 0;
}
