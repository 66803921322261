.assets-components-common-buttons-RemoveButton-index---removeButton---e-1pE {
  position: absolute;
  top: var(--tinyHorzGutter);
  right: var(--tinyHorzGutter);
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: var(--inky500);
  border-radius: var(--elementBorderRadius);
  color: #fff;
  cursor: pointer;
  text-align: center;
}

.assets-components-common-buttons-RemoveButton-index---removeButton---e-1pE:hover {
  color: #fff;
}
