.assets-pages-NewComposer-Variation-PostPreview-Post-MultiImageHelpText-index---root---ykwTB {
  display: flex;
  margin-bottom: var(--midVertGutter);
  text-align: left;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-MultiImageHelpText-index---infoIcon---1YF8i {
  margin-top: 3px;
  margin-right: var(--tinyHorzGutter);
}
