.assets-components-common-NewAccountSelect-index---toggleAll---oNXN6 {
  padding: 0 var(--midHorzGutter) 4px 0;
}

.assets-components-common-NewAccountSelect-index---toggleAll---oNXN6 > label {
  margin-left: 0;
}

.assets-components-common-NewAccountSelect-index---list---3VVeX {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0 0 20px;
}

.assets-components-common-NewAccountSelect-index---alertContainer---2hdEb {
  padding-top: var(--tinyVertGutter);
}

.assets-components-common-NewAccountSelect-index---alerts---2DjV7 {
  padding: var(--midVertGutter) var(--midHorzGutter);
  border-top: solid 1px var(--inky200);
}

.assets-components-common-NewAccountSelect-index---alerts---2DjV7 > * {
  margin-top: var(--tinyVertGutter);
}
