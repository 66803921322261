.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Header-Menu-index---root---1UOhx {
  flex-grow: 0;
  width: 30px;
  height: 30px;
  font-size: 20px;
  text-align: center;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Header-Menu-index---root---1UOhx img {
  width: 100%;
}
