.assets-components-ImportManager-index---header---1rVjO {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: var(--largeVertGutter);
}

.assets-components-ImportManager-index---newUIHeader---o6PWN {
  margin-bottom: var(--largeVertGutter);
}

.assets-components-ImportManager-index---header---1rVjO > h1 {
  margin-bottom: 0;
}

.assets-components-ImportManager-index---body---Uot9U {
  width: 99%;
  margin: auto;
}

.assets-components-ImportManager-index---tabs---2oKNX {
  width: 100%;
}
