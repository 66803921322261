.assets-components-common-content-common-Accounts-Avatars-index---avatarsContainer---V96XG {
  display: flex;
  flex-wrap: wrap;
}

.assets-components-common-content-common-Accounts-Avatars-index---item---1sPDO {
  margin-right: var(--smallHorzGutter);
}

.assets-components-common-content-common-Accounts-Avatars-index---exhaustedContainer---CtS4B {
  margin-top: var(--midVertGutter);
}

.assets-components-common-content-common-Accounts-Avatars-index---exhaustedAvatar---3QC_W {
  opacity: var(--lighterDisabledOpacity);
}
