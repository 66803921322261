.assets-components-Search-index---root---29Pya {
  position: relative;
  flex: 1;
}

.assets-components-Search-index---field---3vJDm {
  padding-top: var(--tinyVertGutter);
  padding-bottom: var(--tinyVertGutter);
  padding-left: calc(var(--bodyFontSize) + 2 * var(--smallHorzGutter));
  line-height: var(--bodyLineHeight);
  border-radius: var(--elementBorderRadius);
  box-shadow: 0 2px 10px -3px var(--inky100);
  font-size: var(--bodyFontSize);
  outline: none;
}

.assets-components-Search-index---disabledField---EtFzK::-webkit-input-placeholder {
  border-color: var(--inky200);
  color: var(--inky200);
}

.assets-components-Search-index---disabledField---EtFzK::-moz-placeholder {
  border-color: var(--inky200);
  color: var(--inky200);
}

.assets-components-Search-index---disabledField---EtFzK:-ms-input-placeholder {
  border-color: var(--inky200);
  color: var(--inky200);
}

.assets-components-Search-index---disabledField---EtFzK:-moz-placeholder {
  border-color: var(--inky200);
  color: var(--inky200);
}

a.assets-components-Search-index---iconAction---10jIu {
  position: absolute;
  top: 0;
  height: 100%;
  padding: var(--tinyVertGutter) var(--smallHorzGutter);
  line-height: calc(var(--bodyLineHeight) + 2 * var(--tinyVertGutter));
  text-decoration: none;
}

a.assets-components-Search-index---iconAction---10jIu:hover {
  text-decoration: none;
}

a.assets-components-Search-index---iconAction---10jIu > i {
  display: block;
  height: 100%;
  line-height: var(--bodyLineHeight);
  color: var(--inky400);
  font-size: 16px;
}

a.assets-components-Search-index---disabledAction---1xbRY > i {
  color: var(--inky200);
}
