.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-Video-index---root---2sW1V {
  position: relative;
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-Video-index---root---2sW1V .assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-Video-index---video---3jvuY {
  overflow: hidden;
  max-height: 625px;
  -o-object-fit: cover;
     object-fit: cover;
  background: white;
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-Video-index---root---2sW1V video {
  border-radius: 10px;
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-Video-index---root---2sW1V .rh5v-Overlay_component {
  display: none;
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-Video-index---timer---1RjWy {
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
}
