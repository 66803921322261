.assets-pages-Payment-PromoCode-index---root---4as56 {
  margin: var(--largeVertGutter) 0;
}

.assets-pages-Payment-PromoCode-index---container---38XBT {
  display: flex;
  width: 100%;
}

.assets-pages-Payment-PromoCode-index---couponField---2sCn1 {
  flex: 3 0;
  margin-right: var(--smallHorzGutter);
}

.assets-pages-Payment-PromoCode-index---applyButton---3VXxY {
  flex: 1 0;
}

.assets-pages-Payment-PromoCode-index---errorMessage---3zR3Y {
  margin-top: var(--tinyVertGutter);
  color: var(--coral600);
  font-size: var(--captionFontSize);
}

.assets-pages-Payment-PromoCode-index---root---4as56 input.assets-pages-Payment-PromoCode-index---hasError---1M9ef {
  border: 2px solid var(--coral300);
}

.assets-pages-Payment-PromoCode-index---successMessage---3IKya {
  margin-top: var(--tinyVertGutter);
  color: var(--kelp400);
}

.assets-pages-Payment-PromoCode-index---successMessage---3IKya a {
  margin-left: var(--tinyHorzGutter);
  color: var(--inky500);
  font-size: var(--captionFontSize);
  text-decoration: underline;
}

.assets-pages-Payment-PromoCode-index---successMessage---3IKya a:hover {
  cursor: pointer;
}

.assets-pages-Payment-PromoCode-index---root---4as56 .assets-pages-Payment-PromoCode-index---applyButton---3VXxY a {
  width: 100%;
}
