@keyframes assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---fade---1Iu5k {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---root---32BKa {
  width: 40px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.67);
  border-radius: 15px;
  color: white;
  letter-spacing: 2px;
  text-align: center;
}

/* See FADE_OUT_TIME in index.js, should match (delay + animation time) here */
.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---appearActive---xQ-1M,
.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---enterActive---2fFgW {
  animation: assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---fade---1Iu5k 0.75s linear 1s forwards;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---exit---1tZUy,
.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---exitActive---M78Cu,
.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---exitDone---2Gd9V,
.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---enterDone---3xj2w {
  opacity: 0;
}
