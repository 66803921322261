.assets-pages-Library-Header-index---root---2bR5k {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.assets-pages-Library-Header-index---navTabs---1yPbq {
  flex: 0 0 100%;
}

.assets-pages-Library-Header-index---bulkActions---B4Abr {
  flex: 0 0 100%;
}

.assets-pages-Library-Header-index---resultCount---2pTNS {
  width: 100%;
  height: 30px;
  line-height: 30px;
}

.assets-pages-Library-Header-index---informationBox---2teFT {
  margin-bottom: var(--midVertGutter);
}
