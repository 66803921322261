.assets-components-common-content-common-ActionBar-common-Approved-index---root---3h74B {
  display: flex;
}

.assets-components-common-content-common-ActionBar-common-Approved-index---root---3h74B > * {
  align-self: center;
}

.assets-components-common-content-common-ActionBar-common-Approved-index---root---3h74B > a:last-child {
  margin-left: var(--midVertGutter);
}
