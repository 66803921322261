.assets-pages-NewComposer-Variation-PostPreview-Post-GooglePost-ByLine-index---root---28vh7 {
  display: flex;
  align-items: center;
  padding: 12px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-GooglePost-ByLine-index---image---1288y {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-GooglePost-ByLine-index---details---2dkI4 {
  line-height: 1.34em;
  color: var(--fbTextGrey);
  font-size: 12px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-GooglePost-ByLine-index---name---1K8pw {
  margin-top: 1.5px;
  line-height: 1.38em;
  color: #333;
  font-size: 14px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-GooglePost-ByLine-index---arrow---3Ynoo {
  margin: 0 5px;
  color: rgb(170, 170, 170);
}
