.assets-pages-NewComposer-Variation-Title-index---root---3oiOF {
  display: flex;
  align-items: baseline;
  margin-bottom: var(--tinyVertGutter);
}

.assets-pages-NewComposer-Variation-Title-index---label---_pm_E {
  flex-grow: 1;
}

.assets-pages-NewComposer-Variation-Title-index---icon---EKIFa {
  margin-left: var(--tinyHorzGutter);
}

.assets-pages-NewComposer-Variation-Title-index---required---2Tvok {
  line-height: 0;

  /* hack to prevent vertical jiggle */
  color: var(--coral500);
  font-size: var(--captionFontSize);
  font-weight: normal;
}

.assets-pages-NewComposer-Variation-Title-index---remainingCharacterCountsContainer---3Zd3I {
  /* margin-right: var(--tinyHorzGutter); */
}

.assets-pages-NewComposer-Variation-Title-index---root---3oiOF .assets-pages-NewComposer-Variation-Title-index---deleteLink---QTVGm {
  margin-bottom: 4px;
  margin-left: var(--midHorzGutter);
  color: var(--inky400);
  text-decoration: none;
}

.assets-pages-NewComposer-Variation-Title-index---blurred---1P6pf .assets-pages-NewComposer-Variation-Title-index---deleteLink---QTVGm {
  display: none;
}

.assets-pages-NewComposer-Variation-Title-index---hovered---2EE1Z .assets-pages-NewComposer-Variation-Title-index---deleteLink---QTVGm {
  display: block;
}

.assets-pages-NewComposer-Variation-Title-index---root---3oiOF .assets-pages-NewComposer-Variation-Title-index---deleteLink---QTVGm:hover {
  color: var(--inky400);
  text-decoration: underline;
}

.assets-pages-NewComposer-Variation-Title-index---previewButton---2CaG0 {
  align-self: center;
  margin-left: var(--midHorzGutter);
}

.assets-pages-NewComposer-Variation-Title-index---blurred---1P6pf .assets-pages-NewComposer-Variation-Title-index---previewButton---2CaG0 {
  display: none;
}

.assets-pages-NewComposer-Variation-Title-index---hovered---2EE1Z .assets-pages-NewComposer-Variation-Title-index---previewButton---2CaG0 {
  display: block;
}

.assets-pages-NewComposer-Variation-Title-index---root---3oiOF .assets-pages-NewComposer-Variation-Title-index---postPreviewButtonTooltipTether---3k1Sj {
  background-color: red;
}

.assets-pages-NewComposer-Variation-Title-index---twitterPostedNotice---16iPb {
  color: var(--inky300);
  font-size: var(--captionFontSize);
}

.assets-pages-NewComposer-Variation-Title-index---twitterPostedNotice---16iPb::before {
  margin: 0 var(--tinyHorzGutter);
  content: "·";
}
