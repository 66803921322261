.assets-components-BulkEditTable-Variation-index---variation---34XJo {
  display: grid;
  max-width: 100%;
  padding-bottom: var(--miniVertGutter);
  grid-template-columns: 36px auto;
}

.assets-components-BulkEditTable-Variation-index---variation---34XJo:first-child {
  margin-top: calc(-1 * var(--miniVertGutter));
}

.assets-components-BulkEditTable-Variation-index---variation---34XJo:last-child {
  padding-bottom: 0;
}

.assets-components-BulkEditTable-Variation-index---thumbnailWrapper---AC1kJ {
  height: calc(36px + var(--miniVertGutter));
  padding-top: var(--miniVertGutter);
}

.assets-components-BulkEditTable-Variation-index---text---18J8g {
  position: relative;
  display: -webkit-box;
  overflow: hidden;
  padding-top: var(--miniVertGutter);
  border-top: 1px solid var(--inky200);
  margin: 0 var(--smallHorzGutter);

  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
}

.assets-components-BulkEditTable-Variation-index---variation---34XJo:first-child .assets-components-BulkEditTable-Variation-index---text---18J8g {
  border-top: 0;
}

@-moz-document url-prefix() {
  .assets-components-BulkEditTable-Variation-index---text---18J8g {
    display: inline-block;
    height: var(--midInputHeight);
    white-space: nowrap;
  }
}
