.assets-components-common-layout-Alert-index---root---WJd2n {
  display: flex;
  align-items: baseline;
  padding: var(--midVertGutter) var(--smallHorzGutter);
  border: solid 1px var(--inky300);
  border-radius: var(--elementBorderRadius);
  color: var(--inky500);
  font-size: var(--bodyFontSize);
}

.assets-components-common-layout-Alert-index---root---WJd2n a {
  color: var(--inky500);
  font-weight: var(--semiBold);
  text-decoration: underline;
}

.assets-components-common-layout-Alert-index---root---WJd2n a:hover {
  color: var(--inky400);
  text-decoration: none;
}

.assets-components-common-layout-Alert-index---icon---1v0J1 {
  margin-right: var(--smallHorzGutter);
}

.assets-components-common-layout-Alert-index---closeButton---4PstZ {
  margin-left: var(--smallHorzGutter);
  color: var(--inky400);
  cursor: pointer;
}

.assets-components-common-layout-Alert-index---closeButton---4PstZ:hover {
  color: var(--inky300);
}

.assets-components-common-layout-Alert-index---notice---yOHU4 {
  background-color: var(--algae200);
  border-color: var(--algae300);
}

.assets-components-common-layout-Alert-index---notice---yOHU4 .assets-components-common-layout-Alert-index---icon---1v0J1 {
  color: var(--algae400);
}

.assets-components-common-layout-Alert-index---info---1gRUo {
  background-color: var(--whale100);
  border-color: var(--whale200);
}

.assets-components-common-layout-Alert-index---info---1gRUo .assets-components-common-layout-Alert-index---icon---1v0J1 {
  color: var(--whale400);
}

.assets-components-common-layout-Alert-index---alert---2jYNq {
  background-color: var(--starfish300);
  border-color: var(--starfish400);
}

.assets-components-common-layout-Alert-index---alert---2jYNq .assets-components-common-layout-Alert-index---icon---1v0J1 {
  color: var(--starfish400);
}

.assets-components-common-layout-Alert-index---error---2YOEJ {
  background-color: var(--coral50);
  border-color: var(--coral400);
}

.assets-components-common-layout-Alert-index---error---2YOEJ .assets-components-common-layout-Alert-index---icon---1v0J1 {
  color: var(--coral400);
}

.assets-components-common-layout-Alert-index---twitter---3OTmi,
.assets-components-common-layout-Alert-index---facebook---kkN23 {
  background-color: var(--whale100);
  border-color: var(--whale200);
}

.assets-components-common-layout-Alert-index---twitter---3OTmi .assets-components-common-layout-Alert-index---icon---1v0J1 {
  color: var(--twitter);
}

.assets-components-common-layout-Alert-index---facebook---kkN23 .assets-components-common-layout-Alert-index---icon---1v0J1 {
  color: var(--facebook);
}

.assets-components-common-layout-Alert-index---instagram---3myGv .assets-components-common-layout-Alert-index---icon---1v0J1 {
  color: var(--instagram);
}

.assets-components-common-layout-Alert-index---pinterest---3TOQ6 .assets-components-common-layout-Alert-index---icon---1v0J1 {
  color: var(--pinterest);
}

.assets-components-common-layout-Alert-index---bland---1I0Si .assets-components-common-layout-Alert-index---icon---1v0J1 {
  color: var(--inky400);
}

.assets-components-common-layout-Alert-index---clear---2_36p {
  display: block;
  padding: 0;
  background-color: transparent;
  border-color: transparent;
}

.assets-components-common-layout-Alert-index---body---2dJXt {
  flex: max-content;
}

.assets-components-common-layout-Alert-index---clear---2_36p .assets-components-common-layout-Alert-index---icon---1v0J1,
.assets-components-common-layout-Alert-index---clear---2_36p .assets-components-common-layout-Alert-index---body---2dJXt {
  display: inline;
}

.assets-components-common-layout-Alert-index---clear---2_36p .assets-components-common-layout-Alert-index---body---2dJXt {
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-components-common-layout-Alert-index---clear---2_36p .assets-components-common-layout-Alert-index---icon---1v0J1 {
  margin-right: 0;
}

.assets-components-common-layout-Alert-index---clear---2_36p .assets-components-common-layout-Alert-index---icon---1v0J1::after {
  content: " ";
}

.assets-components-common-layout-Alert-index---small---nkfwz {
  padding: var(--smallVertGutter) var(--tinyHorzGutter);
}

.assets-components-common-layout-Alert-index---small---nkfwz .assets-components-common-layout-Alert-index---body---2dJXt {
  font-size: var(--captionFontSize);
}
