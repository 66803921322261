.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeTwo-index---container---gGd7W {
  width: 480px;
  height: 480px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeTwo-index---image---2OZiX {
  width: 476px;
  height: 237px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-LandscapeTwo-index---image---2OZiX > div {
  border-radius: 0;
}
