@keyframes assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---fade---3c4Ce {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---root---15-p5 {
  width: 40px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.67);
  border-radius: 15px;
  color: white;
  letter-spacing: 2px;
  text-align: center;
}

/* See FADE_OUT_TIME in index.js, should match (delay + animation time) here */
.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---appearActive---2mImj,
.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---enterActive---2wp6b {
  animation: assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---fade---3c4Ce 0.75s linear 1s forwards;
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---exit---17s0g,
.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---exitActive---1kYLl,
.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---exitDone---2_yV0,
.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---enterDone---GPqBF {
  opacity: 0;
}
