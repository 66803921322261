.assets-components-AICredits-index---manager---COEDG {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  margin-top: calc(2 * var(--smallVertGutter));
  background-color: #fff;
  border-radius: var(--cardBorderRadius);
  box-shadow: 0 2px 10px -3px var(--inky150);
}
.assets-components-AICredits-index---forminput---ULABJ {
  border: 1px solid #dedede;
  border-radius: 0;
  padding: 8px 0 8px 18px;
  text-align: center;
  width: 70px;

  &:focus {
    outline: none;
    border-color: #d1d1d1;
  }
}

.assets-components-AICredits-index---header---2dMd8 {
  display: flex;
  justify-content: space-between;
}


.assets-components-AICredits-index---priceDetails---bdF0V {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 20px;
  gap: 5px; /* Added gap between elements */
}

.assets-components-AICredits-index---form---3ltrR {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.assets-components-AICredits-index---total---3pNXr {
  font-weight: bold;
}

.assets-components-AICredits-index---error---1GfUF {
  color: red;
  font-size: 14px;
}
.assets-components-AICredits-index---warning---25Xzd {
  color: var(--warningColor);
  font-size: 14px;
  margin-top: 10px;
}
.assets-components-AICredits-index---warning---25Xzd i{
  margin-right: 10px;
}