.assets-components-common-forms-TextInput-index---root---3iLRz {
  width: 100%;
}

.assets-components-common-forms-TextInput-index---multiline---1668o {
  display: block;
  resize: none;
}

.assets-components-common-forms-TextInput-index---hasError---2O8YR {
}

.assets-components-common-forms-TextInput-index---clear---16SeH {
  padding: 0;
  border: none;
  outline: none;
}

.assets-components-common-forms-TextInput-index---small---1GUNW {
  padding: 0 var(--tinyHorzGutter);
  line-height: var(--smallInputHeight);
  font-size: var(--captionFontSize);
}
