.assets-pages-NewComposer-Variation-TextField-index---root---3yjwb {
  position: relative;
}

.assets-pages-NewComposer-Variation-TextField-index---sniplyTarget---sWwrm {
  position: absolute;
  right: 2px;
  bottom: 2px;
  width: 16px;
  height: 16px;
}

.assets-pages-NewComposer-Variation-TextField-index---blurred---3iOs4 {
  padding-top: 0;
  padding-bottom: 0;
}

.assets-pages-NewComposer-Variation-TextField-index---hasTopSection---TMXBa.assets-pages-NewComposer-Variation-TextField-index---blurred---3iOs4 {
  padding-top: 5px;
}

.assets-pages-NewComposer-Variation-TextField-index---hasTopSection---TMXBa .assets-pages-NewComposer-Variation-TextField-index---blurredInputContainer---2KimC > div {
  line-height: inherit;
}

.assets-pages-NewComposer-Variation-TextField-index---errorList---3Gv8k {
  margin-bottom: var(--smallVertGutter);
}

.assets-pages-NewComposer-Variation-TextField-index---hasErrors---2N_-s {
  border: 2px solid var(--coral400) !important;
}

.assets-pages-NewComposer-Variation-TextField-index---hasWarnings---1EHZo {
  border: 2px solid var(--starfish400) !important;
}

.assets-pages-NewComposer-Variation-TextField-index---hasTopSection---TMXBa.assets-pages-NewComposer-Variation-TextField-index---hasWarnings---1EHZo {
  border: solid 1px rgba(0, 0, 0, 0.32) !important;
  border-bottom: 0 !important;
}
