.assets-components-common-NewAccountSelect-EmptyState-index---noAccountsHeader---2CTAc {
  padding: 0 0 var(--tinyHorzGutter);
  border-bottom: solid 1px var(--inky200);
}

.assets-components-common-NewAccountSelect-EmptyState-index---socialIcon---3DL0j {
  margin: 20px 18px 0 0;
  font-size: 36px;
}

.assets-components-common-NewAccountSelect-EmptyState-index---socialIcon---3DL0j.assets-components-common-NewAccountSelect-EmptyState-index---facebook---35-sG {
  color: var(--facebook);
}

.assets-components-common-NewAccountSelect-EmptyState-index---socialIcon---3DL0j.assets-components-common-NewAccountSelect-EmptyState-index---linkedin---37Kk_ {
  color: var(--linkedin);
}

.assets-components-common-NewAccountSelect-EmptyState-index---socialIcon---3DL0j.assets-components-common-NewAccountSelect-EmptyState-index---twitter---2nGrW {
  color: var(--twitter);
}

.assets-components-common-NewAccountSelect-EmptyState-index---socialIcon---3DL0j.assets-components-common-NewAccountSelect-EmptyState-index---instagram---NibWA {
  color: var(--instagram);
}

.assets-components-common-NewAccountSelect-EmptyState-index---socialIcon---3DL0j.assets-components-common-NewAccountSelect-EmptyState-index---pinterest---2Y9ZB {
  color: var(--pinterest);
}
