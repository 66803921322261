.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-AccountBadge-index---root---1raeW {
  position: relative;
  display: flex;
  line-height: 0;
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-AccountBadge-index---avatar---1Agy7 {
  display: inline-block;
  width: 37px;
  height: 37px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-AccountBadge-index---providerWrapper---PN6eu {
  position: absolute;
  right: -3px;
  bottom: -1px;
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-AccountBadge-index---providerWrapper---PN6eu img {
  width: 11px;
  height: 11px;
  border-radius: 2px;
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-AccountBadge-index---tiny---odNAL > .assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-AccountBadge-index---avatar---1Agy7,
.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-AccountBadge-index---tiny---odNAL > .assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-AccountBadge-index---platformIcon---1uYNH {
  width: calc(var(--badgeSize) * 0.666);
  height: calc(var(--badgeSize) * 0.666);
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-AccountBadge-index---small---2m7qd > .assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-AccountBadge-index---avatar---1Agy7,
.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-AccountBadge-index---small---2m7qd > .assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-AccountBadge-index---platformIcon---1uYNH {
  width: calc(var(--badgeSize) * 1);
  height: calc(var(--badgeSize) * 1);
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-AccountBadge-index---tipBody---s4JNp {
  font-family: var(--primaryFont);
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-AccountBadge-index---tipCaption---P2V_Q {
  overflow: hidden;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
}
