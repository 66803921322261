.videomodal_player {
  width: initial;
  margin: 0 30px;
}

.videomodal_player .rh5v-DefaultPlayer_controls svg {
  padding: 2px;
}

.videomodal_player video.rh5v-DefaultPlayer_video {
  max-height: 500px;
}

@media (max-height: 670px) {
  .rh5v-DefaultPlayer_component .videomodal_player video.rh5v-DefaultPlayer_video {
    max-height: 300px;
  }
}
