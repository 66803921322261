
.assets-components-TopNav-UtilityNav-index---root---1Oc4Y {
  position: absolute;
  top: 7px;
  right: 10px;
  text-align: right;
}

.assets-components-TopNav-UtilityNav-index---root---1Oc4Y a {
  font-size: 11px;
}

@media (min-width: /*$tablet*/600px) {
  .assets-components-TopNav-UtilityNav-index---root---1Oc4Y {
    position: relative;
    top: 9px;
    right: 0;
    float: right;
  }

  .assets-components-TopNav-UtilityNav-index---root---1Oc4Y a {
    font-size: var(--captionFontSize);
  }
}
