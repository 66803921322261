.assets-pages-NewComposer-CustomModal-index---root---2CtWi {
  width: 90%;
  max-width: 512px;
  padding: 32.5px;
  border-radius: 12px;
}

@media (min-width: 700px) {
  .assets-pages-NewComposer-CustomModal-index---root---2CtWi {
    max-width: 530px;
    padding: 45px;
  }
}
