* {
  box-sizing: border-box;
  margin: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  background-color: var(--newuibg);
  overflow-y: scroll;
}

a,
a:visited {
  color: var(--kelp500);
}

.component--bootstrap {
  height: 100%;
}

:root {
  /*
   * BREAKPOINTS
   */
  --mobileWidth: 599px;

  /*
   * LAYOUT
   * breakpoints are
   * @media (min-width: 600px) - TABLET
   * @media (min-width: 995px) - DESKTOP
   */
  --desktopContentWidth: 970px;
  --desktopNewUIContentWidth: 1100px;
  --desktopNewContentWidth: 1376px;
  --mainContentMinWidth: 720px;
  --elementBorderRadius: 3px;
  --cardBorderRadius: 12px;
  --midBorderRadius: 12px;
  --largeBorderRadius: 15px;
  --thickBorderWidth: 2px;
  --miniHorzGutter: 2px;
  --miniVertGutter: 3px;
  --tinyHorzGutter: 5px;
  --tinyVertGutter: 6px;
  --smallHorzGutter: 10px;
  --smallVertGutter: 10px;
  --midVertGutter: 12px;
  --midHorzGutter: 15px;
  --cardVertGutter: 18px;
  --largeVertGutter: 24px;
  --largeHorzGutter: 25px;
  --hugeVertGutter: 48px;
  --hugeHorzGutter: 30px;
  --giganticVertGutter: 60px;
  --previewImageSize: 120px;
  --previewVideoHeight: 120px;
  --previewVideoWidth: 213px; /* 16:9 */
  --mediaThumbnailSize: 80px;
  --instagramPreviewWidth: 500px;
  --midContainerWidth: 267px;

  /*
  * EFFECTS
  */
  --lightBoxShadow: 0 1px 2px 0 var(--inky300);
  --menuBoxShadow: 0 4px 8px 0 var(--inky300);

  /*
   * COLORS
   */
  --newuibg: rgba(250, 250, 255, 1);

  /* Deprecated colors */
  --lightGrey: #e6e6e6;
  --lighterGrey: #f1f1f1;
  --grey: #a1a1a1;
  --darkGrey: #585858;
  --whaleGrey: #77817c;
  --purpleGrey: #826a75;
  --transparentGrey: rgba(0, 0, 0, 0.3);
  --semiTransparentGrey: rgba(0, 0, 0, 0.8);
  --primaryTextColor: #6ca8a9;
  --dangerBgColor: #f2dede;

  /* Greys */
  --greybg: rgba(242, 242, 247, 1);
  --inky50: rgba(0, 0, 0, 0.04);
  --inky100: rgba(0, 0, 0, 0.06);
  --inky150: rgba(0, 0, 0, 0.12);
  --inky200: rgba(0, 0, 0, 0.18);
  --inky300: rgba(0, 0, 0, 0.32);
  --inky400: rgba(0, 0, 0, 0.65);
  --inky500: rgba(0, 0, 0, 0.87);
  --inky1000: rgba(0, 0, 0, 1);

  /* Whites */
  --sanddollar200: rgba(255, 255, 255, 0.2);
  --sanddollar400: rgba(255, 255, 255, 0.5);
  --sanddollar500: rgba(255, 255, 255, 0.87);
  --sanddollar600: rgba(255, 255, 255, 0.95);

  /* Primary Colors */
  --kelp50: #deeaea;
  --kelp100: #bad5d3;
  --kelp200: #97ccce;
  --kelp300: #6cbdc5;
  --kelp400: #2e9690;
  --kelp500: #398180;
  --whale100: #d9e6f1;
  --whale200: #a3c5df;
  --whale300: #65a8c7;
  --whale400: #268baf;
  --whale500: #226f98;

  /* Secondary Colors */
  --algae200: #d1f7d0;
  --algae300: #7bb979;
  --algae400: #3e703c;
  --starfish300: #fcf8e3;
  --starfish400: #f6cc50;
  --urchin300: #574980;
  --urchin400: #8480be;
  --coral50: #f2dede;
  --coral100: #e5d7d7;
  --coral200: #daa3a3;
  --coral300: #dc6767;
  --coral400: #dd4b6f;
  --coral500: #ca4263;
  --coral600: #a63232;

  /* Aliased Colors */
  --primaryColor: var(--kelp400);
  --textMidColor: var(--inky300);
  --textDarkColor: var(--inky500);
  --dangerColor: var(--coral400);
  --warningColor: var(--starfish400);

  /* Network colors */
  --facebook: #1877f2;
  --tiktok: #000;
  --threads: #000;
  --linkedin: #0a66c2;
  --twitter: #1da1f2;
  --x: #000;
  --instagram: #f00075;
  --pinterest: #e60023;
  --google: #fff;
  --fbLinkBlue: #365899;
  --fbTextGrey: rgb(144, 148, 156);
  --fbTextDark: rgb(28, 33, 41);
  --twitterLinkBlue: #0084b4;
  --twitterTextLight: #8899a6;
  --twitterTextMid: #657786;
  --twitterTextDark: #14171a;
  --twitterBorderLightGrey: #e1e8ed;
  --instagramBlack: #262626;
  --instagramGray: #999;
  --instagramLightGray: #dbdbdb;
  --instagramNavy: #003569;

  /* Network fonts */
  --instagramFontStack: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --pinterestFontStack: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen-Sans", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", "Helvetica", "\30D2\30E9\30AE\30CE\89D2\30B4  Pro W3",
    "Hiragino Kaku Gothic Pro", "\30E1\30A4\30EA\30AA", "Meiryo",
    "\FF2D\FF33  \FF30\30B4\30B7\30C3\30AF", "Arial", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  /*
   * FONTS
   */
  --primaryFont: "Open Sans", sans-serif;
  --fontMeta: "Helvetica Neue", Helvetica, Arial, sans-serif;

  /*
   * TYPOGRAPHY
   */
  --largeFontSize: 40px;
  --display2FontSize: 25px;
  --display1FontSize: 21px;
  --subheadingFontSize: 17px;
  --bodyFontSize: 15px;
  --captionFontSize: 13px;
  --bugFontSize: 11px;
  --maxLineHeight: 36px;
  --largeLineHeight: 30px;
  --midLineHeight: 24px;
  --bodyLineHeight: 24px;
  --captionLineHeight: 18px;
  --bugLineHeight: 16px;
  --normalWeight: 400;
  --semiBold: 600;

  /* 23px gets checkbox to render ~20px */
  --actualLargeIconSize: 23px;
  --largeIconSize: 20px;
  --hugeIconSize: 30px;
  --hugeIconBorderRadius: 5px;
  --platformIconSize: 15px;
  --badgeSize: 24px;
  --avatarSize: 24px;
  --helpIconFontSize: 12px;

  /*
   * FORMS
   */
  --inputHeight: var(--maxLineHeight);

  /* Account for borders on input */
  --inputLineHeight: calc(var(--inputHeight) - 2px);
  --borderRadius: var(--elementBorderRadius);
  --textAreaLineHeight: var(--bodyLineHeight);
  --horzInputPadding: 10px;
  --vertInputPadding: 12px;
  --midInputWidth: 320px;
  --smallInputHeight: 20px;
  --midInputHeight: var(--midLineHeight);
  --minButtonWidth: 90px;
  --midButtonHeight: 26px;
  --checkboxOffset: calc(var(--largeIconSize) + var(--smallHorzGutter) + 1px);

  /*
   * OPACITY
   */
  --disabledOpacity: 0.4;
  --hoverOpacity: 0.32;
  --lighterDisabledOpacity: 0.18;

  /*
   * TOOLTIPS
   */
  --tooltipZIndex: 999;
  --modalZIndex: 100;
}
