.assets-components-TeamManager-DeleteInvitation-index---main---4h11h {
  padding: var(--tinyHorzGutter) 0;
  text-align: center;
}

.assets-components-TeamManager-DeleteInvitation-index---title---25k2Y {
  padding: var(--largeVertGutter) 0 var(--midVertGutter);
}

.assets-components-TeamManager-DeleteInvitation-index---message---KUfhz {
  padding: var(--tinyVertGutter) 0 var(--hugeHorzGutter);
}

.assets-components-TeamManager-DeleteInvitation-index---buttonCancel---1oDfw {
  margin-right: var(--smallVertGutter);
}
