.react-datepicker {
  font-size: 1rem;
}

.react-datepicker__day--selected {
  background-color: var(--kelp400);
}

.assets-components-common-forms-NewDateTimeField-index---root---SlRac {
  margin-bottom: var(--midVertGutter);
}

.assets-components-common-forms-NewDateTimeField-index---helpText---1dc5O {
  margin: 0 0 var(--midVertGutter) 0;
}

.assets-components-common-forms-NewDateTimeField-index---inputs---Vsj8K {
  display: flex;
  align-items: center;
}

.assets-components-common-forms-NewDateTimeField-index---timeInput---2yg2g {
  margin-right: 8px;
}

.assets-components-common-forms-NewDateTimeField-index---resetLink---1nqV6 {
  display: block;
}

.assets-components-common-forms-NewDateTimeField-index---textLeft---S8PYI,
.assets-components-common-forms-NewDateTimeField-index---textCenter---1PgnD,
.assets-components-common-forms-NewDateTimeField-index---textRight---1xywU {
  display: none;
  font-size: 14px;
}

@media (min-width: 768px) {
  .assets-components-common-forms-NewDateTimeField-index---textLeft---S8PYI,
  .assets-components-common-forms-NewDateTimeField-index---textCenter---1PgnD,
  .assets-components-common-forms-NewDateTimeField-index---textRight---1xywU {
    display: block;
  }
}

.assets-components-common-forms-NewDateTimeField-index---textLeft---S8PYI,
.assets-components-common-forms-NewDateTimeField-index---textCenter---1PgnD {
  margin-right: 8px;
}

.assets-components-common-forms-NewDateTimeField-index---textRight---1xywU {
  margin-left: 8px;
}
