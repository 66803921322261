.assets-components-common-content-common-Accounts-Avatars-Exhausted-index---root---K9bIw {
  display: inline-flex;
  align-items: center;
  padding: var(--miniVertGutter) var(--smallHorzGutter);
  border: 1px solid var(--inky200);
  background-color: var(--inky100);
  border-radius: var(--elementBorderRadius);
}

.assets-components-common-content-common-Accounts-Avatars-Exhausted-index---avatar---3i7Oh {
  margin-right: var(--tinyHorzGutter);
  opacity: 0.5;
}

.assets-components-common-content-common-Accounts-Avatars-Exhausted-index---caption---1mHjw {
  margin-bottom: var(--miniVertGutter);
}
