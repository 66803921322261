.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Header-Menu-index---root---1lqDi {
  flex-grow: 0;
  width: 30px;
  height: 30px;
  font-size: 20px;
  text-align: center;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramStory-Header-Menu-index---root---1lqDi img {
  width: 100%;
}
