.assets-components-TopNav-Logo-index---logo---2qgib {
  float: left;
  width: 123px;
  margin: 8px 0 4px 35px;
}

.assets-components-TopNav-Logo-index---link---2wte2,
.assets-components-TopNav-Logo-index---target---2ZtoS {
  display: inline-block;
  width: 100%;
}

.assets-components-TopNav-Logo-index---caption---2q9Dq {
  font-family: var(--primaryFont);
}

.assets-components-TopNav-Logo-index---logo---2qgib img {
  height: 28px;
  border: none;
}

.assets-components-TopNav-Logo-index---tooltip---eggb7 {
  z-index: 100;
  margin-top: calc(-1 * var(--tinyVertGutter));
}

@media (min-width: 600px) {
  .assets-components-TopNav-Logo-index---logo---2qgib {
    height: 39px;
    margin: 8px 0 0;
  }

  .assets-components-TopNav-Logo-index---logo---2qgib img {
    height: 37px;
  }
}

@media (min-width: 995px) {
  .assets-components-TopNav-Logo-index---link---2wte2 {
    padding-bottom: var(--tinyVertGutter);
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: calc(2 * var(--thickBorderWidth));
  }

  .assets-components-TopNav-Logo-index---activeLink---2pQJu:link,
  .assets-components-TopNav-Logo-index---activeLink---2pQJu:visited {
    border-bottom-color: rgb(83, 153, 154);
  }
}
