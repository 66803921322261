.assets-components-common-content-common-ActionBar-common-Pending-index---root---1VSju {
  display: flex;
}

.assets-components-common-content-common-ActionBar-common-Pending-index---root---1VSju > * {
  align-self: center;
}

.assets-components-common-content-common-ActionBar-common-Pending-index---root---1VSju > *:last-child,
.assets-components-common-content-common-ActionBar-common-Pending-index---root---1VSju > *:nth-last-child(2) {
  margin-left: var(--midVertGutter);
}
