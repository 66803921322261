.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-Video-index---root---2gnNU {
  position: relative;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-Video-index---root---2gnNU .assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-Video-index---video---9Jlzc {
  overflow: hidden;
  max-height: 625px;
  -o-object-fit: cover;
     object-fit: cover;
  background: white;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-Video-index---root---2gnNU video {
  border-radius: 10px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-Video-index---root---2gnNU .rh5v-Overlay_component {
  display: none;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-Video-index---timer---JJNp1 {
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
}
