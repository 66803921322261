.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-index---root---2tY2G {
  position: relative;
  width: var(--instagramPreviewWidth);
  margin-top: var(--smallVertGutter);
  margin-bottom: calc(var(--smallVertGutter));
  color: var(--instagramBlack);
  font-family: var(--instagramFontStack);
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-index---innerCarousel---2HPER {
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-index---scroller---2KW6s {
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 500px;
  margin-top: -15px;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-index---imageXOfYIndicator---3s_To {
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-index---allImages---1rnUS {
  overflow: hidden;
  width: calc(10 * 500px);
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-index---allImages---1rnUS img {
  max-height: 625px;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-index---scaledImage---2T8zs {
  border-radius: 0;
}

@keyframes assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-index---slide-left---2DQ3h {
  100% { transform: translateX(-100%); }
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-index---enterActive---14AjJ,
.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-index---exitActive---2SWV0 {
  animation: assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-index---slide-left---2DQ3h 0.5s ease-in;
}

.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-index---exitDone---1noq1 {
  display: none;
}

/* The Transition component works by injecting style props
   directly into its child(ren) element/component. In our case,
   the child element is a ScaledImage, which also modifies its
   own style props to adjust its own image size, thus overriding
   those props injected by the parent Transition component. So
   we need to wrap the ScaledImage in a div to give the Transition
   a component into which it may safely inject props. Not sure
   why it doesn't do that already, maybe I should submit a PR. -md */
.assets-pages-Composer-Variation-PostPreview-Post-InstagramPost-Media-ImageCarousel-index---thisIsNeededForTransitionToInject---27kxc {
  display: inline-block;
}
