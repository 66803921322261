.assets-pages-NewComposer-Variation-Attachments-common-MediaList-AutoScrollContainer-index---root---29R_c {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-AutoScrollContainer-index---rootLinkedIn---2B93y {
  position: relative;
  display: block;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-AutoScrollContainer-index---root---29R_c::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-AutoScrollContainer-index---root---29R_c::-webkit-scrollbar-thumb {
  border-color: rgba(0, 0, 0, 0);
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-AutoScrollContainer-index---root---29R_c > * {
  margin-right: var(--smallHorzGutter);
  margin-bottom: 3px;
}

.assets-pages-NewComposer-Variation-Attachments-common-MediaList-AutoScrollContainer-index---root---29R_c > *:last-child {
  margin-right: 0;
}
