
.assets-components-Filters-Header-index---root---2ARQL {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--inky200);
  line-height: calc(var(--maxLineHeight));
  color: var(--inky400);
}

.assets-components-Filters-Header-index---title---1Z2DG {
  line-height: var(--maxLineHeight);
}
