.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFive-index---container---1Ygt3 {
  flex-direction: column;
  width: 480px;
  height: 480px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFive-index---primaryImage---2v-jo {
  width: 237px;
  height: 238px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFive-index---image---17S79 {
  width: 237px;
  height: 158px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFive-index---image---17S79 > div,
.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFive-index---primaryImage---2v-jo > div {
  border-radius: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitFive-index---overlay---1rzHS {
  line-height: 157px;
  font-size: 36px;
}
