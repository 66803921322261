
.assets-components-common-typography-H4-index---root---HjDdw {
  margin-top: 0;
  margin-bottom: 0;
  line-height: var(--midLineHeight);
  font-size: var(--bodyFontSize);
  font-weight: var(--semiBold);
}

.assets-components-common-typography-H4-index---hasBottomMargin---1H0Cw {
  margin-bottom: var(--midLineHeight);
}
