.assets-components-UpdatePaymentMethod-index---main---2m5f8 {
  padding: var(--midVertGutter) 0 var(--largeVertGutter);
}

.assets-components-UpdatePaymentMethod-index---buttonCancel---1G6km {
  margin-right: var(--smallVertGutter);
}

.assets-components-UpdatePaymentMethod-index---security---QnLe- {
  font-size: var(--captionFontSize);
}

.assets-components-UpdatePaymentMethod-index---security---QnLe- i {
  margin-right: var(--tinyHorzGutter);
  color: var(--kelp400);
}

.assets-components-UpdatePaymentMethod-index---sectionButton---2EhHu {
  width: 200px;
}
