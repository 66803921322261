.assets-components-ImportManager-AddNew-ImportModal-index---rootModal---QMBuD {
  padding: 0;
}

.assets-components-ImportManager-AddNew-ImportModal-index---rootModal---QMBuD main {
  overflow: hidden;
}

.assets-components-ImportManager-AddNew-ImportModal-index---header---65mEQ > * {
  padding-right: var(--hugeHorzGutter);
  padding-left: var(--hugeHorzGutter);
}

.assets-components-ImportManager-AddNew-ImportModal-index---header---65mEQ h2 {
  padding-top: var(--largeVertGutter);
  padding-bottom: var(--largeVertGutter);
}

.assets-components-ImportManager-AddNew-ImportModal-index---header---65mEQ p {
  padding-bottom: var(--tinyVertGutter);
  font-size: var(--captionFontSize);
  text-align: justify;
}

.assets-components-ImportManager-AddNew-ImportModal-index---header---65mEQ p.assets-components-ImportManager-AddNew-ImportModal-index---finderErrorMsg---hy5Ed {
  margin-bottom: 0;
  color: var(--coral600);
}

.assets-components-ImportManager-AddNew-ImportModal-index---header---65mEQ .assets-components-ImportManager-AddNew-ImportModal-index---textInputContainer---1cnaI {
  position: relative;
  width: 100%;
  padding-bottom: var(--largeVertGutter);
}

.assets-components-ImportManager-AddNew-ImportModal-index---divider---13hVT {
  width: 100%;
  border-bottom: 2px var(--inky200) solid;
}

.assets-components-ImportManager-AddNew-ImportModal-index---pinterestFeedTypeDivider---2pIba {
  font-weight: bolder;
  text-align: center;
}

.assets-components-ImportManager-AddNew-ImportModal-index---actions---3p8vj {
  float: right;
  padding: var(--largeVertGutter) var(--hugeHorzGutter);
  display: flex;
}

.assets-components-ImportManager-AddNew-ImportModal-index---shopifyActions---3uvcT {
  float: right;
  padding: 37px 0 37px;
}

.assets-components-ImportManager-AddNew-ImportModal-index---shopifyActions---3uvcT .assets-components-ImportManager-AddNew-ImportModal-index---confirmButtonContainer---3ACOo {
  display: flex;
}

.assets-components-ImportManager-AddNew-ImportModal-index---actions---3p8vj button {
  margin: 0 var(--tinyHorzGutter) 0 var(--tinyHorzGutter);
}

.assets-components-ImportManager-AddNew-ImportModal-index---actions---3p8vj .assets-components-ImportManager-AddNew-ImportModal-index---confirmButtonContainer---3ACOo {
  float: right;
}

.assets-components-ImportManager-AddNew-ImportModal-index---inputErrorIsPresent---1HMbA {
  border: solid 2px var(--coral300);
}

.assets-components-ImportManager-AddNew-ImportModal-index---inputErrorIsPresent---1HMbA:focus {
  /* this overrides the default in forms.css */
  outline-color: var(--coral300);
}
