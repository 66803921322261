.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-LinkedInPostSelect-index---radioButton---tVMYA {
  display: flex;
  align-items: center;
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-LinkedInPostSelect-index---title---1FyBz {
  padding-left: var(--tinyHorzGutter);
  font-size: var(--bodyFontSize);
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-LinkedInPostSelect-index---description---josBD {
  display: block;
  padding-left: var(--largeHorzGutter);
  color: var(--inky400);
  font-size: var(--captionFontSize);
  font-size: 0.9em;
  font-weight: 300;
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-Option-LinkedInPostSelect-index---helpText---1HODn {
  padding-left: var(--largeHorzGutter);
  font-size: var(--captionFontSize);
}
