.assets-components-common-buttons-CloseButton-index---root---3uMfa {
  position: absolute;
  top: var(--tinyHorzGutter);
  right: var(--tinyHorzGutter);
}

.assets-components-common-buttons-CloseButton-index---root---3uMfa.assets-components-common-buttons-CloseButton-index---staticPositioned---2ivZE {
  position: static;
  top: unset;
  right: unset;
}

.assets-components-common-buttons-CloseButton-index---icon---iXWp- {
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: var(--inky400);
  cursor: pointer;
  text-align: center;
}

.assets-components-common-buttons-CloseButton-index---icon---iXWp-:hover {
  color: var(--inky500);
}

@media print {
  .assets-components-common-buttons-CloseButton-index---root---3uMfa {
    display: none;
  }
}
