.assets-components-common-forms-NewDateTimeField-TimeField-index---root---1eUm8 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 5px 3px 5px 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  color: #000;
  font-size: 14px;
}

.assets-components-common-forms-NewDateTimeField-TimeField-index---root---1eUm8 .assets-components-common-forms-NewDateTimeField-TimeField-index---clock---2BS_7 {
  margin-right: 4px;
}

.assets-components-common-forms-NewDateTimeField-TimeField-index---root---1eUm8.assets-components-common-forms-NewDateTimeField-TimeField-index---disabled---3IYo3 {
  cursor: not-allowed;
}

.assets-components-common-forms-NewDateTimeField-TimeField-index---root---1eUm8.assets-components-common-forms-NewDateTimeField-TimeField-index---disabled---3IYo3 .assets-components-common-forms-NewDateTimeField-TimeField-index---clock---2BS_7 {
  opacity: 0.8;
}

.assets-components-common-forms-NewDateTimeField-TimeField-index---root---1eUm8 input {
  width: 68px;
  padding-top: 2px;
  border: 0;
  background-color: transparent;
}

.assets-components-common-forms-NewDateTimeField-TimeField-index---root---1eUm8 input:focus {
  outline: none;
}
