.assets-pages-Composer-Variation-PostPreview-Post-MultiImageHelpText-index---root---15Moh {
  display: flex;
  margin-bottom: var(--midVertGutter);
  text-align: left;
}

.assets-pages-Composer-Variation-PostPreview-Post-MultiImageHelpText-index---infoIcon---1Tcew {
  margin-top: 3px;
  margin-right: var(--tinyHorzGutter);
}
