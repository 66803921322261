.assets-pages-Schedule-NewCalendar-Timeslots-index---focused---dtvhz {
  animation-name: assets-pages-Schedule-NewCalendar-Timeslots-index---flash---mbbk5;
}

.assets-pages-Schedule-NewCalendar-Timeslots-index---noAccounts---2Ri-t {
  padding: var(--tinyVertGutter) var(--tinyHorzGutter);
  border: 1px solid var(--coral100);
  margin: var(--tinyVertGutter) 0 0 0;
  background-color: var(--coral300);
  border-radius: var(--borderRadius);
  text-align: center;
}

.assets-pages-Schedule-NewCalendar-Timeslots-index---noAccountsIcon---3dnYP {
  display: block;
  margin: var(--tinyVertGutter) 0;
}

@keyframes assets-pages-Schedule-NewCalendar-Timeslots-index---flash---mbbk5 {
  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}
