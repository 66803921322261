@keyframes assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---fade---dxUE_ {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---root---32Ic9 {
  width: 40px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.67);
  border-radius: 15px;
  color: white;
  letter-spacing: 2px;
  text-align: center;
}

/* See FADE_OUT_TIME in index.js, should match (delay + animation time) here */
.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---appearActive---249GE,
.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---enterActive---3Mksw {
  animation: assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---fade---dxUE_ 0.75s linear 1s forwards;
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---exit---3uhc2,
.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---exitActive---1FE9S,
.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---exitDone---1tb7R,
.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Media-Video-Timer-index---enterDone---jv0CR {
  opacity: 0;
}
