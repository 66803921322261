.assets-pages-NewComposer-Variation-VideoAttachmentButton-index---tooltiperBody---13TPc {
  display: flex;
}

.assets-pages-NewComposer-Variation-VideoAttachmentButton-index---tooltiperBodyLeft---3nzut {
  width: 50%;
  padding: 0 5px 0 0;
}

.assets-pages-NewComposer-Variation-VideoAttachmentButton-index---tooltiperBodyRight---AQ3Z4 {
  width: 50%;
  padding: 0 0 0 5px;
}

.assets-pages-NewComposer-Variation-VideoAttachmentButton-index---tooltiperBody---13TPc strong {
  display: block;
  margin-top: 5px;
}
