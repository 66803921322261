.assets-pages-Composer-Variation-TextField-ButtonRow-index---container---3ehuK {
  display: flex;
}

.assets-pages-Composer-Variation-TextField-ButtonRow-index---emojiSelect---gmOCk {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: var(--tinyHorzGutter);
}

.assets-pages-Composer-Variation-TextField-ButtonRow-index---emojiSelect---gmOCk > div > button {
  height: auto;
  margin-top: var(--miniVertGutter);
  line-height: var(--midButtonHeight);
  border-color: var(--inky300);
  color: var(--inky400);
  font-size: 1.25em;
  text-align: center;
}

.assets-pages-Composer-Variation-TextField-ButtonRow-index---buttonRow---2htlM {
  display: flex;
  align-items: center;
  padding: var(--tinyHorzGutter) 0;
}

.assets-pages-Composer-Variation-TextField-ButtonRow-index---addLink---1MJqo {
  margin-right: var(--smallHorzGutter);
}

.assets-pages-Composer-Variation-TextField-ButtonRow-index---icon---3RnEA {
  margin-right: var(--tinyHorzGutter);
  color: #fff;
}

.assets-pages-Composer-Variation-TextField-ButtonRow-index---addLink---1MJqo:hover .assets-pages-Composer-Variation-TextField-ButtonRow-index---icon---3RnEA {
  color: #fff;
}
