.assets-pages-AccountCancellation-index---loading---1AGy8 {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-pages-AccountCancellation-index---section---1Mw9T {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  border: 1px solid var(--inky200);
  margin-top: calc(2 * var(--smallVertGutter));
  background-color: #fff;
  border-radius: var(--elementBorderRadius);
}

.assets-pages-AccountCancellation-index---sectionField---147D7 {
  display: flex;
  padding: var(--midVertGutter) 0;
  border-bottom: 1px solid var(--inky200);
}

.assets-pages-AccountCancellation-index---sectionField---147D7:last-child {
  border-bottom: none;
}

.assets-pages-AccountCancellation-index---sectionFieldLabel---1ybyy {
  width: 30%;
}

.assets-pages-AccountCancellation-index---button---3bcd3 {
  width: 200px;
}

.assets-pages-AccountCancellation-index---pushRight---udq0A {
  margin-left: auto;
}
