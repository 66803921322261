.assets-components-common-widgets-ProgressBar-index---root---2JXvm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  color: var(--inky500);
  font-size: var(--captionFontSize);
  font-weight: normal;
}

.assets-components-common-widgets-ProgressBar-index---label---2yfy0,
.assets-components-common-widgets-ProgressBar-index---value---1fm2K {
  font-weight: var(--semiBold);
}

.assets-components-common-widgets-ProgressBar-index---bound---2geFI {
  color: var(--inky400);
}

.assets-components-common-widgets-ProgressBar-index---meterContainer---1TzkD {
  width: 100%;
  margin-top: var(--tinyVertGutter);
  line-height: 0;
  background-color: var(--inky100);
  border-radius: 3px;
}

.assets-components-common-widgets-ProgressBar-index---meter---2u8pz {
  display: inline-block;
  height: var(--tinyVertGutter);
  background-color: var(--kelp400);
  border-radius: 3px;
}
