.assets-components-PostsTable-Account-index---account---fJn7t {
  display: flex;
  width: 125px;
  max-width: 125px;
}

.assets-components-PostsTable-Account-index---accountIcon---ezsRr {
  position: relative;
  display: inline-block;
  margin: 0 var(--tinyHorzGutter) var(--tinyHorzGutter) 0;
}

.assets-components-PostsTable-Account-index---accountName---2KIQ7 {
  display: -webkit-box;
  overflow: hidden;
  padding: 0 var(--tinyVertGutter);

  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
}

.assets-components-PostsTable-Account-index---instagramBusinessCaption---34XA9 {
  margin-left: var(--tinyHorzGutter);
}

@-moz-document url-prefix() {
  .assets-components-PostsTable-Account-index---accountName---2KIQ7 {
    display: inline-block;
    height: var(--midInputHeight);
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
