.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Actions-Paginator-Indicator-index---root---waCRD {
  display: inline-block;
  background-color: var(--instagramLightGray);
  border-radius: 50%;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Actions-Paginator-Indicator-index---large---36rkn,
.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Actions-Paginator-Indicator-index---active---1Rk3u {
  width: 6px;
  height: 6px;
  margin: 2px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Actions-Paginator-Indicator-index---medium---3LqzO {
  width: 4px;
  height: 4px;
  margin: 3px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Actions-Paginator-Indicator-index---small---3hDkx {
  width: 3px;
  height: 3px;
  margin: 4px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Actions-Paginator-Indicator-index---active---1Rk3u {
  background-color: #3897f0;
}
