.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Four-index---container---3CPfy {
  width: 551px;
  height: 551px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Four-index---primaryImage---3hgOv {
  width: 551px;
  height: 330px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Four-index---image---2IwLL {
  width: 181px;
  height: 221px;
}

.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Four-index---image---2IwLL > div,
.assets-pages-Composer-Variation-PostPreview-Post-LinkedinPost-Media-ImageCollage-Four-index---primaryImage---3hgOv > div {
  border-radius: 0;
}
