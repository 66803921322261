.assets-pages-Dashboard-index---loading---SHE4j {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-pages-Dashboard-index---sectionHeader---3RE71 {
  margin: var(--hugeVertGutter) 0 var(--midVertGutter);
}

.assets-pages-Dashboard-index---sectionHeader---3RE71:first-child {
  margin-top: calc(var(--largeVertGutter) - var(--smallVertGutter));
}

.assets-pages-Dashboard-index---box---17JHB {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  border: 1px solid var(--inky150);
  background-color: #fff;
  border-radius: var(--elementBorderRadius);
}

.assets-pages-Dashboard-index---quickStart---2Fdw6 {
  text-align: center;
}

.assets-pages-Dashboard-index---quickStartBody---2qG9- {
  margin: calc(var(--largeVertGutter) - var(--tinyVertGutter)) 0 var(--largeVertGutter);
}

.assets-pages-Dashboard-index---socialMediaIcons---kj5kt {
  padding: var(--midVertGutter) 0 0;
  margin: 0;
  list-style: none;
}

.assets-pages-Dashboard-index---socialMediaIcon---3wgnb {
  display: inline-block;
  margin: 0 var(--tinyHorzGutter);
}

.assets-pages-Dashboard-index---socialIconImage---3dD4_ {
  width: var(--hugeIconSize);
  height: var(--hugeIconSize);
  border-radius: var(--elementBorderRadius);
}

.assets-pages-Dashboard-index---startButtonContainer---3MkoL {
  position: relative;
  display: inline-block;
}

.assets-pages-Dashboard-index---startButtonContainer---3MkoL::before {
  position: absolute;
  top: calc(-1 * var(--largeVertGutter));
  right: calc(-1 * var(--hugeVertGutter));
  bottom: calc(-1 * var(--largeVertGutter));
  left: calc(-1 * var(--hugeVertGutter));
  display: block;
  background-image: url(/assets/webpack/8395da83ed254be4ae0e54796fb35e17.svg);
  background-position: -4px center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
}

.assets-pages-Dashboard-index---articleWrap---27SMF {
  display: block;
  text-decoration: none;
}

.assets-pages-Dashboard-index---articleImage---30UO4 {
  display: block;
  width: 100%;
}

.assets-pages-Dashboard-index---articleCenterTop---2O5Qo {
  display: flex;
  justify-content: center;
  padding: var(--largeVertGutter) var(--midHorzGutter);
}

.assets-pages-Dashboard-index---articleHeadshotImage---25aDp {
  width: 44%;
}

/* This is a little hacky, but the `img` element was wrapped to accommodate a Safari flex bug. */
.assets-pages-Dashboard-index---articleHeadshotImage---25aDp > img {
  display: block;
  width: 100%;
  border-radius: 50%;
}

.assets-pages-Dashboard-index---articleIconImage---1_TxP {
  display: block;
  width: 40%;
}

.assets-pages-Dashboard-index---articleTitleLink---Oaedi,
.assets-pages-Dashboard-index---articleTitleLink---Oaedi:visited {
  color: inherit;
  text-decoration: inherit;
}
