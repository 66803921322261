.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-Scroller-index---root---3GD2y {
  width: 500px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-Scroller-index---root---3GD2y img {
  height: 30px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-Scroller-index---rightChevron---3BXcN {
  float: right;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-Scroller-index---leftChevron---3CgcS:hover,
.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-Scroller-index---rightChevron---3BXcN:hover {
  cursor: pointer;
}
