
.assets-components-common-typography-Label-index---root---2KCgk {
  display: block;
  margin: 0; /* cancels out bootstrap */
  line-height: var(--midLineHeight);
  font-size: var(--bodyFontSize);
  font-weight: var(--semiBold);
}

.assets-components-common-typography-Label-index---link---1UaSN {
  cursor: pointer;
  text-decoration: underline;
}

.assets-components-common-typography-Label-index---link---1UaSN:hover {
  text-decoration: none;
}

.assets-components-common-typography-Label-index---small---2HX3o {
  line-height: var(--bodyLineHeight);
  font-size: var(--captionFontSize);
  font-weight: normal;
}

.assets-components-common-typography-Label-index---small---2HX3o.assets-components-common-typography-Label-index---link---1UaSN {
  line-height: var(--captionLineHeight);
  color: var(--inky400);
}

.assets-components-common-typography-Label-index---small---2HX3o.assets-components-common-typography-Label-index---link---1UaSN:hover {
  color: var(--inky300);
}

.assets-components-common-typography-Label-index---bold---XIUCf {
  padding: 0 0.5em;
  background-color: var(--coral400);
  border-radius: 0.7em;
  color: white;
  font-weight: bolder;
}
