.assets-components-ImportManager-Manage-Table-Row-index---feedRow---YiB4S > td {
  padding: 2rem;
}

.assets-components-ImportManager-Manage-Table-Row-index---feedRow---YiB4S > td:first-child {
  padding-left: 0;
}

.assets-components-ImportManager-Manage-Table-Row-index---feedRow---YiB4S > td:last-child {
  padding-right: 0;
}

.assets-components-ImportManager-Manage-Table-Row-index---importTo---3NqPo {
  color: gray;
  font-size: var(--captionFontSize);
  vertical-align: middle;
}

.assets-components-ImportManager-Manage-Table-Row-index---platformAccount---XiyWT {
  display: block;
  white-space: nowrap;
}

.assets-components-ImportManager-Manage-Table-Row-index---platformIcon---3Dhl4 {
  font-size: 1.5em;
  vertical-align: middle;
}

.assets-components-ImportManager-Manage-Table-Row-index---accountCount---3zXI2 {
  color: gray;
  font-size: var(--captionFontSize);
  vertical-align: middle;
}

.assets-components-ImportManager-Manage-Table-Row-index---actions---2cKGi a {
  width: 100%;
}

.assets-components-ImportManager-Manage-Table-Row-index---feedName---24IKg {
  display: block;
  margin-bottom: 0.25em;
  line-height: 1.3em;
  font-size: 1.3em;
}

.assets-components-ImportManager-Manage-Table-Row-index---editOptions---3xCOO {
  position: absolute;
  top: -3px;
  right: 0;
  width: 9em;
  padding: 0;
  border: 1px solid #d9d9d9;
  margin: 0;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  list-style: none;
}

.assets-components-ImportManager-Manage-Table-Row-index---editOptions---3xCOO > li {
  display: block;
  border-bottom: 1px solid #d9d9d9;
  margin: 0;
  text-align: left;
}

.assets-components-ImportManager-Manage-Table-Row-index---editOptions---3xCOO > li:last-child {
  border: none;
}

.assets-components-ImportManager-Manage-Table-Row-index---editOptions---3xCOO > li > a {
  display: block;
  padding: 13px 2px 10px 10px;
  margin: 0;
  font-size: 13px;
  font-weight: 500;
}

.assets-components-ImportManager-Manage-Table-Row-index---editOptions---3xCOO > li > a:hover {
  background: #edf3f3;
  text-decoration: none;
}
