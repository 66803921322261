.assets-pages-ScheduleOld-Calendar-Timeslots-index---minute---1ko0n {
  text-align: left;
}

.assets-pages-ScheduleOld-Calendar-Timeslots-index---minuteLabel---16jN1 {
  margin: var(--tinyVertGutter) var(--tinyHorzGutter) 0 var(--tinyHorzGutter);
}

.assets-pages-ScheduleOld-Calendar-Timeslots-index---timeslot---2m1QK {
  padding: var(--tinyVertGutter) var(--tinyHorzGutter);
  margin: var(--tinyVertGutter) var(--tinyHorzGutter);
  animation-duration: 2s;
  animation-fill-mode: both;
  border-radius: var(--elementBorderRadius);
  box-shadow: 0 2px 10px -3px var(--inky150);
  color: var(--sanddollar500);
  cursor: pointer;
  word-break: break-word;
}

.assets-pages-ScheduleOld-Calendar-Timeslots-index---focused---ZkJ_L {
  animation-name: assets-pages-ScheduleOld-Calendar-Timeslots-index---flash---ulob2;
}

.assets-pages-ScheduleOld-Calendar-Timeslots-index---timeslot---2m1QK:hover {
  box-shadow: 0 2px 19px -2px var(--inky300);
}

.assets-pages-ScheduleOld-Calendar-Timeslots-index---suggestedTimeslot---ax5pJ {
  border: 1px solid var(--inky200);
  background: repeating-linear-gradient(
    -55deg,
    #f7f7f7,
    #f7f7f7 10px,
    #fff 10px,
    #fff 20px
  );
  color: black;
}

.assets-pages-ScheduleOld-Calendar-Timeslots-index---accountBadges---9F3z4 {
  display: grid;
  margin-top: var(--tinyVertGutter);
  grid-gap: var(--tinyVertGutter) var(--tinyHorzGutter);
  grid-template-columns: repeat(auto-fill, 32px);
}

.assets-pages-ScheduleOld-Calendar-Timeslots-index---noAccounts---ECRW8 {
  padding: var(--tinyVertGutter) var(--tinyHorzGutter);
  border: 1px solid var(--coral100);
  margin: var(--tinyVertGutter) 0 0 0;
  background-color: var(--coral300);
  border-radius: var(--borderRadius);
  text-align: center;
}

.assets-pages-ScheduleOld-Calendar-Timeslots-index---noAccountsIcon---1zIbz {
  display: block;
  margin: var(--tinyVertGutter) 0;
}

@keyframes assets-pages-ScheduleOld-Calendar-Timeslots-index---flash---ulob2 {
  100% {
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}
