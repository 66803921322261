.assets-components-TopNav-HamburgerButton-index---root---2pRBt {
  position: absolute;
  top: 7px;
  left: 10px;
}

.assets-components-TopNav-HamburgerButton-index---root---2pRBt a {
  color: #3d3d3d;
}

.assets-components-TopNav-HamburgerButton-index---hamburger-button---1tRu1 {
  display: inline-block;
  padding: 6px 10px;
  border: 1px solid #adadad;
  margin-bottom: 0;
  line-height: 1.42857143;
  background-image: none;
  border-radius: 4px;
  color: #3d3d3d;
  cursor: pointer;
  font-size: 11px;
  font-weight: normal;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.assets-components-TopNav-HamburgerButton-index---open---3PSqp {
  background: #fefefe;
  background-image: linear-gradient(to bottom, #ebebeb, #fefefe);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.assets-components-TopNav-HamburgerButton-index---closed---31E8D {
  background: #ebebeb;
  background-image: linear-gradient(to bottom, #fefefe, #ebebeb);
}

@media (min-width: /*$tablet*/600px) {
  .assets-components-TopNav-HamburgerButton-index---root---2pRBt {
    display: none;
  }
}
