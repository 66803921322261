.assets-components-Categories-CategoryActionModal-index---label---U-mMQ {
  padding-bottom: var(--tinyVertGutter);
  font-weight: bold;
}

.assets-components-Categories-CategoryActionModal-index---requiredSymbol---2H-PR {
  color: var(--coral300);
}

.assets-components-Categories-CategoryActionModal-index---categoryNameContainer---2ty0k {
  margin: var(--midVertGutter) 0;
}

.assets-components-Categories-CategoryActionModal-index---errorMessage---1lMpU {
  color: var(--coral500);
}

.assets-components-Categories-CategoryActionModal-index---categoryUseForRandomCheckboxLabel---2cs6e {
  font-size: var(--captionFontSize);
}

.assets-components-Categories-CategoryActionModal-index---actions---2y3k1 {
  padding-bottom: var(--smallVertGutter);
}

.assets-components-Categories-CategoryActionModal-index---secondaryActions---3FQHJ {
  margin-right: var(--midHorzGutter);
}
