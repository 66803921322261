.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFour-index---container---1jn4V {
  flex-direction: column;
  width: 480px;
  height: 480px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFour-index---primaryImage---3HXpJ {
  width: 317px;
  height: 476px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFour-index---image---2NOyA {
  width: 157px;
  height: 157px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFour-index---image---2NOyA > div,
.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFour-index---primaryImage---3HXpJ > div {
  border-radius: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-PortraitFour-index---overlay---1dv4q {
  line-height: 157px;
  font-size: 36px;
}
