.assets-pages-NewComposer-Variation-PostPreviewButton-index---root---3MqTn {
  color: var(--inky400);
  cursor: pointer;
  font-size: 1.7em; /* prevents visual shift on hover */
}

.assets-pages-NewComposer-Variation-PostPreviewButton-index---root---3MqTn:hover {
  color: var(--inky500);
}

.assets-pages-NewComposer-Variation-PostPreviewButton-index---previewButtonTooltip---3FUAz {
  text-align: center;
}

.assets-pages-NewComposer-Variation-PostPreviewButton-index---postPreviewButtonTooltipTether---J-NiU {
  align-self: center;
}
