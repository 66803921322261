.assets-components-common-forms-RadioGroup-RadioButton-index---radioButton---OjU1G {
  display: flex;
  cursor: pointer;
}

.assets-components-common-forms-RadioGroup-RadioButton-index---status---3j3Ad {
  width: 18px;
  height: 18px;
  background-color: var(--inky200);
  border-radius: 2.5rem;
  text-align: center;
}

.assets-components-common-forms-RadioGroup-RadioButton-index---status---3j3Ad > i {
  line-height: 16px;
}

.assets-components-common-forms-RadioGroup-RadioButton-index---selected---1bVfR {
  background-color: var(--kelp400);
  color: white;
}

.assets-components-common-forms-RadioGroup-RadioButton-index---deselected---1MnFt {
  color: white;
}

.assets-components-common-forms-RadioGroup-RadioButton-index---deselected---1MnFt i {
  font-size: 16px;
}

.assets-components-common-forms-RadioGroup-RadioButton-index---selected---1bVfR i {
  line-height: 18px;
  vertical-align: top;
}

.assets-components-common-forms-RadioGroup-RadioButton-index---radioButton---OjU1G > input[type="radio"] {
  display: none;
}

.assets-components-common-forms-RadioGroup-RadioButton-index---label---3-rsZ {
  display: inline-block;
  width: 100%;
  margin-left: 1rem;
  cursor: pointer;
  vertical-align: top;
}
