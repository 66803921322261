.assets-components-common-layout-NavTabs-index---root---Vsgxh {
  display: flex;
  border-bottom: 1px solid var(--inky200);
  margin-bottom: var(--largeVertGutter);
}

.assets-components-common-layout-NavTabs-index---root---Vsgxh > * {
  margin: 0 var(--midHorzGutter);
}

.assets-components-common-layout-NavTabs-index---root---Vsgxh > *:first-child {
  margin-left: 0;
}
