.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFive-index---container---Umvov {
  width: 480px;
  height: 410px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFive-index---primaryImage---2hEeD {
  width: 236px;
  height: 252px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFive-index---image---1p2Ly {
  width: 157px;
  height: 156px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFive-index---image---1p2Ly > div,
.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFive-index---primaryImage---2hEeD > div {
  border-radius: 0;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFive-index---overlay---3Tcef {
  line-height: 157px;
  font-size: 36px;
}
