.assets-components-common-AccountSelect-Option-InstagramPersonalCaption-index---select---3dKSB {
  width: 100%;
}

.assets-components-common-AccountSelect-Option-InstagramPersonalCaption-index---description---2ScGc {
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-components-common-AccountSelect-Option-InstagramPersonalCaption-index---link---3pP_0 {
  margin-right: var(--tinyHorzGutter);
}
