
.assets-components-common-layout-Header-index---header---2B8F1 {
  padding-top: 2em;
}

.assets-components-common-layout-Header-index---header---2B8F1 h1 {
  padding: 0;
  margin: 0 0 8px;
  font-size: 2em;
}

.assets-components-common-layout-Header-index---moreNav---2zHR5 {
  margin: 0 0.75em 0.75em 0;
  color: var(--purpleGrey);
  font-family: var(--primaryFont);
  font-size: 14px;
  font-weight: 300;
}

.assets-components-common-layout-Header-index---moreNav---2zHR5 > a {
  color: var(--kelp500);
  font-weight: 500;
}
