.assets-components-SubscriptionManager-Product-index---plan---21Q9G {
  padding: var(--midVertGutter) var(--midHorzGutter);
  border: 1px solid var(--inky200);
  border-radius: var(--elementBorderRadius);
}

.assets-components-SubscriptionManager-Product-index---selectablePlan---sBzF9 {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.assets-components-SubscriptionManager-Product-index---unselectedPlan---WXwUN {
  border: 0;
  background: var(--inky100);
}

.assets-components-SubscriptionManager-Product-index---selectedPlan---19OsJ {
  border: 1px solid var(--kelp400);
  box-shadow: var(--lightBoxShadow);
}

.assets-components-SubscriptionManager-Product-index---radioButton---1qSCo {
  margin: var(--tinyHorzGutter) var(--smallHorzGutter) 0 0;
}

.assets-components-SubscriptionManager-Product-index---labelOverride---1_Jqm {
  cursor: pointer;
}

.assets-components-SubscriptionManager-Product-index---savings---mjnkP {
  color: var(--kelp400);
}

.assets-components-SubscriptionManager-Product-index---planLabelDetails---yaPv2 {
  font-weight: normal;
}
