.assets-components-TopNav-MainNav-index---root---2BgOf {
  position: absolute;
  z-index: 10;
  top: calc(44px - 1px);
  left: 0;
  width: 100%;
  padding: 0;
  margin-left: -100%; /* offscreen */
  background: #cde4e4;
  box-shadow: 0 1000px 0 1000px rgba(0, 0, 0, 0);
}

.assets-components-TopNav-MainNav-index---open---39RP8 {
  margin-left: 0;
}

.assets-components-TopNav-MainNav-index---list---2BPgm {
  padding: 0;
  margin: 0 0;
  list-style: none;
}

.assets-components-TopNav-MainNav-index---close---11r3u {
  display: block;
  padding: 2px 15px 4px;
  background: #80c6c7;
  font-size: 16px;
  font-weight: 800;
  text-align: right;
  text-decoration: none;
}

.assets-components-TopNav-MainNav-index---close---11r3u:link,
.assets-components-TopNav-MainNav-index---close---11r3u:active,
.assets-components-TopNav-MainNav-index---close---11r3u:visited {
  color: rgb(236, 246, 247);
}

.assets-components-TopNav-MainNav-index---close---11r3u:hover {
  background: rgb(16, 35, 35);
  color: rgb(236, 246, 247);
  text-decoration: none;
}

.assets-components-TopNav-MainNav-index---item---1AZ9e {
  position: relative;
  border-bottom: 1px solid #cce2e2;
}

.assets-components-TopNav-MainNav-index---alert---3Ir_7 {
  position: relative;
  display: inline-block;
  padding: 8px 0;
  border-left: 1px solid #cce2e2;
  font-size: 18px;
}

.assets-components-TopNav-MainNav-index---alert---3Ir_7:link,
.assets-components-TopNav-MainNav-index---alert---3Ir_7:visited {
  color: #a04250;
}

.assets-components-TopNav-MainNav-index---alert---3Ir_7:hover {
  color: rgb(189, 123, 133);
}

.assets-components-TopNav-MainNav-index---mobileUserMenu---2X-N1 {
  width: 100%;
  padding: 0;
  margin: 0;
  background: #cde4e4;
  list-style: none;
}

.assets-components-TopNav-MainNav-index---mobileUserMenu---2X-N1 > li:last-child {
  border: none;
}

.assets-components-TopNav-MainNav-index---link---3AG-d,
.assets-components-TopNav-MainNav-index---mobileUserMenu---2X-N1 > a {
  display: inline-block;
  padding: 10px;
  color: #3d3d3d;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: var(--semiBold);
}

.assets-components-TopNav-MainNav-index---mobileUserMenu---2X-N1 > a {
  position: relative;
  display: block;
  border-bottom: 1px solid #cce2e2;
  margin: 0;
  font-weight: var(--normalWeight);
}

.assets-components-TopNav-MainNav-index---link---3AG-d:link,
.assets-components-TopNav-MainNav-index---link---3AG-d:visited {
  color: #3d3d3d;
  text-decoration: none;
}

.assets-components-TopNav-MainNav-index---link---3AG-d:hover {
  color: #80c6c7;
  text-decoration: none;
}

.assets-components-TopNav-MainNav-index---link---3AG-d:active {
  color: #3d3d3d;
  text-decoration: none;
}

.assets-components-TopNav-MainNav-index---link---3AG-d.assets-components-TopNav-MainNav-index---disabled---2bt84 {
  color: #b2b2b2;
  cursor: default;
}

.assets-components-TopNav-MainNav-index---activeLink---3yqKY {
  color: rgb(83, 153, 154);
}

.assets-components-TopNav-MainNav-index---activeLink---3yqKY:link,
.assets-components-TopNav-MainNav-index---activeLink---3yqKY:active,
.assets-components-TopNav-MainNav-index---activeLink---3yqKY:visited {
  color: rgb(83, 153, 154);
}

@media (min-width: 600px) {
  .assets-components-TopNav-MainNav-index---root---2BgOf {
    top: calc(55px - 1px);
    padding: 0 20px;
    margin-left: 0;
    box-shadow: none;
    text-align: center;
    transition: none;
  }

  .assets-components-TopNav-MainNav-index---open---39RP8 {
    box-shadow: none;
  }

  .assets-components-TopNav-MainNav-index---close---11r3u {
    display: none;
  }

  .assets-components-TopNav-MainNav-index---mobile---2tjqp {
    transition: none;
  }

  .assets-components-TopNav-MainNav-index---hover---St12x {
    background: none;
    text-decoration: underline;
  }

  .assets-components-TopNav-MainNav-index---item---1AZ9e {
    display: inline-block;
    border: none;
  }

  .assets-components-TopNav-MainNav-index---alert---3Ir_7 {
    position: relative;
    top: 6px;
    right: 0;
    display: block;
    float: right;
    padding: 0 4px;
    border: none;
    margin-left: -1em;
    background: transparent;
  }

  .assets-components-TopNav-MainNav-index---alert---3Ir_7 span {
    display: none;
  }

  .assets-components-TopNav-MainNav-index---link---3AG-d {
    display: inline-block;
    padding: 18px 2px 15px 0;
    margin: 0 10px;
    font-size: 12px;
  }

  .assets-components-TopNav-MainNav-index---pendingCount---2YiRr {
    position: absolute;
    z-index: 1;
    top: 6px;
    right: -7px;
  }

  .assets-components-TopNav-MainNav-index---newBadge---2YQkQ {
    position: absolute;
    z-index: -1;
    top: 5px;
    right: -2px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    padding: 0 5px;
    font-size: 10.5px;
  }

  .assets-components-TopNav-MainNav-index---newBadge---2YQkQ span {
    margin-top: -1.5px;
  }
}

.assets-components-TopNav-MainNav-index---link---3AG-d .assets-components-TopNav-MainNav-index---username---1nPYl {
  display: flex;
  align-items: center;
}

.assets-components-TopNav-MainNav-index---link---3AG-d .assets-components-TopNav-MainNav-index---username---1nPYl span {
  margin: 0 4px 0 0;
}

@media (min-width: 695px) {
  .assets-components-TopNav-MainNav-index---alert---3Ir_7 {
    right: 6px;
  }

  .assets-components-TopNav-MainNav-index---link---3AG-d {
    padding: 18px 9px 15px;
    margin: 0 2px;
    font-size: 13px;
    text-align: center;
  }

  .assets-components-TopNav-MainNav-index---link---3AG-d .assets-components-TopNav-MainNav-index---username---1nPYl span {
    overflow: hidden;
    max-width: 82px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (min-width: 995px) {
  .assets-components-TopNav-MainNav-index---root---2BgOf {
    position: relative;
    top: 0;
    border: none;
  }

  .assets-components-TopNav-MainNav-index---list---2BPgm {
    float: right;
    margin-right: 1em;
  }

  .assets-components-TopNav-MainNav-index---item---1AZ9e {
    float: left;
  }

  .assets-components-TopNav-MainNav-index---activeLink---3yqKY:link,
  .assets-components-TopNav-MainNav-index---activeLink---3yqKY:visited {
    border-bottom: 4px solid rgb(83, 153, 154);
    color: rgb(83, 153, 154);
  }

  .assets-components-TopNav-MainNav-index---link---3AG-d {
    text-align: left;
  }
}
