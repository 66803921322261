.assets-pages-NewComposer-Variation-ImageAttachmentButton-index---tooltiperBody---1fCwC {
  display: flex;
}

.assets-pages-NewComposer-Variation-ImageAttachmentButton-index---tooltiperBodyLeft---3GJC7 {
  width: 50%;
  padding: 0 5px 0 0;
}

.assets-pages-NewComposer-Variation-ImageAttachmentButton-index---tooltiperBodyRight---A2FKZ {
  width: 50%;
  padding: 0 0 0 5px;
}

.assets-pages-NewComposer-Variation-ImageAttachmentButton-index---tooltiperBody---1fCwC strong {
  display: block;
  margin-top: 5px;
}
