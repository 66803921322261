
.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---root---3c2qI {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 270px;
  height: 70px;
  padding: var(--tinyVertGutter) var(--smallHorzGutter) var(--miniVertGutter);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---root---3c2qI:active {
  background-color: var(--whale200);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---root---3c2qI:hover {
  background-color: var(--whale100);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---rootFocused---2SRZP {
  background-color: var(--whale100);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---imageContainer---16NLw {
  flex: 1;
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---image---2etoh {
  display: inline-block;
  width: auto;
  height: auto;
  border-radius: var(--elementBorderRadius);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---textContainer---3N_a2 {
  display: flex;
  flex: 3;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---name---3Bj3-,
.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---description---7WrLH {
  display: inline-block;
  align-self: flex-start;
  overflow: hidden;
  font-size: var(--bodyFontSize);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---description---7WrLH {
  color: var(--inky300);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---checkCircle---3GxqJ {
  color: var(--whale400);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---infoIcon---1jnq9 {
  padding-right: var(--tinyHorzGutter);
  color: var(--inky300);
}

.assets-pages-NewComposer-Variation-TextField-PostEditor-MentionSuggestionsEntry-index---edgarInfoEntry---3rUKR {
  padding: 0 var(--tinyHorzGutter) var(--smallHorzGutter) var(--smallHorzGutter);
  line-height: var(--captionLineHeight);
  cursor: default;
  font-size: var(--captionFontSize);
}
