.assets-pages-NewComposer-Actions-SetSendAtModal-index---root---1Tm9- {
  width: 90%;
  max-width: 512px;
  padding: 32.5px;
}

@media (min-width: 700px) {
  .assets-pages-NewComposer-Actions-SetSendAtModal-index---root---1Tm9- {
    max-width: 530px;
    padding: 45px;
  }
}
