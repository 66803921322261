.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-EmptyState-index---noAccountsHeader---ejcel {
  padding: 0 0 var(--tinyHorzGutter);
  border-bottom: solid 1px var(--inky200);
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-EmptyState-index---socialIcon---1zfZg {
  margin: 20px 18px 0 0;
  font-size: 36px;
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-EmptyState-index---socialIcon---1zfZg.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-EmptyState-index---facebook---3zR7A {
  color: var(--facebook);
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-EmptyState-index---socialIcon---1zfZg.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-EmptyState-index---linkedin---1R5OB {
  color: var(--linkedin);
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-EmptyState-index---socialIcon---1zfZg.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-EmptyState-index---twitter---hBwcJ {
  color: var(--twitter);
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-EmptyState-index---socialIcon---1zfZg.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-EmptyState-index---instagram---1Mjrp {
  color: var(--instagram);
}

.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-EmptyState-index---socialIcon---1zfZg.assets-pages-NewComposer-AccountSelect-BaseAccountSelect-EmptyState-index---pinterest---26XB1 {
  color: var(--pinterest);
}
