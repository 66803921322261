.assets-pages-Invoices-index---loading---2PMpa {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-pages-Invoices-index---info---28zFC {
  margin-left: var(--tinyHorzGutter);
}

.assets-pages-Invoices-index---section---EygL1 {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  border: 1px solid var(--inky200); /* TODO: This may need to be lighter via Dashboard PR. */
  margin-top: calc(2 * var(--smallVertGutter));
  background-color: #fff;
  border-radius: var(--elementBorderRadius);
}

.assets-pages-Invoices-index---table---2fZx6 {
  width: 100%;
  border-top: 2px solid var(--kelp400);
  border-collapse: collapse;
  border-spacing: 0;
}

.assets-pages-Invoices-index---table---2fZx6 th {
  border-bottom: 2px solid var(--inky200);
  text-align: left;
  vertical-align: bottom;
}

.assets-pages-Invoices-index---table---2fZx6 th,
.assets-pages-Invoices-index---table---2fZx6 td {
  padding: 8px;
}

.assets-pages-Invoices-index---table---2fZx6 td {
  border-top: 1px solid var(--inky100);
}

.assets-pages-Invoices-index---table---2fZx6 tr:nth-child(odd) td {
  background-color: var(--inky50);
}

.assets-pages-Invoices-index---table---2fZx6 tr:first-child td {
  border-top: 0;
}
