.assets-components-PlanModal-index---customModal---1s7F1 {
	text-align: center;
	margin-top: 17px;
}

.assets-components-PlanModal-index---modalText---1lEr- {
	font-size: 18px;
	font-style: normal;
  font-weight: 400;
  margin-top: 44px;
  margin-bottom: 43px;
  text-align: center;

} 

.assets-components-PlanModal-index---modalIcon---_U-AH {
	text-align: center;
}

span img{
	top: 5px !important;
}

.assets-components-PlanModal-index---paymentModal---Mz39B {
	width: 500px;
}