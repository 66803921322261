.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-common-index---container---3lPCQ {
  display: flex;
  flex-wrap: wrap;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-common-index---primaryImage---3mC7l {
  overflow: hidden;
  margin: 0.5px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-common-index---image---13L44 {
  position: relative;
  overflow: hidden;
  margin: 0.5px;
}
