.assets-components-TopNav-Flasher-Flash-index---root---2XXj1 {
  display: flex;
  overflow: hidden;
  max-width: 500px;
}

.assets-components-TopNav-Flasher-Flash-index---rootOld---2IsJy {
  display: flex;
  overflow: hidden;
  border-top: none;
  border-radius: 0 0 i__const_var_6(--borderRadius) i__const_var_6(--borderRadius);
}
