.assets-pages-NewComposer-Variation-Attachments-common-AttachmentContainer-index---root---3iK8s {
  position: relative;
  padding: 0 var(--smallHorzGutter);
  border: 1px solid var(--inky200);
  line-height: var(--captionLineHeight);
  background-color: var(--inky100);
  border-radius: var(--elementBorderRadius);
  color: var(--inky400);
  font-size: var(--captionFontSize);
}

.assets-pages-NewComposer-Variation-Attachments-common-AttachmentContainer-index---hasClearBackground---3yOeJ {
  background-color: transparent;
  border-color: transparent;
}
