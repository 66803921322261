.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-ByLine-index---root---1fCC9 {
  display: flex;
  margin-bottom: 16px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-ByLine-index---root---1fCC9 a {
  cursor: pointer;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-ByLine-index---image---1uMAL {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;
  cursor: pointer;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-ByLine-index---details---2tZ5G {
  line-height: 1.34em;
  color: var(--fbTextGrey);
  font-size: 12px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-ByLine-index---details---2tZ5G a {
  color: var(--fbTextGrey);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-ByLine-index---name---2Avhg {
  line-height: 1.38em;
  color: var(--fbTextGrey);
  font-size: 14px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-ByLine-index---name---2Avhg a {
  color: var(--fbLinkBlue);
  cursor: pointer;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-ByLine-index---name---2Avhg a:first-of-type {
  font-weight: bold;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-ByLine-index---name---2Avhg a:hover {
  text-decoration: underline;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-ByLine-index---arrow---30_ff {
  margin: 0 5px;
  color: rgb(170, 170, 170);
}
