.assets-pages-Composer-Variation-Attachments-LinkPreviewAttachmentToggle-LinkPreviewAttachment-index---header---1sAB6 {
  display: flex;
  align-items: center;
  margin-right: var(--midVertGutter);
  margin-bottom: var(--tinyVertGutter);
}

.assets-pages-Composer-Variation-Attachments-LinkPreviewAttachmentToggle-LinkPreviewAttachment-index---content---1hDya {
  padding: var(--smallVertGutter) var(--smallHorzGutter);
}

.assets-pages-Composer-Variation-Attachments-LinkPreviewAttachmentToggle-LinkPreviewAttachment-index---header---1sAB6 > * {
  margin-right: var(--tinyHorzGutter);
}

.assets-pages-Composer-Variation-Attachments-LinkPreviewAttachmentToggle-LinkPreviewAttachment-index---url---8CqC4 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assets-pages-Composer-Variation-Attachments-LinkPreviewAttachmentToggle-LinkPreviewAttachment-index---loadingIconContainer---f2aZg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--hugeVertGutter);
  color: var(--inky200);
  font-size: var(--hugeIconSize);
}
