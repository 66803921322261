.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Header-ProfilePicture-index---root---3tr5y {
  flex-grow: 0;
  width: 40px;
  height: 40px;
  padding: 0 var(--tinyHorzGutter);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Header-ProfilePicture-index---image---2Gg_N {
  width: 100%;
  border-radius: 20px;
}
