.assets-components-Analytics-ShortLinksTable-SplitTestLabel-index---thumbsUp---2A4cP {
  color: var(--algae400);
}

.assets-components-Analytics-ShortLinksTable-SplitTestLabel-index---thumbsDown---1j-C- {
  color: var(--coral600);
}

.assets-components-Analytics-ShortLinksTable-SplitTestLabel-index---thumbsDown---1j-C- > a {
  color: var(--coral600);
  font-weight: var(--semiBold);
  text-decoration: underline;
}
