.assets-pages-Composer-AdvancedSettings-index---root---24Piy {
  padding: var(--largeVertGutter) 0;
}

.assets-pages-Composer-AdvancedSettings-index---header---466TC {
  display: flex;
  align-items: center;
  color: var(--kelp500);
  cursor: pointer;
}

.assets-pages-Composer-AdvancedSettings-index---header---466TC:hover {
  color: var(--kelp400);
}

.assets-pages-Composer-AdvancedSettings-index---arrowIcon---2dS9_ {
  margin: 0 var(--smallHorzGutter) 0 var(--tinyHorzGutter);
  font-size: 10px;
  vertical-align: middle;
}

.assets-pages-Composer-AdvancedSettings-index---settings---8r3Dx {
  padding-top: var(--midVertGutter);
}

.assets-pages-Composer-AdvancedSettings-index---helpText---2_bzz {
  margin-bottom: var(--largeVertGutter);
}

.assets-pages-Composer-AdvancedSettings-index---helpText---2_bzz:last-child {
  margin-bottom: 0;
}

.assets-pages-Composer-AdvancedSettings-index---field---3vXu4 {
  /* 1px of padding to allow for active checkbox styles.
   * Without it they end up getting cut off in Chrome. */
  padding-left: 1px;
  margin-bottom: var(--midVertGutter);
}

.assets-pages-Composer-AdvancedSettings-index---field---3vXu4:last-child {
  margin-bottom: 0;
}

.assets-pages-Composer-AdvancedSettings-index---disabledAlert---kPc4v {
  margin-bottom: var(--midVertGutter);
}
