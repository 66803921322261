.assets-pages-Queue-UpcomingPostList-index---root---3TqzZ {
  margin-top: var(--largeVertGutter);
}

.assets-pages-Queue-UpcomingPostList-index---day----5qS3 {
  margin-bottom: var(--midVertGutter);
}

.assets-pages-Queue-UpcomingPostList-index---resultCount---Fn7tl {
  float: right;
  line-height: var(--largeLineHeight);
}
