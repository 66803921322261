@keyframes assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---fade---DzR-j {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---root---1wW_9 {
  width: 40px;
  padding: 4px;
  background: rgba(0, 0, 0, 0.67);
  border-radius: 15px;
  color: white;
  letter-spacing: 2px;
  text-align: center;
}

/* See FADE_OUT_TIME in index.js, should match (delay + animation time) here */
.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---appearActive---54tVh,
.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---enterActive---1Ht_J {
  animation: assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---fade---DzR-j 0.75s linear 1s forwards;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---exit---2uHyL,
.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---exitActive---3FYSo,
.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---exitDone---3AMP-,
.assets-pages-NewComposer-Variation-PostPreview-Post-ThreadsPost-Media-ImageCarousel-ImageXOfYIndicator-index---enterDone---Gv5OC {
  opacity: 0;
}
