.assets-components-PostsTable-TableHeader-index---tableHeader---pB5f0 {
  padding: var(--tinyVertGutter) 0 var(--tinyVertGutter) var(--tinyVertGutter);
  color: #777;
  font-size: 10px;
  text-align: left;
}

.assets-components-PostsTable-TableHeader-index---tableHeader---pB5f0:first-child {
  padding-left: var(--midHorzGutter);
}

.assets-components-PostsTable-TableHeader-index---tableHeader---pB5f0:last-child {
  padding-right: var(--midHorzGutter);
}

.assets-components-PostsTable-TableHeader-index---sortable---5j73c {
  display: flex;
  align-items: flex-end;
}

.assets-components-PostsTable-TableHeader-index---sortTrigger---1ohQL {
  text-decoration: none;
}

.assets-components-PostsTable-TableHeader-index---icon---3l9Jo {
  margin: 0 var(--tinyHorzGutter);
}
