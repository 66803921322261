.assets-pages-Composer-Variation-TextField-index---root---FE8Ji {
  position: relative;
}

.assets-pages-Composer-Variation-TextField-index---sniplyTarget---NtmFO {
  position: absolute;
  right: 2px;
  bottom: 2px;
  width: 16px;
  height: 16px;
}

.assets-pages-Composer-Variation-TextField-index---blurred---UuX0l {
  padding-top: 0;
  padding-bottom: 0;
}

.assets-pages-Composer-Variation-TextField-index---hasTopSection---1-tZ0.assets-pages-Composer-Variation-TextField-index---blurred---UuX0l {
  padding-top: 5px;
}

.assets-pages-Composer-Variation-TextField-index---hasTopSection---1-tZ0 .assets-pages-Composer-Variation-TextField-index---blurredInputContainer---1ofLm > div {
  line-height: inherit;
}

.assets-pages-Composer-Variation-TextField-index---errorList---3poGv {
  margin-bottom: var(--smallVertGutter);
}

.assets-pages-Composer-Variation-TextField-index---hasErrors---lky4m {
  border: 2px solid var(--coral400) !important;
}

.assets-pages-Composer-Variation-TextField-index---hasWarnings---3mykz {
  border: 2px solid var(--starfish400) !important;
}

.assets-pages-Composer-Variation-TextField-index---hasTopSection---1-tZ0.assets-pages-Composer-Variation-TextField-index---hasWarnings---3mykz {
  border: solid 1px rgba(0, 0, 0, 0.32) !important;
  border-bottom: 0 !important;
}
