.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Header-Menu-index---root---1pknp {
  flex-grow: 0;
  width: 30px;
  height: 30px;
  font-size: 20px;
  text-align: center;
}

.assets-pages-Composer-Variation-PostPreview-Post-ThreadsPost-Header-Menu-index---root---1pknp img {
  width: 100%;
}
