.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Two-index---container---2_oMm {
  width: 504px;
  height: 254px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Two-index---image---23mw8 {
  width: 250px;
  height: 252px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Two-index---image---23mw8:first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Two-index---image---23mw8:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TwitterPost-Media-ImageCollage-Two-index---image---23mw8 > div {
  border-radius: 0;
}
