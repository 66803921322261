.assets-pages-Composer-Variation-PostPreview-index---root---3uSnQ {
  min-width: var(--mainContentMinWidth);
}

.assets-pages-Composer-Variation-PostPreview-index---root---3uSnQ > main {
  padding: 0;
}

.assets-pages-Composer-Variation-PostPreview-index---accountSelectContainer---3I7Fn {
  padding: 0 30px;
  margin: var(--midVertGutter) 0;
}

.assets-pages-Composer-Variation-PostPreview-index---accountSelectContainerHeader---itOiG {
  margin: 0 0 var(--tinyVertGutter) 0;
}

.assets-pages-Composer-Variation-PostPreview-index---postContainer---1XUTT {
  padding: var(--midVertGutter) 90px;
  background-color: var(--lighterGrey);
  text-align: center;
}

.assets-pages-Composer-Variation-PostPreview-index---nullStateContainer---1iG7L {
  padding: 0 30px;
}
