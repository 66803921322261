.assets-components-common-icons-AccountBadge-index---root---3Xd6g {
  display: flex;
  line-height: 0;
}

.assets-components-common-icons-AccountBadge-index---image---1i5s3 {
  width: var(--badgeSize);
  height: var(--badgeSize);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.assets-components-common-icons-AccountBadge-index---avatar---AsXlK {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: var(--elementBorderRadius);
  border-top-left-radius: var(--elementBorderRadius);
  color: transparent;
}

.assets-components-common-icons-AccountBadge-index---platformIcon---28uV2 {
  border-bottom-right-radius: var(--elementBorderRadius);
  border-top-right-radius: var(--elementBorderRadius);
}

.assets-components-common-icons-AccountBadge-index---tiny---3TEC_ > .assets-components-common-icons-AccountBadge-index---avatar---AsXlK,
.assets-components-common-icons-AccountBadge-index---tiny---3TEC_ > .assets-components-common-icons-AccountBadge-index---platformIcon---28uV2 {
  width: calc(var(--badgeSize) * 0.666);
  height: calc(var(--badgeSize) * 0.666);
}

.assets-components-common-icons-AccountBadge-index---small---CbB6f > .assets-components-common-icons-AccountBadge-index---avatar---AsXlK,
.assets-components-common-icons-AccountBadge-index---small---CbB6f > .assets-components-common-icons-AccountBadge-index---platformIcon---28uV2 {
  width: calc(var(--badgeSize) * 1);
  height: calc(var(--badgeSize) * 1);
}

.assets-components-common-icons-AccountBadge-index---tipBody---2uOnV {
  font-family: var(--primaryFont);
}

.assets-components-common-icons-AccountBadge-index---tipCaption---3qlCX {
  overflow: hidden;
  color: white;
  text-overflow: ellipsis;
  white-space: nowrap;
}
