.assets-pages-NewComposer-index---loading---2ejni {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-pages-NewComposer-index---toggle_area---1-BMM {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 24%;
  padding: 0 0 20px 0;
  margin-top: -25px;
}

.assets-pages-NewComposer-index---toggle_area---1-BMM p {
  line-height: 24px;
  font-size: 18px;
  font-weight: 600;
}

@media (min-width: 768px) and (max-width: 994px) {
  .assets-pages-NewComposer-index---toggle_area---1-BMM {
    display: flex;

    /* margin-left: 25px; */
    align-items: center;
    justify-content: space-between;
    width: 42%;
  }
}

.assets-pages-NewComposer-index---wrapper---hI0X- {
  text-align: center;
  background-color: #cff4fc;
  border: 1px solid #b6effb;
  border-radius: 4px;
  /* display: flex; */
  /* justify-content: space-between; */
  margin: 20px 0 30px;
  padding: 16px;
  width: 100%;
}
