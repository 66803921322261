
.assets-components-SubscriptionManager-CouponModal-index---fieldExplanation---35-KH {
  padding: 1.5em;
  border: 1px solid var(--lightGrey);
  border-top: none;
  background-color: var(--lighterGrey);
}

.assets-components-SubscriptionManager-CouponModal-index---fieldExplanation---35-KH > h3 {
  margin: 0;
  line-height: 1.8em;
  color: var(--darkGrey);
  font-weight: 400;
}

.assets-components-SubscriptionManager-CouponModal-index---fieldExplanation---35-KH > p {
  line-height: 1.4em;
  color: var(--darkGrey);
  font-weight: 100;
}

.assets-components-SubscriptionManager-CouponModal-index---wrapper---Ofb3W {
  display: flex;
  flex-direction: column;
}

.assets-components-SubscriptionManager-CouponModal-index---couponInput---3_Zl4 {
  margin: 0.5em 0;
}
