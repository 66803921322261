.assets-pages-NewComposer-Actions-SeeMorePendingContentDialog-index---root---1j1eR {
  text-align: center;
}

.assets-pages-NewComposer-Actions-SeeMorePendingContentDialog-index---title---2dcbl {
  margin-bottom: var(--largeVertGutter);
}

.assets-pages-NewComposer-Actions-SeeMorePendingContentDialog-index---message---2GbRY {
  display: block;
  margin-bottom: var(--hugeVertGutter);
}
