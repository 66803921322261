.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitTwo-index---container---2wBEf {
  width: 480px;
  height: 480px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitTwo-index---image---3GXv0 {
  width: 237px;
  height: 476px;
}

.assets-pages-Composer-Variation-PostPreview-Post-TikTokPost-Media-ImageCollage-PortraitTwo-index---image---3GXv0 > div {
  border-radius: 0;
}
