.assets-components-common-icons-AccountProviderIcon-index---platformIcon---1Io5B {
  display: inline-block;
  width: 23px;
  height: 23px;
}

.assets-components-common-icons-AccountProviderIcon-index---small---339y_ {
  width: var(--platformIconSize);
  height: var(--platformIconSize);
  margin-left: var(--tinyHorzGutter);
  border-radius: var(--elementBorderRadius);
  vertical-align: sub;
}

.assets-components-common-icons-AccountProviderIcon-index---facebookIcon---SLtBQ {
  background-color: var(--facebook);
}

.assets-components-common-icons-AccountProviderIcon-index---twitterIcon---2VUu2 {
  background-color: var(--twitter);
}

.assets-components-common-icons-AccountProviderIcon-index---xIcon---2TpWn {
  background-color: var(--twitter);
}

.assets-components-common-icons-AccountProviderIcon-index---linkedinIcon---QcgNG {
  background-color: var(--linkedin);
}

.assets-components-common-icons-AccountProviderIcon-index---instagramIcon---3oDNq {
  padding: 3px;
  background-color: var(--instagram);
}

.assets-components-common-icons-AccountProviderIcon-index---pinterestIcon---3JmzM {
  background-color: var(--pinterest);
}

.assets-components-common-icons-AccountProviderIcon-index---googleIcon---3PPpg {
  padding: 0 1px 0 3px;
  background-color: var(--google);
}

.assets-components-common-icons-AccountProviderIcon-index---googleBusinessIcon---2W4TP {
  background-color: var(--google);
}

.assets-components-common-icons-AccountProviderIcon-index---tiktokIcon---2FcGM {
  padding: 0 1px 0 3px;
  background-color: var(--tiktok);
}

.assets-components-common-icons-AccountProviderIcon-index---threadsIcon---2MzaG {
  padding: 0 1px 0 3px;
  background-color: var(--threads);
}
