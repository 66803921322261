
.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Actions-index---root---3M_Ay {
  padding: 0 var(--tinyHorzGutter);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Actions-index---instaButton---3_DnU {
  width: 30px;
  margin: var(--smallVertGutter) var(--smallHorzGutter);
}

.assets-pages-NewComposer-Variation-PostPreview-Post-InstagramPost-Actions-index---bookmark---26V3T {
  float: right;
}
