.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFour-index---container---17zJU {
  width: 480px;
  height: 480px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFour-index---primaryImage---2leMB {
  width: 476px;
  height: 317px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFour-index---image---3jG-k {
  width: 157px;
  height: 157px;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFour-index---image---3jG-k > div,
.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFour-index---primaryImage---2leMB > div {
  border-radius: 0;
}

.assets-pages-NewComposer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-LandscapeFour-index---overlay---1ps5S {
  line-height: 157px;
  font-size: 36px;
}
