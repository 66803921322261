.assets-components-OnboardingBar-StepInstruction-index---root---1y45F {
  --rootHeight: 68px;
  --iconSize: 24px;

  display: flex;
  align-items: center;
  min-height: var(--rootHeight);
  padding: 0 var(--midHorzGutter);
  background-color: var(--sanddollar600);
  border-radius: var(--elementBorderRadius);
  box-shadow: var(--lightBoxShadow);
}

.assets-components-OnboardingBar-StepInstruction-index---checkIcon---EiBGs {
  margin-right: var(--tinyHorzGutter);
  color: var(--kelp400);
  font-size: var(--iconSize);
}

.assets-components-OnboardingBar-StepInstruction-index---textWrapper---2ZkBz {
  display: flex;
  align-items: center;
}

.assets-components-OnboardingBar-StepInstruction-index---text---2iqFb,
.assets-components-OnboardingBar-StepInstruction-index---returnLink---wMqLC {
  font-size: var(--bodyFontSize);
  white-space: nowrap;
}

.assets-components-OnboardingBar-StepInstruction-index---returnLink---wMqLC,
.assets-components-OnboardingBar-StepInstruction-index---returnLink---wMqLC:visited {
  color: var(--inky500);
  font-weight: var(--semiBold);
  text-decoration: underline;
}

.assets-components-OnboardingBar-StepInstruction-index---returnLink---wMqLC:hover,
.assets-components-OnboardingBar-StepInstruction-index---returnLink---wMqLC:active {
  color: var(--inky400);
  text-decoration: none;
}

.assets-components-OnboardingBar-StepInstruction-index---nextStepLink---2_ryP {
  flex-grow: 1;
  text-align: right;
}

.assets-components-OnboardingBar-StepInstruction-index---nextStepLink---2_ryP[disabled] > * {
  color: var(--inky200);
}

.assets-components-OnboardingBar-StepInstruction-index---nextStepIcon---6saa2 {
  margin-right: 2px; /* Hack to prevent clipping after animation */
  font-size: var(--iconSize);
}

.assets-components-OnboardingBar-StepInstruction-index---nextStepLink---2_ryP:not([disabled]) {
  animation: assets-components-OnboardingBar-StepInstruction-index---shake---UDgho 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  backface-visibility: hidden;
  perspective: 1000px;
  transform: translate3d(0, 0, 0);
}

@keyframes assets-components-OnboardingBar-StepInstruction-index---shake---UDgho {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
