.assets-pages-AccountCancellationConfirmation-index---loading---3PWBV {
  width: 100%;
  margin-top: var(--giganticVertGutter);
  color: var(--inky300);
  font-size: 48px;
  text-align: center;
}

.assets-pages-AccountCancellationConfirmation-index---section---vfAQr {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  border: 1px solid var(--inky200);
  margin-top: calc(2 * var(--smallVertGutter));
  background-color: #fff;
  border-radius: var(--elementBorderRadius);
}
