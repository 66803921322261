.assets-components-common-CategorySelect-AddCategoryOption-index---root---q-ogf {
  display: flex;
  align-items: center;
  height: 100%;
  padding: var(--smallVertGutter) var(--smallHorzGutter);
  color: var(--kelp400);
  cursor: pointer;
  font-weight: var(--semiBold);
}

.assets-components-common-CategorySelect-AddCategoryOption-index---root---q-ogf > * {
  margin-right: var(--smallHorzGutter);
}
