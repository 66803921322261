/* Per Kevin */

.assets-pages-Payment-Testimonials-index---root---1peDU {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 350px;
  background: linear-gradient(var(--whale300), var(--kelp100));
  text-align: center;
}

.assets-pages-Payment-Testimonials-index---pager---GX-Gr {
  position: absolute;
  bottom: -10px;
  width: 100%;
  text-align: center;
}

@media (min-height: 850px) {
  .assets-pages-Payment-Testimonials-index---tentacle---1ndCF {
    position: absolute;
    bottom: 0;
    left: var(--midHorzGutter);
    display: block;
    height: 150px;
  }

  .assets-pages-Payment-Testimonials-index---wave---38JWD {
    position: absolute;
    right: var(--midHorzGutter);
    bottom: calc(150px + var(--largeVertGutter));
    display: block;
    height: 20px;
  }
}

@media (max-height: 850px) {
  .assets-pages-Payment-Testimonials-index---tentacle---1ndCF,
  .assets-pages-Payment-Testimonials-index---wave---38JWD {
    display: none;
  }
}
