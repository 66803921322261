.assets-components-common-layout-Card-index---root---17oOR {
  background-color: white;
  border-radius: var(--cardBorderRadius);
  box-shadow: 0 2px 10px -3px var(--inky150);
}

.assets-components-common-layout-Card-index---root---17oOR.assets-components-common-layout-Card-index---is-recommended---3qZmu {
  position: relative;
}

.assets-components-common-layout-Card-index---padded---vECgd {
  padding: var(--largeVertGutter) var(--largeHorzGutter);
  margin-bottom: var(--largeVertGutter);
}

.assets-components-common-layout-Card-index---is-link---s5Exv {
  cursor: pointer;
}

.assets-components-common-layout-Card-index---is-recommended---3qZmu label {
  position: absolute;
  top: 7px;
  right: 7px;
  line-height: 1.4;
  font-size: smaller;
}

.assets-components-common-layout-Card-index---root---17oOR:hover {
  box-shadow: 0 2px 19px -2px var(--inky300);
}
