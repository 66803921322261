.assets-styles-forms---baseInput---1MtwG {
  max-height: 36px;
  padding: 0 10px;
  border: solid 1px #d0d7de;
  line-height: 34px;
  background-color: #fff;
  border-radius: 3px;
  color: black;
  font-size: 15px;
}

/* 5px vert padding lines up with line-height on single row inputs */
.assets-styles-forms---textareaInput---2xkZE {
  max-height: none;
  padding: 5px 10px;
  line-height: 24px;
}

.assets-styles-forms---baseInput---1MtwG:focus {
  outline-color: #479d9d;
}

.assets-styles-forms---baseInput---1MtwG:disabled,
.assets-styles-forms---baseInputDisabled---1zg7J {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.18);
  color: rgba(0, 0, 0, 0.18);
}

.assets-styles-forms---baseInput---1MtwG input::-moz-placeholder, .assets-styles-forms---baseInput---1MtwG::-moz-placeholder {
  color: rgba(0, 0, 0, 0.32);
}

.assets-styles-forms---baseInput---1MtwG input::placeholder,
.assets-styles-forms---baseInput---1MtwG::placeholder {
  color: rgba(0, 0, 0, 0.32);
}

.assets-styles-forms---erroredInput---HbR3k {
  border-color: #dd4b6f;
}

.assets-styles-forms---erroredInput---HbR3k:focus {
  outline-color: #dd4b6f;
}

.assets-styles-forms---iconAppendedInput---2F97L {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0;
}

/* width: 100%; prevents safari from "shrinking" the icon */
.assets-styles-forms---iconAppendedInput---2F97L > input {
  width: 100%;
  height: 34px;
  padding: 0 10px;
  border-color: rgba(0, 0, 0, 0.32);
  border-style: solid;
  border-width: 0 1px 0 0;
}

.assets-styles-forms---iconAppendedInput---2F97L i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  color: rgba(0, 0, 0, 0.32);
  text-align: center;
}

.assets-styles-forms---iconAppendedInput---2F97L[data-disabled="true"] {
  border-color: rgba(0, 0, 0, 0.18);
}

.assets-styles-forms---iconAppendedInput---2F97L[data-disabled="true"] i {
  color: rgba(0, 0, 0, 0.18);
}

.assets-styles-forms---iconAppendedInput---2F97L input:disabled {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.18);
  color: rgba(0, 0, 0, 0.18);
}

*[disabled], html input[disabled] {
  cursor: not-allowed;
}
