.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareTwo-index---container---InDx9 {
  width: 480px;
  height: 239px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareTwo-index---image---2Eegi {
  width: 238px;
  height: 237px;
}

.assets-pages-Composer-Variation-PostPreview-Post-FacebookPost-Media-ImageCollage-SquareTwo-index---image---2Eegi > div {
  border-radius: 0;
}
