.assets-components-Analytics-ShortLinksTable-index---root---1UAje {
  padding-top: var(--hugeVertGutter);
  padding-bottom: var(--largeVertGutter);
}

.assets-components-Analytics-ShortLinksTable-index---clockIcon---HtxPE {
  padding-right: var(--tinyHorzGutter);
  color: var(--inky400);
  font-size: var(--bodyFontSize);
}

.assets-components-Analytics-ShortLinksTable-index---table---3jvCk {
  width: 100%;
  border: 1px solid var(--inky200);
  margin: var(--midVertGutter) 0;
}

.assets-components-Analytics-ShortLinksTable-index---row---BFmRH > td {
  padding: var(--midVertGutter) var(--smallHorzGutter);
}

.assets-components-Analytics-ShortLinksTable-index---row---BFmRH:nth-child(even) {
  background-color: var(--inky100);
}

.assets-components-Analytics-ShortLinksTable-index---value---3zHLe {
  font-size: var(--bodyFontSize);
  font-weight: var(--semiBold);
}

.assets-components-Analytics-ShortLinksTable-index---caption---1ReRO {
  font-size: var(--captionFontSize);
}

.assets-components-Analytics-ShortLinksTable-index---link---htG4R {
  color: var(--kelp500);
}

.assets-components-Analytics-ShortLinksTable-index---tableData---5_OpN:first-child {
  width: 40%;
}
